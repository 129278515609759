import React, {Fragment, useState} from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import HorizontalMenuIcon  from '../../../assets/images/icons/horizontal-menu-icon.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import DataTable from '../../../components/elements/DataTable'
import { Link } from 'react-router-dom'
import { convertCamelCase } from '../../../utils'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

export const reviews = [
    {
        id: "1",
        customer: "Christopher Kightley",
        product: "Trek Crocket 5 Disc",
        review: "Great Product",
        date: "2021-04-08",
        status: "approved",
        actions: ""
    },
    {
        id: "2",
        customer: "Bella Alubo",
        product: "Specialized Tactic III MIPS",
        review: "Perfect fit",
        date: "2021-04-07",
        status: "awaiting-approval",
        actions: ""
    },
    {
        id: "3",
        customer: "Granit Xhaka",
        product: "403 Granite V2",
        review: "Matched my needs",
        date: "2021-04-07",
        status: "awaiting-approval",
        actions: ""
    },
    {
        id: "4",
        customer: "Reuben Neves",
        product: "Trek District + 1M Gy 300Wh",
        review: "Seat a bit too small",
        date: "2020-12-10",
        status: "approved",
        actions: ""
    },
    {
        id: "5",
        customer: "Max Payne",
        product: "Rfr pedals flat salmon sal",
        review: "Great product",
        date: "2020-07-27",
        status: "removed",
        actions: ""
    },
    {
        id: "6",
        customer: "Kiss Daniel",
        product: "Rfr pedals flat salmon sal",
        review: "Tyres good grip",
        date: "2020-08-16",
        status: "approved",
        actions: ""
    },
]
const Reviews = () => {

    const tabs = [
        {
            tabId: "all",
            tabLabel: "All",
            all: 2375
        },
        {
            tabId: "awaiting-approval",
            tabLabel: "Awaiting Approval",
            count: 95
        },
        {
            tabId: "approved",
            tabLabel: "Approved",
            count: 22
        },
        {
            tabId: "removed",
            tabLabel: "Removed",
            count: 18
        }
    ]

    const [activeTab, setActiveTab] = useState(tabs[0].tabId)
    const [allReviews, setAllReviews] = useState(reviews)
    const [showTable, setShowTable] = useState(true)

    let changeActiveTab = (tab) => {
        setShowTable(false)
        if (tab.tabId === 'all') {
            setAllReviews(reviews)
        } else {
            const filtered = reviews.filter((review) => {
                return review.status === tab.tabId;
            })
            setAllReviews(filtered)
        }
        setActiveTab(tab.tabId)
        setTimeout(() => {
            setShowTable(true)
        }, 200);
    }


    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const tableOptions = {
        selectable: true
    }

    const columnWidths = {
        id: "w-full lg:w-1/12",
        customer: "w-full lg:w-2/12",
        product: "w-full lg:w-3/12",
        review: "w-full lg:w-2/12",
        date: "w-full lg:w-1/12",
        status: "w-full lg:w-2/12",
        actions: "w-full lg:w-1/12"
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

    // const getTabsDropdownItems = (startIndex) => {
    //     let items = []
    //     tabs.forEach((tab, tabIndex) => {
    //         if(tabIndex >= startIndex) {
    //             items.push({
    //                 label: `${tab.tabLabel} (${tab.count})`,
    //                 icon: null,
    //                 link: "/",
    //                 action: null,
    //                 // action: changeActiveTab(tab),
    //             })
    //         }
    //     })
    //     return items
    // }

    const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedReviewsCount(count)
    }

    const ReviewStatus = ({status}) => {
        return (
            <div className="flex flex-row items-center gap-x-2">
                <span className={`w-2 h-2 rounded-full ${status === "approved" ? "bg-green-300" : "bg-gray-300"}`} /> 
                <span className="capitalize font-sofia-pro text-xs">{status.replace(/-/g, ' ')}</span>
            </div>
        )
    }

    const ReviewLink = ({reviewId}) => {
        return (
            <Link to={`reviews/${reviewId}`} className="">{reviewId}</Link>
        )
    }


    const ActionsButton = ({menuItems}) => {
        return (
            <img alt="" src={HorizontalMenuIcon} className="w-4 h-4" />
            // <DropdownMenu 
            //         buttonIcon={HorizontalMenuIcon} 
            //         buttonType='icon' 
            //         buttonText="" 
            //         menuItems={menuItems} 
            //         iconClasses="w-3" 
            //         buttonClasses="text-xs rounded-tr-lg font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200" />
        )
    }

    const cleanUpReviews = (reviewsArray) => {
        let finalResult = [] 
        reviewsArray.forEach((review, reviewIndex) => {
            const reviewObject = {
                id: <ReviewLink reviewId={review.id} />,
                customer: review.customer,
                product: review.product,
                review: review.review,
                date: review.date,
                status: <ReviewStatus status={review.status} />,
                actions: <ActionsButton menuItems={review.actions} />
            }
            finalResult.push(reviewObject)
        })
        return finalResult
    }

    const [selected, setSelected] = useState(tabs[0].tabId)

    return (
        <Fragment>
            {/* Tabs */}
            <div className="hidden w-full lg:flex flex-row gap-x-2 border-b border-gray-300">
                {tabs.slice(0, 6).map((tab, tabIndex) =>(
                    <button 
                        key={tabIndex} 
                        className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                        onClick={()=>{changeActiveTab(tab)}}>
                        {tab.tabLabel} 
                            <span className="rounded-full px-2 py-1 bg-ink-navy text-white ml-2">
                                {tab.tabId === 'all' ? reviews.length :
                                    reviews.filter((review) => { return review.status === tab.tabId }).length}
                            </span>
                    </button>
                ))}
                {/* <DropdownMenu 
                    buttonIcon={HorizontalMenuIcon} 
                    buttonType='icon' 
                    buttonText="" 
                    menuItems={getTabsDropdownItems(6)} 
                    iconClasses="w-5" 
                    buttonClasses="text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200 bg-gray-100" /> */}
            </div>

            <div className="w-full lg:hidden flex-row items-center justify-between mt-5">
                <label className="text-gray-400 text-xs mb-2 block mt-2">Select Category</label>
                <div className="">
                    <Listbox value={selected} onChange={setSelected}>
                        <div className="relative mt-1">
                        <Listbox.Button className={`relative w-full text-left text-xs rounded border border-gray-300 text-ink-navy font-medium font-sofia-pro inline-block px-3 py-2 transition duration-200`}>
                            <span className="block truncate text-sm lg:text-xs">{selected}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                                className="w-4 h-4 text-gray-400"
                                aria-hidden="true"
                            />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {tabs.map((option, optionIndex) => (
                                <Listbox.Option
                                    key={optionIndex}
                                    className={({ active }) =>
                                        `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                            cursor-default select-none relative py-2 pl-10 pr-4`
                                    }
                                    value={option.tabLabel}
                                    
                                    >
                                    {({ selected, active }) => (
                                        <>
                                        <span
                                            className={`${
                                            selected ? 'font-medium' : 'font-normal'
                                            } block truncate text-sm`}
                                            onClick={()=>{changeActiveTab(option)}}
                                        >
                                            {option.tabLabel}
                                        </span>
                                        {selected ? (
                                            <span
                                            className={`${
                                                active ? 'text-amber-600' : 'text-amber-600'
                                            }
                                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                            >
                                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                            </Listbox.Options>
                        </Transition>
                        </div>
                    </Listbox>
                </div>
            </div>

            {/* Tab area */}
            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between pt-12">
                    <div className="flex flex-row items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search reviews" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4" /> <span className='hidden lg:inline'>Filters</span>
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-3 lg:mt-0">
                        {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Export
                        </button>
                    </div>
                </div>

                {/* Table */}

                {showTable && <DataTable 
                    tableHeaders={tableHeadersFields(reviews[0]).headers} 
                    tableData={cleanUpReviews(allReviews)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(reviews[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />}
                
            </div>
        </Fragment>

    )
}

export default Reviews
