import React, {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';
import axios from "axios"
import Spinner from '../../../elements/icons/Spinner'

import PlusIcon from '../../../elements/icons/PlusIcon';
import AutocompleteSelect from '../../../elements/AutocompleteSelect';
import CloseIcon from '../../../elements/icons/CloseIcon';
import ErrorMessage from '../../../elements/ErrorMessage';
import { baseUrl } from '../../../../utils';
import SuccessMessage from '../../../elements/SuccessMessage';


const UpdateRole = ({triggerClose, updateRole, allPermissions}) => {

    const [activeRole, setActiveRole] = useState(updateRole)

    const [newPermission, setNewPermission] = useState(null)
    const [ cookies ] = useCookies(['user'])
    const [fetching, setFetching] = useState(true)
    const [newRoleError, setNewRoleError] = useState(null)

    useEffect(() => {
      
        const getRoleDetails = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setFetching(true)
            try {
                const response = await axios.get(`/api/v1/admin/roles/${updateRole.id}`, { headers })       
                // console.log(response.data.data)     
                setActiveRole(response.data.data)
                setFetching(false)
      
            } catch (error) {
                console.log(error.response)
                setNewRoleError(error.response.data.developer_message.errors)
                setFetching(false)

            }
        }

        getRoleDetails()


    
      return () => {
        
      }
    }, [cookies.accessToken, cookies.tokenType, updateRole.id])
    


  const addPermissionToRole = () => {
    console.log('...', newPermission)
    if(!newPermission || newPermission === null) {
      return
    }
    const tempRole = { ...activeRole }
    if(!tempRole.permissions) {
      tempRole.permissions =[]
    }
    tempRole.permissions.push({id: newPermission.id})
    console.log(tempRole)
    setActiveRole(tempRole)
    setNewPermission(null)
  }

  const removePermissionFromRole = (perm) =>{
    const tempRole = { ...activeRole }

    const filtered = tempRole.permissions.filter((permission) => {
      return permission.id !== perm.id
    })

    tempRole.permissions = filtered

    setActiveRole(tempRole)

  }

  const permissionName = (id) => {
    const filtered = allPermissions.filter((permission) => {
      return permission.id === id
    })

    return filtered[0].name
  }

  const [ validationErrors, setValidationErrors ] = useState({})

  const validateForm  = () => {
      let errors = {}
      
      if(!activeRole.name || activeRole.name === '') {
          errors.name = true
      }

      if(!activeRole.permissions || activeRole.permissions.length === 0) {
          errors.permissions = true
      }

      setValidationErrors(errors)
      console.log('Validation',errors)
      return errors
  }
  const [updateSUccess, setUpdateSUccess] = useState(null)
  const [updating, setUpdating] = useState(false)
  const doUpdateRole = async () => {
    const headers = {
      'Content-Type': 'application/json', 
      'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
    }

    if (Object.values(validateForm  ()).includes(true)) {
        setNewRoleError('Please check the highlighted fields')
        return
    }
    
    const requestPayload = activeRole

    setUpdating(true)


    try {
        await axios.patch(`/api/v1/admin/roles/${activeRole.id}`, requestPayload, { headers })            

        triggerClose()
        setUpdating(false)
        setUpdateSUccess('Role has been updated successfully')

    } catch (error) {
        console.error(error)
        setNewRoleError(error.response.data.msg)
        setUpdating(false)
    }
  }

  return (
    <div className="mt-2">
        {fetching ? 
        <div className='w-full flex flex-col items-center justify-center mt-10'>
            <Spinner />
            <p className='text-sm text-gray-500 mt-5'>Fetching Roles...</p>
        </div>
        :
        <>
            <label className='block mb-2 font-sofia-pro text-ink-navy text-sm'>Name</label>
            <input 
            type="text"  
            value={activeRole.name || ''} 
            onChange={(e)=>{setActiveRole({...activeRole, ...{name: e.target.value}})}} 
            className={`w-full px-4 py-3 border text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500 mb-6 ${validationErrors && validationErrors.name === true ? 'border-red-600' : 'border-gray-300' }`} 
            placeholder="Permission name" />

            <label className='block mb-1 font-sofia-pro text-ink-navy text-sm'>Add Permissions</label>
            <label className='block mb-2 font-sofia-pro text-gray-500 text-xs'>Select a permission below and click on the + button to add the permission for this role </label>
            <div className='flex flex-row gap-x-2'>
            <AutocompleteSelect 
                placeholderText="Find a permission..." 
                selectOptions={allPermissions}
                titleField='name'
                displayImage={false}
                imageField=''
                hasError={validationErrors && validationErrors.permissions === true}
                returnFieldValue={(value) => {setNewPermission(value)}}
            />

            <button className='px-3 py-2 rounded bg-blue-100 text-ink-navy hover:bg-ink-navy hover:text-white text-xs transition duration-200' onClick={()=>{addPermissionToRole()}}>
                <PlusIcon className="w-5 h-5" />
            </button>
            </div>

            {
            activeRole.permissions && activeRole.permissions.length > 0 &&<div className='my-5'>
                <label className='block mb-2 font-sofia-pro text-ink-navy text-sm'>Permissions on this role</label>

                {activeRole.permissions.map((permission, permissionIndex) => (
                <span key={permissionIndex} className='inline rounded px-2 py-1 bg-gray-200 text-gray-500 gap-x-3 text-xs mr-2 mb-4 w-max'>
                    {permissionName(permission.id)} <button onClick={()=>{removePermissionFromRole(permission)}}><CloseIcon classes="w-4 h-4" /></button>
                </span>
                ))}    
            </div>
            }
        </>}

    <div className="mt-4 w-full flex flex-row-reverse gap-x-3 mt-24">
      
      {/* {activeBrand && activeBrand !== '' 
          ?
              <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-ink-navy transition duration-200 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={()=>{updateBrand()}}
              >
                  {/* Update Brand */}
                  {/* {updating ? <span className='flex items-center gap-x-2'><Spinner /> Updating brand </span> : 'Update brand' } */}

              {/* </button> */}
          {/* :  */}
              <button
                  type="button"
                  className="inline-flex justify-center gap-x-2 items-center px-4 py-2 text-sm font-medium text-white bg-ink-navy transition duration-200 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={()=>{doUpdateRole()}}
                  disabled={updating}
              >
                  {updating ? <span className='flex items-center gap-x-2'><Spinner /> Updating Role </span> : 'Update role' }
              </button>
      {/* } */}
      <button
        type="button"
        className="inline-flex justify-center px-4 py-2 transition duration-200 text-sm font-medium text-gray-500 bg-transparent border border-transparent rounded-md hover:bg-blue-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        onClick={()=>{triggerClose()}}
      >
        Cancel
      </button>
    </div>
    {newRoleError && newRoleError!=='' &&  
      <ErrorMessage message={newRoleError} dismissHandler={()=>{setNewRoleError('')}} />
    } 
    {updateSUccess && updateSUccess!==null &&  
      <SuccessMessage message={newRoleError} dismissHandler={()=>{setUpdateSUccess(null)}} />
    } 
</div>
  )
}

export default UpdateRole