import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import DataTable from '../../../components/elements/DataTable'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import HorizontalMenuIcon  from '../../../assets/images/icons/horizontal-menu-icon.svg'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from '../../../components/elements/icons/Spinner'

const UserManagement = () => {
    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

    // const users = [
    //     {
    //         id: "236",
    //         name: "Larkfield Park",
    //         email: "info@larkfieldcycles.co.uk",
    //         username: "larkfield",
    //         phone: "2342343435",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "232",
    //         name: "Chris Hill",
    //         email: "chris.timecycles@gmail.com",
    //         username: "timecycle",
    //         phone: "+91-33-67992222",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "230",
    //         name: "Dan Lewis",
    //         email: "dan@objective1.co.uk",
    //         username: "dlewis",
    //         phone: "020 444 555 67",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "200",
    //         name: "Dave Munday",
    //         email: "davepmunday@icloud.com",
    //         username: "dmunday",
    //         phone: "+447980901799",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "100",
    //         name: "Prosun",
    //         email: "testcustomer3@email.com",
    //         username: "Prosun",
    //         phone: "000 111 222 34",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "001",
    //         name: "John Anderson",
    //         email: "jo@pushsales.co.uk",
    //         username: "Objective1Ltd",
    //         phone: "2342343435",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "007",
    //         name: "Keith Southam",
    //         email: "ks@pushsales.co.uk",
    //         username: "keithsouth",
    //         phone: "+91-33-67992222",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "002",
    //         name: "Julia Jefferson",
    //         email: "jj@pushsales.co.uk",
    //         username: "julfferson",
    //         phone: "020 444 5556",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "120",
    //         name: "Podolski Almfamitano",
    //         email: "chris.timecycle@gmail.com",
    //         username: "Podolski",
    //         phone: "002 333 4445",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "003",
    //         name: "Tess Customer",
    //         email: "testcustomer3@gmail.com",
    //         username: "Tess",
    //         phone: "001 222 3334",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "256",
    //         name: "Boris Becker",
    //         email: "testcustomer3@gmail.com",
    //         username: "BorisB",
    //         phone: "001 222 3334",
    //         status: "active",
    //         actions: []
    //     },
    //     {
    //         id: "278",
    //         name: "Objective 1 Ltd",
    //         email: "andy@objective1.co.uk",
    //         username: "Avon",
    //         phone: "001 222 3334",
    //         status: "active",
    //         actions: []
    //     },
    // ]

    const [ cookies ] = useCookies(['user'])
    const history = useHistory();
    const location = useLocation()
    const [users, setUsers] = useState([])

    const [processing, setProcessing] = useState(true)
    const [ error, setError ] = useState('')
    const [ pagination, setPagination ] = useState({ 
        page: 1, 
        perPage: 25, 
        totalRecords: 0,
        nextPage: '',
        lastPage: '',
        previousPage: '',
        firstPage: ''
    })

    useEffect(() => {
        const fetchUsers = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try { 
                let response = null 

                response = await axios.get(`/api/v1/admin/users/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc`, { headers })            
                setUsers(response.data.data)

                setProcessing(false)
            } catch (error) {
                console.error(error)
                if(error.response.data.developer_message.exception === "AuthenticationException") {
                    window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
                } else {
                    setError(error.response.data.developer_message.errors)
                    setProcessing(false)
                }
            }
        }

        fetchUsers()
    
    }, [cookies.accessToken, cookies.tokenType, history, location.pathname, pagination.page, pagination.perPage])
    

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const convertCamelCase = (camelCaseText) => {
        const text = camelCaseText;
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult
    }

    const tableOptions = {
        // selectable: true
    }

    const columnWidths = {
        id: "w-full lg:w-1/12",
        name: "w-full lg:w-3/12",
        email: "w-full lg:w-3/12",
        username: "w-full lg:w-1/12",
        phone: "w-full lg:w-2/12",
        status: "w-full lg:w-1/12",
        actions: "w-full lg:w-1/12"
    }

    const columnDataStyles = {
        // status: {
        //     isConditional: true,
        //     conditionals: {
        //         active: "px-3 py-1 capitalize rounded-full bg-green-100 text-green-400",
        //         inactive: "px-3 py-1 capitalize rounded-full bg-gray-200 text-gray-400"
        //     }
        // }
    }

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key

            if(key === 'id') {
                columnDisplayName = '#ID'
                columnDataType = 'JSX'
            }
            
            if(key === 'actions') {
                sortable = false
                columnDataType = 'JSX'
            }

            if(key === 'status') {
                columnDataType = 'JSX'
            }

            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });
        return {headers, fields}
    }

    const UserStatus = ({userStatus}) => {
        return (
            <div className="flex flex-row items-center gap-x-2">
                <span className={`w-1 h-1 rounded-full ${userStatus === "active" ? "bg-green-300" : "bg-gray-300"}`} /> <span className="capitalize font-sofia-pro text-xs">{userStatus}</span>
            </div>
        )
    }

    const ActionsButton = ({menuItems}) => {
        return (
            <img alt="" src={HorizontalMenuIcon} className="w-4 h-4" />
            // <DropdownMenu 
            //         buttonIcon={HorizontalMenuIcon} 
            //         buttonType='icon' 
            //         buttonText="" 
            //         menuItems={menuItems} 
            //         iconClasses="w-3" 
            //         buttonClasses="text-xs rounded-tr-lg font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200" />
        )
    }

    const [selectedUsersCount, setSelectedUsersCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedUsersCount(count)
    }

    const IdLink = ({id}) => {
        return  (
            <Link to={`user-management/${id}`}>{id}</Link>
        )
    }

    const cleanUpUsers = (users) => {
        let finalResult = [] 
        users.forEach((user, userIndex) => {
            let userObject = {
                id: <IdLink id={user.id}/>,
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                username: '',
                phone: '',
                status: <UserStatus userStatus='active' />,
                actions: <ActionsButton menuItems={user.actions} />
            }
            finalResult.push(userObject)
        })        
        return finalResult
    }

    return (
        <div className="p-10">
           <div className="w-full block md:flex flex-row justify-between">
                <div className="flex flex-row gap-x-4 items-center w-full md:w-3/5">
                    <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full md:w-9/12">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm mt-1">
                                <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                            </span>
                        </div>
                        <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search" />
                    </div>
                    <button className="rounded border-gray-200 py-4 md:py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                        <img src={FilterIcon} alt="search" className="w-4" /> <span className='ml-2 hidden md:inline py-2'>Filters</span>
                    </button>
                </div>
                <div className="flex flex-row gap-x-2 mt-3 md:mt-0">
                    {selectedUsersCount > 1 && 
                        <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                    }
                    <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                        <img src={ExportIcon} alt="search" className="w-4 mr-2 transform -rotate-90" /> Export
                    </button>
                    <Link to="user-management/new-user" className="rounded border-blue-700 hover:bg-blue-900 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>
                        Invite a new user
                    </Link>
                </div>
            </div>

            {/* Tab area */}
            <div className="w-full">
            {!processing && error === '' ?
                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpUsers(users)[0]).headers} 
                    tableData={cleanUpUsers(users)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpUsers(users)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                    pagination={pagination}
                    paginate={setPagination}
                />   
                :
                    <div className='w-full flex flex-col items-center justify-center mt-10'>
                        <Spinner />
                        <p className='text-sm text-gray-500 mt-5'>Fetching users...</p>
                    </div>
                }                
            </div>
        </div>
    )
}

export default UserManagement
