import React from 'react'
import Layout from '../../components/layout/Layout'
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
} from "react-router-dom";
import AllSupportTickets from './support/AllSupportTickets';
import SupportTicket from './support/SupportTicket';
import NewSupportTicket from './support/NewSupportTicket';

const Support = () => {
    let { 
        path, 
        // url 
    } = useRouteMatch();
    const sidebarLinks = [
        {
            label: "Support Tickets",
            location: "/admin/support",
            subLinks: [
                {
                    label: "All tickets",
                    location: "/admin/support/all-tickets"
                },
                {
                    label: "Unassigned",
                    location: "/admin/support/unassigned"
                },
                {
                    label: "Assigned to me",
                    location: "/admin/support/my-tickets"
                },
            ]
        },
        {
            label: "Bike fit requests",
            location: "/admin/settings/admin-settings",
            subLinks: []
        },
        {
            label: "Special requests",
            location: "/admin/settings/user-management",
            subLinks: []
        },
        {
            label: "Notes",
            location: "/admin/settings/roles-permissions",
            subLinks: []
        },
        {
            label: "FAQs",
            location: "/admin/settings/roles-permissions",
            subLinks: []
        }
    ]
    return (
        <Layout pageTitle="Support" sidebarLinks={sidebarLinks} noPadding={true}>
             <Switch>
                <Route
                    path={path}
                    exact
                    render={() => {
                        return (
                        <Redirect to="/admin/support/all-tickets" />
                        )
                    }}
                />

                <Route path={`${path}/all-tickets`} exact>
                    <AllSupportTickets />
                </Route>
                <Route path={`${path}/ticket/:id`} exact>
                    <SupportTicket />
                </Route>
                <Route path={`${path}/create-ticket`} exact>
                    <NewSupportTicket />
                </Route>
            </Switch>
        </Layout>
    )
}

export default Support