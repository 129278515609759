import React from 'react'
import EditIcon from '../../../assets/images/icons/edit.svg'
import UserIcon from '../../../assets/images/icons/user-icon.svg'

const ContactDetails = ({details}) => {
    // const customerImage = "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"

    // const customerDetails = {
    //     firstName: "Mike",
    //     lastName: "Lewis",
    //     email: "mikelewisvi@gmail.com",
    //     phone: "07498838113",
    //     passwordPlaceholder: "iweuryt489853"
    // }
    return (
        <div className="w-full">
            <div className="w-1/5">
                {/* <img alt="" src="" /> */}
                <div className="w-32 rounded-full h-32 border border-gray-200 relative" style={{ backgroundImage: `url("${UserIcon}")`, backgroundSize: 'cover'}}>
                    <button className="border border-gray-300 rounded-full p-2 absolute right-0 top-0 hover:border-gray-500 transition duration-200">
                        <img src={EditIcon} alt="" className="w-4 opacity-50 hover:opacity-90 transition duration-200 block" />
                    </button>
                </div>
            </div>

            <h3 className="mt-6 text-ink-navy font-medium text-md">Personal Information</h3>

            <div className="w-full flex flex-row gap-x-6 mt-6 mb-6">
                <div className="w-1/2">
                    <label className="block text-gray-400 text-xs font-sofia-pro mb-2">First Name</label>
                    <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={details.firstName} />
                </div>
                <div className="w-1/2">
                    <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Last Name</label>
                    <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={details.lastName} />
                </div>
            </div>
            <div className="w-full flex flex-row gap-x-6">
                <div className="w-1/2">
                    <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Email</label>
                    <input type="email" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={details.email} />
                </div>
                <div className="w-1/2">
                    <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Phone</label>
                    <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={details.phone} />
                </div>
            </div>

            <h3 className="text-ink-navy font-medium text-md mb-3 mt-6">Password Reset</h3>

            <div className="w-full flex flex-row gap-x-6">
                <div className="w-1/2">
                    <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Email</label>
                    <input type="email" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={details.email} />
                </div>
                <div className="w-1/2">
                    <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Phone</label>
                    <input type="password" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={details.passwordPlaceholder} />
                </div>
            </div>

            <div className="flex flex-row items-center mt-6 gap-x-4">
                <button className="px-4 py-2 border border-ink-navy text-ink-navy font-medium rounded text-xs">Reset Password</button>
                <p className="text-gray-600 text-xs"><button className="text-blue-700 font-medium">Passwor reset link</button> will be sent to the user's email</p>
            </div>
        </div>
    )
}

export default ContactDetails
