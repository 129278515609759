import React, { useState } from 'react'

const BookingsIcons = () => {
    const [color, setColor] = useState("#B8C0D5")

    return (
        <svg onMouseEnter={()=>{setColor("#ffffff")}} onMouseLeave={()=>{setColor("#B8C0D5")}} id="weekly-calendar-page-symbol" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18.876 18.876">
            <path id="Path_20" data-name="Path 20" d="M16.989,0H1.888A1.894,1.894,0,0,0,0,1.888v15.1a1.894,1.894,0,0,0,1.888,1.888h15.1a1.894,1.894,0,0,0,1.888-1.888V1.888A1.894,1.894,0,0,0,16.989,0ZM5.094,16.612H2.2V14.04H5.094v2.572Zm0-3.435H2.2V10.607H5.094v2.571ZM4.876,2.861a.8.8,0,1,1,.8-.8A.8.8,0,0,1,4.876,2.861ZM8.953,16.612H6.062V14.04H8.953Zm0-3.435H6.062V10.607H8.953Zm0-3.432H6.062V7.174H8.953ZM8.637,2.059a.8.8,0,1,1,.8.8A.8.8,0,0,1,8.637,2.059Zm4.177,11.118H9.923V10.607h2.892v2.571Zm0-3.432H9.923V7.174h2.892V9.745ZM13.2,2.059a.8.8,0,1,1,.8.8A.8.8,0,0,1,13.2,2.059Zm3.475,11.118H13.782V10.607h2.892Zm0-3.432H13.782V7.174h2.892Z" fill={color}/>
        </svg>
    )
}

export default BookingsIcons
