import React, { useState, useRef, useEffect } from 'react'
import Wysiwyg from '../../../../components/elements/Wysiwyg'
import { FileDrop } from 'react-file-drop'
import { isValidObject, slugify } from '../../../../utils'
import Spinner from '../../../../components/elements/icons/Spinner';
import axios from "axios"
import { baseUrl } from '../../../../utils';
import ErrorMessage from '../../../../components/elements/ErrorMessage'
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom'
import CodeIcon from '../../../../components/elements/icons/CodeIcon';
import MobilePhoneIcon from '../../../../components/elements/icons/MobilePhoneIcon';
import HorizontalMenuAction from '../../../../components/elements/icons/HorizontalMenuAction';
import ChevronIcon from '../../../../components/elements/icons/ChevronIcon'
import UserIcon from '../../../../components/elements/icons/UserIcon';
import EditIcon from '../../../../components/elements/icons/EditIcon';

const NewPage = () => {
    const fileInputRef = useRef(null);

    const onFileInputChange = (event) => {
        const { files } = event.target;
        // do something with your files...
        handleFile(files)
    }
    
    // const [ pageContent, setPageContent ] = useState({})
    const [ pageTitle, setPageTitle ] = useState('')
    const [ content, setContent ] = useState('')
    const [ metaTitle, setMetaTitle ] = useState('')
    const [ metaDescription, setMetaDescription ] = useState('')
    const [ coverImage, setCoverImage ] = useState('')
    
    // const [ pageSlug, setPageSlug ] = useState('')
    // const pageSlug = useRef()

    const { pageSlug } = useParams()

    const [ fileDetails, setFileDetails ] = useState(null)
    const [ processing, setProcessing ] = useState(false)
    const [ cookies ] = useCookies(['user']);
    const [error, setError] = useState('')  

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleFile = (files) => {
        setFileDetails({
            name: files[0].name,
            size: `${Math.ceil(files[0].size/1000000).toString()} MB` ,
            type: files[0].type
        })
        let base64Image = '';

        getBase64(files[0], (result) => {
            base64Image = result;
            setCoverImage(base64Image)
        });
    }

    const loadWysiwygValue = (value) => {
        setContent(value)
    }

    const [ validationErrors, setValidationErrors ] = useState({})

    const validatePageForm  = () => {
        let errors = {}
        
        if(!pageTitle || pageTitle === '') {
            errors.pageTitle = true
        }

        if(!pageSlug || pageSlug === '') {
            errors.pageSlug = true
        }
        
        if(!content || content === '') {
            errors.content = true
        }

        setValidationErrors(errors)
        return errors
    }

    const updatePage = async () => {
        const headers = {
            'Content-Type': 'application/json', 
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        if (Object.values(validatePageForm  ()).includes(true)) {
            setError('Please check the highlighted fields')
            return
        }
        
        const requestPayload = {
            title: pageTitle,
            title_image_url_data: coverImage,
            slug: pageSlug,
            content: content,
            seo_meta_description : metaDescription,
            seo_meta_title: metaTitle
        }

        console.log(requestPayload)

        setProcessing(true)

        try {
            const response = await axios.post(`/api/v1/admin/pages`, requestPayload, { headers })            
            console.log(response)

            setProcessing(false)
        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setProcessing(false)
        }
    }

    // const updatePageTitle = (title) => {
    //     setPageTitle(title)
    //     setPageSlug(slugify(title))
    // }

    const [ pages, setPages ] = useState([])

    useEffect(() => {
        const fetchPages = async  () => {
            const headers = {
                'Content-Type': 'application/json'
            }
            setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/pages/?page[offset]=1&page[limit]=1000000&page[order]=desc`, { headers })            
                console.log(response)
                setPages(response.data.data)
                setProcessing(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }

        fetchPages()

    }, [pageSlug])

    
    const [activeContentPreview, setActiveContentPreview] = useState(null)
    const [pageSettingsOpen, setPageSettingsOpen] = useState(true)
    const [imageSettiongsOpen, setImageSettiongsOpen] = useState(true)
    const [revisionsOpen, setRevisionsOpen] = useState(true)

    return (
        <div className='block lg:flex flex-row gap-x-6'>
            <div className='w-full lg:w-8/12'>
                <div className="w-full">
                    <label className={`text-xs text-sm block mb-2 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>Page Title</label>
                    <input type="text" className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="About Cycle Concept" 
                    // onChange={(e)=>{updatePageTitle(e.target.value)}} 
                    />
                </div> 
                <div className="w-full mt-4">
                    <label className={`text-xs text-sm block mb-2 ${validationErrors.pageSlug && validationErrors.pageSlug === true ? 'text-red-500' : 'text-ink-navy'}`}>Page Slug</label>
                    <input 
                        type="text" 
                        className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.pageSlug && validationErrors.pageSlug === true ? 'border-red-500' : 'border-gray-0'} `} 
                        placeholder="about_cycle_concept" 
                        // value={pageSlug}
                        // onChange={(e)=>{setPageSlug(e.target.value)}}
                        />
                </div>

                <div className='w-full border border-gray-200 mt-8' style={{minHeight: '500px'}}>
                        <div className='w-full px-4 p-3 bg-gray-100 text-sm'>
                            <p className='text-ink-navy text-sm'>Content</p>
                        </div>

                        <div className='p-6'>
                            <div className='flex flex-row items-center gap-x-4'>
                                <button className='rounded-full p-2 bg-gray-100 transition duration-200 hover:bg-gray-200'>
                                    <MobilePhoneIcon className="h-6 w-6 text-gray-400" />
                                </button>
                                <button className='rounded-full p-2 bg-gray-100 transition duration-200 hover:bg-gray-200'>
                                    <CodeIcon className="h-5 w-5 text-gray-400" />
                                </button>
                                <button className='rounded-full p-2 bg-gray-100 transition duration-200 hover:bg-gray-200'>
                                    <HorizontalMenuAction className="h-5 w-5 text-gray-400" />
                                </button>
                            </div>
                        </div>

                        <div className='px-6 long-text borderless'>
                            <Wysiwyg 
                                fieldTitle=""
                                updateValue={loadWysiwygValue}
                            />
                        </div>

                    </div>
                {error && error!=='' &&  
                    <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                } 
            </div>
            <div className='w-full lg:w-4/12'>
                <div className='w-full flex flex-row-reverse'>
                    <button className='px-6 text-ink-navy bg-blue-100 mb-6 py-4 text-center rounded text-xs font-medium transition duration-200 hover:bg-blue-200 flex items-center gap-x-2' onClick={()=>{}}>
                        <span>Preview Page</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transform -rotate-45 text-ink-navy" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </button>
                </div>
                <div className=' border border-gray-200'>
                    <div className='p-4 bg-gray-100 flex items-center justify-between'>
                        <h3 className='text-ink-navy text-sm font-medium'>Page Settings</h3>
                        <button onClick={()=>{setPageSettingsOpen(!pageSettingsOpen)}} >
                            <ChevronIcon className={`text-ink-navy w-4 h-4 transition duration-200 transform ${pageSettingsOpen ? 'rotate-180' : ''}`} />
                        </button>
                    </div>

                    {pageSettingsOpen &&
                        <div className='p-6'>
                            {/* <div className="w-full my-5">
                                <div className='w-full flex items-center justify-between'>
                                    <label className="text-xs text-gray-500 text-sm block mb-2">Display Date</label>
                                    <p className="text-xs text-ink-navy text-sm block mb-2">2021-03-22</p>
                                </div>
                            </div> */}

                             {pages && pages.length > 0 && <div className="w-full my-5">
                                <label className="text-xs text-gray-500 text-sm block mb-2">Parent Page</label>
                                <div type="text" className="select-wrapper border rounded border-gray-400 px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="About Cycle Concept" >
                                    <select 
                                        className="w-full outline-none"
                                        // onChange={(e)=>{setMetaDescription(e.target.value)}}
                                    >
                                        <option>Select parent page</option>
                                        {pages.map((page, pageIndex) => (
                                            <option key={pageIndex} value={page.id}>{page.title}</option>
                                        ))}
                                    </select>
                                </div>
                            </div> }

                            <div className="w-full my-5">
                                <label className="text-xs text-gray-500 text-sm block mb-2">Publish Date</label>
                                <input type="date" className="border rounded border-gray-400 px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="About Cycle Concept" onChange={()=>{}} />
                            </div>

                            <div className="w-full my-5">
                                <label className="text-xs text-gray-500 text-sm block mb-2">Visit Page</label>
                                <a href={`https://cycleconcept.co.uk/pages/${pageSlug}`} rel="noreferrer" target="_blank" className="text-sm text-blue-500 text-sm block mb-2">https://cycleconcept.co.uk/pages/${pageSlug}</a>
                            </div> 

                            <div className="w-full my-5">
                                <label className="text-xs text-gray-500 text-sm block mb-2">SEO Meta Title</label>
                                <input type="text" className="border rounded border-gray-400 px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="About Cycle Concept" onChange={(e)=>{setMetaTitle(e.target.value)}} />
                            </div> 

                            <div className="w-full my-5">
                                <label className="text-xs text-gray-500 text-sm block mb-2">SEO Meta Description</label>
                                <input type="text" className="border rounded border-gray-400 px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="About Cycle Concept" onChange={(e)=>{setMetaDescription(e.target.value)}} />
                            </div> 
                            
                           

                        </div>
                    }

                    <div className='w-full flex gap-x-3 items-center p-6'>
                        {/* <button className='w-full py-4 text-center bg-gray-200 rounded text-gray-700 text-xs font-medium transition duration-200 hover:bg-gray-400 hover:text-white'>Save Draft</button> */}
                        {processing ?
                            <button className='w-full py-4 text-center bg-ink-navy rounded text-white text-xs font-medium transition duration-200 hover:bg-gray-600'>
                                Creating page... 
                            </button>
                            :
                            <button className='w-full py-4 text-center bg-ink-navy rounded text-white text-xs font-medium transition duration-200 hover:bg-gray-600' onClick={()=>{updatePage()}}>Update page</button>
                        }
                    </div>

                    <div className='p-4 bg-gray-100 flex items-center justify-between'>
                        <h3 className='text-ink-navy text-sm font-medium'>Image Settings</h3>
                        <button onClick={()=>{setImageSettiongsOpen(!imageSettiongsOpen)}} >
                            <ChevronIcon className={`text-ink-navy w-4 h-4 transition duration-200 transform ${imageSettiongsOpen ? 'rotate-180' : ''}`} />
                        </button>
                    </div>

                    {imageSettiongsOpen && <div className='w-full p-6'>
                        <label className="text-xs text-ink-navy text-sm block mb-2">Cover Image</label>
                        <input
                            onChange={onFileInputChange}
                            ref={fileInputRef}
                            type="file"
                            className="hidden"
                            />
                        <FileDrop
                            onTargetClick={onTargetClick}
                            onFrameDragEnter={(event) => {}}
                            onFrameDragLeave={(event) => {}}
                            onFrameDrop={(event) => {}}
                            onDragOver={(event) => {}}
                            onDragLeave={(event) => {}}
                            onDrop={(files, event) => handleFile(files, event)}
                        >
                            <div className="flex flex-col items-center justify-center gap-y-3">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <p className='text-gray-400 text-xs'>Drop an image</p>
                            </div>
                        </FileDrop>

                        {isValidObject(fileDetails) && 
                            <div className='w-full mt-3 mb-5 bg-gray-100 rounded p-5 flex flex-row gap-x-2'>
                                <div className='w-2/5'>
                                    <img src={coverImage} alt="" />
                                </div>
                                <div>
                                    <p className='text-sm text-gray-600 mb-1'>{fileDetails.name}</p>
                                    <p className='text-xs text-gray-600'>{fileDetails.size} - <span className='text-gray-400'>{fileDetails.type}</span></p>
                                </div>
                            </div>
                        }

                        <div className='flex items-center justify-between'>
                            <button className='px-4 py-2 rounded border border-ink-navy text-ink-navy bg-transparent text-xs'>
                                Replace Image
                            </button>
                            <button className='text-xs text-red-600 py-3'>Remove Image</button>
                        </div>
                    </div>}

                    <div className='p-4 bg-gray-100 flex items-center justify-between'>
                        <h3 className='text-ink-navy text-sm font-medium'>Manage Sliding Header Images</h3>
                        <button onClick={()=>{setImageSettiongsOpen(!imageSettiongsOpen)}} >
                            <ChevronIcon className={`text-ink-navy w-4 h-4 transition duration-200 transform ${imageSettiongsOpen ? 'rotate-180' : ''}`} />
                        </button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default NewPage
