import React, { useState } from 'react'
import { Switch } from '@headlessui/react'
import Wysiwyg from '../../elements/Wysiwyg'

const Communication = () => {
    const [onContactPage, setOnContactPage] = useState(true)
    const [onProductPage, setOnProdutPage] = useState(true)
    const [onArticlePage, setOnArticlePage] = useState(false)
    const [sendEmailCopy, setSendEmailCopy] = useState(true)

    const [emailBackgroundColour, setEmailBackgroundColour] = useState('#999999')
    const [emailBackgroundTextColour, setEmailBackgroundTextColour] = useState('#ffffff')
    const [emailMessageBoxBackgroundColour, setEmailMessageBoxBackgroundColour] = useState("#fdfdfd")
    const [emailMessageBoxTextColour, setEmailMessageBoxTextColour] = useState("#333333")
    const [emailMessageContrastBackgroundColour, setEmailMessageContrastBackgroundColour] = useState('#ff3d20')
    const [emailMessageContrastTextColour, setEmailMessageContrastTextColour] = useState("#ffffff")
    const [emailDividerColour, setEmailDividerColour] = useState("#fdfdfd")
    const [sendOrderProcessingPreview, setSendOrderProcessingPreview] = useState(true)
    const [sendOrderConfirmationPreview, setSendOrderConfirmationPreview] = useState(false)
    const [sendOrderDispatchedPreview, setSendOrderDispatchedPreview] = useState(false)
    const [sendOrderReadyPreview, setSendOrderReadyPreview] = useState(true)
    const [sendProductInStockPreview, setSendProductInStockPreview] = useState(true)

    const [sendPreview, setSendPreview] = useState(false)
    const [sendReviewPreview, setSendReviewPreview] = useState(false)

    const contactFormInitialValue = "<h1><bold>Need help with your order?</bold></h1><p>For help with your order or any order enquiry, you can call us on 01275 770 705 - DEMO WEBSITE or use the email form below</p>"
    const [contactFormContent, setContactFormContent] = useState(contactFormInitialValue)
    const privacyNoticeInitialValue = "<p>For a full listing of our privacy policy, <a href='#'>click here</a></p>"

    const previewStyles = {
        backgroundColor: "#ffffff",
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 8 8'%3E%3Cg fill='%23c1c1c1' fill-opacity='0.15'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`
    }

    return (
        <div className="w-full pb-10">
            <h3 className="font-medium text-ink-navy mb-7 text-lg">Contact / Communication</h3>
            <div className="w-full lg:w-2/5">
                <div className="w-full flex justify-between my-4">
                    <label className="text-sm text-gray-500 font-sofia-pro">Contact page</label>
                    <Switch
                        checked={onContactPage}
                        onChange={setOnContactPage}
                        className={`${
                            onContactPage ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                onContactPage ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
                <div className="w-full flex justify-between my-4">
                    <label className="text-sm text-ink-navy font-sofia-pro">Product page (Q&A)</label>
                    <Switch
                        checked={onProductPage}
                        onChange={setOnProdutPage}
                        className={`${
                            onProductPage ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                onProductPage ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
                <div className="w-full flex justify-between my-4">
                    <label className="text-sm text-ink-navy font-sofia-pro">Article comments</label>
                    <Switch
                        checked={onArticlePage}
                        onChange={setOnArticlePage}
                        className={`${
                            onArticlePage ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on article page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                onArticlePage ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
            </div>

            <div className="w-full py-4">
                <Wysiwyg fieldTitle="Contact us (Top page content)" initialValue={contactFormContent} updateValue={()=>{setContactFormContent()}} />
                <div className="flex flex-row justify-between mt-4">
                    <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Appears on top of the contact us page</label>
                    {/* <button className="text-xs text-gray-400 bg-gray-300 bg-opacity-40 border rounded border-gray-400 px-3">Preview</button> */}
                </div>
            </div>

            <div className="block md:flex flex-row gap-x-4 mb-4 w-full py-8 border-b border-gray-300">
                <div className="w-full mb-3">
                    <label className="text-xs text-ink-navy block mb-3">Email Notifications</label>
                    <input type="email" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Cycle Concept" />
                    <label className="text-xs text-gray-400 mb-3">Send a copy of all system emails to this address <input type="checkbox" checked={sendEmailCopy} onChange={setSendEmailCopy} className="mt-3 ml-3" /></label>
                </div>
                <div className="w-full mb-3">
                    <label className="text-xs text-ink-navy block mb-3">From Email</label>
                    <input type="email" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="PushRetail Demo Store" />
                </div>
            </div>

            <div className="block lg:flex flex-row gap-x-8 mb-4 w-full py-8">
                <div className="w-full lg:w-1/3">
                    <label className="text-xs text-ink-navy block mb-3">Email Background Color</label>
                    <div className="flex flex-row">
                        <input type="color" className="border rounded-l-md bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailBackgroundColour} onChange={(e)=>{setEmailBackgroundColour(e.target.value)}} />
                        <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailBackgroundColour} readOnly />
                    </div>
                   
                    <label className="text-xs text-ink-navy block mt-4 mb-3">Email Background Text Color</label>
                    <div className="flex flex-row">
                        <input type="color" className="border rounded-l-md bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailBackgroundTextColour} onChange={(e)=>{setEmailBackgroundTextColour(e.target.value)}} />
                        <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailBackgroundTextColour} readOnly />
                    </div>
                   
                    <label className="text-xs text-ink-navy block mt-4 mb-3">Email Message Box Background Color</label>
                    <div className="flex flex-row">
                        <input type="color" className="border rounded-l-md bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailMessageBoxBackgroundColour} onChange={(e)=>{setEmailMessageBoxBackgroundColour(e.target.value)}} />
                        <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailMessageBoxBackgroundColour} readOnly />
                    </div>
                   
                    <label className="text-xs text-ink-navy block mt-4 mb-3">Email Message Box Text Color</label>
                    <div className="flex flex-row">
                        <input type="color" className="border rounded-l-md bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailMessageBoxTextColour} onChange={(e)=>{setEmailMessageBoxTextColour(e.target.value)}} />
                        <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailMessageBoxTextColour} readOnly />
                    </div>
                   
                    <label className="text-xs text-ink-navy block mt-4 mb-3">Email Message Contrast Background Color</label>
                    <div className="flex flex-row">
                        <input type="color" className="border rounded-l-md bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailMessageContrastBackgroundColour} onChange={(e)=>{setEmailMessageContrastBackgroundColour(e.target.value)}} />
                        <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailMessageContrastBackgroundColour} readOnly />
                    </div>
                   
                    <label className="text-xs text-ink-navy block mt-4 mb-3">Email Message Contrast Text Color</label>
                    <div className="flex flex-row">
                        <input type="color" className="border rounded-l-md bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailMessageContrastTextColour} onChange={(e)=>{setEmailMessageContrastTextColour(e.target.value)}} />
                        <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailMessageContrastTextColour} readOnly />
                    </div>

                    <label className="text-xs text-ink-navy block mt-4 mb-3">Email Divider Color</label>
                    <div className="flex flex-row">
                        <input type="color" className="border rounded-l-md bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailDividerColour} onChange={(e)=>{setEmailDividerColour(e.target.value)}} />
                        <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={emailDividerColour} readOnly />
                    </div>
                </div>
                
                <div className="w-full lg:w-2/3  h-screen lg:h-screen/2">
                    <p className="w-full border border-gray-500 p-3 bg-gray-100 text-xs text-gray-500 mt-5">Preview</p>
                    <div className="border-dashed border border-gray-200 min-h-full p-4" style={previewStyles}>
                        {/*  */}
                    </div>
                </div>
            </div>

            <div className="w-full py-4 mt-8 pb-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Email Disclaimer / Privacy Notice" initialValue={privacyNoticeInitialValue} updateValue={()=>{}} />
            </div>

            <div className="w-full py-4 mt-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Newsletter Subscribe (Double Opt-in)" initialValue="" updateValue={()=>{}} />
                <div className="w-full flex gap-x-4 my-4">
                    <label className="text-sm text-gray-500 font-sofia-pro">Send a preview to <a href="mailTo:admin@objective1.co.uk">admin@objective1.co.uk</a></label>
                    <Switch
                        checked={sendPreview}
                        onChange={setSendPreview}
                        className={`${
                            sendPreview ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                sendPreview ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
            </div>

            <div className="w-full py-4 mt-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Review Request Email" initialValue="" updateValue={()=>{}} />
                <div className="w-full flex gap-x-4 my-4">
                    <label className="text-sm text-gray-500 font-sofia-pro">Send a preview to <a href="mailTo:admin@objective1.co.uk">admin@objective1.co.uk</a></label>
                    <Switch
                        checked={sendReviewPreview}
                        onChange={setSendReviewPreview}
                        className={`${
                            sendReviewPreview ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                sendReviewPreview ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
                <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Order details are appended to this email body.<br />
                    &#123;STORE&#125; = Store Name (click and collect store)<br />
                    &#123;ORDER_ID&#125; = Order ID<br />
                    &#123;FIRST_NAME&#125; = First Name
                </label>
            </div>

            <div className="w-full py-4 mt-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Order Processing Email" initialValue="" updateValue={()=>{}}/>
                <div className="w-full flex gap-x-4 my-4">
                    <label className="text-sm text-gray-500 font-sofia-pro">Send a preview to <a href="mailTo:admin@objective1.co.uk">admin@objective1.co.uk</a></label>
                    <Switch
                        checked={sendOrderProcessingPreview}
                        onChange={setSendOrderProcessingPreview}
                        className={`${
                            sendOrderProcessingPreview ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                sendOrderProcessingPreview ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
                <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Order details are appended to this email body.<br /> 
                    &#123;STORE&#125; = Store Name (click and collect store)<br />
                    &#123;ORDER_ID&#125; = Order ID<br />
                    &#123;FIRST_NAME&#125; = First Name
                </label>
            </div>

            <div className="w-full py-4 mt-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Order Confirmation Email" initialValue=""updateValue={()=>{}} />
                <div className="w-full flex gap-x-4 my-4">
                    <label className="text-sm text-gray-500 font-sofia-pro">Send a preview to <a href="mailTo:admin@objective1.co.uk">admin@objective1.co.uk</a></label>
                    <Switch
                        checked={sendOrderConfirmationPreview}
                        onChange={setSendOrderConfirmationPreview}
                        className={`${
                            sendOrderConfirmationPreview ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                sendOrderConfirmationPreview ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
                <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Order details are appended to this email body.<br /> 
                    &#123;STORE&#125; = Store Name (click and collect store)<br />
                    &#123;ORDER_ID&#125; = Order ID<br />
                    &#123;FIRST_NAME&#125; = First Name
                </label>
            </div>

            <div className="w-full py-4 mt-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Order Dispatched Email (Posted)" initialValue="" updateValue={()=>{}} />
                <div className="w-full flex gap-x-4 my-4">
                    <label className="text-sm text-gray-500 font-sofia-pro">Send a preview to <a href="mailTo:admin@objective1.co.uk">admin@objective1.co.uk</a></label>
                    <Switch
                        checked={sendOrderDispatchedPreview}
                        onChange={setSendOrderDispatchedPreview}
                        className={`${
                            sendOrderDispatchedPreview ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                sendOrderDispatchedPreview ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
                <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Order details are appended to this email body.<br /> 
                    &#123;STORE&#125; = Store Name (click and collect store)<br />
                    &#123;ORDER_ID&#125; = Order ID<br />
                    &#123;FIRST_NAME&#125; = First Name
                </label>
            </div>

            <div className="w-full py-4 mt-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Order Ready for Collection (Click & Collect)" initialValue="" updateValue={()=>{}} />
                <div className="w-full flex gap-x-4 my-4">
                    <label className="text-sm text-gray-500 font-sofia-pro">Send a preview to <a href="mailTo:admin@objective1.co.uk">admin@objective1.co.uk</a></label>
                    <Switch
                        checked={sendOrderReadyPreview}
                        onChange={setSendOrderReadyPreview}
                        className={`${
                            sendOrderReadyPreview ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                sendOrderReadyPreview ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
                <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Order details are appended to this email body.<br /> 
                    &#123;STORE&#125; = Store Name (click and collect store)<br />
                    &#123;ORDER_ID&#125; = Order ID<br />
                    &#123;FIRST_NAME&#125; = First Name
                </label>
            </div>

            <div className="w-full py-4 mt-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Product in Stock email" initialValue="" updateValue={()=>{}} />
                <div className="w-full flex gap-x-4 my-4">
                    <label className="text-sm text-gray-500 font-sofia-pro">Send a preview to <a href="mailTo:admin@objective1.co.uk">admin@objective1.co.uk</a></label>
                    <Switch
                        checked={sendProductInStockPreview}
                        onChange={setSendProductInStockPreview}
                        className={`${
                            sendProductInStockPreview ? 'bg-ink-navy' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                sendProductInStockPreview ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
                <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Order details are appended to this email body.<br /> 
                    &#123;STORE&#125; = Store Name (click and collect store)<br />
                    &#123;ORDER_ID&#125; = Order ID<br />
                    &#123;FIRST_NAME&#125; = First Name
                </label>
            </div>
        </div>
    )
}

export default Communication
