import React, {Fragment } from 'react'
import DataTable from '../../../components/elements/DataTable'
import SearchIcon from '../../../assets/images/icons/search.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import { convertCamelCase } from '../../../utils'
import DateRangeStepper from '../../../components/elements/DateRangeStepper'

const ProductSales = () => {
    const products= [
        {
            product: "Emonda SLR Race Shop Limited framsesettrek black/viper red",
            salesValue: 2590,
            orders: 1,
            units: 1,
            date: "2021-04-08"
        },
        {
            product: "Emonda SLR Disc eTap voodoo trek white/blue 60",
            salesValue: 5250,
            orders: 1,
            units: 1,
            date: "2021-04-12"
        },
        {
            product: "Emonda SLR Disc eTap purple phaze/anthracyte 52",
            salesValue: 5250,
            orders: 1,
            units: 10,
            date: "2021-04-12"
        },
        {
            product: "Emonda SL frameset matter dnister black",
            salesValue: 5250,
            orders: 1,
            units: 1,
            date: "2021-04-13"
        },
        {
            product: "XT and saint SM-RT81 ice tech center-lock disc rotor silver",
            salesValue: 5250,
            orders: 1,
            units: 10,
            date: "2021-04-12"
        },
        {
            product: "X-Caliber 9 slate 13.5 (27.5' wheel)",
            salesValue: 5250,
            orders: 1,
            units: 10,
            date: "2021-04-12"
        },
    ]

    const tableOptions = {
        selectable: false
    }

    const columnWidths = {
        product: 'w-full lg:w-7/12',
        salesValue: 'w-full lg:w-2/12',
        orders: 'w-full lg:w-1/12',
        units: 'w-full lg:w-1/12',
        date: 'w-full lg:w-1/12'
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key

            if(key === 'actions') {
                sortable = false
            }
            
            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const cleanUpProductGroups = (products) => {
        let finalResult = [] 
        products.forEach((product, productIndex) => {
            // group.actions = <EditGroupButton groupId={group.id} />
            // group.label = <GroupLink groupId={group.id} label={group.label} />
            const object = {
                product: product.product,
                salesValue: `£${product.salesValue.toLocaleString()}`,
                orders: product.orders,
                units: product.units,
                date: product.date
            } 
            finalResult.push(object)
        })
        return finalResult
    }

    // const [selectedProductsCount, setSelectedProductsCount] = useState(0)

    // const getSelectionCount = (count) => {
    //     return setSelectedProductsCount(count)
    // }

    return (
        <Fragment>
            <div className="w-full">
                <div className="w-full block lg:flex lg:flex-row lg:justify-between">
                    <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full lg:w-8/12 mr-1 mb-4 lg:mb-0">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm mt-1">
                                <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                            </span>
                        </div>
                        <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search" />
                    </div>

                    <div className="block md:flex flex-row gap-x-2 ml-0 md:ml-3">
                        <div className='flex flex-row items-center gap-x-3'>
                            <p className='hidden md:inlinetext-xs text-gray-400'>
                                Date 
                            </p>
                            <DateRangeStepper numberOfDays={5} />
                        </div>
                        <button className="mt-4 md:mt-0 rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border w-full lg:w-max">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Export
                        </button>
                    </div>
                </div>

                <DataTable 
                    tableHeaders={tableHeadersFields(products[0]).headers} 
                    tableData={cleanUpProductGroups(products)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(products[0]).fields}
                    onSelectItems={()=>{}}
                    tableOptions={tableOptions}
                />
            </div>
        </Fragment>
    )

    
}

export default ProductSales
