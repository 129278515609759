import React, { Fragment, useState, useEffect } from 'react'
import WhiteLogo from '../assets/images/logo-w.png'
// import Logo from '../assets/images/logo.png'
import axios from "axios"
import { baseUrl } from '../utils';
import ErrorMessage from '../components/elements/ErrorMessage'
import SuccessMessage from '../components/elements/SuccessMessage'
import Spinner from '../components/elements/icons/Spinner';
import { Link } from 'react-router-dom';


const RegisterPage = () => {
    const [userDetails, setUserDetails] = useState({
        currency: 'GBP'
    })
    const [processing, setProcessing] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})  
    const [error, setError] = useState('')  
    const [success, setSuccess] = useState('')  
    const [registered, setRegistered] = useState(false)
    const [count, setCount] = useState(1)

    // const [countDownExpired, setCountDownExpired] = useState(false)
    const [resendOpen, setResendOpen] = useState(false)
    const [processingResend, setProcessingResend] = useState(false)
    const [resendSuccess, setResendSuccess] = useState('')
    const [resendError, setResendError] = useState('')

    const validateForm = () => {
        let errors = {}
        if(!userDetails.firstName || userDetails.firstName === '') {
            errors.firstName = true
        }
        if(!userDetails.lastName || userDetails.lastName === '') {
            errors.lastName = true
        }
        if(!userDetails.email || userDetails.email === '') {
            errors.email = true
        }
        if(!userDetails.password || userDetails.password === '') {
            errors.password = true
        }
        if(!userDetails.retypedPassword || userDetails.retypedPassword === '') {
            errors.retypedPassword = true
        }
        if(userDetails.password !== userDetails.retypedPassword) {
            errors.password = true
            errors.retypedPassword = true
        }

        setValidationErrors(errors)
        return errors
    }

    const register = async () => {
        console.log(userDetails)
        const headers = {
            'Content-Type': 'application/json'
        }
        
        if (Object.values(validateForm()).includes(true)) {
            setError('Please check the highlighted fields above')
            return
        }

        setProcessing(true)

        const requestPayload = {
            first_name: userDetails.firstName,
            last_name: userDetails.lastName,
            email: userDetails.email,
            password: userDetails.password,
            c_password: userDetails.retypedPassword,
            currency_code : userDetails.currency
        }

        try {
            const response = await axios.post(`/api/v1/auth/register`, requestPayload, { headers })            
            console.log(response)
            setSuccess("You have been signed up successfully, please check your email to proceed.")
            setRegistered(true)
            setProcessing(false)
            setCount(60)
        } catch (error) {
            setError(error.response.data.developer_message)
            setProcessing(false)
        }
    }

    const resendEmail = async () => {
        const headers = {
            'Content-Type': 'application/json'
        }
        
        // if (Object.values(validateForm()).includes(true)) {
        //     setError('Please check the highlighted fields above')
        //     return
        // }

        if(!userDetails.email || userDetails.email === '') {
            setValidationErrors({...validationErrors, ...{email : true}})
            return
        }

        setProcessingResend(true)

        try {
            const response = await axios.get(`/api/v1/verify/resend/${userDetails.email}`, { headers })            
            console.log(response)
            setResendSuccess("Email resent successfully, please check again.")
            setProcessingResend(false)
        } catch (error) {
            setResendError(error.response.data.user_message)
            setProcessingResend(false)
        }
    }

    
    
    // const runTimer = () => {
        useEffect(() => {
            setTimeout( () => {
                if (count <= 0) {
                   return
                    //counter ended, do something here
                    // return;
                } else {
                    setCount(count - 1)
                    // return
                }
            }, 1000)
        }, [count])

        //Do code for showing the number of seconds here
    // }

    return (
        <Fragment>
            <div className="w-full block lg:flex flex-row items-center">
                <div className="h-32 lg:h-screen w-full bg-ink-navy flex flex-col justify-between items-center py-24">
                    <div className="w-36">
                        <img src={WhiteLogo} alt="push retail logo" />
                    </div>
                    <div className="hidden h-12 lg:flex flex-col justify-center items-center">
                        <div className="flex flex-row justify-between gap-x-2">
                            <span className="h-2 w-2 rounded-full bg-white" />
                            <span className="h-2 w-2 rounded-full bg-white" />
                            <span className="h-2 w-5 rounded-full bg-white" />
                        </div>
                        <p className="text-sm text-white mt-12">https://www.pushretail.io</p>
                    </div>
                </div>
                <div className="h-screen w-full bg-white flex flex-col justify-center px-6 lg:px-44">
                    <div>
                        <div className="w-full mb-10">
                            <p className="relative text-ink-navy text-xl">Create a new account</p>
                        </div>

                        <div className='w-full block md:flex flex-row gap-x-4 items-center'>
                            <div className='w-full'>
                                <label className={`text-sm text-ink-navy transition duration-200 ${validationErrors.firstName && validationErrors.firstName === true ? 'text-red-500' : ''}`}>First name</label> 
                                <input 
                                    type="text" 
                                    placeholder="Your first name" 
                                    className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.firstName && validationErrors.firstName === true ? 'text-red-500 border-red-500' : ''}`} 
                                    onChange={(e) => setUserDetails({ ...userDetails, ...{firstName: e.target.value}})}
                                /> 
                            </div>
                            <div className='w-full'>
                                <label className={`text-sm text-ink-navy transition duration-200 ${validationErrors.lastName && validationErrors.lastName === true ? 'text-red-500' : ''}`}>Last name</label> 
                                <input 
                                    type="text" 
                                    placeholder="Your last name" 
                                    className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.lastName && validationErrors.lastName === true ? 'text-red-500 border-red-500' : ''}`} 
                                    onChange={(e) => setUserDetails({ ...userDetails, ...{lastName: e.target.value}})}
                                /> 
                            </div>
                        </div>
                        
                        <div className='w-full flex flex-row gap-x-4 items-center'>
                            <div className='w-3/4'>
                                <label className={`text-sm text-ink-navy transition duration-200 ${validationErrors.email && validationErrors.email === true ? 'text-red-500' : ''}`}>Email address</label> 
                                <input 
                                    type="email" 
                                    placeholder="Your email address" className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.email && validationErrors.email === true ? 'text-red-500 border-red-500' : ''}`} 
                                    onChange={(e) => setUserDetails({ ...userDetails, ...{email: e.target.value}})}    
                                /> 
                            </div>
                            <div className='w-1/4'>
                                <label className="text-sm text-ink-navy">Currency</label> 
                                <div className='select-wrapper w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:shadow-md focus:border-gray-200'>
                                    <select 
                                        className='w-full focus:outline-none'
                                        onChange={(e) => setUserDetails({ ...userDetails, ...{currency: e.target.value}})}    
                                    >
                                        <option value="GBP">GBP</option>
                                        <option value="USD">USD</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className='w-full block md:flex flex-row gap-x-4 items-center'>
                            <div className='w-full'>
                                <label className={`text-sm text-ink-navy transition duration-200 ${validationErrors.password && validationErrors.password === true ? 'text-red-500' : ''}`}>Password</label> 
                                <input 
                                    type="password" 
                                    placeholder="Your password" 
                                    className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.password && validationErrors.password === true ? 'text-red-500 border-red-500' : ''}`} 
                                    onChange={(e) => setUserDetails({ ...userDetails, ...{password: e.target.value}})}    
                                />  
                            </div>
                            <div className='w-full'>
                                <label className={`text-sm text-ink-navy transition duration-200 ${validationErrors.retypedPassword && validationErrors.retypedPassword === true ? 'text-red-500' : ''}`}>Re-Type Password</label> 
                                <input 
                                    type="password" 
                                    placeholder="Your password" 
                                    className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.retypedPassword && validationErrors.retypedPassword === true ? 'text-red-500 border-red-500' : ''}`} 
                                    onChange={(e) => setUserDetails({ ...userDetails, ...{retypedPassword: e.target.value}})}    
                                />  
                            </div>
                        </div>

                        
                        
                        {/* <div className="w-full text-right">
                            <button className="text-xs text-ink-navy" href="#">Forgot Password?</button>                     
                        </div> */}
                        
                        <div className="w-full mt-5 text-center">

                            <button className="flex flex-row gap-x-3 items-center justify-center rounded bg-ink-navy text-sm hover:bg-blue-900 transition duration-200 w-full py-3 text-center text-white focus:border-transparent focus:outline-none" onClick={()=>{
                                if(!processing) { register() }
                                    }}>{processing ? <><Spinner />Creating your account...</> : 'Register'}
                            </button>

                            <p className="mt-3 text-sm">Already have an account? <Link to="/" className="text-blue-800 hover:text-blue-900">Go to log in</Link></p>

                            {error && error!=='' &&  
                                <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                            } 

                            {success && success!=='' &&  
                                <SuccessMessage message={success} dismissHandler={()=>{setSuccess(false)}} />
                            } 


                            {resendOpen &&
                                <div className='w-full px-10 py-5 rounded-md bg-gray-100 mt-5 text-left relative'>
                                    <button className='absolute right-10 top-5' onClick={() => {setResendOpen(false)}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                    <label className={`text-sm text-ink-navy transition duration-200 ${validationErrors.email && validationErrors.email === true ? 'text-red-500' : ''}`}>Email address</label> 
                                    <input 
                                        type="email" 
                                        placeholder="Your email address" className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.email && validationErrors.email === true ? 'text-red-500 border-red-500' : ''}`} 
                                        value={userDetails.email}
                                        onChange={(e) => setUserDetails({ ...userDetails, ...{email: e.target.value}})}    
                                    /> 
                                    <button className="flex flex-row gap-x-3 items center justify-center rounded bg-ink-navy text-sm hover:bg-blue-900 transition duration-200 w-full py-3 text-center text-white focus:border-transparent focus:outline-none" onClick={()=>{
                                        if(!processingResend) { resendEmail() }
                                            }}>{processingResend ? <><Spinner />Resending...</> : 'Resend Email'}
                                    </button>

                                    {resendError && resendError!=='' &&  
                                        <ErrorMessage message={resendError} dismissHandler={()=>{setResendError(false)}} />
                                    } 

                                    {resendSuccess && resendSuccess!=='' &&  
                                        <SuccessMessage message={resendSuccess} dismissHandler={()=>{setResendSuccess(false)}} />
                                    } 
                                </div>
                            }

                            {registered && 
                                <div className='w-full mt-5'>
                                    <p className='text-sm text-gray-500'>
                                        Didn't receive the email? {
                                            count <= 0 
                                            ? 
                                            <button className='font-medium text-ink-navy' onClick={()=>{setResendOpen(true)}}>Click here to resend it</button> 
                                            :
                                            <span className='text-sm font-medium text-geen-400'>Wait {count} seconds...</span>  
                                            }
                                    </p>
                                </div>
                            }

                            
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RegisterPage
