import React from 'react'

const Address = ({addresses, shipping, billing}) => {
    // const customerDetails = {
    //     firstName: "Mike",
    //     lastName: "Lewis",
    //     email: "mikelewisvi@gmail.com",
    //     phone: "07498838113",
    //     passwordPlaceholder: "iweuryt489853"
    // }
    return (
        <>
            {addresses.map((address, addressIndex) => (
                <div key={addressIndex} className="w-full pb-12 border-b border-gray-200">
                    {address.id === shipping && <h3 className="mt-6 mb-6 text-ink-navy font-medium text-md">Default Shipping Address</h3>}
                    {address.id === billing && <h3 className="mt-6 mb-6 text-ink-navy font-medium text-md">Default Billing Address</h3>}

                    <div className="w-full flex flex-row gap-x-6 mt-6 mb-6">
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">First Name</label>
                            <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.first_name} />
                        </div>
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Last Name</label>
                            <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.last_name} />
                        </div>
                    </div>

                    <div className="w-full flex flex-row gap-x-6">
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Address line 1</label>
                            <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.address_1} />
                        </div>
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Address line 2</label>
                            <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.address_2} />
                        </div>
                    </div>

                    <div className="w-full flex flex-row gap-x-6 mt-6">
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">City</label>
                            <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.city} />
                        </div>
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">County</label>
                            <input disabled type="text" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.county} />
                        </div>
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Country Code</label>
                            <input disabled type="text" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.country_code} />
                        </div>
                    </div>

                    <div className="w-full flex flex-row gap-x-6 mt-6">
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Post code</label>
                            <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.post_code} />
                        </div>
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Phone</label>
                            <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.phone} />
                        </div>
                        <div className="w-full">
                            <label className="block text-gray-400 text-xs font-sofia-pro mb-2">Company</label>
                            <input type="text" disabled className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" value={address.company} />
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default Address
