import React from 'react'
import Chart from "react-apexcharts";

const DeliveryMethodsBar = () => {
    const chartState = { 
        options: {
            series: [{
                data: [2, 3, 10, 1]
            }],
            chart: {
                toolbar: {
                    show: false
                },
                height: 350,
                type: 'bar',
                // events: {
                //   click: function(chart, w, e) {
                //     // console.log(chart, w, e)
                //   }
                // }
          },
          colors: ['#5B5194'],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          markers: {
            size: 0
          },
          yaxis: {
            show: true,
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontFamily: 'SofiaPro, sans-serif',
                    fontWeight: 400,
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return "£" + y.toFixed(0) + "K";
                    }
                    return y;
                }
            },
            axisBorder: {
                show: true,
                color: '#eeeff1',
                offsetX: 0,
                offsetY: 0
            }
          },
          grid: {
            show: false,
          },
          xaxis: {
            categories: [
              ['600', 'Collect in store - Free', 'Clevedon'],
              ['400', 'Collect in store - Free', 'Hertfordshire Demo Center'],
              ['20295', '£0.00'],
              ['15', 'UK Next Day Delivery - £10.00']
            ],
            labels: {
              style: {
                colors: ['#999', '#999', '#999', '#999'],
                fontSize: '12px',
                fontFamily: 'SofiaPro, sans-serif'
              },
              y: {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return "£" + y.toFixed(0) + "K";
                  }
                  return y;
                }
              }
            }
          }
        }
      }
    // }
    return (
        <div id="chart">
            <Chart options={chartState.options} series={chartState.options.series} type="bar" height={350} />
        </div>
    )
}

export default DeliveryMethodsBar
