import React, { useState, useEffect } from 'react'
import DeleteIcon  from '../../assets/images/icons/delete-icon.svg'
import RefreshIcon  from '../../assets/images/icons/refresh-icon.svg'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../utils';
import Spinner from '../elements/icons/Spinner';

const OrderHistory = ({order}) => {
    const [ cookies ] = useCookies(['user'])
    const [processing, setProcessing] = useState(true);
    const [error, setError] = useState('');
    const [orderHistory, setOrderHistory] = useState([]);
    useEffect(() => {
        const getOrderHistory = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            try {
                const response = await axios.get(`/api/v1/admin/orders/${order.order_uuid}/history?page[offset]=1&page[limit]=100`, { headers })    
                setOrderHistory(response.data.data) 
                setProcessing(false)
            } catch (error) {
                console.log(error.response.data.developer_message.errors)
                setError(JSON.stringify(error.response.data.developer_message.errors))
                setProcessing(false)
            }
        }

        getOrderHistory()
        return () => {
            
        };
    }, [cookies.accessToken, cookies.tokenType, order.order_uuid]);
    return (
        <>        
        {processing ?
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                <p className='text-sm text-gray-500 mt-5'>Fetching order history...</p>
            </div>
            :
        
        <>
            {error === '' ? 
                <>
                    <div className="mt-10 px-5">
                        {orderHistory.map((item, itemIndex) => (
                            <div key={itemIndex} className="w-full flex flex-row justify-between items-center my-3">
                                <div className="flex flex-row gap-x-3">
                                    <img src={RefreshIcon} alt="" className="w-5 h-5 p-1 rounded-full bg-green-600" />
                                    
                                    {item.status === 'Note Update' && <p className="text-sm text-gray-500"><span className="text-ink-navy text-capitalize">{item.status} - <span className='text-gray-600'>{item.message}</span></span> </p>}
                                    
                                    {item.message === 'Status Change' && <p className="text-sm text-gray-500"><span className="text-ink-navy text-capitalize">{item.message} to {item.status}</span> </p>}
                                </div>
                                <p className="text-gray-300 text-sm">{new Date(item.created_at).toDateString()}</p>
                            </div>
                        ))}

                        {/* <div className="w-full flex flex-row justify-between items-center my-3">
                            <div className="flex flex-row gap-x-3">
                                <img src={RefreshIcon} alt="" className="w-5 h-5 p-1 rounded-full bg-green-600" />
                                <p className="text-sm text-gray-500"><span className="text-ink-navy text-capitalize">Updated</span> by Admin</p>
                            </div>
                            <p className="text-gray-300 text-sm">Friday - 10/08/2021</p>
                        </div>
                        <div className="w-full flex flex-row justify-between items-center my-3">
                            <div className="flex flex-row gap-x-3">
                                <img src={DeleteIcon} alt="" className="w-5 h-5 p-1 rounded-full bg-red-600" />
                                <p className="text-sm text-gray-500"><span className="text-ink-navy text-capitalize">Deleted product</span> by Admin</p>
                            </div>
                            <p className="text-gray-300 text-sm">Friday - 10/08/2021</p>
                        </div>
                        <div className="w-full flex flex-row justify-between items-center my-3">
                            <div className="flex flex-row gap-x-3">
                                <img src={RefreshIcon} alt="" className="w-5 h-5 p-1 rounded-full bg-green-600" />
                                <p className="text-sm text-gray-500"><span className="text-ink-navy text-capitalize">Order created</span> by Customer</p>
                            </div>
                            <p className="text-gray-300 text-sm">Friday - 10/08/2021</p>
                        </div> */}
                    </div>
                </>
            :
                <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                    <p className='text-sm text-gray-600 mt-1'>{error}</p>
                </div>
            }
        </>
        }
    </>
        
    )   
}

export default OrderHistory
