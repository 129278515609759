import React, { Fragment, useState } from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
// import ListboxSelect from '../../../components/elements/ListboxSelect'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
const SupplierData = () => {

    const categories = [
        {
            displayName: "Bikes",
            categoryId: "bikes",
            selected: false,
            disclosureOpen: false,
            subCategories: [
                {
                    displayName: "Electric Bikes",
                    categoryId: "electric-bikes",
                    selected: false,
                    subCategories: [
                        {
                            displayName: "Electric Mountain",
                            categoryId: "electric-mountain",
                            selected: false,
                            disclosureOpen: false,
                            subCategories: []  
                        },
                        {
                            displayName: "Urban",
                            categoryId: "urban",
                            disclosureOpen: false,
                            selected: false,
                            subCategories: []  
                        },
                        {
                            displayName: "Road/Gravel",
                            categoryId: "road-gravel",
                            selected: false,
                            disclosureOpen: false,
                            subCategories: []  
                        },
                    ]
                },
                {
                    displayName: "Mountain",
                    categoryId: "mountain",
                    selected: false,
                    disclosureOpen: false,
                    subCategories: [
                        {
                            displayName: "Full Suspension",
                            categoryId: "full-suspension",
                            selected: false,
                            disclosureOpen: false,
                            subCategories: []  
                        },
                        {
                            displayName: "Road/Gravel",
                            categoryId: "road-gravel",
                            disclosureOpen: false,
                            selected: false,
                            subCategories: []  
                        },
                    ]
                },
                {
                    displayName: "Kids",
                    categoryId: "kids",
                    selected: false,
                    disclosureOpen: false,
                    subCategories: []
                },
                {
                    displayName: "Hybrid",
                    categoryId: "hybrid",
                    selected: false,
                    disclosureOpen: false,
                    subCategories: []
                },
                {
                    displayName: "Road",
                    categoryId: "road",
                    selected: false,
                    disclosureOpen: false,
                    subCategories: []
                },
            ]
        },
        {
            displayName: "E-Bikes",
            categoryId: "e-bikes",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
        {
            displayName: "Helmets",
            categoryId: "helmets",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
        {
            displayName: "Clothing",
            categoryId: "clothing",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
        {
            displayName: "Accessories",
            categoryId: "accessories",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
        {
            displayName: "Component",
            categoryId: "component",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
    ]

    const [activeTab, setActiveTab] = useState(0)
    let changeActiveTab = (categoryIndex) => {
        setActiveTab(categoryIndex)
    }

    const [filtersActive, setFiltersActive] = useState(false)

    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const [selected, setSelected] = useState(categories[0].displayName)

    return (
        <Fragment>
            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between">
                    <div className="flex flex-row justify-between items-center w-full lg:w-5/12 mb-4 lg:mb-0">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full lg:w-10/12 mr-1">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> <span className='hidden md:inline'>Filters</span>
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2">
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border w-full lg:w-max">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Import
                        </button>
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border w-full lg:w-max">
                            Update
                        </button>
                    </div>
                </div>
                {/* Tabs */}
                <div className="hidden w-full lg:flex flex-row items-center justify-between mt-5">
                    <div className="flex flex-row gap-x-1 border-b border-gray-300 w-full mt-5">
                        {/* {tabs.map((tab, tabIndex) =>( */}
                        {categories.map((category, categoryIndex) =>(
                            <button 
                                key={categoryIndex} 
                                className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === categoryIndex ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                                onClick={()=>{changeActiveTab(categoryIndex)}}>
                                {category.displayName} 
                            </button>
                        ))}
                    </div>
                </div>


                <div className="w-full lg:hidden flex-row items-center justify-between mt-5">
                    {/* tabs in dropdown fashion */}
                    <label className="text-gray-400 text-xs mb-2 block mt-2">Select Category</label>
                    <div className="">
                        <Listbox value={selected} onChange={setSelected}>
                            <div className="relative mt-1">
                            <Listbox.Button className={`relative w-full text-left text-xs rounded border border-gray-300 text-ink-navy font-medium font-sofia-pro inline-block px-3 py-2 transition duration-200`}>
                                <span className="block truncate text-sm lg:text-xs">{selected}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                    className="w-4 h-4 text-gray-400"
                                    aria-hidden="true"
                                />
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {categories.map((option, optionIndex) => (
                                    <Listbox.Option
                                        key={optionIndex}
                                        className={({ active }) =>
                                            `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                                cursor-default select-none relative py-2 pl-10 pr-4`
                                        }
                                        value={option.displayName}
                                        
                                        >
                                        {({ selected, active }) => (
                                            <>
                                            <span
                                                className={`${
                                                selected ? 'font-medium' : 'font-normal'
                                                } block truncate text-sm`}
                                                onClick={()=>{changeActiveTab(optionIndex)}}
                                            >
                                                {option.displayName}
                                            </span>
                                            {selected ? (
                                                <span
                                                className={`${
                                                    active ? 'text-amber-600' : 'text-amber-600'
                                                }
                                                        absolute inset-y-0 left-0 flex items-center pl-3`}
                                                >
                                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                                </Listbox.Options>
                            </Transition>
                            </div>
                        </Listbox>
                    </div>
                </div>
                {/* Tab area */}
                <div className="w-full py-7 px-12">
                    {categories[activeTab].subCategories.map((category, categoryIndex) => (
                        <div key={categoryIndex} className="relative border-b py-2 pl-8">
                            <input type="checkbox" checked={category.selected} className="absolute left-1 top-4" />
                            <Disclosure>
                                {({ open }) => (
                                <>
                                    <Disclosure.Button className="pt-2 flex justify-between w-full bg-transparent text-sm text-left text-gray-500 focus:outline-none focus-visible:ring-opacity-75 hover:text-ink-navy">
                                        <span>{category.displayName}</span>
                                        <ChevronUpIcon
                                            className={`${
                                            open ? '' : 'transform rotate-180'
                                            } w-5 h-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-2 pb-0 text-sm text-gray-500">
                                        {category.subCategories.map((subCategory, subCategoryIndex) => (
                                            subCategory.subCategories && subCategory.subCategories.length > 0 ?
                                            <div className="relative" key={subCategoryIndex}>
                                                <input type="checkbox" checked={category.selected} className="absolute -left-5 top-2" />
                                                <Disclosure>
                                                    {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="pt-2 flex justify-between w-3/5 bg-transparent text-sm text-left text-gray-500 focus:outline-none focus-visible:ring-opacity-75 hover:text-ink-navy">
                                                            <span>{subCategory.displayName}</span>
                                                            <ChevronUpIcon
                                                                className={`${
                                                                open ? '' : 'transform rotate-180'
                                                                } w-5 h-5 text-gray-500`}
                                                            />
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel className="pl-6 pt-2 pb-0 text-sm text-gray-500">
                                                            {subCategory.subCategories.map((subSubCategory, subSubCategoryIndex) => (
                                                                <div key={subSubCategoryIndex} className="flex flex-row relative">
                                                                    <input type="checkbox" checked={subSubCategory.selected} className="absolute -left-5 top-1" />
                                                                    <p className="block py-1 hover:text-ink-navy">{subSubCategory.displayName}</p>  
                                                                </div>
                                                            ))}
                                                        </Disclosure.Panel>
                                                    </>
                                                    )}
                                                </Disclosure> 
                                            </div>

                                            :
                                            
                                            <div key={subCategoryIndex} className="relative">
                                                <input type="checkbox" checked={subCategory.selected} className="absolute -left-5 top-2" />
                                                <p className="block py-1 hover:text-ink-navy">{subCategory.displayName}</p>  
                                            </div>
                                        ))}
                                    </Disclosure.Panel>
                                </>
                                )}
                            </Disclosure> 
                        </div>
                    ))}
                </div>
            </div>
    </Fragment>
    )
}

export default SupplierData
