import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../../utils';
import Spinner from '../../../../components/elements/icons/Spinner'
import { useParams, useHistory, useLocation } from 'react-router-dom';
import DeleteIcon  from '../../../../assets/images/icons/delete.svg'
import AutocompleteSelect from '../../../../components/elements/AutocompleteSelect';
import CloseIcon from '../../../../components/elements/icons/CloseIcon';
import GlobalSearchV2 from '../../../../components/elements/GlobalSearchV2';

const SizeGuide = () => {
    const history = useHistory();
    const location = useLocation()
    const { id } = useParams()

    const [sizeGuide, setSizeGuide] = useState({})
    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])
    const [loadData, setLoadData] = useState(true);
    const [loadResources, setLoadResources] = useState(true);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [addingProducts, setAddingProducts] = useState(false);
    const [addingBrands, setAddingBrands] = useState(false);
    const [addingCategories, setAddingCategories] = useState(false );
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [availableBrands, setAvailableBrands] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [availableProducts, setAvailableProducts] = useState([]);
    useEffect(() => {
        const fetchSizeGuideDetails = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try { 
                let response = null 

                response = await axios.get(`/api/v1/size_guides/${id}`, { headers })            
                console.log(response)                
                setSizeGuide(response.data.data)

                const guideBrands = []
                response.data.data.brands.map((brand)=>{
                    guideBrands.push(brand.id)
                    return brand
                })

                const guideProducts = []
                response.data.data.products.map((product)=>{
                    guideProducts.push(product.id)
                    return product
                })
                
                const guideCategories = []
                response.data.data.categories.map((category)=>{
                    guideCategories.push(category.id)
                    return category
                })
                
                setAvailableCategories(guideCategories)
                setAvailableBrands(guideBrands)
                setAvailableProducts(guideProducts)

                // if(loadResources && loadResources === true) {
                //     fetchProducts()
                // } else {
                //     setProcessing(false)
                // }

                setProcessing(false)


            } catch (error) {
                console.error(error)
                if(error.response.data.developer_message.exception === "AuthenticationException") {
                    window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
                } else {
                    setError(error.response.data.developer_message.errors)
                    setProcessing(false)
                }
            }
        }

        if(loadData && loadData === true) {
            fetchSizeGuideDetails()
        }

    
    }, [cookies.accessToken, cookies.tokenType, history, id, location.pathname, loadData, loadResources])

    function createMarkup(text) { return {__html: text}; };

    const GuideBody = ({content}) => {
        return (
            <div dangerouslySetInnerHTML={createMarkup(content)} />
        )
    }

    const selectBrand = () => {
        console.log(selectedBrand)
        if(!selectedBrand || selectedBrand === '') {
            return
        }
    
        const tempSelectedBrands = [...selectedBrands]

        if(tempSelectedBrands.includes(selectedBrand.id)) {
            return
        }

        tempSelectedBrands.push(selectedBrand.id)
        setSelectedBrands(tempSelectedBrands)
        setSelectedBrand('')
    }

    const brandName = (brandId) => {
        let brandSelected = brands.filter((brand)=> {
            return brand.id === brandId
        })

        return brandSelected[0].name
    }

    const selectCategory = () => {
        if(!selectedCategory || selectedCategory === '') {
            return
        }

        const tempSelectedCategories = [...selectedCategories]

        if(tempSelectedCategories.includes(selectedCategory.id)) {
            return
        }

        tempSelectedCategories.push(selectedCategory.id)
        setSelectedCategories(tempSelectedCategories)
        setSelectedCategory('')
    }

    const categoryName = (categoryId) => {
        let categorySelected = categories.filter((category)=> {
            return category.id === categoryId
        })

        return categorySelected[0].category
    }

    const selectProduct = () => {
        if(!selectedProduct || selectedProduct === '') {
            return
        }

        const tempSelectedProducts = [...selectedProducts]

        if(tempSelectedProducts.includes(selectedProduct.id)) {
            return
        }

        tempSelectedProducts.push(selectedProduct.id)
        setSelectedProducts(tempSelectedProducts)
        setSelectedProduct('')
    }

    const productName = (productId) => {
        let productSelected = products.filter((product)=> {
            return product.id === productId
        })

        return productSelected[0].name
    }

    const removeBrand = (brandId) => {
        const tempSelectedBrands = [...selectedBrands]
        let deleted = tempSelectedBrands.filter((brand)=> {
            return brand !== brandId
        })
        setTimeout(() => {
            setSelectedBrands(deleted)
        }, 50);
    }

    const removeCategory = (categoryId) => {
        const tempSelectedCategories = [...selectedCategories]
        let deleted = tempSelectedCategories.filter((category)=> {
            return category !== categoryId
        })

        setSelectedCategories(deleted)
    }

    const removeProduct = (productId) => {
        const tempSelectedProducts = [...selectedProducts]
        let deleted = tempSelectedProducts.filter((product)=> {
            return product !== productId
        })

        setSelectedProducts(deleted)
    }

    const [updating, setUpdating] = useState(false);

    const updateSizeGuide = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        const requestPayload = {
            product_ids: [...availableProducts, ...selectedProducts],
            category_ids: [...availableCategories, ...selectedCategories],
            brand_ids: [...availableBrands, ...selectedBrands],
            // content: sizeGuideContent,
            // image_path_data: activeGuideThumbnail
        }
        setUpdating(true)
        try { 
            await axios.patch(`/api/v1/admin/size_guides/${id}`, requestPayload, { headers })                            
            setUpdating(false)
            setLoadData(true)
        } catch (error) {
            console.error(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(JSON.stringify(error.response.data.developer_message.errors))
                setUpdating(false)
            }
        }
    }

    const [searchingBrands, setSearchingBrands] = useState(false);

    const searchForBrand = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        try { 
            setSearchingBrands(true)
            let response = null 

            response = await axios.get(`/api/v1/brands/?filter[%name%]=${term}`, { headers })                            
            setBrands(response.data.data)
            setSearchingBrands(false)
        } catch (error) {
            setBrands([])
            setSearchingBrands(false)
            console.error(error)
            setError(error.response.data.msg)
            setSearchingBrands(false)
        }
    }

    const [searchingCategories, setSearchingCategories] = useState(false);

    const searchForCategory = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        try { 
            setSearchingCategories(true)
            let response = null 

            response = await axios.get(`/api/v1/categories/?filter[%name%]=${term}`, { headers })                            
            setCategories(response.data.data)
            setSearchingCategories(false)
        } catch (error) {
            setCategories([])
            setSearchingCategories(false)
            console.error(error)
            setError(error.response.data.msg)
            setSearchingCategories(false)

        }
    }

    const [searchingProducts, setSearchingProducts] = useState(false);

    const searchForProducts = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        try { 
            setSearchingProducts(true)
            let response = null 

            response = await axios.get(`/api/v1/admin/products/list?filter[%25name%25]=${term}`, { headers })                            
            setProducts(response.data.data)
            setSearchingProducts(false)
        } catch (error) {
            setSearchingProducts(false)
            console.error(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setSearchingProducts(false)
            }
        }
    }

    return ( 
    <>
        {/* <div className="w-full block lg:flex flex-row justify-between pt-5">
            <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm mt-1">
                            <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                        </span>
                    </div>
                    <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search size guides" />
                </div>
                <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                    <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                </button>
            </div>
            <div className="flex flex-row gap-x-2">
                {selectionCount > 1 && 
                    <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                }
                <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                    <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Export
                </button>
                <Link to="size-guide/new-size-guide" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg> 
                    Create Size Guide
                </Link>
            </div>
        </div> */}
        {!processing && error === '' ?

        <div className='w-9/12 mx-auto'>
            <div className='flex items-center justify-between'>
                <p className='text-sm font-medium uppercase mb-5 text-gray-600'>Size guide content</p>

            </div>
            <div className='text-sm font-sofia-pro'>
                {<GuideBody content={sizeGuide.content} />}
            </div>

            <div className='mt-8 pt-8 border-t border-gray-100'>
                <div className='flex items-center justify-between'>
                    <p className='text-sm font-medium uppercase mb-5 text-gray-600'>Size guide Products</p>
                    <button className='bg-blue-100 text-ink-navy px-4 py-2 text-sm rounded transition duration-200 hover:bg-blue-700 hover:text-white' 
                    onClick={()=>{setAddingProducts(true)}}>Add Product</button>
                </div>

                {addingProducts && addingProducts === true && <div>
                    <div className='w-full py-2 text-right flex flex-row-reverse'>
                        <button className='bg-gray-200 p-1 rounded flex items-center justify-center hover:bg-gray-300 transition duration-200' onClick={()=>{setAddingProducts(false)}}><CloseIcon classes="w-4 h-4" /></button>
                    </div>
                    <div className='w-full mb-5 flex items-center justify-between gap-x-2 relative'>
                        {/* <select type="text" className="w-9/12 px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500">
                            <option>All Brands</option>
                        </select> */}
                        <div className='w-9/12'>
                            {/* <AutocompleteSelect 
                                placeholderText="Find a product..." 
                                selectOptions={products}
                                titleField='name'
                                displayImage={false}
                                imageField=''
                                returnFieldValue={(value) => {setSelectedProduct(value)}}
                            /> */}
                            <GlobalSearchV2 
                                performSearch={(term)=>{searchForProducts(term)}}
                                searchResults={products}
                                resultDisplayField={'name'}
                                returnSelectedResult={(index)=>{setSelectedProduct(products[index])}}
                                placeholderText={`Search for product`}
                                searchInProgress={searchingProducts}
                            />
                        </div>
                        <button className='bg-blue-100 text-ink-navy px-4 py-3 text-xs rounded transition duration-200 hover:bg-blue-700 hover:text-white w-3/12'
                        onClick={()=>{selectProduct()}}
                        >
                            Add Product</button>
                    </div>
                    <label className="block text-xs text-ink-navy my-2">Add products for this style guide by selecting a brand above and clicking the "Add Product" button. You can add multiple brproductsands </label>

                    {selectedProducts && selectedProducts.length > 0 &&
                        <div className="my-5">
                            {selectedProducts.map((product, productIndex)=>(
                                <span key={productIndex} className="px-2 py-1 inline-block mb-2 text-xs text-gray-500 bg-gray-100 rounded mr-2">
                                    {productName(product)} 
                                    <button  onClick={()=>{removeProduct(product)}}><CloseIcon classes="w-4 h-4" /></button>
                                </span>
                            ))}
                        </div>
                    }
                </div>}
                
                {sizeGuide.products && sizeGuide.products.length > 0 ? 
                    <>
                        <div className='flex items-center text-xs gap-x-3 text-gray-400 uppercase my-3 rounded p-4'>
                            <div className='w-6/12'><p>Product Name</p></div>
                            <div className='w-2/12'><p>Style code</p></div>
                            <div className='w-1/12'><p>Year</p></div>
                            <div className='w-2/12'><p>Purchase Count</p></div>
                            <div className='w-1/12'><p>Price</p></div>
                        </div>

                        {sizeGuide.products.map((product, productIndex) => (
                            <div key={productIndex} className='flex items-center text-sm gap-x-3 bg-blue-100 bg-opacity-20 my-3 rounded p-4 relative'>
                                <div className='w-6/12'><p className='text-ink-navy text-sm'>{product.name}</p></div>
                                <div className='w-2/12'><p className='text-gray-600 text-sm'>{product.style_code}</p></div>
                                <div className='w-1/12'><p className='text-gray-600 text-sm'>{product.year}</p></div>
                                <div className='w-2/12'><p className='text-gray-600 text-sm'>{parseInt(product.purchase_count).toLocaleString()}</p></div>
                                <div className='w-1/12'><p className='text-gray-600 text-sm'>{parseInt(product.price).toLocaleString()}</p></div>
                                
                                <button className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center absolute right-2 top-2" onClick={()=>{}}>
                                    <img src={DeleteIcon} alt="" className="w-3 inline" />
                                </button>
                            </div>
                        ))}
                    </>
                    : 
                    <div className='bg-gray-100 p-5 w-full items-center justify-center mt-5'>
                        <p className='text-xs text-gray-500'>No products in this size guide yet</p>
                    </div>
                }
            </div>

            <div className='block lg:flex gap-x-8 w-full justify-between'>
                <div className='mt-8 pt-8 border-t border-gray-100 w-full'>
                    <div className='flex items-center justify-between'>
                        <p className='text-sm font-medium uppercase mb-5 text-gray-600'>Size guide categories</p>
                        <button onClick={()=>{setAddingCategories(true)}} className='bg-blue-100 text-ink-navy px-4 py-2 text-sm rounded transition duration-200 hover:bg-blue-700 hover:text-white'>Add Category</button>
                    </div>

                    {addingCategories && addingCategories === true && <div>
                        <div className='w-full py-2 flex flex-row-reverse'>
                            <button className='bg-gray-200 p-1 rounded flex items-center justify-center hover:bg-gray-300 transition duration-200' onClick={()=>{setAddingCategories(false)}}>
                                <CloseIcon classes="w-4 h-4" />
                            </button>
                        </div>
                        <div className='w-full mb-5 flex items-center justify-between gap-x-2'>
                            <div className='w-9/12'>
                                {/* <AutocompleteSelect 
                                    placeholderText="Find a category..." 
                                    selectOptions={categories}
                                    titleField='category'
                                    displayImage={false}
                                    imageField=''
                                    returnFieldValue={(value) => {setSelectedCategory(value)}}
                                /> */}
                                <GlobalSearchV2 
                                    performSearch={(term)=>{searchForCategory(term)}}
                                    searchResults={categories}
                                    resultDisplayField={'category'}
                                    returnSelectedResult={(index)=>{setSelectedCategory(categories[index])}}
                                    placeholderText={`Search for category`}
                                    searchInProgress={searchingCategories}
                                />
                            </div>
                            {/* <select type="text" className="w-9/12 px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500">
                                <option>All Brands</option>
                            </select> */}
                            <button className='bg-blue-100 text-ink-navy px-4 py-3 text-xs rounded transition duration-200 hover:bg-blue-700 hover:text-white w-3/12'
                            onClick={()=>{selectCategory()}}>
                                Add Category</button>
                        </div>
                        <label className="block text-xs text-ink-navy my-2">Add categories for this style guide by selecting a brand above and clicking the "Add Category" button. You can add multiple categories </label>

                        {selectedCategories && selectedCategories.length > 0 &&
                            <div className="my-5">
                                {selectedCategories.map((category, categoryIndex)=>(
                                    <span key={categoryIndex} className="px-2 py-1 inline mb-5 text-xs mr-4 text-gray-500 bg-gray-100 rounded">
                                        {categoryName(category)} 
                                        <button  onClick={()=>{removeCategory(category)}}><CloseIcon classes="w-4 h-4 ml-2 pt-1" /></button>
                                    </span>
                                ))}
                            </div>
                        }

                    </div>}
                    
                    {sizeGuide.categories && sizeGuide.categories.length > 0 ?
                        <>
                            <div className='flex items-center text-xs gap-x-3 text-gray-400 uppercase my-3 rounded p-4'>
                                <div className='w-6/12'><p>Category Name</p></div>
                                <div className='w-6/12'><p>Slug</p></div>
                            </div>

                            {sizeGuide.categories.map((category, categorytIndex) => (
                                <div key={categorytIndex} className='flex items-center text-sm gap-x-3 bg-blue-100 bg-opacity-20 my-3 rounded p-4 relative'>
                                    <div className='w-6/12'><p className='text-ink-navy text-sm'>{category.category}</p></div>
                                    <div className='w-6/12'><p className='text-gray-600 text-sm'>{category.slug}</p></div>

                                    <button className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center absolute right-2 top-2" onClick={()=>{}}>
                                        <img src={DeleteIcon} alt="" className="w-3 inline" />
                                    </button>
                                </div>
                            ))}
                        </>
                        : 
                        <div className='bg-gray-100 p-5 w-full items-center justify-center mt-5'>
                            <p className='text-xs text-gray-500'>No categories in this sizw guide yet</p>
                        </div>
                    }
                </div>

                <div className='mt-8 pt-8 border-t border-gray-100 w-full'>
                    <div className='flex items-center justify-between'>
                        <p className='text-sm font-medium uppercase mb-5 text-gray-600'>Size guide Brands</p>
                        <button onClick={()=>{setAddingBrands(true)}} className='bg-blue-100 text-ink-navy px-4 py-2 text-sm rounded transition duration-200 hover:bg-blue-700 hover:text-white'>Add Brand</button>
                    </div>

                    {addingBrands && addingBrands === true && <div>
                        <div className='w-full py-2 text-right flex flex-row-reverse'>
                            <button className='bg-gray-200 p-1 rounded flex items-center justify-center hover:bg-gray-300 transition duration-200' onClick={()=>{setAddingBrands(false)}}><CloseIcon classes="w-4 h-4" /></button>
                        </div>
                        <div className='w-full mb-5 flex items-center justify-between gap-x-2'>
                            {/* <select type="text" className="w-9/12 px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500">
                                <option>All Brands</option>
                            </select> */}
                            <div className='w-9/12'>
                                <GlobalSearchV2 
                                    performSearch={(term)=>{searchForBrand(term)}}
                                    searchResults={brands}
                                    resultDisplayField={'name'}
                                    returnSelectedResult={(index)=>{setSelectedBrand(brands[index])}}
                                    placeholderText={`Search for brand`}
                                    searchInProgress={searchingBrands}
                                />
                            </div>
                            <button className='bg-blue-100 text-ink-navy px-4 py-3 text-xs rounded transition duration-200 hover:bg-blue-700 hover:text-white w-3/12' onClick={()=>{selectBrand()}}>
                                Add Brand
                            </button>
                        </div>
                        <label className="block text-xs text-ink-navy my-2">Add brands for this style guide by selecting a brand above and clicking the "Add Brand" button. You can add multiple brands </label>

                        {selectedBrands && selectedBrands.length > 0 &&
                            <div className="my-5">
                                {selectedBrands.map((brand, brandIndex)=>(
                                    <span key={brandIndex} className="px-2 py-1 inline mb-2 text-xs mr-4 text-gray-500 bg-gray-100 rounded">
                                        {brandName(brand)} 
                                        <button onClick={()=>{removeBrand(brand)}}><CloseIcon classes="w-4 h-4 ml-2 pt-1" /></button>
                                    </span>
                                ))}
                            </div>
                        }

                    </div>}
                    
                    {sizeGuide.brands && sizeGuide.brands.length > 0 ?
                        <>
                            <div className='flex items-center text-xs gap-x-3 text-gray-400 uppercase my-3 rounded p-4'>
                                <div className='w-6/12'><p>Brand Name</p></div>
                                <div className='w-6/12'><p>Slug</p></div>
                            </div>

                            {sizeGuide.brands.map((brand, brandIndex) => (
                                <div key={brandIndex} className='flex items-center text-sm gap-x-3 bg-blue-100 bg-opacity-20 my-3 rounded p-4 relative'>
                                    <div className='w-6/12'><p className='text-ink-navy text-sm'>{brand.name}</p></div>
                                    <div className='w-6/12'><p className='text-gray-600 text-sm'>{brand.slug}</p></div>

                                    <button className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center absolute right-2 top-2" onClick={()=>{}}>
                                        <img src={DeleteIcon} alt="" className="w-3 inline" />
                                    </button>
                                </div>
                            ))}
                        </>
                        : 
                        <div className='bg-gray-100 p-5 w-full items-center justify-center mt-5'>
                            <p className='text-xs text-gray-500'>No categories in this sizw guide yet</p>
                        </div>
                    }
                </div>
            </div>

            <button onClick={()=>{updateSizeGuide()}} className='bg-ink-navy text-white px-6 py-4 text-sm rounded transition duration-200 hover:bg-blue-700 hover:text-white flex items-center gap-x-2' disabled={updating}>
                {updating && <Spinner />}
                {updating && updating === true ?
                    'Updating Size Guide...'
                    :
                    'Update Size Guide'
                }
            </button>


        </div>
         :
        <div className='w-full flex flex-col items-center justify-center mt-10'>
            <Spinner />
            <p className='text-sm text-gray-500 mt-5'>Fetching size guide details...</p>
        </div>
        }
    </>
    )
}

export default SizeGuide
