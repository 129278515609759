import React, {useState} from 'react'

const NewEditPostageRule = () => {
    const [storeContent, setStoreContent] = useState(true)
    const [warehouseProducts, setWarehouseProducts] = useState(false)
    const [majorProducts, setMajorProducts] = useState(true)
    const [block, setBlock] = useState(false)

    const ruleDetails = {
        id: "1",
        label: "UK Next Day",
        location: "Europe, GB",
        basketValue: {
            lower: 0.00,
            higher: 10000000000.00
        },
        majorMinior: "",
        productGroup: "2021-04-08",
        price: 10.00,
        actions: ""
    }

    return (
        <div className='w-full'>
        <h3 className='font-medium text-md text-ink-navy mb-4'>Postage Details</h3>

        <div className='w-full flex flex-row gap-x-8 my-5'>
            <div className='w-1/2'>
                <label className="block text-xs text-gray-400 mb-2">Label *</label>
                <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={ruleDetails.label} />
            </div>
            <div className='w-1/2'>
                <label className="block text-xs text-gray-400 mb-2">Price</label>
                <input type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500"  value={ruleDetails.price} />
            </div>
        </div>

        <div className='w-full flex flex-row gap-x-8 my-5'>
            <div className='w-1/2'>
                <label className="block text-xs text-gray-400 mb-2">Min Basket Value</label>
                <input type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={ruleDetails.basketValue.lower} />
            </div>
            <div className='w-1/2'>
                <label className="block text-xs text-gray-400 mb-2">Max Basket Value</label>
                <input type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={ruleDetails.basketValue.higher} />
            </div>
        </div>

        <div className='w-full flex flex-row gap-x-8 my-5'>
            <div className='w-1/2'>
                <label className="block text-xs text-gray-400 mb-2">Continent</label>
                <select className='w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500'>
                    <option>Asia</option>
                </select>
            </div>
            <div className='w-1/2'>
                <label className="block text-xs text-gray-400 mb-2">Country</label>
                <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" />
            </div>
        </div>

        <div className='w-full flex flex-row gap-x-8 my-5'>
            <div className='w-1/2'>
                <label className="block text-xs text-gray-400 mb-2">PostCode prefixes</label>
                <textarea type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 resize-none h-28 text-xs text-gray-500" />
            </div>
            <div className="w-1/2" />
        </div>

        <div className='w-full flex flex-row gap-x-8 my-5'>
            <div className='w-1/2'>
                <label className="block text-xs text-gray-400 mb-2">Lead time</label>
                <input type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" />
            </div>
            <div className='w-1/2'>
                <label className="block text-xs text-gray-400 mb-2">Warehouse Goods Lead TIme</label>
                <input type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" />
            </div>
        </div>

        <h3 className='font-medium text-sm text-gray-500 mt-6 mb-2'>Availability</h3>

        <div className='w-full flex flex-row gap-x-8 mt-1 mb-5'>
            <div className='w-1/3'>
                <label className="text-xs text-gray-600 mb-3"><input type="checkbox" checked={storeContent} onChange={setStoreContent} className="mt-3 mr-3" />Store Products </label>

            </div>
            <div className='w-1/3'>
                <label className="text-xs text-gray-600 mb-3"><input type="checkbox" checked={warehouseProducts} onChange={setWarehouseProducts} className="mt-3 mr-3" /> Warehouse Products </label>
            </div>
            <div className='w-1/3'>
                <label className="text-xs text-gray-600 mb-3"><input type="checkbox" checked={majorProducts} onChange={setMajorProducts} className="mt-3 mr-3" />
                Major Products (not if ONLY accessories in basket) 
                </label>
            </div>
        </div>
        
        <div className='w-full flex flex-row gap-x-8 mt-5 mb-5'>
            <div className="w-1/2">
                <label className="block text-xs text-gray-400 mb-2">Restrict to Group of Products</label>
                <select className='w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs'>
                    <option>All Products</option>
                </select>
            </div>
            <div className="w-1/2">
                <label className="block text-xs mb-2 text-white">Restrict to Group of Products</label>
                <button className='px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs'>
                    Manage Groups
                </button>
            </div>
        </div>

        <div className='w-full flex flex-row gap-x-3 items-center'>
            <label className="text-xs text-gray-600 mb-3">
                block 
                <input type="checkbox" checked={block} onChange={setBlock} className="mt-3 ml-3" />
            </label>
            <p className="text-xs font-medium text-red-600">Disallow delivery if ALL of these combination are met.</p>
        </div>

        <div className='flex flex-row-reverse gap-x-3'>
            <button className='px-5 py-3 border border-blue-700 bg-blue-700 text-white rounded transition duration-200 text-xs'>
                Save
            </button>
            <button className='px-5 py-3 border border-ink-navy text-ink-navy rounded transition duration-200 text-xs'>
                Cancel
            </button>
        </div>
    </div>
    )
}

export default NewEditPostageRule
