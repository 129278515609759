import React, { useState } from 'react'
import SearchIcon from '../../assets/images/icons/search.svg'
import { reviews } from '../../pages/admin/products/Reviews'
import { bookings } from '../../pages/admin/bookings/AllBookings'
import ProductImage  from '../../assets/images/bike-image.png'
import { customers } from '../../pages/admin/customers/AllCustomers'
import { Link } from 'react-router-dom'

const GlobalSearch = () => {
    const [searchResults, setSearchResults] = useState({})
    const [resultsActive, setResultsActive] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const orders = [
        {
            orderId: "11143",
            customer: "Mary Hare",
            orderValue: 92.99,
            payment: "Klarna",
            date: "2021-04-08",
            device: "Desktop",
            status: "Awaiting Payment",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            selected: false
        },
        {
            orderId: "11144",
            customer: "Bella Alubo",
            orderValue: 649.99,
            payment: "WP",
            date: "2021-04-07",
            device: "Desktop",
            status: "Awaiting Payment",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            selected: false
        },
        {
            orderId: "11143",
            customer: "Mary Hare",
            orderValue: 92.99,
            payment: "Klarna",
            date: "2021-04-08",
            device: "Desktop",
            status: "Dispatched",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            selected: false
        },
        {
            orderId: "11144",
            customer: "Bella Alubo",
            orderValue: 649.99,
            payment: "WP",
            date: "2021-04-07",
            device: "Desktop",
            status: "Complete",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            selected: false
        },
        {
            orderId: "11145",
            customer: "Granit Xhaka",
            orderValue: 129.99,
            payment: "PP_EC",
            date: "2021-04-07",
            device: "Mobile",
            status: "Awaiting Payment",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            selected: false
        },
        {
            orderId: "11146",
            customer: "Ruben Nevez",
            orderValue: 3100.00,
            payment: "SP",
            date: "2020-12-10",
            device: "PPH",
            status: "Awaiting Payment",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            selected: false
        },
        {
            orderId: "11147",
            customer: "Max Payne",
            orderValue: 649.99,
            payment: "PPH",
            date: "2020-07-27",
            device: "PPH",
            status: "Awaiting Payment",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            selected: false
        },
        {
            orderId: "11146",
            customer: "Ruben Nevez",
            orderValue: 3100.00,
            payment: "SP",
            date: "2020-12-10",
            device: "PPH",
            status: "Complete",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            selected: false
        },
        {
            orderId: "11147",
            customer: "Max Payne",
            orderValue: 649.99,
            payment: "PPH",
            date: "2020-07-27",
            device: "PPH",
            status: "Complete",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            selected: false
        },
        {
            orderId: "11148",
            customer: "Terry Very Merry",
            orderValue: 4000.00,
            payment: "Klarna",
            date: "2020-08-16",
            device: "Desktop",
            status: "Processing",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            selected: false
        },
        {
            orderId: "11149",
            customer: "Mary Jayne",
            orderValue: 259.00,
            payment: "Klarna",
            date: "2020-12-25",
            device: "Desktop",
            status: "Awaiting Payment",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            selected: false
        },
        {
            orderId: "11148",
            customer: "Terry Very Merry",
            orderValue: 4000.00,
            payment: "Klarna",
            date: "2020-08-16",
            device: "Desktop",
            status: "Ready to process",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            selected: false
        },
        {
            orderId: "11149",
            customer: "Mary Jayne",
            orderValue: 259.00,
            payment: "Klarna",
            date: "2020-12-25",
            device: "Desktop",
            status: "Dispatched",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            selected: false
        },
        {
            orderId: "11150",
            customer: "Olegunar Solskjaer",
            orderValue: 307.99,
            payment: "PP_EC",
            date: "2020-08-22",
            device: "Mobile",
            status: "Processing",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            selected: false
        },
        {
            orderId: "11151",
            customer: "Podolski Alfamilano",
            orderValue: 289.00,
            payment: "PP_EC",
            date: "2020-01-14",
            device: "Mobile",
            status: "Awaiting Payment",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            selected: false
        },
        {
            orderId: "11150",
            customer: "Olegunar Solskjaer",
            orderValue: 307.99,
            payment: "PP_EC",
            date: "2020-08-22",
            device: "Mobile",
            status: "Cancelled",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            selected: false
        },
        {
            orderId: "11151",
            customer: "Podolski Alfamilano",
            orderValue: 289.00,
            payment: "PP_EC",
            date: "2020-01-14",
            device: "Mobile",
            status: "Deleted",
            actions: [],
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            selected: false
        },
    ]

    const products = [
        {
            productId: "11143",
            productImage: ProductImage,
            productName: "Rfr pedals flat salmon sal",
            sampleSku: "14156",
            brand: "RFR",
            year: 2020,
            stock: 1,
            status: "live",
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            stockIncrease: true,
            actions: [],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            selected: false,
            featured: true,
            visibleOn: ""
        },
        {
            productId: "11144",
            productName: "Bella 650A/19 - 26",
            sampleSku: "6307190",
            brand: "British Eagle",
            status: "live",
            stock: 3,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: true,
            year: 2018,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11145",
            productName: "403 Granite V2",
            sampleSku: "W403V2-1",
            brand: "Whyte",
            status: "live",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11146",
            productName: "Cube acid 240 disc reefblue/kiwi/red…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11147",
            productName: "Cube cubie 120 green/blue 2020",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11148",
            productName: "Cube cubie 120 walk action team bl…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11149",
            productName: "Trek District+ 1 M Gy 300Wh",
            sampleSku: "32320024",
            brand: "RFR",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11143",
            productName: "Rfr pedals flat salmon sal",
            sampleSku: "14156",
            brand: "RFR",
            status: "live",
            stock: 1,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: true,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11144",
            productName: "Bella 650A/19 - 26",
            sampleSku: "6307190",
            brand: "British Eagle",
            status: "live",
            stock: 3,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: true,
            year: 2018,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11145",
            productName: "403 Granite V2",
            sampleSku: "W403V2-1",
            brand: "Whyte",
            status: "live",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11146",
            productName: "Cube acid 240 disc reefblue/kiwi/red…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11147",
            productName: "Cube cubie 120 green/blue 2020",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11148",
            productName: "Cube cubie 120 walk action team bl…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11149",
            productName: "Trek District+ 1 M Gy 300Wh",
            sampleSku: "32320024",
            brand: "RFR",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
    ]

    const openSearchResults = (term) => {
        setSearchTerm(term)
        if(searchTerm.length < 2) {
            setResultsActive(false)
            return
        }
        setResultsActive(true)
        performSearches(term)
    }

    const performSearches = (term) => {
        const results = {}
        const filteredOrders = orders.filter((order) => {
            return order.customer.toLowerCase().includes(term.toLowerCase()) || 
            order.customer.toLowerCase().includes(term.toLowerCase()) ||
            order.orderId.toLowerCase().includes(term.toLowerCase()) 
        })
        results.orders = filteredOrders

        const filteredBookings = bookings.filter((booking) => {
            return booking.customerName.toLowerCase().includes(term.toLowerCase()) || 
            booking.workshop.toLowerCase().includes(term.toLowerCase()) || 
            booking.jobNo.toLowerCase().includes(term.toLowerCase())
        })
        results.bookings = filteredBookings

        const filteredReviews = reviews.filter((review) => {
            return review.customer.toLowerCase().includes(term.toLowerCase()) || 
            review.product.toLowerCase().includes(term.toLowerCase()) || 
            review.review.toLowerCase().includes(term.toLowerCase()) 
        })
        results.reviews = filteredReviews

        const filteredProducts = products.filter((product) => {
            return product.productName.toLowerCase().includes(term.toLowerCase()) || 
            product.brand.toLowerCase().includes(term.toLowerCase()) || 
            product.sampleSku.toLowerCase().includes(term.toLowerCase()) ||
            product.description.toLowerCase().includes(term.toLowerCase()) 
        })
        results.products = filteredProducts

        const filteredCustomers = customers.filter((customer) => {
            return customer.name.toLowerCase().includes(term.toLowerCase()) || 
            customer.email.toLowerCase().includes(term.toLowerCase()) || 
            customer.location.toLowerCase().includes(term.toLowerCase())
        })
        results.customers = filteredCustomers

        setSearchResults(results)
    }

    return (
        <div className='relative'>
            <div className="relative rounded shadow-sm border border-gray-200 py-2 px-4 bg-white w-full">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm mt-1">
                        <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                    </span>
                </div>
                <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7" placeholder="Search" onChange={(e)=>(openSearchResults(e.target.value))} />
            </div>
            
            {resultsActive && <div className='w-full absolute top-12 bg-white z-50 shadow-lg rounded-md border border-gray-200 overflow-y-scroll p-6' style={{height:'80vh'}}>
                <button className='absolute top-3 right-3 text-gray-600 hover:text-gray-400 transition duration-200' onClick={()=>{setResultsActive(false)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <h3 className='text-md font-medium text-ink-navy'>Search Results</h3>
                <div className='w-full border-b border-gray-200 py-6'>
                    <p className='font-medium text-sm text-ink-navy mb-3'>Orders</p>
                    {searchResults.orders && searchResults.orders.length > 0 ?
                    searchResults.orders.map((result, resultIndex) => (
                        <button className='w-full flex flex-row gap-x-4 items-center'>
                            <Link to={`order/${result.orderId}`} className='font-medium text-ink-navy'>{result.orderId}</Link>
                            {/* <div> */}
                                <img src={result.customerImage} alt="" className="rounded-full w-5 h-5 inline-block mr-2" />
                                <span className="font-sofia-pro text-xs">{result.customer}</span>
                                <span className="font-sofia-pro text-xs">{result.date}</span>
                                <span className="font-sofia-pro text-xs">{result.status}</span>
                            {/* </div> */}
                        </button>
                    )) : 
                        <div className='rounded bg-gray-100 w-4/5 rounded-sm p-4 items-center m-auto'>
                            <p className='text-xs text-gray-400'>No results in orders</p>
                        </div>
                    }
                </div>

                <div className='w-full py-6'>
                    <p className='font-medium text-sm text-ink-navy mb-3'>Products</p>
                    {searchResults.products && searchResults.products.length > 0 ?
                    searchResults.products.map((result, resultIndex) => (
                        <button className='w-full flex flex-row gap-x-4 mb-3 border-b border-gray-100'>
                            <span className="font-sofia-pro text-xs">{result.productId}</span>
                            <img className='rounded-sm w-18' src={result.productImage} alt="" />
                            <div className='text-left'>
                                <span className="font-sofia-pro text-xs">{result.productName}</span><br />
                                <span className="font-sofia-pro text-xs text-gray-400">{result.description}</span>
                            </div>
                            
                        </button>
                    )) : 
                        <div className='rounded bg-gray-100 w-4/5 rounded-sm p-4 items-center m-auto'>
                            <p className='text-xs text-gray-400'>No results in products</p>
                        </div>
                    }
                </div>

                <div className='w-full py-6'>
                    <p className='font-medium text-sm text-ink-navy mb-3'>Reviews</p>
                    {searchResults.reviews && searchResults.reviews.length > 0 ?
                    searchResults.reviews.map((result, resultIndex) => (
                        <button className='w-full text-left border-b border-gray-100 py-3'>
                            <span className="font-sofia-pro text-xs">{result.customer}</span><br/>
                            <span className="font-sofia-pro text-xs">{result.product}</span><br />
                            <span className="font-sofia-pro text-xs text-gray-400">{result.review}</span>
                        </button>
                    )) : 
                        <div className='rounded bg-gray-100 w-4/5 rounded-sm p-4 items-center m-auto'>
                            <p className='text-xs text-gray-400'>No results in reviews</p>
                        </div>
                    }
                </div>

                <div className='w-full py-3'>
                    <p className='font-medium text-sm text-ink-navy mb-3'>Bookings</p>
                    {searchResults.bookings && searchResults.bookings.length > 0 ?
                    searchResults.bookings.map((result, resultIndex) => (
                        <button className='w-full flex flex-row gap-x-4 items-center py-3 border-b border-gray-100'>
                            <span className="font-sofia-pro text-xs">{result.jobNo}</span>
                            <div className="flex flex-row items-center gap-x-3">
                                <img alt="" src={result.customerImage} className="w-7 h-7 rounded-full" />
                                <span className="font-sofia-pro text-xs">{result.customerName}</span>
                            </div>
                            <span className="font-sofia-pro text-xs">{result.workshop}</span>
                            <span className="font-sofia-pro text-xs">{result.date}</span>
                            <span className="font-sofia-pro text-xs">{result.status}</span>
                        </button>
                    )) : 
                    <div className='rounded bg-gray-100 w-4/5 rounded-sm p-4 items-center m-auto'>
                            <p className='text-xs text-gray-400'>No results in bookings</p>
                        </div>
                    }
                </div>

                <div className='w-full py-6'>
                    <p className='font-medium text-sm text-ink-navy mb-3'>Customers</p>
                    {searchResults.customers && searchResults.customers.length > 0 ?
                    searchResults.customers.map((result, resultIndex) => (
                        <button key={resultIndex} className='w-full flex flex-row gap-x-4 items-center py-4 border-b border-gray-100'>
                            <span className="font-sofia-pro text-xs">{result.id}</span>
                            <div className="flex flex-row items-center gap-x-3">
                                <img alt="" src={result.image} className="w-7 h-7 rounded-full" />
                                <span className="font-sofia-pro text-xs">{result.name}</span>
                            </div>
                            <span className="font-sofia-pro text-xs">{result.email}</span>
                        </button>
                    )) : 
                        <div className='rounded bg-gray-100 w-4/5 rounded-sm p-4 items-center m-auto'>
                            <p className='text-xs text-gray-400'>No results in customers</p>
                        </div>
                    }
                </div>

            </div>}
        </div>
    )
}

export default GlobalSearch
