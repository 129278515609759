import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory, useLocation } from 'react-router'
import ErrorMessage from '../../../components/elements/ErrorMessage'
import ChevronIcon from '../../../components/elements/icons/ChevronIcon'
import SuccessMessage from '../../../components/elements/SuccessMessage'
import Wysiwyg from '../../../components/elements/Wysiwyg'
import axios from "axios"
import { baseUrl } from '../../../utils';
import Spinner from '../../../components/elements/icons/Spinner'
import AutocompleteSelect from '../../../components/elements/AutocompleteSelect'
import StatusSelect from '../../../components/partials/support/StatusSelect'
import PrioritiesSelect from '../../../components/partials/support/PrioritiesSelect'

const SupportTicket = () => {
  const [detailsOpen, setDetailsOpen] = useState(true)
  const [notesOpen, setNotesOpen] = useState(true)
  const [validationErrors, setValidationErrors] = useState({})

  const validateForm  = () => {
    let errors = {}
    
    // if(!pageTitle || pageTitle === '') {
    //     errors.pageTitle = true
    // }
    setValidationErrors(errors)
    return errors
  }

  const [ cookies ] = useCookies(['user'])  
  const history = useHistory();
  const location = useLocation()
  const [users, setUsers] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [processing, setProcessing] = useState(true)
  const [ error, setError ] = useState('')

  useEffect(() => {
    const fetchUsers = async  () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        setLoadingData(true)
        try { 
            let response = null 
            response = await axios.get(`/api/v1/admin/users/?page[offset]=1&page[limit]=1000000&page[order]=desc`, { headers })     
            // const cleanUsers = await Promise.all(response.data.data.map(async (user) => {
            //   user.name = `${user.first_name} ${user.last_name}`
            //   return
            // }))
            let tempUsers = response.data.data
            tempUsers.forEach((user) => {
              user.name = `${user.first_name} ${user.last_name}`
              return
            })
            setUsers(tempUsers)
            setLoadingData(false)
        } catch (error) {
            console.error(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
              window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setLoadingData(false)
            }
        }
    }

    fetchUsers()

}, [cookies.accessToken, cookies.tokenType, history, location.pathname])

  const createTicket = () => {
    try {
      setProcessing(true)
      
    } catch (error) {
      setProcessing(false)
      
    }
  }

  const [message, setMessage] = useState("")
  const [newNote, setNewNote] = useState('')

  const loadWysiwygValue = (value) => {
    setMessage(value)
  }

  const [addingNote, setAddingNote] = useState(false)
  const [noteSuccess, setNoteSuccess] = useState('')
  const [newNoteError, setNewNoteError] = useState('')
  const [assignedTo, setAssignedTo] = useState('')

  return (
    <>
     {!loadingData && error === '' ?
     <>
        <div className='w-full flex'>
          <div className='w-full lg:w-8/12 px-12 py-8'>
            <div className="w-full my-8">
                <label className={`text-xs text-sm block mb-2 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>Name *</label>
                <input type="text" className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="About Cycle Concept" 
                // onChange={(e)=>{updatePageTitle(e.target.value)}} 
                />
            </div> 
            <div className="w-full my-8">
                <label className={`text-xs text-sm block mb-2 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>Topic *</label>
                <input type="text" className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="About Cycle Concept" 
                // onChange={(e)=>{updatePageTitle(e.target.value)}} 
                />
            </div> 
            <div className="w-full my-8">
                <label className={`text-xs text-sm block mb-2 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>Message</label>
                <div className='long-text'>
                    <Wysiwyg 
                        fieldTitle=""
                        updateValue={loadWysiwygValue}
                    />
                </div>
            </div> 
          </div>

          <div className='w-full lg:w-4/12 h-inherit border-l border-b pb-12'>
            <div className='p-4 bg-gray-100 flex items-center justify-between'>
                <h3 className='text-ink-navy text-sm font-medium'>Details</h3>
                <button onClick={()=>{setDetailsOpen(!detailsOpen)}} >
                    <ChevronIcon className={`text-ink-navy w-4 h-4 transition duration-200 transform ${detailsOpen ? 'rotate-180' : ''}`} />
                </button>
            </div>

            {detailsOpen && 
              <div className='p-6'>
                <div className="w-full mb-3">
                  <label className={`text-xs text-sm block mb-2 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>Created by</label>
                  <input type="text" className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="About Cycle Concept" 
                  />
                </div> 

                <div className="w-full mb-3">
                  <label className={`text-xs text-sm block mb-2 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>Assigned to</label>
                  {/* <input type="text" className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="About Cycle Concept" 
                  /> */}
                  <AutocompleteSelect 
                      placeholderText="Find a user" 
                      selectOptions={users}
                      titleField='name'
                      displayImage={false}
                      imageField=''
                      hasError={false}
                      returnFieldValue={(value) => {setAssignedTo(value)}}
                  />
                </div> 

                <div className="w-full mb-3">
                  <label className={`text-xs text-sm block mb-2 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>Status</label>
                  <StatusSelect />
                </div> 

                <div className="w-full mb-3">
                  <label className={`text-xs text-sm block mb-2 ${validationErrors.pageTitle && validationErrors.pageTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>Priority</label>
                  <PrioritiesSelect />
                </div> 

                <div className="w-full mb-3 flex items-center justify-between mt-8">
                  <p className='text-sm text-gray-400'>Date Created</p>
                  <p className='text-sm text-ink-navy'>2020-02-28</p>
                </div>
                <div className="w-full mb-3 flex items-center justify-between">
                  <p className='text-sm text-gray-400'>Last Modified</p>
                  <p className='text-sm text-ink-navy'>2020-02-28</p>
                </div>

              </div>
            }

            <div className='p-4 bg-gray-100 flex items-center justify-between'>
                <h3 className='text-ink-navy text-sm font-medium'>Notes</h3>
                <button onClick={()=>{setNotesOpen(!notesOpen)}} >
                    <ChevronIcon className={`text-ink-navy w-4 h-4 transition duration-200 transform ${notesOpen ? 'rotate-180' : ''}`} />
                </button>
            </div>

            {notesOpen &&
              <div className='p-6'>
                <textarea onChange={(e)=>{setNewNote(e.target.value)}} className="w-full px-4 py-2 bg-white border border-gray-400 border-2 mb-4 rounded-md h-24  w-full font-sofia-pro" placeholder="Add new note..." />
                <div className="w-full text-right">
                    <button disabled={addingNote} onClick={()=>{}} className="mb-3 font-sofia-pro px-4 py-2 bg-ink-navy text-white text-sm rounded">
                        {addingNote ? 'Adding note...' : 'Add note'}
                    </button>
                    {noteSuccess && noteSuccess !== '' &&
                        <SuccessMessage message={noteSuccess} dismissHandler={()=>{setNoteSuccess('')}} />
                    }
                    {newNoteError && newNoteError !== '' &&
                        <ErrorMessage message={newNoteError} dismissHandler={()=>{setNewNoteError('')}} />
                    }
                </div>
              </div>

            }

          </div>

        </div>
        <div className='p-6 w-full flex flex-row-reverse'>
          <div className='w-44'>
            {processing ?
                <button className='w-full py-4 text-center bg-blue-700 rounded text-white text-xs font-medium transition duration-200 hover:bg-gray-600'>
                    Creating ticket... 
                </button>
                :
                <button className='w-full py-4 text-center bg-blue-700 rounded text-white text-xs font-medium transition duration-200 hover:bg-gray-600' onClick={()=>{createTicket()}}>Create Ticket</button>
            }
          </div>
        </div>
    </>
    :
      <div className='w-full flex flex-col items-center justify-center mt-10'>
          <Spinner />
          {/* <p className='text-sm text-gray-500 mt-5'>Fetching users...</p> */}
      </div>
    } 
    </>
  )
}
export default SupportTicket