import React, { Fragment, useState } from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
// import ExportIcon from '../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import ProductImage  from '../../../assets/images/bike-image.png'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import { Link } from 'react-router-dom'
import DataTable from '../../../components/elements/DataTable'
import { yesteryears } from '../../../utils'

const UncategorizedProducts = () => {
    let products = [
        {
            id: "11143",
            productImage: ProductImage,
            sku: "14156",
            barcode: "4054571245243",
            colour:"blu/lim",
            simpleColour: "Blue",
            stock: 1,
            size: "TS4",
            rrp: "2199",
            salePrice: ""
        },      
        {
            id: "12143",
            productImage: ProductImage,
            sku: "6307190",
            barcode: "4054571245243",
            colour:"blk/red",
            simpleColour: "Red",
            stock: 1,
            size: "TS4",
            rrp: "2199",
            salePrice: ""
        },      
        {
            id: "34143",
            productImage: ProductImage,
            sku: "W407190",
            barcode: "4054571245243",
            colour:"blu/lim",
            simpleColour: "White",
            stock: 1,
            size: "TS4",
            rrp: "2199",
            salePrice: ""
        },      
    ]

    const tableOptions = {
        selectable: true
    }

    const columnWidths = {
        id: 'w-full lg:w-1/12',
        image: 'w-full lg:w-1/12',
        sku: 'w-full lg:w-1/12',
        barcode: 'w-full lg:w-2/12',
        colour: 'w-full lg:w-1/12',
        simpleColour: 'w-full lg:w-2/12',
        stock: 'w-full lg:w-1/12',
        size: 'w-full lg:w-1/12',
        rrp: 'w-full lg:w-1/12',
        salePrice: 'w-full lg:w-1/12'
    }

    const columnDataStyles = {}

    const convertCamelCase = (camelCaseText) => {
        const text = camelCaseText;
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult
    }

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key
            
            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true

            if(index > 10) {
                fieldSelected = false
            }
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });
        return {headers, fields}
    }

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const bulkActions = [
        {
            label: "Add to Group",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

    const filters = [
        {
            optionName: "Brands",
            options: [
                {name: "RFR", selected: false},
                {name: "British League", selected: false},
                {name: "White", selected: false},
                {name: "Cube", selected: false},
            ]
        }, 
        {
            optionName: "Years",
            options: yesteryears(4),
        },
        {
            optionName: "Stock",
            options: [
                {name: "Available", selected: false},
                {name: "Out of Stock", selected: false},
            ],
        },
        {
            optionName: "Status",
            options: [
                {name: "Live", selected: false},
                {name: "Inactive", selected: false},
            ],
        },
        {
            optionName: "Filter options",
            options: [
                {name: "Option 1", selected: false},
                {name: "Option 2", selected: false},
                {name: "Option 3", selected: false},
            ]
        }
    ]

    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0)

    const [allFilters, setAllFilters] = useState(filters)
    const toggleFilterSelection = (optionIndex, filterIndex) => {
        let newFilters = JSON.parse(JSON.stringify(allFilters))
        newFilters[optionIndex].options[filterIndex].selected = !newFilters[optionIndex].options[filterIndex].selected
        setAllFilters(newFilters)
        setSelectedFiltersCount(countSelectedFilters(newFilters))
    }

    const countSelectedFilters = (filters) => {
        let count = 0
        filters.forEach((option) => {
            option.options.forEach((filter) => {
                if(filter.selected) {
                    count++
                }
            })
        })
        return count
    }

    const clearFilters = () => {
        let newFilters = JSON.parse(JSON.stringify(allFilters))
        newFilters.forEach((option) => {
            option.options.forEach((filter) => {
                filter.selected = false
            })
        })
        setAllFilters(newFilters)
        setSelectedFiltersCount(countSelectedFilters(newFilters))
    }

    const ProductPhoto = ({image}) => {
        return (
            <img src={image} alt="" className='w-3/5 lg:w-full' />
        )
    }

    const cleanUpProducts = (products) => {
        let finalResult = [] 
        products.forEach((object, objectIndex) => {
            const product = {
                id: object.id,
                image: <ProductPhoto image={object.productImage} />,
                sku: object.sku,
                barcode: object.barcode,
                colour:object.colour,
                simpleColour: object.simpleColour,
                stock: object.stock,
                size: object.size,
                rrp: object.rrp,
                salePrice: object.salePrice
            }
            finalResult.push(product)
        })
        return finalResult
    }

    const [selectedProductsCount, setSelectedProductsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedProductsCount(count)
    }

    return (
        <Fragment>
            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between">
                    <div className="flex flex-row justify-between items-center w-full lg:w-5/12 mb-4 lg:mb-0">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full lg:w-10/12 mr-1">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search products" />
                        </div>
                        <div className="relative">
                            <button 
                                className={`rounded py-3 px-4 text-xs flex flex-row items-center border ${filtersActive ? 'text-gray-800 border-gray-800' : 'text-gray-400 border-gray-200'}`} 
                                onClick={()=>{toggleFilters()}} 
                            >
                                <img src={FilterIcon} alt="search" className="w-4 mr-2" /> <span className='hidden md:inline'>Filters</span> {selectedFiltersCount > 0 && <span className={`text-xs text-white rounded px-1 ml-1 mr-3 ${filtersActive ? 'bg-ink-navy' : 'bg-gray-400'}`}>{selectedFiltersCount}</span>}
                            </button>
                            {/* Filters popover */}
                            {filtersActive && 
                                <div className="absolute shadow-lg bg-white top-16 border border-gray-100 z-10" style={{width: '800px', left: '-200px',}}>
                                    <div className="w-full flex flex-row items-center justify-between border-b p-4">
                                        <p className="font-medium text-ink-navy text-xs">Filters</p>
                                        <button className="font-sofia-pro underline text-blue-700 text-xs" onClick={()=>{clearFilters()}}>Clear filters</button>
                                    </div>
                                    <div className="w-full flex flex-row justify-between border-b p-8">
                                        {allFilters.map((filterOption, optionIndex) => (
                                            <div key={optionIndex}>
                                                <p className="font-medium text-xs mb-3 text-ink-navy">{filterOption.optionName}</p>
                                                {filterOption.optionName === 'Brands' &&
                                                    <input type="text" placeholder="Search" className="py-2 px-3 w-full border rounded border-gray-300 text-xs" />
                                                }
                                                {filterOption.options.map((filter, filterIndex) => (
                                                    <div className="w-2/10 mt-1" key={filterIndex}>
                                                        <input type="checkbox" onChange={()=>toggleFilterSelection(optionIndex, filterIndex)} checked={filter.selected} className="mr-2 mt-3" />
                                                        <p className="text-xs inline-block mt-3">{filter.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-2 justify-between">
                        {selectedProductsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        {/* <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border w-full lg:w-max">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden xl:inline" /> Import CSV
                        </button>
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border w-full lg:w-max">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden xl:inline" /> Export
                        </button> */}
                        <Link to="new-product" className="rounded border-blue-800 bg-blue-800 py-3 px-4 text-xs text-white flex flex-row items-center border w-full lg:w-max">
                            Group Products
                        </Link>
                    </div>
                </div>

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpProducts(products)[0]).headers} 
                    tableData={cleanUpProducts(products)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpProducts(products)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />
            </div>
        </Fragment>
    )

}

export default UncategorizedProducts
