import React from 'react'
import Layout from '../../components/layout/Layout'
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
  } from "react-router-dom";
import AdminErrorPage from './AdminErrorPage';
import AllBookings from './bookings/AllBookings';
// import BookingsCalendar from './bookings/BookingsCalendar';
import BookingsCalendarNew from './bookings/BookingsCalendarNew';
import NewBooking from './bookings/NewBooking';

const Bookings = () => {
    let { 
        path, 
        // url 
    } = useRouteMatch();

    const sidebarLinks = [
        {
            label: "Bookings Calendar",
            location: "/admin/bookings/bookings-calendar",
            subLinks: []
        },
        {
            label: "All Bookings",
            location: "/admin/bookings/all-bookings",
            sublinks: []
        }
    ]
    return (
        <Layout pageTitle="Bookings" noPadding={true} sidebarLinks={sidebarLinks}>
            {/* {path} */}
            <Switch>
                <Route
                    path={path}
                    exact
                    render={() => {
                        return (
                        <Redirect to="/admin/bookings/bookings-calendar" />
                        )
                    }}
                    // render={() => {
                    //     return (
                    //     this.state.isUserAuthenticated ?
                    //     <Redirect to="/home" /> :
                    //     <Redirect to="/test1" /> 
                    //     )
                    // }}
                />

                <Route path={`${path}/all-bookings`} exact>
                    <AllBookings />
                </Route>
                
                <Route path={`${path}/bookings-calendar`} exact>
                    {/* <BookingsCalendar/> */}
                    <BookingsCalendarNew />
                </Route>
                
                <Route path={`${path}/new-booking`} exact>
                    {/* <BookingsCalendar/> */}
                    <NewBooking />
                </Route>

                <Route component={AdminErrorPage} />

            </Switch>
        </Layout>
    )
}

export default Bookings
