import React, { Fragment, useState, useEffect } from 'react'
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import MaximizeIcon from '../../../../assets/images/icons/maximize.svg'
import Nestable from 'react-nestable';
import { Dialog, Transition } from '@headlessui/react'
import Spinner from '../../../../components/elements/icons/Spinner';
import axios from "axios"
import { baseUrl } from '../../../../utils';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom'
import NavPreview from '../../../../components/partials/navigations/NavPreview';
import NewMenuItem from '../../../../components/partials/navigations/NewMenuItem';

const MenuDetails = () => {
    const [ cookies ] = useCookies(['user']);
    const { id } = useParams()

    const [processing, setProcessing] = useState(true)
    // const [navDetails, setNavDetails] = useState(null)
    const [error, setError] = useState('')
    const [items, setItems] = useState([])
    const [reload, setReload] = useState(true);

    useEffect(() => {
        const fetchMenus = async  () => {
            setReload(false)
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/navigation/${id}`, { headers })            
                console.log(response)
                setItems(response.data.data.children)
                // setPagination({
                //     page: pagination.page,
                //     perPage: pagination.perPage,
                //     totalRecords: response.data.total_records,
                //     nextPage: response.data.next,
                //     lastPage: response.data.last
                // })
                setProcessing(false)
            } catch (error) {
                console.log(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }
        fetchMenus()
    }, [cookies.accessToken, cookies.tokenType, id, reload])

    let [navPreviewOpen, setNavPreviewOpen] = useState(false)

    function closeNavPreview() {
        setNavPreviewOpen(false)
    }

    function openNavPreview() {
        setNavPreviewOpen(true)
    }

    const updateItems = (update) => {
        setItems(update.items)
    }
    const [activeFlyout, setActiveFlyout] = useState(null)
    const changeActiveFlyout = (categoryId) => {
        setActiveFlyout(categoryId)
    }

    const removeFlyout = () => {
        setActiveFlyout(null)
    }

    const previewStyles = {
        backgroundColor: "#ffffff",
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 8 8'%3E%3Cg fill='%23c1c1c1' fill-opacity='0.15'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`
    }

    const setChildUpdate = (parentIndex, grandParentIndex, index, key, value) => {
        console.log(items)
    }

    const renderItem = ({ item }) => (
        <Disclosure>
        {({ open }) => (
        <>
            <Disclosure.Button className="relative text-left rounded bg-gray-100 border border-gray-300 px-5 py-3 text-xs text-ink-navy w-full cursor-move">
                <span>{item.name}</span>
                <ChevronUpIcon
                    className={`inline absolute right-4 top-3 ${
                    open ? '' : 'transform rotate-180'
                    } w-4 h-4 text-gray-500`}
                />
            </Disclosure.Button>
            <Disclosure.Panel className="pb-0 text-sm text-gray-500">
                {/* {listLinks.map((link, linkIndex) => (
                    <Link to={link.location} key={linkIndex} className="block py-1 hover:text-ink-navy">{link.label}</Link>  
                ))} */}
                <div className="border border-gray-200 bg-gray-100 bg-opacity-40 px-5 py-2 pb-4 rounded-b-lg">
                    <label className="text-xs text-gray-500 mt-5 mb-2 block">Label</label>
                    <input className="px-2 border rounded py-4 border-gray-300 w-full block text-xs active:border-gray-400 transition duration-200" placeholder="Navigation Label" value={item.name} onChange={()=>{setChildUpdate()}}  />

                    <label className="text-xs text-gray-500 mt-5 mb-2 block">Link</label>
                    <input className="px-2 border rounded py-4 border-gray-300 w-full block text-xs active:border-gray-400 transition duration-200" placeholder="Link URL"  />

                    <label className="text-xs text-gray-500 mt-5 mb-2 block">Custom link</label>
                    <input className="px-2 border rounded py-4 border-gray-300 w-full text-xs block active:border-gray-400 transition duration-200" placeholder="Custom link URL"  />

                    <div className='flex flex-row justify-between items-center'>
                        <div className="flex gap-x-4 w-full">
                            <button className="mt-5 rounded border-blue-800 bg-blue-800 py-2 px-3 text-xs text-white flex flex-row items-center border">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                </svg>
                                Add Category
                            </button>
                        </div>

                        <div className="flex flex-row-reverse gap-x-4 w-full">
                            <button className="text-right mt-5 text-xs text-ink-navy px-3 py-2 rounded border border-ink-navy bg-blue-100 hover:bg-ink-navy hover:text-white transition duration-200" onClick={()=>{}}>Update</button>
                            <button className="text-right mt-5 text-xs text-red-700">Delete</button>
                        </div>
                    </div>
                </div>
            </Disclosure.Panel>
        </>
        )}
        </Disclosure>
    );

    let [newMenuItemOpen, setNewMenuItemOpen] = useState(false)

    const closeNewMenuItem = () => {
        setNewMenuItemOpen(false)
    }


    return (
        <>
            {!processing && error === '' ?
            <div className="w-full">
                {/* <button className="rounded border-blue-800 bg-blue-800 py-3 px-5 text-xs text-white flex flex-row items-center border">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                    Add Category
                </button> */}
                <div className="block lg:flex flex-row w-full mt-5">
                    <div className="w-full lg:w-7/12">
                        <div className='flex justify-between mb-6 items-start'>
                            <div className='w-8/12'>
                                <p className="text-xs text-gray-500 mb-5">Drag each item into the order you prefer. Click the arrow on the right of the item to reveal additional configuration options</p>
                            </div>

                            <button 
                                className='rounded px-4 py-3 text-xs bg-blue-700 text-white transition duration-200 hover:bg-ink-navy' 
                                onClick={()=>{setNewMenuItemOpen(true)}}>Add menu item</button>
                        </div>
                        {items && items.length > 0 ? 
                            <Nestable
                                items={items}
                                renderItem={renderItem}
                                onChange={updateItems}
                            />
                        :
                            <div className='w-full rounded-sm border border-ink-navy bg-gray-200 p-4 mt-6'>
                                <p className='text-xs text-ink-navy'>No items added for this menu yet</p>
                            </div>
                        }
                    </div>
                    <div className="w-full lg:w-5/12 px-3 mt-5">
                        <button className='lg:hidden block flex flex-row items-center text-xs text-gray-400 border rounded border-gray-400 px-6 w-full py-4 mb-5 gap-x-4' onClick={openNavPreview}>
                            <img alt="" src={MaximizeIcon} className="w-4 h-4" /> Open Preview
                        </button>
                        <div className="flex flex-row justify-between gap-x-6">
                            <button className="w-1/2 px-4 py-2 bg-white text-ink-navy border rounded border-ink-navy text-xs">
                                Reset Mega Nav
                            </button>
                            <button className="w-1/2 px-4 py-2 bg-white text-ink-navy border rounded border-ink-navy text-xs">
                                Reset Categories
                            </button>
                        </div>

                        <div className="hidden w-full border border-gray-500 p-3 bg-gray-100 mt-5 lg:flex flex-row justify-between">
                            <p className="text-xs text-gray-500">Preview</p>
                            <button onClick={openNavPreview}>
                                <img alt="" src={MaximizeIcon} className="w-4 h-4" />
                            </button>
                        </div>
                        <div className='hidden lg:block'>
                            <NavPreview
                                items={items} 
                                activeFlyout={activeFlyout} 
                                changeActiveFlyout={(id)=>changeActiveFlyout(id)} 
                                previewStyles={previewStyles} 
                                removeFlyout={()=>{removeFlyout()}} />
                        </div>
                    </div>
                </div>

                {/* Full screen preview */}
                <>
                    <Transition appear show={navPreviewOpen} as={Fragment}>
                        <Dialog
                        as="div"
                        className="fixed inset-0 z-10 overflow-y-auto"
                        onClose={closeNavPreview}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        <NavPreview 
                                            items={items} 
                                            activeFlyout={activeFlyout} 
                                            changeActiveFlyout={(id)=>changeActiveFlyout(id)} 
                                            previewStyles={previewStyles} 
                                            removeFlyout={()=>{removeFlyout()}}
                                        />
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
                {/* Full screen preview */}
                <>
                    <Transition appear show={newMenuItemOpen} as={Fragment}>
                        <Dialog
                        as="div"
                        className="fixed inset-0 z-10 overflow-y-auto"
                        onClose={closeNewMenuItem}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        {/* <NavPreview items={items} /> */}
                                        <Dialog.Title
                                            as="h3"
                                            className="text-md font-medium leading-6 text-ink-nvy"
                                        >
                                            Add a new item to this menu 
                                        </Dialog.Title>
                                        <NewMenuItem closeModal={()=>{closeNewMenuItem()}} items={items} navId={id} triggerReload={()=>{setReload(true)}} />
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            </div>
            :
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                <p className='text-sm text-gray-500 mt-5'>Fetching navigation menu details...</p>
            </div>
            }
        </>
    )
}

export default MenuDetails