import React, {useState, useEffect} from 'react'
import Spinner from '../../../../components/elements/icons/Spinner'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { baseUrl } from '../../../../utils';
import UserIcon from '../../../../assets/images/icons/user-icon.svg'
import AutocompleteSelect from '../../../../components/elements/AutocompleteSelect';

const BikeFitRequest = () => {
    const history = useHistory();
    const location = useLocation()
    const [ cookies ] = useCookies(['user'])
    const { requestNumber } = useParams()

    // const [orderDetails, setOrderDetails] = useState({})
    const [customerDetails, setCustomerDetails] = useState({})
    const [users, setUsers] = useState([])
    const [requestDetails, setRequestDetails] = useState({})
    const [processing, setProcessing] = useState(true)
    const [error, setError] = useState(null)
    const [addingComment, setAddingComment] = useState(false)
    const [addingWatcher, setAddingWatcher] = useState(false)
    const [watchers, setWatchers] = useState([]);
    const [loadData, setLoadData] = useState(true);

    useEffect(() => {
      const fetchRequestDetails = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        setProcessing(true)
        try {
            const response = await axios.get(`/api/v1/admin/bike_fits/${requestNumber}`, { headers })       
            setRequestDetails(response.data.data)
            console.log(response.data.data.watchers)

            const watchersArray = response.data.data.watchers
            const intWorkersArray = watchersArray.map((watcherId) => { 
              return parseInt(watcherId); 
            });
            setWatchers(intWorkersArray)
            fetchCustomerDetails(response.data.data.customer_id)
        } catch (error) {
            console.log(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
              window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setProcessing(false)
            }
        }
    }

    const fetchCustomerDetails = async (customerId) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        // setProcessing(true)
        try {
            const response = await axios.get(`/api/v1/admin/customer/${customerId}`, { headers })     
            setCustomerDetails(response.data.data)
            fetchAllUsers()

        } catch (error) {
            console.log(error.response.data.developer_message.errors)
            setError(error.response.data.developer_message.errors)
            setProcessing(false)
        }
    }

    const fetchAllUsers = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        try {
            const response = await axios.get(`/api/v1/admin/users?page[offset]=1&page[limit]=200000000`, { headers })     
            setUsers(response.data.data)
            setProcessing(false)
            setLoadData(false)
        } catch (error) {
            console.log(error)
            setError(error.response.data.developer_message.errors)
            setProcessing(false)
        }
    }

    if(loadData && loadData === true) {
      fetchRequestDetails()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies.accessToken, cookies.tokenType, history, location.pathname, loadData])

    const user = (userId) => {
      const filtered = users.filter((user, index)=>{
        return userId === index + 1
      })
      // console.log('user id----', userId)
      // console.log('user----', filtered)
      return filtered[0]
    }

    const [saving, setSaving] = useState(false)

    const saveUpdates = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        setSaving(true)
        const requestObject = { ...requestDetails, ...{watchers: watchers}}
        // requestDetails.watchers = watchers

        try {
            await axios.patch(`/api/v1/admin/bike_fits`, requestObject, { headers })       
            // console.log(response.data.data)     
            // setRequests(response.data.data)
            setSaving(false)
            setLoadData(true)

        } catch (error) {
            if(error.response.data.developer_message.exception === "AuthenticationException") {
              window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setSaving(false)
            }
        }
    }

    const [newWatcher, setNewWatcher] = useState('')

    const addWatcher = () => {
      if(!newWatcher || newWatcher === '') {
        return
      }

      let tempWatchers = [...watchers]
      tempWatchers.push(newWatcher.id)
      setWatchers(tempWatchers)
      setNewWatcher('')
      setAddingWatcher(false)
    }

    const [newComment, setNewComment] = useState('')
    // const [showComments, setShowComments] = useState(true)

    // const addComment = () => {
    //   if(!newComment || newComment === '') {
    //     return
    //   }

    //   let tempRequest = {...requestDetails}

    //   console.log('---> ', tempRequest)
    //   const comment = {
    //       comment_created_by_user_id: cookies.details.id,
    //       comment_updated_by_user_id: cookies.details.id,
    //       comment_message: newComment,
    //       comment_customer: requestDetails.customerId,
    //       comment_watchers: cookies.details.id
    //   }

    //   tempRequest.comments.push(comment)
    //   setShowComments(false)

    //   setRequestDetails(tempRequest)

    //   setTimeout(() => {
    //     setShowComments(true)
    //     console.log(requestDetails)
    //   }, 100);

    //   // setAddingComment(false)
    //   setNewComment('')
    // }

    const [savingComment, setSavingComment] = useState(false)

    const addComment = async () => {
      if(!newComment || newComment === '') {
        return
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
      }
      setSavingComment(true)
      const requestObject = {
        message: newComment
      }
      try {
          await axios.post(`/api/v1/admin/bike_fits/${requestNumber}/comment`, requestObject, { headers })       
          // console.log(response.data.data)     
          // setRequests(response.data.data)
          setSavingComment(false)
          setNewComment('')
          setLoadData(true)
      } catch (error) {
          if(error.response.data.developer_message.exception === "AuthenticationException") {
            window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
          } else {
              setError(error.response.data.developer_message.errors)
              setSavingComment(false)
          }
      }
    }

    return (
      <>
        {processing ?
          <div className='w-full flex flex-col items-center justify-center mt-10'>
              <Spinner />
              <p className='text-sm text-gray-500 mt-5'>Fetching request details...</p>
          </div>
          
          :

          <>
            {!error || error === '' ? 
              <>
                <div className='w-10/12 ml-auto mr-auto pt-6'>
                  <label className='block text-sm text-gray-400 font-sofia-pro mb-2'>Subject</label>
                  <p className='font-medium'>{requestDetails.ticket_subject}</p>

                  <div className="flex flex-row gap-x-4 mt-8">
                    <div className='w-4/12'>
                      <label className='block text-sm text-gray-400 font-sofia-pro mb-2'>Watchers</label>
                      {watchers && watchers.length > 0 && watchers.map((watcher, watcherIndex) => (
                        <div key={watcherIndex} className='flex gap-x-1 -mt-1 items-center'>
                          <div className='rounded-full bg-gray-200 w-2 h-2' />
                          <p className="text-sm text-gray-500 my-2">{user(parseInt(watcher))?.first_name} {user(parseInt(watcher))?.last_name}</p>
                        </div>
                      ))}

                      <div className="w-full">
                         {addingWatcher && 
                          <div className='mb-3'>
                            <AutocompleteSelect 
                                placeholderText="Find a user..." 
                                selectOptions={users}
                                titleField='first_name'
                                displayImage={true}
                                imageField='productImage'
                                returnFieldValue={(value) => {setNewWatcher(value)}}
                              />
                          </div>
                          }
                        {addingWatcher ? 
                          <button className='px-3 py-2 rounded bg-blue-100 text-ink-navy hover:bg-ink-navy hover:text-white text-xs transition duration-200' onClick={()=>{addWatcher()}}>
                            Add watcher
                          </button>
                          : 
                          <button className='px-3 py-2 rounded bg-blue-100 text-ink-navy hover:bg-ink-navy hover:text-white text-xs transition duration-200' onClick={()=>{setAddingWatcher(true)}}>
                            Add a new watcher
                          </button>
                        }
                      </div>

                    </div>
                  </div>

                  <div className='w-full mt-8'>
                    <label className='block text-sm text-gray-400 font-sofia-pro mb-2'>Customer details</label>

                    <div className="flex flex-row gap-x-4 bg-gray-100 rounded p-5">
                      <div className="flex gap-x-2">
                        <img src={UserIcon} alt="" className="w-10 h-10 rounded-full mb-3" />
                        <div>
                          <p className="text-sm text-ink-navy mb-1">{customerDetails.user.first_name} {customerDetails.user.last_name}</p>
                          <p className="mt-1 text-gray-400 text-sm">{customerDetails.user.email}</p>
                        </div>
                      </div>

                      <p className='text-sm ml-5'>{customerDetails.currency_symbol}{parseInt(customerDetails.amount_spent).toLocaleString()} spent</p>
                    </div>
                  </div>
                  
                  <div className='w-full mt-8'>
                    <label className='block text-sm text-gray-400 font-sofia-pro mb-2'>Comments</label>
                    {requestDetails.comments && requestDetails.comments.map((comment, commentIndex) => (
                      <div key={commentIndex} className="my-5 border-l-2 border-blue-200 pl-4">
                        <p className='text-gray-700 text-sm'>{comment.message}</p>
                        {comment.created_by_user_id && <span className='text-xs text-gray-500'>by <span className='font-medium'>{user(comment.created_by_user_id).first_name} {user(comment.created_by_user_id).last_name}</span></span>}
                      </div>
                    ))}

                    {addingComment && 
                    <div className="mb-3">
                      <label className='block text-sm text-gray-400 font-sofia-pro mb-2'>Please type your comment below</label>
                      <textarea value={newComment} className="resize-none outline-none rounded w-1/2 h-24 border border-gray-400 p-3 text-sm font-sofia-pro" onChange={(e)=>{setNewComment(e.target.value)}} />
                    </div>
                    }

                    <div className="w-full">
                      {addingComment ? 
                        <button disabled={savingComment} className='px-3 py-2 rounded bg-blue-100 text-ink-navy hover:bg-ink-navy hover:text-white text-xs transition duration-200' onClick={()=>{addComment()}}>
                          {savingComment ? 'Posting...' : 'Post comment'}
                        </button>
                        : 
                        <button className='px-3 py-2 rounded bg-blue-100 text-ink-navy hover:bg-ink-navy hover:text-white text-xs transition duration-200' onClick={()=>{setAddingComment(true)}}>
                          Add new comment
                        </button>
                      }
                    </div>
                  </div>

                    <div className='mt-8'>
                      <button className='rounded border-blue-800 bg-blue-800 py-3 px-4 text-xs text-white flex flex-row items-center    border w-full lg:w-max' 
                        onClick={()=>{
                                if(!saving) { saveUpdates() }
                                    }}
                      >
                        {saving ? <><Spinner />Saving updates...</> : 'Save updates'}
                      </button>
                    </div>

                </div>

              </>
            :
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                <p className='text-sm text-gray-600 mt-1'>{error}</p>
            </div>
            }
          </>
        }
      </>
    )
}

export default BikeFitRequest