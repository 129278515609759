import React, { useState } from 'react'

const RangeSelector = ({returnRange}) => {
    const [rangeLower, setRangeLower] = useState('');
    const [rangeUpper, setRangeUpper] = useState('');

    const saveRange = () => {
        if(rangeLower === '' || rangeUpper === '') return
        returnRange(`${rangeLower}><${rangeUpper}`)
    }

    return (
        <div className='w-full'>
            <label className='block mb-2 text-xs'>From:</label>
            <input type="number" 
                placeholder='Lower limit' 
                onChange={(e)=>{
                    setRangeLower(e.target.value)
                }} 
                onBlur={()=>{saveRange()}}
                className="py-2 px-3 w-full border rounded border-gray-300 text-xs" 
            />
            <label className='block mb-2 text-xs mt-4'>To:</label>
            <input 
                placeholder='Upper limit' 
                type="number" 
                onChange={(e)=>{
                    setRangeUpper(e.target.value)
                }} 
                onBlur={()=>{saveRange()}}
                className="py-2 px-3 w-full border rounded border-gray-300 text-xs"
            />
        </div>
    )
}

export default RangeSelector