import React from 'react'
import TrashIcon from "../../../components/elements/icons/TrashIcon";
import CloseIcon from "../../../components/elements/icons/CloseIcon";
import EditIcon from "../../../components/elements/icons/EditIcon";
import { hexToRgbA } from '../../../utils';

const ActiveBooking = ({activeBooking, closeControl}) => {
    return (
        <div className="bg-white w-96 min-h-screen border-l border-gray-300 z-50 fixed top-0 right-0">
            <div className="p-4 flex flex-row-reverse gap-x-3">
                <button onClick={()=>{closeControl()}}>
                    <CloseIcon classes="w-5 h-5 text-gray-500" />
                </button>
                <button>
                    <TrashIcon classes="w-5 text-gray-500" />
                </button>
                <button className="mr-2">
                    <EditIcon />
                </button>
            </div>

            <div className="w-full mt-20 p-4">
                <div className="flex flex-row gap-x-8 my-4">
                    <div className="w-7 h-7 flex items-center justify-center rounded-md border-2" style={{borderColor: activeBooking.staff.colour}}>
                        <div className="rounded-sm" 
                            style={{width: '90%', height: '90%', borderRadius: '3px', borderWidth:'2px', opacity: '20%', backgroundColor: activeBooking.staff.colour}}>
                        </div>
                    </div>
                    <p className="text-md font-medium text-ink-navy mt-2">{activeBooking.booking.title}</p>
                </div>

                <div className="flex flex-row gap-x-8 my-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-1 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <p className="text-sm text-gray-400">20th September 2021</p>
                </div>

                <div className="flex flex-row gap-x-8 my-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-1 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p className="text-sm text-gray-400">{activeBooking.booking.startTime} - {activeBooking.booking.endTime}</p>
                </div>
                
                <div className="flex flex-row gap-x-6 mt-12 my-4">
                    <img alt="" src={activeBooking.staff.image} className="rounded-full w-10" style={{borderWidth: "1px", borderStyle: "solid", borderColor: `${activeBooking.staff.colour}`}} />
                    <div>
                        <h2 className="text-sm">{activeBooking.staff.name}</h2>
                        <p className='text-blue-700 text-xs'>Clevedon Workshop</p>
                    </div>
                </div>
            </div>

            <div className="border-t border-gray-200 mb-8" />

            <div className='w-full px-4'>
                <div className="flex flex-row gap-x-8 my-4">
                    <div className="rounded-full w-7 h-7 mx-1 flex items-center justify-center" 
                        style={{backgroundColor: hexToRgbA(activeBooking.staff.colour, 0.2)}}
                    >
                        <p className='font-medium mt-1' style={{color: activeBooking.staff.colour}}>
                            {activeBooking.booking.customer.charAt(0)}
                        </p>
                    </div>
                    <p className="text-md font-medium text-ink-navy mt-1">{activeBooking.booking.customer}</p>
                </div>

                <div className="flex flex-row gap-x-8 my-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-2 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <p className="text-sm text-gray-400">frank.franksen@gmail.com</p>
                </div>

                <div className="flex flex-row gap-x-8 my-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-2 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    <p className="text-sm text-gray-400">+1223334455</p>
                </div>
            </div>
        </div>
    )
}

export default ActiveBooking
