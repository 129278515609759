import React from 'react'

const CustomerBookings = () => {
    const bookings = [
        {
            jobNo: "111123",
            customerName: "Mary Hare",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2021-04-08",
            workshop: "Clevedon",
            statusOfProduct: "Awaiting Check-in",
            status: "pending",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                },
            ]
        },
        {
            jobNo: "111124",
            customerName: "Bella Alubo",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2021-04-07",
            workshop: "Clevedon",
            statusOfProduct: "Awaiting Check-in",
            status: "pending",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                },
            ]
        },
        {
            jobNo: "111125",
            customerName: "Granit Xhaka",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2021-04-07",
            workshop: "Clevedon",
            statusOfProduct: "Awaiting Check-in",
            status: "paused",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                },
            ]
        },
        {
            jobNo: "111126",
            customerName: "Reuben Neves",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2021-12-10",
            workshop: "Clevedon",
            statusOfProduct: "Awaiting Check-in",
            status: "complete",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                },
            ]
        },
        {
            jobNo: "111127",
            customerName: "Max Payne",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2021-12-27",
            workshop: "Clevedon",
            statusOfProduct: "Awaiting Check-in",
            status: "paused",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                },
            ]
        },
        {
            jobNo: "111128",
            customerName: "Terry Very Merry",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2021-08-16",
            workshop: "Bristol",
            statusOfProduct: "Awaiting Check-in",
            status: "pending",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                }
            ]
        },
        {
            jobNo: "111129",
            customerName: "Mary Jayne",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2021-08-16",
            workshop: "Clevedon",
            statusOfProduct: "Awaiting Check-in",
            status: "cancelled",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                },
            ]
        },
        {
            jobNo: "111129",
            customerName: "Olegunnar Solskjaer",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2020-08-22",
            workshop: "Bristol",
            statusOfProduct: "Awaiting Check-in",
            status: "pending",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                },
            ]
        },
        {
            jobNo: "111129",
            customerName: "Podolski Alfamitano",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2021-01-14",
            workshop: "Bristol",
            statusOfProduct: "Awaiting Check-in",
            status: "complete",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                },
            ]
        },
        {
            jobNo: "111129",
            customerName: "Brandy Candy",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            date: "2021-01-14",
            workshop: "Bristol",
            statusOfProduct: "Awaiting Check-in",
            status: "complete",
            actions: [
                {
                    label: "Change Status",
                    icon: null,
                    link: "/",
                    action: null
                },
                {
                    label: "delete",
                    icon: null,
                    link: "/",
                    action: null
                },
            ]
        },
    ]
    return (
        <div>
            customer bookings
        </div>
    )
}

export default CustomerBookings
