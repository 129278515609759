import React, { useState } from 'react'

const PopularProductsIcon = () => {
    const [color, setColor] = useState("#B8C0D5")

    return (
        <svg onMouseEnter={()=>{setColor("#ffffff")}} onMouseLeave={()=>{setColor("#B8C0D5")}} id="pie-chart_1_" data-name="pie-chart(1)" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19.999 20">
            <path id="Path_2644" data-name="Path 2644" d="M166.809,87.106h-.586v8.111l-4.81,6.52.471.348a8.3,8.3,0,1,0,4.924-14.979Z" transform="translate(-155.109 -83.703)" fill="#adb5d4"/>
            <path id="Path_2645" data-name="Path 2645" d="M2.409,118.691a11.114,11.114,0,0,0-1.333,1.8,8.3,8.3,0,0,0,.8,9.3A10.552,10.552,0,0,0,3.86,131.58l5.247-7.112Z" transform="translate(-0.016 -114.055)" fill="#adb5d4"/>
            <path id="Path_2646" data-name="Path 2646" d="M86.767,0A8.074,8.074,0,0,0,83.3.628a8.084,8.084,0,0,0-2.739,2.216l6.2,5.351Z" transform="translate(-77.417)" fill={color}/>
        </svg>

    )
}

export default PopularProductsIcon
