import axios from 'axios'
import React, { useState } from 'react'
import CheckboxGroup from './CheckboxGroup'
import DateRangeSelector from './DateRangeSelector'
import GlobalSearchV2 from './GlobalSearchV2'
import CloseIcon from './icons/CloseIcon'
import RangeSelector from './RangeSelector'

const Filters = ({closeFilters, allFilters, selectFilters, clearFilters}) => {


    const [selectedFilters, setSelectedFilters] = useState([]);

    const addToActiveFilters = (selected, filter) => {
        // add filters here then send them to the main component
        const addedFilter = {
            filter: filter.value,
            operator: '=',
            term: selected
        }

        if(filter.type === 'search' && filter.searchConfig.searchReference === 'customers') {
            addedFilter.term = customers[selected].id
        }

        if(filter.type === 'select') {
            // get the selected index
            const added = []
            selected.map((option, optionIndex) => {
                added.push(option)
                return option
            })
            setSelectedFilters([...selectedFilters, ...[{
                filter: filter.value,
                operator: '=',
                term: added.join(',')
            }]])
            return
        }

        // selectFilter(addedFilter)
        setSelectedFilters([...selectedFilters, addedFilter])
    }

    const returnFilters = () => {
        if(selectedFilters.length === 0) {
            return
        }
        selectFilters(selectedFilters)
    }
    
    // const clearFilters = () => {
    //     setSelectedFilters([])
    // }

    const [processingIndex, setProcessingIndex] = useState('');
    const [customers, setCustomers] = useState(null);

    const performSearch = (term, optionIndex, option) => {
        console.log(option)
        if(option?.searchConfig?.searchReference === 'customers'){
            findCustomer(term, optionIndex)
        }
        if(option?.searchConfig?.searchReference === 'brands'){
            findBrand(term, optionIndex)
        }
    } 

    const findCustomer = async (term, index) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        try { 
            let response = null 
            setProcessingIndex(index)
            response = await axios.get(`/api/v1/admin/customers/?filter[%first_name%]=${term}`, { headers })            
            setCustomers(response.data.data)
            setProcessingIndex('')
        } catch (error) {
            setProcessingIndex('')
            console.error(error.response.data)
            // setError(error.response.data.msg)
            // setProcessing(false)
        }
    }

    const [brands, setBrands] = useState([]);

    const findBrand = async (term, index) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        try { 
            let response = null 
            setProcessingIndex(index)
            response = await axios.get(`/api/v1/admin/brands/?filter[%name%]=${term}`, { headers })            
            setBrands(response.data.data)
            setProcessingIndex('')
        } catch (error) {
            setProcessingIndex('')
            console.error(error.response.data)
            // setError(error.response.data.msg)
            // setProcessing(false)
        }
    }
    
    return (
        <div className="absolute shadow-lg bg-white top-16 border border-gray-100 z-10" style={{width: '800px', left: '0px',}}>
            <div className="w-full flex flex-row items-center justify-between border-b p-4">
                <div>
                    <p className="font-medium text-ink-navy text-xs">Filters</p>
                </div>

                <button onClick={()=>{closeFilters()}}><CloseIcon classes='w-5 h-5' /></button>
            </div>
            <div className="w-full flex flex-row justify-between border-b p-8">
                {allFilters.map((filterOption, optionIndex) => (
                    <div key={optionIndex}>
                        <p className="font-medium text-xs mb-3 text-ink-navy">{filterOption.optionName}</p>
                        {(filterOption?.addSearch === true || filterOption.type==='search') &&
                            <GlobalSearchV2 
                                performSearch={(term)=>{performSearch(term, optionIndex, filterOption)}}
                                searchResults={filterOption?.searchConfig?.searchReference === 'customers' ? customers : brands}
                                resultDisplayField={filterOption?.searchConfig?.searchReference === 'customers' ? 'full_name' : 'name'}
                                returnSelectedResult={(selectedIndex)=>{addToActiveFilters(selectedIndex, filterOption)}}
                                placeholderText={filterOption?.searchConfig?.searchPlaceholder}
                                searchInProgress={processingIndex === optionIndex}
                            />
                            
                        }
                        
                        {filterOption?.type === 'select' && 
                            <div className={`${filterOption.columnWidth ? filterOption.columnWidth : 'w-2/10'} mt-1`}>
                                <CheckboxGroup 
                                    options={filterOption.options} 
                                    returnSelections={(selections)=>{addToActiveFilters(selections, filterOption)}} 
                                />
                            </div>
                        }
                        
                        {filterOption?.type === 'range' && 
                            <div className={`${filterOption.columnWidth ? filterOption.columnWidth : 'w-2/10'} mt-1`}>
                                <RangeSelector returnRange={(selected)=>{addToActiveFilters(selected, filterOption)}}/>
                            </div>
                        }
                        
                        {filterOption?.type === 'date-range' && 
                            <div className={`${filterOption.columnWidth ? filterOption.columnWidth : 'w-2/10'} mt-1`}>
                                <DateRangeSelector returnRange={(selected)=>{addToActiveFilters(selected, filterOption)}} />
                            </div>
                        }

                        {/* <button className='px-2 py-1 text-xs text-white bg-ink-navy mt-6 rounded'>Add filter</button> */}
                    </div>
                ))}
            </div>

            <div className='w-full flex flex-row-reverse items-center gap-x-3 my-5 px-5'>
                <button onClick={()=>{returnFilters()}} className='px-2 py-2 text-xs text-white bg-ink-navy rounded'>
                    Add Filters
                </button>
                <button className="font-sofia-pro underline text-blue-700 text-xs" onClick={()=>{clearFilters()}}>Clear selected filters</button>

            </div>
        </div>
    )
}

export default Filters