import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import TileMenu from '../../assets/images/icons/tile-menu.svg'
import CloseIcon from '../../assets/images/icons/close.svg'
import AllOrdersIcon from '../../assets/images/icons/all-orders.svg'
import CancelledOrdersIcon from '../../assets/images/icons/cancelled-orders.svg'
import AbandonedBasketsIcon from '../../assets/images/icons/abandoned-basket.svg'
import ActiveProductsIcon  from '../../assets/images/icons/active-products.svg'
import OutOfStockIcon  from '../../assets/images/icons/out-of-stock.svg'
import RepeatPurchasersIcon  from '../../assets/images/icons/repeat-purchasers.svg'
import PurchasingCustomersIcon  from '../../assets/images/icons/purchasing-customers.svg'
import InteractionsIcon  from '../../assets/images/icons/interactions.svg'
import UpTrendIcon  from '../../assets/images/icons/uptrend.svg'
import UpArrowRightIcon  from '../../assets/images/icons/arrow-up-right.svg'
import DownArrowRightIcon  from '../../assets/images/icons/arrow-down-right.svg'
import RightArrow  from '../../assets/images/icons/right-arrow.svg'
import HorizontalMenuIcon  from '../../assets/images/icons/horizontal-menu-icon.svg'
import Product1Image  from '../../assets/images/dash-product-image-1.jpg'
import Product2Image  from '../../assets/images/dash-product-image-2.jpg'
import ChatBubbleIcon  from '../../assets/images/icons/chat-bubble.svg'
import { Link } from 'react-router-dom'
import TurnoverAnalysis from '../../components/partials/TurnoverAnalysis'
import Donut from '../../components/partials/Donut'
import { useCookies } from 'react-cookie';

const Dashboard = (props) => {
    const [activeAnalyticsFrequency, setActiveAnalyticsFrequency] = useState('weekly')
    const changeAnalyticsFrequency = (frequency) => {
        setActiveAnalyticsFrequency(frequency)
    }

    const [notificationOpen, setNotificationOpen] = useState(true)
    const toggleNotificationState = () => {
        setNotificationOpen(!notificationOpen)
    }

    const popularProducts = [
        {
            productImage: Product1Image,
            productName: "Wahoo 24",
            views: 11739,
            changeInViews: 230,
            viewsUp: true,
            stock: 0
        },
        {
            productImage: Product1Image,
            productName: "Emando SLR 9",
            views: 4264,
            changeInViews: 113,
            viewsUp: false,
            stock: 22
        },
        {
            productImage: Product2Image,
            productName: "Rail 5 625W",
            views: 3264,
            changeInViews: 335,
            viewsUp: true,
            stock: 0
        },
        {
            productImage: Product1Image,
            productName: "Muc-Off No Puncture Hassle 140ml - Pouch Only",
            views: 2500,
            changeInViews: 19,
            viewsUp: false,
            stock: 0
        },
    ]
    const popularCampaigns = [
        {
            name: "The Tour Downunder",
            clicks: 81,
            conversions: 21,
            conversionRate: 0.28
        },
        {
            name: "The Tour Downunder",
            clicks: 81,
            conversions: 21,
            conversionRate: 0.28
        },
        {
            name: "Meet Angi",
            clicks: 3,
            conversions: 0,
            conversionRate: 0.00
        },
        {
            name: "Meet Angi",
            clicks: 3,
            conversions: 0,
            conversionRate: 0.00
        },
    ]
    const recentOrderActivity = [
        {
            customerName: "Keith Southam",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            day: "Today",
            time: "3:12PM",
            orderId: "120146",
            orderAmount: 20.23,
            orderStatus: "processing"
        },
        {
            customerName: "Carla Smith",
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            day: "Yesterday",
            time: "1:18PM",
            orderId: "120147",
            orderAmount: 20.23,
            orderStatus: "pending"
        },
        {
            customerName: "Keith Southam",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            day: "Today",
            time: "3:12PM",
            orderId: "120146",
            orderAmount: 20.23,
            orderStatus: "processing"
        },
        {
            customerName: "Carla Smith",
            customerImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80",
            day: "Yesterday",
            time: "1:18PM",
            orderId: "120147",
            orderAmount: 20.23,
            orderStatus: "pending"
        },
        {
            customerName: "Keith Southam",
            customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
            day: "Today",
            time: "3:12PM",
            orderId: "120146",
            orderAmount: 20.23,
            orderStatus: "processing"
        },
    ]
    const  workshopEnquiries = [
        {
            message: "I'm interested in testing the AB10X",
            day: "Today",
            date: "10/08/21",
            time: "3:12PM"
        },
        {
            message: "I'm interested in the booking",
            day: "Yesterday",
            date: "10/08/21",
            time: "1:36PM"
        },
        {
            message: "Full service please",
            day: "Friday",
            date: "10/08/21",
            time: "3:12PM"
        },
        {
            message: "I'm interested in testing the AB10X",
            day: "Wednesday",
            date: "10/08/21",
            time: "3:12PM"
        },
        {
            message: "Full service please",
            day: "Sunday",
            date: "10/08/21",
            time: "3:12PM"
        },
    ]

    const sidebarLinks = [
        // {
        //     label: "Dashboard",
        //     location: "/admin/dashboard"
        // }
    ]



    const storeSalesData = [
        {
            label: 'Shop 1',
            amount: 41,
            colour: '#00D78C'
        },
        {
            label: 'Shop 2',
            amount: 17,
            colour: '#6D3ADD'
        },
        {
            label: 'Shop 3',
            amount: 15,
            colour: '#00EFF9'
        },
    ]

    const cleanUpChartData = (data) => {
        const labels = []
        const series = []
        const colours = []

        data.forEach((item) => {
            labels.push(item.label)
            series.push(item.amount)
            colours.push(item.colour)
        });

        return {
            labels, series, colours
        }
    }

    const systemStats = {
        systemStatus: "All Ok",
        stockUpdate: "13:00",
        productUpdate: "08:52",
        supplierStockUpdate: "08:52"
    }

    const [ cookies ] = useCookies(['user']);
    const userFullName = `${cookies?.details?.first_name?.toLowerCase() || ''} ${cookies?.details?.last_name?.toLowerCase() || ''}`
    
    return (
        <Layout pageTitle="Dashboard" sidebarLinks={sidebarLinks} systemStats={systemStats}>
            <div className="flex flex-row justify-between mb-5">
                <h3><span className="font-medium">Welcome back</span>, <span className='capitalize'>{userFullName}</span></h3>
                <button className="font-sofia-pro text-xs flex items-center">
                    <img src={TileMenu} alt="menu" className="mr-2 inline w-4" /> Manage Tiles
                </button>
            </div>
            <div className="w-full xl:flex lg:flex-row">
                {/* Main area */}
                <div className="w-full xl:w-8/12 p-1">
                    {/* tiles */}
                    <div className="grid grid-cols-2 lg:grid-cols-4 grid-flow-row gap-x-1 gap-y-1 w-full">
                        <div className="rounded border px-4 py-6">
                            <img src={AllOrdersIcon} alt="all-orders" className="w-7" />
                            <p className="mt-3 -mb-2"><span className="text-xs">&pound;</span><span className="text-xl text-ink-navy">2,791,810.83</span></p>
                            <Link to="/" className="mt-0 text-xs text-gray-600 underline">All Orders</Link>
                        </div>
                        {/* tile */}
                        <div className="rounded border px-4 py-6">
                        <img src={CancelledOrdersIcon} alt="all-orders" className="w-7" />
                            <p className="mt-3 -mb-2"><span className="text-xs">&pound;</span><span className="text-xl text-ink-navy">268,381.58</span></p>
                            <Link to="/" className="mt-0 text-xs text-gray-600 underline">Cancelled Orders</Link>
                        </div>
                        {/* tile */}
                        <div className="rounded border px-4 py-6">
                            <img src={AbandonedBasketsIcon} alt="all-orders" className="w-7" />
                            <p className="mt-3 -mb-2"><span className="text-xl text-ink-navy">24,054.68</span></p>
                            <Link to="/" className="mt-0 text-xs text-gray-600 underline">Abandoned Baskets</Link>
                        </div>
                        {/* tile */}
                        <div className="rounded border px-4 py-6">
                            <img src={ActiveProductsIcon} alt="all-orders" className="w-7" />
                            <p className="mt-3 -mb-2"><span className="text-xs"></span><span className="text-xl text-ink-navy">90.68%</span></p>
                            <Link to="/" className="mt-0 text-xs text-gray-600 underline">Active Products</Link>
                        </div>
                        {/* tile */}
                        <div className="rounded border px-4 py-6">
                            <img src={OutOfStockIcon} alt="all-orders" className="w-7" />
                            <p className="mt-3 -mb-2"><span className="text-xl text-ink-navy">10.32%</span></p>
                            <Link to="/" className="mt-0 text-xs text-gray-600 underline">Out of Stock</Link>
                        </div>
                        {/* tile */}
                        <div className="rounded border px-4 py-6">
                            <img src={RepeatPurchasersIcon} alt="all-orders" className="w-7" />
                            <p className="mt-3 -mb-2"><span className="text-xl text-ink-navy">0.02%</span></p>
                            <Link to="/" className="mt-0 text-xs text-gray-600 underline">Repeat Purchasers</Link>
                        </div>
                        {/* tile */}
                        <div className="rounded border px-4 py-6">
                            <img src={PurchasingCustomersIcon} alt="all-orders" className="w-7" />
                            <p className="mt-3 -mb-2"><span className="text-xl text-ink-navy">82.36%</span></p>
                            <Link to="/" className="mt-0 text-xs text-gray-600 underline">Purchasing Customers</Link>
                        </div>
                        {/* tile */}
                        <div className="rounded border px-4 py-6">
                            <img src={InteractionsIcon} alt="all-orders" className="w-7" />
                            <p className="mt-3 -mb-2"><span className="text-xl text-ink-navy">83</span></p>
                            <Link to="/" className="mt-0 text-xs text-gray-600 underline">Interactions</Link>
                        </div>
                    </div>

                    {/* Turnover analytics chart */}
                    <div className="rounded w-full border px-4 py-5 mt-3">
                        <div className="w-full block md:flex md:justify-between">
                            <h3 className="text-ink-navy">Turnover Analytics</h3>
                            <div className="w-full pt-3 md:pt-0 md:w-4/12 flex justify-between flex-row">
                                <button className={`px-3 py-1 rounded font-sofia-pro text-xs ${activeAnalyticsFrequency === 'daily' ? 'bg-blue-100 text-ink-navy' : 'text-gray-400 bg-tranparent'}`} onClick={()=>{changeAnalyticsFrequency('daily')}}>Daily</button>

                                <button className={`px-3 py-1 rounded font-sofia-pro text-xs ${activeAnalyticsFrequency === 'weekly' ? 'bg-blue-100 text-ink-navy' : 'text-gray-400 bg-tranparent'}`} onClick={()=>{changeAnalyticsFrequency('weekly')}}>Weekly</button>

                                <button className={`px-3 py-1 rounded font-sofia-pro text-xs ${activeAnalyticsFrequency === 'monthly' ? 'bg-blue-100 text-ink-navy' : 'text-gray-400 bg-tranparent'}`} onClick={()=>{changeAnalyticsFrequency('monthly')}}>Monthly</button>
                            </div>
                        </div>

                        {/* Chart */}
                        <TurnoverAnalysis />
                        {/* Chart Legend */}
                        <div className="block w-full md:flex md:flex-row md:justify-between mt-4">
                            <div className="rounded border px-4 py-6 bg-gray-100 w-full md:w-4/12 border">
                                <p className="text-xs text-ink-navy -mb-2">Total Turnover <span className="bg-blue-100 rounded p-1 ml-2">Online</span></p>
                                <p className="mt-3"><span className="text-xs">&pound;</span><span className="text-xl text-ink-navy">291,810.83</span></p>
                                <p className="mt-0 text-xs text-gray-500">This Week</p>
                            </div>
                            <div className="w-full md:w-8/12 md:ml-3 md:flex flex-row justify-between gap-x-1">
                                <div className="rounded border px-6 py-3 bg-gray-100 w-full md:w-4/12 border bg-opacity-70">
                                    <p className="mt-3"><span className="text-xs">&pound;</span><span className="text-xl text-ink-navy">1,200</span></p>
                                    <p className="mt-0 text-xs text-gray-500">This Day</p>
                                    <p className="mt-3 text-xs text-green-500"><img src={UpTrendIcon} alt="uptrend" className="mr-2 inline" /> 18%</p>
                                </div>
                                <div className="rounded border px-6 py-3 w-full md:w-4/12 border relative">
                                    <span className="bg-ink-navy h-2 w-2 rounded-full absolute top-10 left-2" />
                                    <p className="mt-3"><span className="text-xs">&pound;</span><span className="text-xl text-ink-navy">10.103</span></p>
                                    <p className="mt-0 text-xs text-gray-500">This Week</p>
                                    <p className="mt-3 text-xs text-green-500"><img src={UpTrendIcon} alt="uptrend" className="mr-2 inline" /> 18%</p>
                                </div>
                                <div className="rounded border px-6 py-3 w-full md:w-4/12 border relative">
                                    <span className="bg-blue-300 h-2 w-2 rounded-full absolute top-10 left-2" />
                                    <p className="mt-3"><span className="text-xs">&pound;</span><span className="text-xl text-ink-navy">12.103</span></p>
                                    <p className="mt-0 text-xs text-gray-500">Previous Week</p>
                                    <p className="mt-3 text-xs text-green-500"><img src={UpTrendIcon} alt="uptrend" className="mr-2 inline" /> 18%</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* POPULAR PRODUCTS */}
                    <div className="rounded w-full border py-1 mt-3 pb-3">
                        {/* Box Heading */}
                        <div className="flex flex-row justify-between w-full p-4 mb-3 border-b border-gray-300">
                            <h3 className="text-ink-navy">Popular Products</h3>
                            <button>
                                <img src={HorizontalMenuIcon} alt="menu" className="w-6" />
                            </button>
                        </div>
                        {/* table headers */}
                        <div className="hidden md:flex flex-row w-full px-6 py-2 gap-x-3">
                            <div className="w-1/12">
                                <p className="text-xs font-light text-gray-300">PRODUCT</p>
                            </div>
                            <div className="w-6/12">
                                <p className="text-xs font-light text-gray-300">PRODUCT NAME</p>
                            </div>
                            <div className="w-3/12">
                                <p className="text-xs font-light text-gray-300">VIEWS</p>
                            </div>

                            <div className="w-2/12">
                                <p className="text-xs font-light text-gray-300">STOCK</p>
                            </div>
                        </div>
                        {/* Table body */}
                        {popularProducts.map((product, productIndex) => (
                            <div className="block clear-left  md:flex flex-row w-full px-6 py-2 gap-x-3 items-center" key={productIndex}>
                                <div className="float-left w-1/3 md:w-1/12 text-ink-navy">
                                    <img src={product.productImage} alt={product.productName} className='m-auto w-3/5 md:w-full mb-3 md:mb-0' />
                                </div>
                                <div className="w-full md:w-6/12 text-ink-navy">
                                    <p className="text-xs"><span className="text-gray-400 md:hidden inline">Product name: </span>{product.productName}</p>
                                </div>
                                <div className="w-full md:w-3/12 text-ink-navy">
                                    <p className="text-xs"><span className="text-gray-400 md:hidden inline">Views: </span>{product.views.toLocaleString()} 
                                        <span className={`inline md:inline-block py-0 px-3 ml-3 bg-opacity-30 rounded-full ${product.viewsUp ? 'bg-green-400 text-green-400' : 'bg-red-400 text-red-400'}`}>
                                            <img src={product.viewsUp ? UpArrowRightIcon : DownArrowRightIcon} alt="views" className="inline mr-1" />
                                            {product.changeInViews.toLocaleString()}
                                        </span>
                                    </p>
                                </div>
                                <div className="w-full md:w-2/12 text-ink-navy">
                                    <p className="text-xs"><span className="text-gray-400 md:hidden inline">Stock: </span>{product.stock}</p>
                                </div>
                            </div>
                        ))}
                        <div className="w-full text-right px-6">
                            <Link to="/" className="text-xs text-ink-navy">
                                VIEW ALL <img src={RightArrow} alt="all" className="inline w-4 ml-2 -mt-1" />
                            </Link>    
                        </div> 
                    </div>

                    {/* DOUBLE COL - Popular campaigns, pie chart */}
                    <div className="w-full lg:flex lg:flex-row">
                        <div className="w-full lg:w-7/12 p-1">
                            {/* POPULAR PRODUCTS */}
                            <div className="rounded w-full border py-1 mt-3 pb-3">
                                {/* Box Heading */}
                                <div className="flex flex-row justify-between w-full p-4 mb-3 border-b border-gray-300">
                                    <h3 className="text-ink-navy">Popular Campaigns</h3>
                                    <button>
                                        <img src={HorizontalMenuIcon} alt="menu" className="w-6" />
                                    </button>
                                </div>
                                {/* table headers */}
                                <div className="hidden md:flex flex-row w-full px-4 py-2 gap-x-3">
                                    <div className="w-6/12">
                                        <p className="text-xs font-light text-gray-300">CAMPAIGN</p>
                                    </div>
                                    <div className="w-2/12">
                                        <p className="text-xs font-light text-gray-300">CLICKS</p>
                                    </div>
                                    <div className="w-2/12">
                                        <p className="text-xs font-light text-gray-300">CONV</p>
                                    </div>
                                    <div className="w-2/12">
                                        <p className="text-xs font-light text-gray-300">CONV. RATE</p>
                                    </div>
                                </div>
                                {/* Table body */}
                                {popularCampaigns.map((campaign, campaignIndex) => (
                                    <div className="block md:flex flex-row w-full px-4 py-3 gap-x-3 items-center" key={campaignIndex}>
                                        <div className="w-full md:w-6/12 text-ink-navy">
                                            <p className="text-xs"><span className="text-gray-400 md:hidden inline">Campaign: </span>{campaign.name}</p>
                                        </div>
                                        <div className="w-full md:w-2/12 text-ink-navy">
                                            <p className="text-xs"><span className="text-gray-400 md:hidden inline">Clicks: </span>{campaign.clicks.toLocaleString()}</p>
                                        </div>
                                        <div className="w-full md:w-2/12 text-ink-navy">
                                            <p className="text-xs"><span className="text-gray-400 md:hidden inline">Conversions: </span>{campaign.conversions.toLocaleString()}</p>
                                        </div>
                                        <div className="w-full md:w-2/12 text-ink-navy">
                                            <p className="text-xs"><span className="text-gray-400 md:hidden inline">Conversion Rate: </span>{campaign.conversionRate}</p>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full text-right px-4">
                                    <Link to="/" className="text-xs text-ink-navy">
                                        VIEW ALL <img src={RightArrow} alt="all" className="inline w-4 ml-2 -mt-1" />
                                    </Link>    
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-5/12 p-1">
                            <div className="rounded w-full border py-1 mt-3 pb-3">
                                <h3 className="text-ink-navy px-4 py-4">Store Sales Analysis</h3>
                                <div className="w-full px-1 py-1 my-5">
                                    <Donut
                                        showLegend={true} 
                                        showDataLabels={false} 
                                        size="55%" 
                                        series={cleanUpChartData(storeSalesData).series} 
                                        colours={cleanUpChartData(storeSalesData).colours} 
                                        labels={cleanUpChartData(storeSalesData).labels}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right sidebar */}
                <div className="w-full xl:w-4/12 p-1">
                    {/* Closable notification */}
                    <div className={`w-full px-8 py-16 bg-ink-navy text-white rounded relative ${notificationOpen ? 'block' : 'hidden'}`}>
                        <button className="absolute top-3 right-2" onClick={()=>{toggleNotificationState()}}><img src={CloseIcon} alt="close" /></button>
                        <h3 className="font-medium">You have 17 order(s) marked as paid and ready to process</h3>
                        <p className="text-sm mt-4">
                            Visit your <Link to="/admin/orders/all-orders/paid-ready-to-proccess" className="font-medium underline text-white text-opacity-70 hover:text-opacity-90">paid & ready to process</Link> orders section so you can mark your orders as processing.
                        </p>
                    </div>

                    {/* Recent Order Activity */}
                    <div className="w-full mt-3 p-4 border rounded">
                        <div className="flex flex-row justify-between w-full mb-3">
                            <h3 className="text-ink-navy">Recent Order Activity</h3>
                            <button>
                                <img src={HorizontalMenuIcon} alt="menu" className="w-6" />
                            </button>
                        </div> 

                        {recentOrderActivity.map((order, orderIndex) => (
                            <div className="flex flex-row justify-between align-center py-4" key={orderIndex}>
                                <div className="w-2/12" >
                                    <img src={order.customerImage} alt="" className="rounded-full w-8 h-8" />
                                    {/* <div className="w-8 h-8 rounded-full" styles={{backgroundImage: `url(${order.customerImage})`}}></div> */}
                                </div>
                                <div className="w-10/12">
                                    <div className="w-full flex justify-between">
                                        <p className="text-xs text-ink-navy">{order.customerName}</p>
                                        <Link to="/" className="text-xs text-ink-navy underline">#{order.orderId}</Link>
                                    </div>
                                    <p className="text-xs text-gray-500 mt-1">We are processing an order totalling</p>
                                    <p className="text-xs text-ink-navy mb-1">&pound;{order.orderAmount.toLocaleString()}</p>
                                    <div className="w-full flex justify-between items-center">
                                        <p className="text-xs text-gray-400">{order.day} - {order.time}</p>
                                        <Link to="/" className="text-xs text-ink-navy bg-blue-100 bg-opacity-60 rounded py-1 px-2">Details</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Workshop Enquiries */}
                    <div className="w-full mt-3 p-4 border rounded">
                        <div className="flex flex-row justify-between w-full mb-3">
                            <h3 className="text-ink-navy">Workshop Enquiries</h3>
                            <button>
                                <img src={HorizontalMenuIcon} alt="menu" className="w-6" />
                            </button>
                        </div> 

                        {workshopEnquiries.map((enquiry, enquiryIndex) => (
                            <div className="flex flex-row justify-between align-center py-4" key={enquiryIndex}>
                                <div className="w-2/12" >
                                    <img src={ChatBubbleIcon} alt="" className="w-6" />
                                    {/* <div className="w-8 h-8 rounded-full" styles={{backgroundImage: `url(${order.customerImage})`}}></div> */}
                                </div>
                                <div className="w-10/12">
                                    <div className="w-full flex justify-between">
                                        <p className="text-xs text-ink-navy mr-2">{enquiry.message}</p>
                                        <button to="/" className="text-xs text-blue-700 border rounded px-3 py-1  border-blue-700 underline">Reply</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

        </Layout>
    )
}

export default Dashboard
