import React, { useState } from 'react'
import UserIcon from '../../assets/images/icons/user-icon.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '../elements/icons/CopyIcon';
import CheckIcon from '../elements/icons/CheckIcon';

const OrderOverview = ({order, customer}) => {

    const getAddress = (addressId) => {
        const address = customer.addresses.filter((address) => {
            return address.id === addressId
        })

        return address
    }

    const getItemImage = (itemObject) => {
        let productImageUrl = ''
        itemObject.product_size.colour.assets.map((asset) => {
            if (asset.type==='image') {
                productImageUrl = asset.original_manuf_image
            }
            return asset
        })

        return productImageUrl
    }

    const [billingCopied, setBillingCopied] = useState(false);
    const [shippingCopied, setShippingCopied] = useState(false);
    const [displayCopyBillingAddress, setDisplayCopyBillingAddress] = useState(false);
    const [displayCopyShippingAddress, setDisplayCopyShippingAddress] = useState(false);

    // const customerImage = "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"

    const billingAddressString = getAddress(order.billing_address_id)[0]?.address_1 + ', ' + getAddress(order.billing_address_id)[0]?.address_2 + ', ' + getAddress(order.billing_address_id)[0]?.city + ', ' + getAddress(order.billing_address_id)[0]?.county + ', ' + getAddress(order.billing_address_id)[0]?.post_code + ', ' + getAddress(order.billing_address_id)[0]?.country_code

    const shippingAddressString = getAddress(order.shipping_address_id)[0]?.address_1 + ', ' + getAddress(order.shipping_address_id)[0]?.address_2 + ', ' + getAddress(order.shipping_address_id)[0]?.city + ', ' + getAddress(order.shipping_address_id)[0]?.county + ', ' + getAddress(order.shipping_address_id)[0]?.post_code + ', ' + getAddress(order.shipping_address_id)[0]?.country_code

    return (
        <div>
            <div className="w-full mt-5">
                <div className="lg:flex flex-row justify-between gap-x-4 print-flex-row">
                    <div className="w-full lg:w-1/2 p-3 bg-gray-100 rounded-md my-2 print-white-bg">
                        <h3 className="w-full pb-3 mb-3 border-b border-gray-200 text-xs text-ink-navy">Deliver to</h3>
                        <div className="w-full xl:flex flex-row gap-x-8">
                            <div className='w-full my-3'>
                                <img src={UserIcon} alt="" className="w-10 h-10 rounded-full mb-3" />
                                <p className="text-sm text-ink-navy mb-1">{getAddress(order.shipping_address_id)[0]?.first_name} {getAddress(order.shipping_address_id)[0]?.last_name}</p>
                                {order.billing_address_id === order.shipping_address_id && <div className="px-2 py-1 text-sm mt-2 rounded-full bg-gray-300 text-gray-500 inline font-sofia-pro">
                                    Same as billing 
                                </div>}
                            </div>
                            <div className='w-full my-3 relative'  
                                onMouseEnter={()=>{setDisplayCopyShippingAddress(true)}} 
                                onMouseLeave={()=>{setDisplayCopyShippingAddress(false)}}>

                                <CopyToClipboard text={shippingAddressString} onCopy={()=>{setShippingCopied(true)}}>
                                    <div>
                                        {displayCopyShippingAddress && <button className={`p-1 rounded transition duration-200 absolute bottom-2 right-0 ${shippingCopied ? 'text-green-600 bg-green-400 bg-opacity-40' : 'text-gray-500 bg-gray-400 bg-opacity-40'}`}>
                                            {shippingCopied ? <CheckIcon className={`w-5 h-5`} /> : <CopyIcon className={`w-5 h-5`} />}
                                        </button>}
                                        <p className="mt-1 text-gray-600 text-sm font-medium">{getAddress(order.shipping_address_id)[0]?.company}</p>
                                        <p className="mt-1 text-gray-400 text-sm">{getAddress(order.shipping_address_id)[0]?.address_1}<br /> {getAddress(order.shipping_address_id)[0]?.address_2}, {getAddress(order.shipping_address_id)[0]?.city}<br /> {getAddress(order.shipping_address_id)[0]?.county} {getAddress(order.shipping_address_id)[0]?.post_code}<br /> {getAddress(order.shipping_address_id)[0]?.country_code}</p>
                                        <p className="mt-1 text-gray-400 text-sm">{getAddress(order.shipping_address_id)[0]?.phone}</p>
                                    </div>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>

                    <div className="w-full lg:w-1/2 p-3 bg-gray-100 rounded-md my-2 print-white-bg">
                        <h3 className="w-full pb-3 mb-3 border-b border-gray-200 text-sm text-ink-navy">Billing to</h3>

                        <div className="w-full xl:flex flex-row gap-x-4">
                            <div className="my-3">
                                <img src={UserIcon} alt="" className="w-10 h-10 rounded-full mb-3" />
                                <p className="text-sm text-ink-navy mb-1">{customer.user.first_name} {customer.user.last_name}</p>
                                <p className="mt-1 text-gray-400 text-sm">{customer.user.email}</p>
                            </div>

                            <div className='my-3 w-full relative' onMouseEnter={()=>{setDisplayCopyBillingAddress(true)}} onMouseLeave={()=>{setDisplayCopyBillingAddress(false)}}>
                                <p className="mt-1 text-gray-600 text-sm capitalize font-medium">{order.payment_provider}</p>

                                <CopyToClipboard text={billingAddressString} onCopy={()=>{setBillingCopied(true)}}>
                                    <div>
                                        {displayCopyBillingAddress && <button className={`p-1 rounded transition duration-200 absolute bottom-2 right-0 ${billingCopied ? 'text-green-600 bg-green-400 bg-opacity-40' : 'text-gray-500 bg-gray-400 bg-opacity-40'}`}>
                                            {billingCopied ? <CheckIcon className={`w-5 h-5`} /> : <CopyIcon className={`w-5 h-5`} />}
                                        </button>}
                                        <p className="mt-1 text-gray-400 text-sm">{getAddress(order.billing_address_id)[0].address_1}<br /> {getAddress(order.billing_address_id)[0].address_2}, {getAddress(order.billing_address_id)[0]?.city}<br /> {getAddress(order.billing_address_id)[0].county} {getAddress(order.billing_address_id)[0].post_code}<br /> {getAddress(order.billing_address_id)[0].country_code}</p>
                                        <p className="mt-1 text-gray-400 text-sm">{getAddress(order.billing_address_id)[0].phone}</p>
                                    </div>
                                </CopyToClipboard>
                            </div>
                        </div>

                    </div>
                    
                </div>
            </div>

            <div className="w-full bg-gray-100 rounded-md mt-5 print-white-bg">
                <div className="w-full px-6 py-3 flex flex-row gap-x-2 items-center bg-gray-200 print-white-bg print-underline rounded-tr-md rounded-tl-md">
                    <div className="w-7/12">
                        <p className="text-ink-navy text-sm font-medium">Product/Reference</p>
                    </div>
                    <div className="w-2/12">
                        <p className="text-ink-navy text-sm font-medium">Price</p>
                    </div>
                    <div className="w-1/12">
                        <p className="text-ink-navy text-sm font-medium">Quantity</p>
                    </div>
                    <div className="w-2/12">
                        <p className="text-ink-navy text-sm font-medium">Sub-total</p>
                    </div>
                    {/* <div className="w-1/12" /> */}
                </div>
                {order.items.map((item, itemIndex) => (<div key={itemIndex} className="w-full px-6 py-3 flex flex-row gap-x-2 items-center">
                    <div className="w-7/12 flex flex-row items-center">
                        <div className="w-2/12">
                            <img alt="" src={getItemImage(item)} className="mr-2 w-3/4" />
                            {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg> */}
                        </div>
                        <div className="w-10/12">
                            <p className="text-ink-navy text-sm">{item.name}</p>
                            <p className="text-gray-500 text-xs">SKU: {item.sku}</p>
                            {/* <p className="text-gray-500 text-xs">SKU: {item.sku} Colour: {item.colour}, Barcode: {item.barcode}</p> */}
                        </div>
                    </div>
                    <div className="w-2/12">
                        <p className="text-ink-navy text-sm">{order.currency_code === 'GBP' ? '₤' : order.currency_code} {item.price}</p>
                    </div>
                    <div className="w-1/12">
                        <p className="text-ink-navy text-sm">{item.quantity}</p>
                    </div>
                    <div className="w-2/12 flex flex-row items-center justify-between">
                        <p className="text-ink-navy text-sm">{order.currency_code === 'GBP' ? '₤' : order.currency_code} {(item.quantity * parseInt(item.price)).toLocaleString()}</p>
                        {/* <button>
                            <img alt="" src={DeleteIcon} />
                        </button> */}
                    </div>
                </div>))}
               
                <div className="w-full px-6 py-3 flex flex-row gap-x-2 items-center bg-gray-200 print-white-bg print-underline">
                    <div className="w-7/12 flex flex-row items-center">
                        <div className="w-2/12">
                            <p className="text-ink-navy text-sm">Shipping:</p>
                        </div>
                        <div className="w-10/12">
                            <p className="text-ink-navy text-sm">UK Next Day Delivery</p>
                        </div>
                    </div>
                    <div className="w-1/12">
                        <p className="text-ink-navy text-sm">{order.currency_code === 'GBP' ? '₤' : order.currency_code} {order.shipping_charge}</p>
                    </div>
                    <div className="w-2/12">
                        {/* <p className="text-ink-navy text-sm">Quantity</p> */}
                    </div>
                    <div className="w-2/12">
                        <p className="text-ink-navy text-sm font-medium">{order.currency_code === 'GBP' ? '₤' : order.currency_code} {order.shipping_charge}</p>
                    </div>
                    {/* <div className="w-1/12" /> */}
                </div>

                {order.discount_code && order.discount_code !== '' &&
                    <div className="w-full px-6 py-3 flex flex-row gap-x-2 items-center">
                        <div className="w-7/12 flex flex-row items-center">
                            <div className="w-2/12">
                                <p className="text-ink-navy text-sm">Discount:</p>
                            </div>
                            <div className="w-10/12">
                                <p className="text-ink-navy text-sm">{order.discount_code}</p>
                            </div>
                        </div>
                        <div className="w-1/12">
                            {/* <p className="text-ink-navy text-sm">£10</p> */}
                        </div>
                        <div className="w-2/12">
                            {/* <p className="text-ink-navy text-sm">Quantity</p> */}
                        </div>
                        <div className="w-2/12">
                            <p className="text-ink-navy text-sm font-medium">{order.currency_code === 'GBP' ? '₤' : order.currency_code}10</p>
                        </div>
                    
                    </div>
                }

                <div className="w-full px-6 py-3 flex flex-row gap-x-2 items-center bg-gray-200 rounded-br-md rounded-bl-md print-underline print-white-bg">
                    <div className="w-7/12 flex flex-row items-center">
                        <div className="w-2/12">
                            <p className="text-ink-navy text-sm font-medium">Total:</p>
                        </div>
                        <div className="w-10/12">
                            {/* <p className="text-ink-navy text-sm">UK Next Day Delivery</p> */}
                        </div>
                    </div>
                    <div className="w-1/12">
                        {/* <p className="text-ink-navy text-sm">£10</p> */}
                    </div>
                    <div className="w-2/12">
                        {/* <p className="text-ink-navy text-sm">Quantity</p> */}
                    </div>
                    <div className="w-2/12">
                        <p className="text-ink-navy text-sm font-medium">{order.currency_code === 'GBP' ? '₤' : order.currency_code} {parseInt(order.total).toLocaleString()}</p>
                    </div>
                    {/* <div className="w-1/12" /> */}
                </div>
            </div>

            {/* <div className="w-full p-6 bg-gray-100 rounded-md mt-5">
                <h3 className="w-full pb-3 mb-3 border-b border-gray-200 text-sm text-ink-navy">Payment</h3>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500 text-ink-navy font-medium capitalize">{order.payment_provider}</p>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Reference:</p>
                    </div>
                    <div className="w-1/2 text-right">

                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Order Id:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        <p className="text-sm text-gray-500">{order.order_uuid}</p>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Payment Type:</p>
                    </div>
                    <div className="w-1/2 text-right">

                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Payment status:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        <p className="text-sm text-red-800">{order.status}</p>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Fraud status:</p>
                    </div>
                    <div className="w-1/2 text-right">

                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Total amount:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        <p className="text-sm text-ink-navy font-bold">{order.currency_code}{parseInt(order.total).toLocaleString()}</p>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Remaining authorized amount:</p>
                    </div>
                    <div className="w-1/2 text-right">

                    </div>
                </div>
            </div> */}

            <div className="w-full flex flex-row-reverse gap-x-2 mt-5">
                {/* <button className="rounded border border-ink-navy bg-ink-navy text-white text-sm py-2 px-4 font-sofia-pro">
                    Update
                </button> */}
                {/* <button className="rounded border border-ink-navy text-ink-navy text-sm py-2 px-4 font-sofia-pro flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 -mt-1" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                    Add Product
                </button> */}
            </div>
        </div>
    )
}

export default OrderOverview
