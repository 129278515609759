import React from 'react'

const OrderStatus = ({status}) => {
    return (
        <>
            {(status === 'Awaiting Payment' || status === 'Payment Pending') && <span className="w-1 h-1 bg-yellow-500 rounded-full mr-2 inline-block" />}
            {status === 'Dispatched' && <span className="w-1 h-1 bg-gray-400 rounded-full mr-2 inline-block" />}
            {status === 'Paid and Ready to Process' && <span className="w-1 h-1 bg-gray-200 rounded-full mr-2 inline-block" />}
            {status === 'Payment pending' && <span className="w-1 h-1 bg-gray-700 rounded-full mr-2 inline-block" />}
            {status === 'Processing' && <span className="w-1 h-1 bg-yellow-700 rounded-full mr-2 inline-block" />}
            {status === 'Deleted' && <span className="w-1 h-1 bg-red-500 rounded-full mr-2 inline-block" />}
            {status === 'Cancelled' && <span className="w-1 h-1 bg-red-700 rounded-full mr-2 inline-block" />}
            {status === 'Complete' && <span className="w-1 h-1 bg-green-700 rounded-full mr-2 inline-block" />}
            {status === 'Returned' && <span className="w-1 h-1 bg-purple-700 rounded-full mr-2 inline-block" />}
            {status === 'Ready for Collection' && <span className="w-1 h-1 bg-green-700 rounded-full mr-2 inline-block" />}
            {status}
        </>
    )
}

export default OrderStatus

