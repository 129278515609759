import React, {Fragment, useState} from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import DataTable from '../../../components/elements/DataTable'
import { Link } from 'react-router-dom'
import { convertCamelCase } from "../../../utils";
import { Switch } from '@headlessui/react'

const SupplierStockFeeds = () => {
    const supplierStockFeeds = [
        {
            supplier: "Chicken",
            displayStockLevels: true,
            minStockValue: 0,
            displayNextAvailableDates: true
        },
        {
            supplier: "CSG",
            displayStockLevels: false,
            minStockValue: 0,
            displayNextAvailableDates: true
        },
        {
            supplier: "Extra UK",
            displayStockLevels: true,
            minStockValue: 0,
            displayNextAvailableDates: true
        },
        {
            supplier: "Giant UK",
            displayStockLevels: false,
            minStockValue: 0,
            displayNextAvailableDates: true
        },
        {
            supplier: "Madison",
            displayStockLevels: false,
            minStockValue: 0,
            displayNextAvailableDates: false
        },
        {
            supplier: "Raleigh",
            displayStockLevels: true,
            minStockValue: 0,
            displayNextAvailableDates: true
        },
    ]

    const [activeFeeds, setActiveFeeds] = useState(supplierStockFeeds)

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const tableOptions = {
        selectable: false
    }

    const columnWidths = {
        supplier: "w-full lg:w-2/12",
        warehouseStockLevels: "w-full lg:w-4/12",
        minStockValue: "w-full lg:w-2/12",
        nextAvailableDates: "w-full lg:w-4/12"
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key !== 'supplier') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]


    const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedReviewsCount(count)
    }
    const NextAvailableDates = ({displayNextAvailableDates}) => {
        const [display, setDisplay] = useState(displayNextAvailableDates)
        const toggleDisplay = () => {
            setDisplay(!display)
        }
        return (
            <div className='w-full flex flex-row items-center justify-between'>
                <p className="text-xs text-gray-600">Display Available Dates <span className='text-white'>Display Available Dates</span></p>
                <Switch
                    checked={display}
                    onChange={toggleDisplay}
                    className={`${
                        display ? 'bg-ink-navy' : 'bg-gray-200'
                    } relative inline-flex items-center h-5 rounded-full w-10`}
                    >
                    <span className="sr-only">Display available dates</span>
                    <span
                        className={`transform transition ease-in-out duration-200 ${
                            display ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-3 h-3 transform bg-white rounded-full`}
                    />
                </Switch>
            </div>
            
        )
    }
   
    const MinStockValue = ({displayStockLevels, minStockValue}) => {
        const [minValue, setMinValue] = useState(minStockValue)
        return (
            <input type="number" className='w-full text-gray-600 px-4 py-2 border rounded border-gray-300 focus:border-gray-500 disabled:bg-gray-100 disabled:border-gray-200 focus:outline-none' disabled={!displayStockLevels} value={minValue} onChange={(e)=>{setMinValue(e.target.value)}} />
        )
    }

    const updateStockDisplay = (display, itemIndex) => {
        let tempFeeds = JSON.parse(JSON.stringify(activeFeeds))

        console.log('Before:::', tempFeeds[itemIndex])
        tempFeeds[itemIndex].displayStockLevels = display

        setActiveFeeds(tempFeeds)
        console.log('After:::', activeFeeds[itemIndex])
    }


    const WarehouseStockLevels = ({itemIndex, displayStockLevels}) => {
        const [display, setDisplay] = useState(displayStockLevels)
        const toggleDisplay = () => {
            setDisplay(!display)
            updateStockDisplay(display, itemIndex)
            // window.location.reload(false)
        }
        return (
            <div className='w-full flex flex-row items-center justify-between'>
                <p className="text-xs text-gray-600">Display Stock Levels<span className='text-white'>Display Stock Levels</span></p>
                <Switch
                    checked={display}
                    onChange={toggleDisplay}
                    className={`${
                        display ? 'bg-ink-navy' : 'bg-gray-200'
                    } relative inline-flex items-center h-5 rounded-full w-10`}
                    >
                    <span className="sr-only">Display stock levels</span>
                    <span
                        className={`transform transition ease-in-out duration-200 ${
                            display ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-3 h-3 transform bg-white rounded-full`}
                    />
                </Switch>
            </div>
            
        )
    }
    
    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            const requestObject = {
                supplier: object.supplier,
                warehouseStockLevels: <WarehouseStockLevels itemIndex={objectIndex} displayStockLevels={object.displayStockLevels} />,
                minStockValue: <MinStockValue displayStockLevels={object.displayStockLevels} minStockValue={object.minStockValue} />,
                nextAvailableDates: <NextAvailableDates displayNextAvailableDates={object.displayNextAvailableDates} />
            }
            finalResult.push(requestObject)
        })
        return finalResult
    }

    return (
        <Fragment>
            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search supplier stock feeds" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap">
                        {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2 hidden lg:inline" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <Link to="stores/new-store" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            Save
                        </Link>
                    </div>
                </div>

                {/* Table */}

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData(activeFeeds)[0]).headers} 
                    tableData={cleanUpData(activeFeeds)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpData(activeFeeds)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />
                
            </div>
        </Fragment>
    )
}

export default SupplierStockFeeds
