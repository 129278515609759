import React, { Fragment, useState, useEffect } from 'react'
import ProductDescription from '../../../components/partials/add-edit-product/ProductDescription'
import ProductDetails from '../../../components/partials/add-edit-product/ProductDetails'
// import ProductGeometry from '../../../components/partials/add-edit-product/ProductGeometry'
import ProductInsights from '../../../components/partials/add-edit-product/ProductInsights'
import ProductOptions from '../../../components/partials/add-edit-product/ProductOptions'
import ProductSpecifications from '../../../components/partials/add-edit-product/ProductSpecifications'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../utils';
import Spinner from '../../../components/elements/icons/Spinner';
import { useParams } from 'react-router-dom';
import ProductReviews from '../../../components/partials/add-edit-product/ProductReviews'

const EditProduct = () => {

    const tabs = [
        {
            tabId: "details",
            tabLabel: "Details",
            all: 2375
        },
        {
            tabId: "description",
            tabLabel: "Description",
            count: 95
        },
        {
            tabId: "specifications",
            tabLabel: "Specifications",
            count: 22
        },
        // {
        //     tabId: "geometry",
        //     tabLabel: "Geometry",
        //     count: 18
        // },
        {
            tabId: "options",
            tabLabel: "Options",
            count: 208
        },
        {
            tabId: "insights",
            tabLabel: "Insights",
            count: 987
        },
        {
            tabId: "reviews",
            tabLabel: "Product Reviews",
            count: 987
        }
    ]
    const [activeTab, setActiveTab] = useState(tabs[0].tabId)
    let changeActiveTab = (tab) => {
        setActiveTab(tab.tabId)
    }

    const [selected, setSelected] = useState(tabs[0].tabId)

    const [ cookies ] = useCookies(['user'])
    const { id } = useParams()
    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ productDetails, setProductDetails ] = useState(null)

    useEffect(()=>{
        const fetchProductDetails = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/products/${id}`, { headers })            
                console.log(response)
                setProductDetails(response.data.data)
                
                setProcessing(false)
            } catch (error) {
                console.log(error.response.data.developer_message.errors)
                setError(JSON.stringify(error.response.data.developer_message.errors))
                setProcessing(false)
            }
        }
        fetchProductDetails()
    }, [cookies.accessToken, cookies.tokenType, id])

    const [preventFeedOverwrite, setPreventFeedOverwrite] = useState(false)

    const togglePreventFeedOverwrite = () => {
        setPreventFeedOverwrite(!preventFeedOverwrite)
    }

    return (
        <Fragment>
        {/* Tabs */}
        {processing ?

            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                <p className='text-sm text-gray-500 mt-5'>Fetching product details...</p>
            </div>

            :
            
            <>
            {error === '' ? 
            <>
                <div className="w-full block md:flex flex-row items-center justify-between">
                    <div className="w-full lg:hidden flex-row items-center justify-between mt-5 mb-5">
                        <label className="text-gray-400 text-xs mb-2 block mt-2">Select Category</label>
                        <div className="">
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative mt-1">
                                <Listbox.Button className={`relative w-full text-left text-xs rounded border border-gray-300 text-ink-navy font-medium font-sofia-pro inline-block px-3 py-2 transition duration-200`}>
                                    <span className="block truncate text-sm lg:text-xs">{selected}</span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon
                                        className="w-4 h-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {tabs.map((option, optionIndex) => (
                                        <Listbox.Option
                                            key={optionIndex}
                                            className={({ active }) =>
                                                `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                                    cursor-default select-none relative py-2 pl-10 pr-4`
                                            }
                                            value={option.tabLabel}
                                            
                                            >
                                            {({ selected, active }) => (
                                                <>
                                                <span
                                                    className={`${
                                                    selected ? 'font-medium' : 'font-normal'
                                                    } block truncate text-sm`}
                                                    onClick={()=>{changeActiveTab(option)}}
                                                >
                                                    {option.tabLabel}
                                                </span>
                                                {selected ? (
                                                    <span
                                                    className={`${
                                                        active ? 'text-amber-600' : 'text-amber-600'
                                                    }
                                                            absolute inset-y-0 left-0 flex items-center pl-3`}
                                                    >
                                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                    </Listbox.Options>
                                </Transition>
                                </div>
                            </Listbox>
                        </div>
                    </div>

                    <div className="hidden lg:flex flex-row gap-x-1 border-b border-gray-300 w-4/5">
                        {/* {tabs.map((tab, tabIndex) =>( */}
                        {tabs.slice(0, 7).map((tab, tabIndex) =>(
                            <button 
                                key={tabIndex} 
                                className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                                onClick={()=>{changeActiveTab(tab)}}>
                                {tab.tabLabel} 
                            </button>
                        ))}
                    </div>
                    <button className="px-5 py-3 rounded text-white font-sofia-pro text-xs bg-blue-700 hover:bg-blue-500 transition duration-200">Save Product</button>
                </div>
                {/* Tab area */}
                <div className="w-full py-7">
                    {activeTab === 'details' && <ProductDetails productDetails={productDetails} />}
                    {activeTab === 'description' && <ProductDescription productDescription={productDetails.description} />}
                    {activeTab === 'specifications' && <ProductSpecifications specifications={productDetails.specifications} />}
                    {/* {activeTab === 'geometry' && <ProductGeometry />} */}
                    {activeTab === 'options' && <ProductOptions />}
                    {activeTab === 'insights' && <ProductInsights />}
                    {activeTab === 'reviews' && <ProductReviews productDetails={productDetails} />}

                    <div className="flex flex-row gap-x-3 mt-5 items-center my-2">
                        <input type="checkbox" checked={preventFeedOverwrite} className="mr-2" onChange={()=>{togglePreventFeedOverwrite()}} />
                        <p className="text-sm text-gray-500">Prevent product feed overwrite (pricing and stock will continue to update when available) </p>
                    </div>
                </div>
        </>
            :
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                <p className='text-sm text-gray-600 mt-1'>{error}</p>
            </div>
            }
            </>
        }
    </Fragment>
    )
}

export default EditProduct
