import React, { useState, Fragment, useEffect } from "react";
import { v4 as uuid } from "uuid";
import ArrowUpRight from "../../../assets/images/icons/arrow-up-right-black.svg"
import VerticalMenuIcon from "../../../components/elements/icons/VerticalMenuIcon";
// import DropdownMenu from "../../../components/elements/DropdownMenu"
import { Menu } from '@headlessui/react'
import { Link } from "react-router-dom";
import ActiveBooking from "../../../components/partials/bookings/ActiveBooking";

const BookingsCalendarNew = () => {
    const [dailyVIew, setDailyVIew] = useState(true)
    const toggleDailyView = () => {
      setDailyVIew(!dailyVIew)
    } 

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []
    )

    const calendarOptions = {
        columns: {
          columnsInDisplay: windowDimensions.width <= 650 ? 1 : 3
        },
        timeSlots: {
          timeslotHeight: 50,
          timeslotsInDisplay: 12,
          timeSlotStartIndex: 0,
          timeSlots: [
            {startTime: '08:00 am'},
            {startTime: '08:30 am'},
            {startTime: '09:00 am'},
            {startTime: '09:30 am'},
            {startTime: '10:00 am'},
            {startTime: '10:30 am'},
            {startTime: '11:00 am'},
            {startTime: '11:30 am'},
            {startTime: '12:00 pm'},
            {startTime: '12:30 pm'},
            {startTime: '01:00 pm'},
            {startTime: '01:30 pm'},
            {startTime: '02:00 pm'},
            {startTime: '02:30 pm'},
            {startTime: '03:00 pm'},
            {startTime: '03:30 pm'},
            {startTime: '04:00 pm'},
            {startTime: '04:30 pm'},
            {startTime: '05:00 pm'},
            {startTime: '05:30 pm'},
            {startTime: '06:00 pm'},
          ]
        }
      }
    
    const bookings = [
        { 
            id: uuid(), 
            title: "Bike Wash",
            assignedStaff: "Mike Lewis",
            customer: "Mr. Frank Frankson",
            timeSlots: 1,
            startTime: "08:00 am",
            endTime: "08:30 am",
            confirmed: true
        },
        { 
            id: uuid(), 
            title: "Another Bike Wash",
            assignedStaff: "Mike Lewis",
            customer: "Mr. Frank Frankson",
            timeSlots: 3,
            startTime: "09:30 am",
            endTime: "11:00 am",
            confirmed: false

        },
        { 
            id: uuid(), 
            title: "Silver Service",
            assignedStaff: "Simon Smith",
            customer: "Mr. Frank Frankson",
            timeSlots: 2,
            startTime: "08:30 am",
            endTime: "09:30 am",
            confirmed: true
        },
        { 
            id: uuid(), 
            title: "Silver Service",
            assignedStaff: "Matt Power",
            customer: "Mr. Frank Frankson",
            timeSlots: 1,
            startTime: "12:00 pm",
            endTime: "12:30 pm",
            confirmed: false
        },
        { 
            id: uuid(), 
            title: "Silver Service",
            assignedStaff: "Barney Stinson",
            customer: "Mr. Theodore Mosby",
            timeSlots: 2,
            startTime: "08:30 am",
            endTime: "09:30 am",
            confirmed: true
        },
        { 
            id: uuid(), 
            title: "Silver Service",
            assignedStaff: "Marshal Ericsson",
            customer: "Miss. Lilian Aldrin",
            timeSlots: 4,
            startTime: "09:00 am",
            endTime: "11:00 am",
            confirmed: false
        }
    ]

    const [staffStartIndex, setStaffStartIndex] = useState(0)

    const stepStaff = (direction) => {

        if(calendarOptions.columns.columnsInDisplay > 1 ) {
            // console.log('condition 1 happened...')
            // return
            if(direction === 'next' && ((staffStartIndex + 1) > (calendarOptions.columns.columnsInDisplay)) ) {
                return
            }
            
            if(direction === 'previous' && staffStartIndex < 0) {
                return
            }
            
            if(direction === 'next'){
                setStaffStartIndex(staffStartIndex + 1)
            }
            
            if (direction === 'previous') {
                setStaffStartIndex(staffStartIndex - 1)
            }
    
            let inView = staff.filter((staff, staffIndex) => {
                return (staffIndex >= staffStartIndex && (staffIndex <= (staffStartIndex + calendarOptions.columns.columnsInDisplay - 1)))
            })
    
            if(inView.length < calendarOptions.columns.columnsInDisplay) { return }
    
            setStaffInView(inView)

        } else {
            if(direction === 'next'){
                if ((staffStartIndex + 1) > ( staff.length - 1)) {
                    return
                }
                setStaffStartIndex(staffStartIndex + 1)
                setStaffInView([staff[staffStartIndex + 1]])
            }
            
            if (direction === 'previous') {
                if (staffStartIndex - 1 < 0) {
                    return
                }
                setStaffStartIndex(staffStartIndex - 1)
                setStaffInView([staff[staffStartIndex - 1]])
            }
        }

    }

    const filterStaffBookings = (staffName) => {
        let staffTimeSlots = []

        calendarOptions.timeSlots.timeSlots.forEach((timeSlot) => {
            let timeSlotBooking = null
            let booked = false
            bookings.forEach((booking) => {
            if(booking.startTime === timeSlot.startTime && booking.assignedStaff === staffName) {
                timeSlotBooking = booking
                booked = true
            }
            })
            staffTimeSlots.push({...timeSlot, ...{booking: timeSlotBooking}, ...{booked}})
        })
        return staffTimeSlots
    }

    const staff = [
        {
            name: "Mike Lewis",
            items: filterStaffBookings("Mike Lewis"),
            image: "https://sunrift.com/wp-content/uploads/2014/12/Blake-profile-photo-square.jpg",
            colour: "#2EB4B5"
        },
        {
            name: "Simon Smith",
            image: "https://profilemagazine.com/wp-content/uploads/2020/04/Ajmere-Dale-Square-thumbnail.jpg",
            items: filterStaffBookings("Simon Smith"),
            colour: "#FFA088"
        },
        {
            name: "Matt Power",
            image: "http://gokubi.com/wp-content/uploads/2013/10/Steve-Andersen-Headshot-square1.jpeg",
            items: filterStaffBookings("Matt Power"),
            colour: "#1476EC"
        },
        {
            name: "Barney Stinson",
            image: "http://gokubi.com/wp-content/uploads/2013/10/Steve-Andersen-Headshot-square1.jpeg",
            items: filterStaffBookings("Barney Stinson"),
            colour: "#0d37ce"
        },
        {
            name: "Marshal Ericsson",
            image: "http://gokubi.com/wp-content/uploads/2013/10/Steve-Andersen-Headshot-square1.jpeg",
            items: filterStaffBookings("Marshal Ericsson"),
            colour: "#ff0000"
        },
    ]

    const [staffInView, setStaffInView] = useState(
        staff.filter((staff, staffIndex) => {
            return (staffIndex >= staffStartIndex && (staffIndex <= (staffStartIndex + calendarOptions.columns.columnsInDisplay - 1)))
        })
    )

    const getCardStyles = (activity) => {
        let styles = {
          padding: 16,
          borderRadius: "15px",
          width:"100%",
          position: "relative",
          height: `${(calendarOptions.timeSlots.timeslotHeight * activity.timeSlots) + (20 * activity.timeSlots) }px`,
          backgroundColor: "rgba(255, 255, 255, 0.8)"
        }
  
        if (!activity.confirmed) {
          styles.backgroundImage = `url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e6f4f5' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E")`
        }
  
        return styles
    }

    
    const allowDrop = (event) => {
        event.preventDefault();
    }

    const [draggingCard, setDraggingCard] = useState("")
    
    const drag = (event) => {
        // event.dataTransfer.setData("text", event.target.id);
        setDraggingCard(event.target.id)
    }
    
    const drop = (event) => {
        event.preventDefault();
        // Do nothing if card is brought back to the same position
        if(!event.target.id || (draggingCard === event.target.id)) {
            return
        }

        let tempStaff = JSON.parse(JSON.stringify(staffInView))

        const dragging = {
            staff: tempStaff[parseInt(draggingCard.split('-')[0])],
            booking: tempStaff[parseInt(draggingCard.split('-')[0])].items[parseInt(draggingCard.split('-')[1])]
        }
        
        const destination = {
            staff: tempStaff[parseInt(event.target.id.split('-')[0])],
            booking: tempStaff[parseInt(event.target.id.split('-')[0])].items[parseInt(draggingCard.split('-')[1])]
        }
        console.log(destination)

        const newBooking = dragging.booking.booking
        newBooking.startTime = destination.booking.startTime

        tempStaff[parseInt(draggingCard.split('-')[0])].items[parseInt(draggingCard.split('-')[1])].booking = null

        tempStaff[parseInt(event.target.id.split('-')[0])].items[parseInt(event.target.id.split('-')[1])].booking = newBooking

        setDraggingCard("")
        setStaffInView(tempStaff)
    } 
    
    const bookingActions = [
        {
            actionLabel: "Accept Booking",
            function: ""
        },
        {
            actionLabel: "Cancel Booking",
            function: ""
        },
        {
            actionLabel: "Reschedule",
            function: ""
        },
        {
            actionLabel: "Reassign",
            function: ""
        }
    ]


    const [activeBooking, setActiveBooking] = useState(null)
    const [bookingFlyout, setBookingFlyout] = useState(false)

    const openBookingFlyout = (activeBooking, staff) => {
        let booking = { ...activeBooking, ...{staff: staff} }
        console.log(booking)
        setActiveBooking(booking)
        setBookingFlyout(true)
    }

    const closeBookingFlyout = () => {
        setBookingFlyout(false)
        setActiveBooking(null)
    }
    
    return (
        <Fragment>
            <div className="border-b border-gray-200 px-4 md:x-16 py-4 w-full block md:flex flex-row items-center gap-x-5 pb-9 md:pb-4">
                {bookingFlyout &&
                    <ActiveBooking activeBooking={activeBooking} closeControl={closeBookingFlyout} />
                }
                <div className="flex flex-row gap-x-4 items-center w-full md:w-4/12">
                    <div className="flex flex-row gap-x-3 items-center relative">
                        <button className="rounded-full border border-gray-200 p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <button className="rounded-full border border-gray-200 p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    
                    <p className="text-sm">
                        <span className="text-ink-navy font-medium">20th September </span><span className="text-gray-400">Today</span>
                    </p>
                </div>

                <div className="flex justify-between items-center mt-5 md:mt-0 w-full md:w-8/12">
                    <div className="flex flex-row justify-between items-center w-3/6 md:w-2/6 py-2 bg-grey rounded-md relative ml-0 md:ml-5">
                        {/* toggle switch underlay */}
                        <div className={`w-1/2 transform transition duration-200 inline-block h-full text-sm cursor-pointer flex items-center justify-center border-2 rounded-md border-gray-200 shadow-md bg-white z-10 ${dailyVIew ? "absolute translate-x-0" : "absolute translate-x-full"}`} />
                        <button 
                            className={`w-1/2 transition duration-200 inline-block h-full text-xs cursor-pointer flex items-center justify-center z-20`} 
                            onClick={() => {toggleDailyView()}}
                        >
                            Day
                        </button>
                        <button 
                            className={`w-1/2 transition duration-200 inline-block h-full text-xs cursor-pointer flex items-center justify-center z-20`}
                            onClick={() => {toggleDailyView()}}
                        >
                            Week
                        </button>
                    </div>
                    
                    <button className="text-gray-500 text-sm flex items-center gap-x-2">
                        All bookings <img alt="" src={ArrowUpRight} />
                    </button>
                </div>

            </div>

            <div className="overflow-x-scroll relative">
                <div className="w-3/12 md:w-1/12 pl-6 pt-24 min-h-screen border-r border-gray-200 float-left">
                    {calendarOptions.timeSlots.timeSlots.map((slot, slotIndex) => (
                        <span 
                        className={`text-xs font-sofia-pro text-gray-300 block`} 
                        style={{
                            height: `${calendarOptions.timeSlots.timeslotHeight}px`,
                            marginBottom: '20px'}} 
                            key={slotIndex}>{slot.startTime}
                        </span>
                    ))}
                </div>
                <div className="w-9/12 md:w-11/12 float-right">
                    <div className="float-left w-full">
                    {staffInView.map((staff, staffIndex) => {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "",
                                    borderRight: '1px solid #e3e3e3',
                                    borderBottom: '1px solid #e3e3e3',
                                    paddingLeft: 0,
                                    paddingRight: 10,
                                    float: "left",
                                    // width: "33.33%"
                                    // minHeight: "100vh"
                                }}
                                className="w-full md:w-1/3"
                                key={staffIndex}
                            >
                                <div className="py-4 flex flex-row items-center gap-x-2 border-b border-gray-200 relative">
                                    {staffIndex === 0 && <button className="rounded-full border border-gray-200 p-1 absolute top-5 -left-5 bg-white" onClick={()=> {stepStaff('previous')}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                        </svg>
                                    </button>}
                                    
                                    <img alt="" src={staff.image} className="rounded-full w-10 ml-5 mr-3" style={{borderWidth: "1px", borderStyle: "solid", borderColor: `${staff.colour}`}} />
                                    <h2 className="text-sm">{staff.name}</h2>

                                    {staffIndex === staffInView.length - 1 && <button className="rounded-full border border-gray-200 p-1 absolute top-5 right-3" onClick={()=> {stepStaff('next')}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                        </svg>
                                    </button>}
                                </div>
                                <div style={{ margin: 8, width: "100%" }}>
                                {staff.items.map((item, itemIndex) => {
                                    return (
                                        <div 
                                            id={`${staffIndex}-${itemIndex}`}
                                            key={itemIndex}
                                            className="border-t border-gray-200 mt-3 relative z-30" 
                                            style={{height: `${calendarOptions.timeSlots.timeslotHeight}px`,
                                            marginBottom: '20px' }}
                                            onDrop={(e) => {drop(e)}} onDragOver={(e)=>{allowDrop(e)}}
                                        >
                                            {item.booking && 
                                                <div 
                                                    id={`${staffIndex}-${itemIndex}`}
                                                    style={{
                                                        //     {
                                                        userSelect: !item.booking ? "none" : "",
                                                        minHeight: `${(calendarOptions.timeSlots.timeslotHeight) }px`,
                                                        // minHeight: `${(calendarOptions.timeSlots.timeslotHeight * item.booking.timeSlots) + (20 * item.booking.timeSlots) }px`,
                                                        margin: "0",
                                                        borderRadius: "15px", 
                                                        position: "absolute",
                                                        width: "99%",
                                                        cursor: "pointer",
                                                        // backgroundColor: snapshot.isDragging
                                                        //     ? "#263B4A"
                                                        //     : "#456C86",
                                                        // color: "white",
                                                        // ...unconfirmedBackground(column.color),
                                                        backgroundColor: !item.booking ? 'transparent' : staff.colour,
                                                        zIndex: 50,
                                                    }}
                                                    draggable="true"
                                                    onDragStart={(e) => drag(e)}
                                                >
                                                    <div className="z-30" style={getCardStyles(item.booking)} onClick={()=>{openBookingFlyout(item, staff)}}>
                                                        <div className="absolute top-5 right-1">
                                                            <Menu>
                                                                <Menu.Button>
                                                                    <VerticalMenuIcon color={staff.colour} />
                                                                </Menu.Button>
                                                                <Menu.Items  className="absolute w-52 right-3 border bg-white p-3 rounded-md" style={{borderColor: `${staff.colour}`}}>
                                                                    {bookingActions.map((action, actionIndex) => (
                                                                        <Menu.Item key={actionIndex}>
                                                                        {({ active }) => (
                                                                            <button
                                                                                style={{color: `${staff.colour}`}}
                                                                                className={`block text-xs py-2 px-4 ${active && 'bg-gray-100'}`}
                                                                            >
                                                                                {action.actionLabel}
                                                                            </button>
                                                                        )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </Menu.Items>
                                                            </Menu>
                                                        </div>
                                                        <p 
                                                        style={{color: staff.colour}} 
                                                        className="font-medium text-xs">{item.booking.title}
                                                        </p>

                                                        <p style={{color: staff.colour}} 
                                                        className="font-medium text-xs">
                                                            {item.booking.startTime} - 
                                                            {item.booking.endTime} 

                                                            <span style={{backgroundColor: staff.colour, display: 'inline-block', width: '5px', height: '5px', borderRadius: '5px', marginLeft: '10px', marginTop: '10px', marginRight: '10px'}} /> 
                                                            
                                                            {item.booking.customer}
                                                        </p>
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        );
                                    })}
                                </div>

                            </div>
                        )
                        })}

                    </div>
                </div>
                <Link to="new-booking" className="w-16 h-16 rounded-full flex items-center justify-center bg-blue-700 text-white fixed bottom-10 right-20 z-40">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                </Link>
            </div>


            {/*  */}
            
      </Fragment>
    )
}

export default BookingsCalendarNew
