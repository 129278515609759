import React, {Fragment, useState} from 'react'
import ShopIcon from '../../assets/images/icons/shop-gray.svg'
import HelpIcon from '../../assets/images/icons/help.svg'
import BellIcon from '../../assets/images/icons/bell.svg'
import AvatarIcon from '../../assets/images/icons/avatar-circle.svg'
import MenuIcon from '../../assets/images/icons/menu.svg'
import DropdownMenu from '../elements/DropdownMenu'
import MobileMenu from './MobileMenu'
import GlobalSearch from './GlobalSearch'
import StoreSelector from './StoreSelector'
import { useLocation, useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { titleCase } from '../../utils'
import axios from 'axios'

const AdminHeader = ({pageTitle, sidebarActive, sidebarToggleFunction}) => {
    const usePathname = () => {
        const location = useLocation();
        const fullRoute = location.pathname.split('/')
        const currentRoute = titleCase(fullRoute[fullRoute.length - 1].replaceAll("-", ' '));
        
        // return location.pathname;
        return currentRoute;
    }

    const history = useHistory();
    // const history = createBrowserHistory();

    const logOut = async () => {
        try {
            await axios.post(`/api/v1/admin/auth/logout`, {})
            // setTimeout(() => {
            //     history.push("/");
            // }, 1000);
            window.location.href='/'
        } catch (error) {
            // setError(error.response.data.developer_message)
            console.log(error)
        }
    }

    const profileButtonOptions = [
        {
            label: "User Profile",
            icon: null,
            link: "/",
            action: null,
            fullObject:{}
        },
        {
            label: "Settings",
            icon: null,
            link: "/",
            action: null,
            fullObject:{}
        },
        {
            label: "Help",
            icon: null,
            link: "/",
            action: null,
            fullObject:{}
        },
        {
            label: "Log out",
            icon: null,
            link: "/",
            action: logOut,
            fullObject: null
        },
    ]

    const [mobileMenuActive, setMobileMenuActive] = useState(false)
    const toggleMobileMenu = () => {
        setMobileMenuActive(!mobileMenuActive)
    }
    const userWebsite = "yourwebsite.co.uk"
    return (
        <Fragment>
            <button className='p-3 bg-ink-navy text-white rounded-full fixed top-16 left-3 z-40 md:hidden' onClick={()=>{toggleMobileMenu()}}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>

            <div className={`min-h-24 block py-5 md:py-2 md:flex md:flex-row md:items-center border-b relative pl-10 md:pr-5 ${sidebarActive ? 'px-5 xl:px-10' : 'xl:px-32'}`}>
                {!sidebarActive && <button className="hidden xl:inline-block cursor-pointer absolute top-5 left-20" onClick={()=>{sidebarToggleFunction()}}>
                    <img src={MenuIcon} alt="toggler" />
                </button>}

                {mobileMenuActive && <MobileMenu toggleMenu={toggleMobileMenu} /> }
                {/* The header */}
                <div className="w-full mb-5 md:mb-1 md:inline-block md:w-2/6">
                    <span className='md:hidden w-full pt-3'>
                        <StoreSelector />
                    </span>
                    {/* <h1 className="text-lg w-full pt-5 md:pt-0">{usePathname()}</h1> */}
                    <h1 className="text-lg w-full pt-5 md:pt-0">{pageTitle}</h1>
                </div>

                <div className='w-full md:w-7/12'>
                    <GlobalSearch />
                </div>
                <div className="w-full lg:w-3/6 xl:w-2/6 flex md:flex-row gap-x-5 flex-row-reverse md:gap-x-0 md:justify-between px-6 md:pt-0">
                    {/* Website/shop link */}
                    <div className="hidden lg:flex flex-row items-center gap-x-2">
                        <img src={ShopIcon} alt="website" className="w-6" />
                        <a className="inline text-gray-500 text-sm" href={`https://${userWebsite}`}>{userWebsite}</a>
                    </div>

                    <a className="inline text-gray-500 text-sm" href={`https://${userWebsite}`}>
                        <img src={HelpIcon} alt="dropdown" className="w-6" />
                    </a>

                    {/* Notifications */}
                    <a className="inline text-gray-500 text-sm relative" href={`https://${userWebsite}`}>
                        <img src={BellIcon} alt="dropdown" className="w-5" />
                        <span className="inline-block absolute top-0 right-0 bg-blue-600 w-2 h-2 rounded-full" />
                    </a>

                    {/* PROFILE DROPDOWN */}    
                    <DropdownMenu 
                        buttonIcon={AvatarIcon} 
                        buttonType='icon' 
                        menuItems={profileButtonOptions}
                        iconClasses="w-6" 
                        buttonClasses="inline-flex justify-center w-full text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" 
                    />

                </div>
            </div>
        </Fragment>
    )
}

export default AdminHeader
