import React from "react";
import "./index.css"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useParams,
  // useRouteMatch
} from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import Admin from "./pages/Admin";
import ErrorPage from "./pages/ErrorPage";
import RegisterPage from "./pages/RegisterPage";
import VerificationPage from "./pages/VerificationPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import PasswordResetRequestPage from "./pages/PasswordResetRequestPage";
import axios from 'axios';


export default function App() {

  let cloud_environments = ["staging", "dev", "production"];
  if(!cloud_environments.includes(process.env.NODE_ENV)){ //if not in a cloud environment spoof the referrer
    axios.defaults.proxy = {
      host: process.env.REACT_APP_REFFERER
    }
  }

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
  axios.defaults.withCredentials = true
  // axios.defaults.xsrfCookieName = 'X-XSRF-TOKEN'
  // axios.defaults.xsrfHeaderName = "XSRF-TOKEN"

  // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  // /**
  //  * Next we will register the CSRF Token as a common header with Axios so that
  //  * all outgoing HTTP requests automatically have it attached. This is just
  //  * a simple convenience so we don't have to attach every token manually.
  //  */


  // let token = document.head.querySelector('meta[name="XSRF-TOKEN"]');

  // if (token) {
  //     axios.defaults.headers.common['X-XSRF-TOKEN'] = token.content;
  // } else {
  //     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
  // }
  
  // axios.interceptors.request.use(
  //   function (req) {
  //     req['X-XSRF-TOKEN'] = "test";
  //     return req;
  //   },
  //   (err) => {
  //     return Promise.reject(err);
  //   }
  // );

  return (
    <main>
      <Router>
        <Switch>
          {/* <Route path="/" exact render={() => <LoginPage />} /> */}
          {/* <Route path="/login" exact render={() => <LoginPage />} /> */}
          <Route path="/" exact>
            <LoginPage />
          </Route>

          <Route path="/admin">
            <Admin />
          </Route>

          <Route path="/register" exact>
            <RegisterPage />
          </Route>

          <Route path="/verify/:userId/:expiration/:hash/:signature" exact>
            <VerificationPage />
          </Route>

          <Route path="/password-reset-request" exact>
            <PasswordResetRequestPage />
          </Route>

          <Route path="/password-reset" exact>
            <PasswordResetPage />
          </Route>
          
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    </main>
  );
}
