import React, {Fragment, useState} from 'react'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
import BulkActionsIcon from '../../../../assets/images/icons/bulk-items.svg'
import DropdownMenu from '../../../../components/elements/DropdownMenu'
import DataTable from '../../../../components/elements/DataTable'
import { Link } from 'react-router-dom'
import { convertCamelCase } from "../../../../utils";
import EditIcon  from '../../../../assets/images/icons/edit.svg'
import DeleteIcon from '../../../../assets/images/icons/delete.svg'
import UpRightArrow from '../../../../assets/images/icons/arrow-up-right-black.svg'

const Downloads = () => {
    const downloads = [
        {
            name: "Logo",
            fileType: "PNG",
            category: "Mountain",
            uploadDate: "2021-11-29",
            user: "Admin",
            display: "",
            actions: ""
        },
        {
            name: "Logo_1080x960",
            fileType: "PNG",
            category: "Mountain",
            uploadDate: "2021-11-29",
            user: "Lewis Hamilton",
            display: "",
            actions: ""
        },
        
    ]

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const tableOptions = {
        selectable: false
    }

    const columnWidths = {
        name: 'w-full lg:w-2/12',
        fileType: 'w-full lg:w-1/12',
        category: 'w-full lg:w-2/12',
        uploadDate: 'w-full lg:w-1/12',
        user: 'w-full lg:w-2/12',
        display: 'w-full lg:w-2/12',
        actions: 'w-full lg:w-2/12'
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]


    const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedReviewsCount(count)
    }

    const EditDeleteButton = ({downloadIndex}) => {
        return (
            <div className='flex flex-row gap-x-2'>
                <Link to={`stores/detail/${downloadIndex}`} className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
                <button className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Delete <img src={DeleteIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></button>
            </div>
        )
    }

    // const StoreLink = ({storeIndex, storeId}) => {
    //     return (
    //         <Link to={`stores/detail/${storeIndex}`} className='text-ink-navy font-bold text-xs'>{storeId}</Link>
    //     )
    // }
    
    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            const requestObject = {
                name: object.name,
                fileType: object.fileType,
                category: object.category,
                uploadDate: object.uploadDate,
                user: object.user,
                display: object.display,
                actions:<EditDeleteButton downloadIndex={objectIndex} />
            }
            finalResult.push(requestObject)
        })
        return finalResult
    }

    return (
        <Fragment>

            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/6">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search stores" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap lg:flex-nowrap">
                        {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2 hidden lg:inline" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <span className='p-px rounded-full border border-ink-navy mr-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                           </span>
                            Update default logos
                        </button>
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={UpRightArrow} alt="search" className="w-3 mr-2 hidden lg:inline" /> Downloads page
                        </button>
                        <Link to="stores/new-store" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            Add Download
                        </Link>
                    </div>
                </div>

                {/* Table */}

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData(downloads)[0]).headers} 
                    tableData={cleanUpData(downloads)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpData(downloads)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />
                
            </div>
        </Fragment>
    )
}

export default Downloads
