export const baseUrl = process.env.REACT_APP_BASE_URL;

export const convertCamelCase = (camelCaseText) => {
    const text = camelCaseText;
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    return finalResult
}

export const yesteryears = (n) => {
    const max = new Date().getFullYear()
    const min = max - n
    const years = []

    for (let i = max; i >= min; i--) {
        years.push({name: i, value: i, selected: false})
    }
    return years
}

export const formatDate = (date) => {
    const dateObj = new Date(date)
    const month = dateObj.getUTCMonth() + 1
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()
    // const time = dateObj.getUTCHours() + ':' + dateObj.getUTCMinutes() + ':' + dateObj.getUTCSeconds()
    const timeStamp = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`
    // const timeStamp = `${month}-${day}-${year} @ ${time}`
    return timeStamp
}

export const hexToRgbA = (hex, opacity) => {
    if(!opacity) {opacity = 1}
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + opacity + ')';
    }
    throw new Error('Bad Hex');
}

export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
 }

export const isValidObject = (obj) => {
    return obj && obj !== 'null' && obj !== 'undefined';
}

export const slugify = (string) => {
    if (!string || string === '' ) {
        return ""
    }
    const updated = string.toLowerCase()
    const slugified = updated.split(' ').join('-')

    return slugified
}

export const parseFilters = (filtersArray, action) => {
    // console.log('--> ', filtersArray)

    if(!filtersArray || filtersArray.length === 0) {
        return null
    }

    const cleanedArray = filtersArray.filter((item, itemIndex) => { 
        return item.term !== ''
    })

    let filtersString = cleanedArray.map((filterObject) => {
        let string = 'or='
        if(action && action === 'filter') {
            string = 'and='
        }
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(filterObject)) {
            string += `${value}||`
        }
        return string.slice(0, -2)
    })

    if (filtersString.length > 0) {
        return filtersString.join('&').toString()
    } else {
        return ''
    }

} 

export const sidebarState = () => {
    
    const sidebarState = JSON.parse(localStorage.getItem('sidebarState'));
    console.log('sbar state -> ', sidebarState)
    if(!sidebarState) {
        return true
    }
    return sidebarState === 'active' ? true : false
}

export const tableConfig = () => {
    const configs = JSON.parse(localStorage.getItem('tableConfigs'));
    if(!configs) {
        return null
    }
    return configs
}