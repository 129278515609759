import React from 'react'
import StarFilled from './icons/StarFilled'

const RatingStars = ({rating}) => {

    const getRating = () => {
        return Math.ceil(rating * 0.5)
    }

    return (
        <div className='flex items-center gap-x-1'>
            <span>
                {getRating() >= 1 ? <StarFilled className="w-5 h-5 text-yellow-500" /> : <StarFilled className="w-5 h-5 text-gray-200" /> }
            </span> 
            <span>
                {getRating() >= 2 ? <StarFilled className="w-5 h-5 text-yellow-500" /> : <StarFilled className="w-5 h-5 text-gray-200" /> }
            </span> 
            <span>
                {getRating() >= 3 ? <StarFilled className="w-5 h-5 text-yellow-500" /> : <StarFilled className="w-5 h-5 text-gray-200" /> }
            </span> 
            <span>
                {getRating() >= 4 ? <StarFilled className="w-5 h-5 text-yellow-500" /> : <StarFilled className="w-5 h-5 text-gray-200" /> }
            </span> 
            <span>
                {getRating() >= 5 ? <StarFilled className="w-5 h-5 text-yellow-500" /> : <StarFilled className="w-5 h-5 text-gray-200" /> }
            </span> 
        </div>
    )

}

export default RatingStars