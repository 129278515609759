import React, { useState } from 'react'
import PropTypes from 'prop-types';

const DateRangeStepper = ({numberOfDays}) => {

    const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10))
    const today = new Date().toISOString().slice(0, 10)
    const updateStartDate = (date) => {
        setStartDate(new Date(date).toISOString().slice(0, 10))
        // setStartDate(formatDate(new Date(date)))
        const endDate = new Date(addDays(date)).toISOString().slice(0, 10)
        setEndDate(endDate)
    }

    const addDays = (date) => {
        var result = new Date(date);
        result.setDate(result.getDate() + numberOfDays);
        return result
    }

    const removeDays = (date) => {
        var result = new Date(date);
        result.setDate(result.getDate() - numberOfDays);
        return result
    }

    const [endDate, setEndDate] = useState(new Date(addDays(startDate)).toISOString().slice(0, 10))

    const updateDates = (verb) => {
        let newStartDate = new Date(addDays(startDate)).toISOString().slice(0, 10)
        let newEndDate = new Date(addDays(endDate)).toISOString().slice(0, 10)

        if (verb !== 'add') {
            newStartDate = new Date(removeDays(startDate)).toISOString().slice(0, 10)
            newEndDate = new Date(removeDays(endDate)).toISOString().slice(0, 10)
        }

        if(newStartDate < today) {
            return
        }

        // setEndDate(formatDate(endDate))
        setStartDate(newStartDate)
        setEndDate(newEndDate)
    }
    
    // const [endDate, setEndDate] = useState('')

    return (
        <div className='w-full flex flex-row border border-gray-300 items-center text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500'>
            <button className='py-3 px-4 border-r border-gray-300' onClick={()=>{updateDates('remove')}}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>                
            </button>

            <div className='w-full flex flex-row items-center'>
                <div className='date-picker-wrapper'>
                    <input type='date' className='custom' min={today} value={startDate} onChange={(e) => {updateStartDate(e.target.value)}} /> 
                </div>
                - 
                <div className='date-picker-wrapper ml-0 md:ml-6'>
                    <input type='date' className='bg-white' value={endDate} disabled /> 
                </div>
            </div>
            
            <button className='py-2 px-4 border-l border-gray-300' onClick={()=>{updateDates('add')}}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>              
            </button>           
        </div>
    )
}

DateRangeStepper.propTypes = {
    numberOfDays: PropTypes.number.isRequired
};

export default DateRangeStepper
