import React, { Fragment, useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'
import axios from "axios"
import { baseUrl, slugify, yesteryears } from '../../../utils';
// import ErrorMessage from '../../../../components/elements/ErrorMessage'
import { useCookies } from 'react-cookie';
// import ChevronIcon from '../../../components/elements/icons/ChevronIcon';
import Spinner from '../../../components/elements/icons/Spinner';
import ErrorMessage from '../../../components/elements/ErrorMessage';
import SuccessMessage from '../../../components/elements/SuccessMessage';
import { useHistory } from "react-router-dom";

const NewProduct = () => {
    const history = useHistory();

    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')  
    const [ cookies ] = useCookies(['user']);

    // const [productDetails, setProductDetails] = useState({})
    const [brands, setBrands] = useState(null)
    const [name, setName] = useState(null)
    const [active, setActive] = useState(false)
    const [slug, setSlug] = useState(null)
    const [styleCode, setStyleCode] = useState(null)
    const [brand, setBrand] = useState(null)
    const [year, setYear] = useState(null)
    const [description, setDescription] = useState(null)
    const [fetching, setFetching] = useState(true)

    useEffect(() => {
        const fetchBrands = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setFetching(true)
            try {
                
                const response = await axios.get(`/api/v1/brands/?page[offset]=1&page[limit]=1000000&page[order]=desc`, { headers })            
                console.log(response)
                setBrands(response.data.data)
                setFetching(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setFetching(false)
            }
        }

        fetchBrands()
    }, [cookies.accessToken, cookies.tokenType])

    const [ validationErrors, setValidationErrors ] = useState({})

    const validateForm = () => {
        let errors = {}
        if(!name || name === '') {
            errors.name = true
        }
        if(!slug || slug === '') {
            errors.slug = true
        }
        if(!description || description === '') {
            errors.description = true
        }
        if(!brand || brand === '') {
            errors.brand = true
        }
        if(!year || year === '') {
            errors.year = true
        }
        if(!styleCode || styleCode === '') {
            errors.styleCode = true
        }

        setValidationErrors(errors)
        return errors
    }

    const [success, setSuccess] = useState(null)

    const createProduct = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        if (Object.values(validateForm()).includes(true)) {
            setError('Please check the highlighted fields above')
            return
        }
        
        const requestPayload = {
            name,
            slug,
            description,
            style_code: styleCode,
            brand_id: parseInt(brand),
            year,
            active 
        }

        setProcessing(true)
        try {
            
            const response = await axios.post(`/api/v1/admin/products`, requestPayload, { headers })            
            // setBrands(response.data.data)
            setSuccess('Product created successfully. redirecting to product page...')
            setTimeout(() => {
                history.push(`/admin/products/product/${response.data.data[0].id}`);
            }, 3000);
            setProcessing(false)
        } catch (error) {
            console.error(error.response.data)
            setError(error.response.data.user_message)
            setProcessing(false)
        }
    }    

    return (
        <Fragment>
            {fetching ? 
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                {/* <p className='text-sm text-gray-500 mt-5'>Fetching brands...</p> */}
            </div>   
            :
            <div className="w-full block md:flex flex-row items-center justify-between">
                <div className='w-8/12 px-8 py-4'>
                    <div className="w-full mb-5">
                        <div className="w-full mb-3">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.name && validationErrors.name === true ? 'text-red-500' : ''}`}>Product Name</label>
                            <input type="text" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.name && validationErrors.name === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="eg: Big wheels" value={name} onChange={(e)=>{setName(e.target.value)}} onBlur={(e)=>{setSlug(slugify(e.target.value))}} />
                        </div>

                        <div className="w-full my-3">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.slug && validationErrors.slug === true ? 'text-red-500' : ''}`}>Slug</label>
                            <input type="text" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.slug && validationErrors.slug === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="eg: big-wheels" value={slug} onChange={(e)=>{setSlug(e.target.value)}} />
                        </div>

                        <div className="w-full flex gap-x-4 mt-6 my-3">
                            <label className="text-xs text-gray-500 font-sofia-pro">Save product as active </label>
                            <Switch
                                checked={active}
                                onChange={setActive}
                                className={`${
                                    active ? 'bg-ink-navy' : 'bg-gray-200'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                <span className="sr-only">Save as active</span>
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        active ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block w-3 h-3 transform bg-white rounded-full`}
                                />
                            </Switch>
                        </div>
                    </div>

                    <div className="w-full my-3">
                        <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.description && validationErrors.description === true ? 'text-red-500' : ''}`}>Product Description</label>
                        <textarea className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 resize-none h-32 ${validationErrors.description && validationErrors.description === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="Describe your product" value={description} onChange={(e)=>setDescription(e.target.value)} />
                    </div>

                    <div className="w-full my-3 flex flex-row gap-x-4">
                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.year && validationErrors.year === true ? 'text-red-500' : ''}`}>Product year</label>
                            <div className={`select-wrapper border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.year && validationErrors.year === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`}>
                                <select className="outline-none w-full" onChange={(e)=>setYear(e.target.value)}>
                                    <option value="">Select year</option>

                                    {yesteryears(20).map((year, yearIndex) => (
                                        <option key={yearIndex} value={year.name}>{year.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.styleCode && validationErrors.styleCode === true ? 'text-red-500' : ''}`}>Style Code</label>
                            <input type="text" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.styleCode && validationErrors.styleCode === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="Create a style code" value={styleCode} onChange={(e)=>{setStyleCode(e.target.value)}} />
                        </div>
                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.brand && validationErrors.brand === true ? 'text-red-500' : ''}`}>Product Brand</label>
                            <div className={`select-wrapper border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brand && validationErrors.brand === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`}>
                                <select className="outline-none w-full" value={brand} onChange={(e)=>setBrand(e.target.value)}>
                                    <option value="">Select brand</option>

                                    {brands.map((brand, brandIndex) => (
                                        <option key={brandIndex} value={brand.id}>{brand.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <button className="px-5 py-3 rounded text-white font-sofia-pro text-xs bg-blue-700 hover:bg-blue-500 transition duration-200 my-3 flex gap-x-2 items-center" onClick={()=>{
                            if(!processing) { createProduct() }
                                }}>{processing ? <><Spinner />Saving Product...</> : 'Save Product'}</button>

                    {error && error!=='' &&  
                        <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                    } 

                    {success && success!=='' &&  
                        <SuccessMessage message={success} dismissHandler={()=>{setSuccess(false)}} />
                    } 

                    </div>
            </div>
            }
    </Fragment>
    )
}

export default NewProduct
