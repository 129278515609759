import React, {Fragment, useState, useEffect} from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import DataTable from '../../../components/elements/DataTable'
import { Link } from 'react-router-dom'
import { convertCamelCase } from "../../../utils";
import EditIcon  from '../../../assets/images/icons/edit.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import { useCookies } from 'react-cookie';
import Notification from '../../../components/elements/Notification';
import axios from "axios"
import { baseUrl } from '../../../utils';
import Spinner from '../../../components/elements/icons/Spinner';

const Categories = () => {
    const [ cookies ] = useCookies(['user'])
    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ notification, setNotification ] = useState(null)

    const [ categories, setCategories ] = useState(null)

    useEffect(() => {
        const fetchCategories = async () => {
         const headers = {
              'Content-Type': 'application/json',
              'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
          }
          setProcessing(true)
          try {
              const response = await axios.get(`/api/v1/categories/nested`, { headers })            
              setCategories(response.data.data)
              setProcessing(false)
          } catch (error) {
              console.log(error)
              setError(error.response.data.developer_message.errors)
              setProcessing(false)
          }
        }
  
        fetchCategories()
      }, [cookies.accessToken, cookies.tokenType])
    

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const tableOptions = {
        selectable: false
    }

    const columnWidths = {
        parent: 'w-full lg:w-2/12',
        categories: 'w-full lg:w-6/12',
        thumb: 'w-full lg:w-1/12',
        status: 'w-full lg:w-1/12',
        actions: 'w-full lg:w-2/12'
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

    const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)
    const getSelectionCount = (count) => {
        return setSelectedReviewsCount(count)
    }

    const EditButton = ({categoryIndex}) => {
        return (
            <Link to={`${categoryIndex}`} className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
        )
    }

    const CategoryStatus = ({categoryStatus}) => {
        return (
            <div className="flex flex-row items-center gap-x-2">
                <span className={`w-2 h-2 rounded-full ${categoryStatus === 1 ? "bg-green-500" : "bg-gray-200"}`} /> <span className="font-sofia-pro text-xs capitalize">{categoryStatus = 1 ? 'Feature' : 'Not Featured'}</span>
            </div>
        )
    }

    const ParentCategory = ({parent}) => {
        return (
            parent !== '' && <div className='flex flex-row gap-x-3 items-center'>
                    <span className='px-3 py-2 rounded text-white bg-gray-600'>{parent}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </div>
        )
    }

    const Categories = ({categories}) => {
        return (
            <div className=''>
                {categories.map((category, categoryIndex)=>(
                    <div key={categoryIndex} className='flex flex-row gap-x-3 items-center'>
                        <div>
                            <span className='px-3 py-2 m-3 rounded text-white bg-gray-500 block'>{category.category}</span>
                        </div>

                        {category.children && category.children.length > 0 &&
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                                {/* <span className='px-3 py-2 rounded text-white bg-gray-400'>{.name}</span> */}
                                <div>
                                    {category.children.map((child, childIndex)=>(
                                        <div key={childIndex} className='my-6'>
                                            <span className='px-3 py-2 rounded text-white bg-gray-500'>{child.category}</span>
                                            {child.children && child.children.length > 0 &&
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                    </svg>
                                                    <span className='px-3 py-2 rounded text-white bg-gray-400'>{child.name}</span>
                                                </>
                        
                                            }
                                        </div>
                                    ))}
                                </div>
                            </>
    
                        }
                    </div>
                ))}
            </div>
        )
    }

    const Thumbnail = ({thumbnailUrl}) => {
        return (
            <img src={thumbnailUrl} alt="" className="w-1/5" />
        )
    }

    
    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            const requestObject = {
                parent: <ParentCategory parent={object.category} />,
                categories: <Categories categories={object.children} />,
                thumbnail: <Thumbnail thumbnailUrl="https://media.istockphoto.com/vectors/picture-icon-photo-frame-symbol-landscape-sign-photograph-gallery-vector-id1324356457?k=20&m=1324356457&s=612x612&w=0&h=piYAdXxZnrM4ZV5JmYTd0gWx4lNFcdZG_TzMhFEDcss=" />,
                status: <CategoryStatus categoryStatus={object.status} />,
                actions: <EditButton categoryIndex={objectIndex} />
                }
                finalResult.push(requestObject)
        })
        return finalResult
    }

    return (
        <Fragment>
             
            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search stores" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap">
                        {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2 hidden lg:inline" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        {/* <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <span className='p-px rounded-full border border-ink-navy mr-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                           </span>
                            Update default logos
                        </button> */}
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-3 mr-2 hidden lg:inline" /> Export
                        </button>
                        {/* <Link to="stores/new-store" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            Add Download
                        </Link> */}
                    </div>
                </div>

                {/* Table */}
                {notification && <Notification type={`${notification.success === true ? 'success' : 'error'}`} isShowing={notification.show} message={notification.message} dismissHandler={()=>{setNotification(null)}} />}

                {processing ?

                <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <Spinner />
                    <p className='text-sm text-gray-500 mt-5'>Fetching categories...</p>
                </div>

                :

                <>
                {error === '' ? 
                <>

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData(categories)[0]).headers} 
                    tableData={cleanUpData(categories)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpData(categories)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />
                
                </>
                :
                <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
    
                    <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                    <p className='text-sm text-gray-600 mt-1'>{error}</p>
                </div>
                }
                </>
            }
            </div>

        </Fragment>
    )
}

export default Categories
