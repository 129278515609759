import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
// import SettingsIcon from '../../assets/images/icons/settings.svg'
// import PopularProductsIcon from '../../assets/images/icons/popular-products.svg'
import DashboardIcon from '../elements/icons/DashboardIcon'
import OrdersIcon from '../elements/icons/OrdersIcon'
import ProductsIcon from '../elements/icons/ProductsIcon'
import NavigationsIcon from '../elements/icons/NavigationsIcon'
// import BookingsIcons from '../elements/icons/BookingsIcon'
import CustomersIcon from '../elements/icons/CustomersIcon'
import PopularProductsIcon from '../elements/icons/PopularProductsIcon'
import SettingsIcon from '../elements/icons/SettingsIcon'
import SupportIcon from '../elements/icons/SupportIcon'

const StaticSidebar = () => {
    // let { 
    //     // path, 
    //     url 
    // } = useRouteMatch();
    // const [color, setColor] = useState("#")
    const [activeToolTip, setActiveToolTip] = useState('')


    return (
        <div className="flex flex-col py-40 justify-between gap-y-6 items-center">
            <NavLink activeClassName="border-white bg-black py-3 bg-opacity-60" to="/admin/dashboard" className="px-4 py-2 border-l-2 border-transparent" >
                <div className='relative w-full' onMouseEnter={()=>{setActiveToolTip('dashboard')}} onMouseLeave={()=>{setActiveToolTip('')}}>
                    <DashboardIcon />
                    {activeToolTip === 'dashboard' && <div className='absolute -top-[14px] -right-[220px] bg-gray-700 p-4 z-50 w-[200px]'>
                        <p className='text-white text-sm'>Dashboard</p>
                    </div>}
                </div>
            </NavLink>

            <NavLink activeClassName="border-white bg-black py-3 bg-opacity-60" to="/admin/orders" className="px-4 py-2 border-l-2 border-transparent" >
                <div className='relative w-full' onMouseEnter={()=>{setActiveToolTip('orders')}} onMouseLeave={()=>{setActiveToolTip('')}}>
                    <OrdersIcon />
                    {activeToolTip === 'orders' && <div className='absolute -top-[14px] -right-[220px] bg-gray-700 p-4 z-50 w-[200px]'>
                        <p className='text-white text-sm'>Orders</p>
                    </div>}
                </div>
            </NavLink>
            <NavLink activeClassName="border-white bg-black py-3 bg-opacity-60" to="/admin/products" className="px-4 py-2 border-l-2 border-transparent" >
                <div className='relative w-full' onMouseEnter={()=>{setActiveToolTip('products')}} onMouseLeave={()=>{setActiveToolTip('')}}>
                    <ProductsIcon />
                    {activeToolTip === 'products' && <div className='absolute -top-[14px] -right-[220px] bg-gray-700 p-4 z-50 w-[200px]'>
                        <p className='text-white text-sm'>Products</p>
                    </div>}
                </div>
            </NavLink>
            <NavLink activeClassName="border-white bg-black py-3 bg-opacity-60" to="/admin/navigations" className="px-4 py-2 border-l-2 border-transparent" >
                <div className='relative w-full' onMouseEnter={()=>{setActiveToolTip('content')}} onMouseLeave={()=>{setActiveToolTip('')}}>
                    <NavigationsIcon />
                    {activeToolTip === 'content' && <div className='absolute -top-[14px] -right-[220px] bg-gray-700 p-4 z-50 w-[200px]'>
                        <p className='text-white text-sm'>Content</p>
                    </div>}
                </div>
            </NavLink>
            {/* <NavLink activeClassName="border-white bg-black py-3 bg-opacity-60" to="/admin/bookings" className="px-4 py-2 border-l-2 border-transparent" >
                <div className='relative w-full' onMouseEnter={()=>{setActiveToolTip('bookings')}} onMouseLeave={()=>{setActiveToolTip('')}}>
                    <BookingsIcons />
                    {activeToolTip === 'bookings' && <div className='absolute -top-[14px] -right-[220px] bg-gray-700 p-4 z-50 w-[200px]'>
                        <p className='text-white text-sm'>Bookings</p>
                    </div>}
                </div>
            </NavLink> */}
            <NavLink activeClassName="border-white bg-black py-3 bg-opacity-60" to="/admin/customers" className="px-4 py-2 border-l-2 border-transparent" >
                <div className='relative w-full' onMouseEnter={()=>{setActiveToolTip('customers')}} onMouseLeave={()=>{setActiveToolTip('')}}>
                    <CustomersIcon />
                    {activeToolTip === 'customers' && <div className='absolute -top-[14px] -right-[220px] bg-gray-700 p-4 z-50 w-[200px]'>
                        <p className='text-white text-sm'>Customers</p>
                    </div>}
                </div>
            </NavLink>
            <NavLink activeClassName="border-white bg-black py-3 bg-opacity-60" to="/admin/reports" className="px-4 py-2 border-l-2 border-transparent" >
                <div className='relative w-full' onMouseEnter={()=>{setActiveToolTip('reports')}} onMouseLeave={()=>{setActiveToolTip('')}}>
                    <PopularProductsIcon />
                    {activeToolTip === 'reports' && <div className='absolute -top-[14px] -right-[220px] bg-gray-700 p-4 z-50 w-[200px]'>
                        <p className='text-white text-sm'>Reports</p>
                    </div>}
                </div>
            </NavLink>
            <NavLink activeClassName="border-white bg-black py-3 bg-opacity-60" to="/admin/settings" className="px-4 py-2 border-l-2 border-transparent" >
                <div className='relative w-full' onMouseEnter={()=>{setActiveToolTip('settings')}} onMouseLeave={()=>{setActiveToolTip('')}}>
                    <SettingsIcon />
                    {activeToolTip === 'settings' && <div className='absolute -top-[14px] -right-[220px] bg-gray-700 p-4 z-50 w-[200px]'>
                        <p className='text-white text-sm'>Settings</p>
                    </div>}
                </div>
            </NavLink>
            <NavLink activeClassName="border-white bg-black py-3 bg-opacity-60" to="/admin/support" className="px-4 py-2 border-l-2 border-transparent" >
                <div className='relative w-full' onMouseEnter={()=>{setActiveToolTip('support')}} onMouseLeave={()=>{setActiveToolTip('')}}>
                    <SupportIcon />
                    {activeToolTip === 'support' && <div className='absolute -top-[14px] -right-[220px] bg-gray-700 p-4 z-50 w-[200px]'>
                        <p className='text-white text-sm'>Support</p>
                    </div>}
                </div>
            </NavLink>
        </div>
    )
}

export default StaticSidebar
