import React, { Fragment, useState } from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
// import EditIcon  from '../../../assets/images/icons/edit.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import DataTable from '../../../components/elements/DataTable'
// import { Link } from 'react-router-dom'
import HorizontalMenuIcon  from '../../../assets/images/icons/horizontal-menu-icon.svg'
import DeliveryMethodsBar from '../../../components/partials/DeliveryMethodsBar'
import Donut from '../../../components/partials/Donut'


const SalesOverview = () => {

    const categories = [
        {
            category: "Component", 
            childCategory: "Bars",
            grandChildCategory: "",
            salesValue: 6649662.95,
            orders: 1743,
            aov: 3945,
            items:1743,
            aiv: 3945,
            actions: "",
        },
        {
            category: "Accessories", 
            childCategory: "Bags",
            grandChildCategory: "",
            salesValue: 4215024.05,
            orders: 1514,
            aov: 3070,
            items:1514,
            aiv: 3070,
            actions: ""
        },
        {            
            category: "Component", 
            childCategory: "Bars",
            grandChildCategory: "",
            salesValue: 3371975.47,
            orders: 874,
            aov: 4472,
            items: 874,
            aiv: 4472,
            actions: "",
        },
        {
            category: "Bikes", 
            childCategory: "Kids",
            grandChildCategory: "",
            salesValue: 574385.00,
            orders: 275,
            aov: 2256,
            items: 275,
            aiv: 2256,
            actions: "",
        },
        {
            category: "Bikes", 
            childCategory: "Hybrid",
            grandChildCategory: "",
            salesValue: 6649662,
            orders: 1743,
            aov: 3945,
            items:1743,
            aiv: 3945,
            actions: "",
        },
        {
            category: "Accessories", 
            childCategory: "Bottles & Cages",
            grandChildCategory: "",
            salesValue: 6649662,
            orders: 1743,
            aov: 3945,
            items:1743,
            aiv: 3945,
            actions: "",
        },
        {
            category: "Component", 
            childCategory: "Bars",
            grandChildCategory: "",
            salesValue: 6649662.95,
            orders: 1743,
            aov: 3945,
            items:1743,
            aiv: 3945,
            actions: "",
        },
        {
            category: "Accessories", 
            childCategory: "Bags",
            grandChildCategory: "",
            salesValue: 4215024.05,
            orders: 1514,
            aov: 3070,
            items:1514,
            aiv: 3070,
            actions: ""
        },
        {            
            category: "Component", 
            childCategory: "Bars",
            grandChildCategory: "",
            salesValue: 3371975.47,
            orders: 874,
            aov: 4472,
            items: 874,
            aiv: 4472,
            actions: "",
        },
        {
            category: "Bikes", 
            childCategory: "Kids",
            grandChildCategory: "",
            salesValue: 574385.00,
            orders: 275,
            aov: 2256,
            items: 275,
            aiv: 2256,
            actions: "",
        },
        {
            category: "Bikes", 
            childCategory: "Hybrid",
            grandChildCategory: "",
            salesValue: 6649662,
            orders: 1743,
            aov: 3945,
            items:1743,
            aiv: 3945,
            actions: "",
        },
        {
            category: "Accessories", 
            childCategory: "Bottles & Cages",
            grandChildCategory: "",
            salesValue: 6649662,
            orders: 1743,
            aov: 3945,
            items:1743,
            aiv: 3945,
            actions: "",
        },
    ]

    const tabs = [
        {
            displayName: "Overview",
            tabId: "overview"
        },
        {
            displayName: "Data Table",
            tabId: "data-table",
        },
        {
            displayName: "Reports",
            tabId: "reports",
        }
    ]

    const [activeTab, setActiveTab] = useState('overview')
    const changeActiveTab = (tabId) => {
        // if (tab.tabId === 'all') {
        //     setAllOrders(orders)
        // } else {
        //     const filtered = orders.filter((order) => {
        //         return order.status === tab.tabLabel;
        //     })
        //     setAllOrders(filtered)
        // }

        setActiveTab(tabId)
    }

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const convertCamelCase = (camelCaseText) => {
        const text = camelCaseText;
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult
    }

    const tableOptions = {
        selectable: false
    }

    const columnWidths = {
        category: 'w-full lg:w-3/12',
        salesValue: 'w-full lg:w-3/12',
        orders: 'w-full lg:w-2/12',
        aov: 'w-full lg:w-1/12',
        items: 'w-full lg:w-1/12',
        aiv: 'w-full lg:w-1/12',
        actions: 'w-full lg:w-1/12',
    }

    const columnDataStyles = {

    }

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key

            if(key === 'id') {
                columnDisplayName = '#ID'
                columnDataType = 'link'
            }

            if(key === 'image') {
                columnDataType = 'image'
                sortable = false
            }
            
            if(key === 'action') {
                sortable = false
            }

            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });
        return {headers, fields}
    }

    const ActionsButton = ({menuItems}) => {
        return (
            <img alt="" src={HorizontalMenuIcon} className="w-4 h-4" />
            // <DropdownMenu 
            //         buttonIcon={HorizontalMenuIcon} 
            //         buttonType='icon' 
            //         buttonText="" 
            //         menuItems={menuItems} 
            //         iconClasses="w-3" 
            //         buttonClasses="text-xs rounded-tr-lg font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200" />
        )
    }
    
    const CategoryColumn = ({category, childCategory, grandChildCategory}) => {
        return (
            <div className="flex flex-row gap-x-2 items-center">
               <span className="text-gray-400 text-xs font-sofia-pro">
                    {category}
               </span>
               {childCategory !== "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>}
               <span className="text-gray-400 text-xs font-sofia-pro">
                    {childCategory}
               </span>
               {grandChildCategory !== "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>}
               <span className="text-gray-400 text-xs font-sofia-pro">
                    {grandChildCategory}
               </span>
            </div>
        )
    }

    const cleanUpProducts = (products) => {
        let finalResult = [] 
        categories.forEach((category, categoryIndex) => {
            const newCategoryObject = {
                category: <CategoryColumn category={category.category} childCategory={category.childCategory} grandChildCategory={category.grandChildCategory} />,
                salesValue: `£${category.salesValue.toLocaleString()}`,
                orders: `${category.orders.toLocaleString()}`,
                aov: `£${category.aov.toLocaleString()}`,
                items:`${category.items.toLocaleString()}`,
                aiv: `£${category.aiv.toLocaleString()}`,
                actions: <ActionsButton menuItems={[]} />,
            }
            finalResult.push(newCategoryObject)
        })
        console.log(finalResult)
        return finalResult
    }

    const orderSources = [
        {
            label: "Organic",
            color: "#5B5194",
            amount: 152153,
            percentage: 100
        }
    ]

    const productSplit = [
        {
            label: "Clothing",
            color: "#05194C",
            amount: 152153,
            percentage: 30
        },
        {
            label: "Bikes",
            color: "#25C24C",
            amount: 17952,
            percentage: 20
        },
        {
            label: "Accessories",
            color: "#FAD732",
            amount: 152153,
            percentage: 10
        },
        {
            label: "Helmets",
            color: "#DB9628",
            amount: 17952,
            percentage: 8
        },
        {
            label: "POP",
            color: "#F05050",
            amount: 99361,
            percentage: 8
        },
        {
            label: "Component",
            color: "#C74184",
            amount: 17952,
            percentage: 8
        },
        {
            label: "No Cat",
            color: "#1B92B7",
            amount: 22131,
            percentage: 8
        },
        {
            label: "No Cat",
            color: "#5B5194",
            amount: 7892,
            percentage: 8
        },
    ]

    const cleanUpChartData = (data) => {
        const labels = []
        const series = []
        const colours = []

        data.forEach((item) => {
            labels.push(item.label)
            series.push(item.amount)
            colours.push(item.color)
        });

        return {
            labels, series, colours
        }
    }

        return (
        <Fragment>
            <div className="w-full">
                
                {/* Tabs */}
                <div className="w-full flex flex-row items-center justify-between mt-5">
                    <div className="flex flex-row gap-x-1 border-b border-gray-300 w-full">
                        {/* {tabs.map((tab, tabIndex) =>( */}
                        {tabs.map((tab, tabIndex) =>(
                            <button 
                                key={tabIndex} 
                                className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                                onClick={()=>{changeActiveTab(tab.tabId)}}>
                                {tab.displayName} 
                            </button>
                        ))}
                    </div>
                </div>

                <div className="w-full block md:flex flex-row justify-between pt-12">
                    <div className="flex flex-row gap-x-4 items-center w-full md:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full md:w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search" />
                        </div>
                        <button className="rounded border-gray-200 py-4 md:py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4" /> <span className='ml-2 hidden md:inline'>Filters</span>
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-4 md:mt-0">
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 transform -rotate-90" /> Export
                        </button>
                    </div>
                </div>

                {/* Tab area */}
                {(activeTab === 'overview' || activeTab === 'data-table') && 
                <div className="w-full">
                    <DataTable 
                        tableHeaders={tableHeadersFields(cleanUpProducts(categories)[0]).headers} 
                        tableData={cleanUpProducts(categories)} 
                        columnWidths={columnWidths}
                        columnDataStyles={columnDataStyles}
                        allFields={tableHeadersFields(cleanUpProducts(categories)[0]).fields}
                        onSelectItems={()=>{}}
                        tableOptions={tableOptions}
                    />                   
                </div>
                }

                {(activeTab === 'overview' || activeTab === 'reports') &&
                    <>
                        <div className='w-full block md:flex flex-row gap-x-6 justify-between mt-12'>
                        <div className='w-full border border-gray-100'>
                            <div className='w-full p-2 bg-gray-300'>
                                <h3 className='text-ink-navy text-sm uppercase'>PRODUCT SPLIT</h3>
                            </div>
                            <div className='w-full p-5'>
                                <p className='text-xs text-gray-400 mb-6'>Items sold split by product type</p>
                                <div className='w-full block lg:flex flex-row gap-x-4 items-center'>
                                    <div className='w-full lg:w-9/12'>
                                        <Donut
                                            showLegend={false} 
                                            showDataLabels={true} 
                                            size="65%" 
                                            series={cleanUpChartData(productSplit).series} 
                                            colours={cleanUpChartData(productSplit).colours} 
                                            labels={cleanUpChartData(productSplit).labels}
                                        />
                                    </div>
                                    <div className='w-full lg:w-3/12'>
                                        {productSplit.map((split, splitIndex)=>(
                                            <div className='flex w-full flex-row gap-x-3' key={splitIndex}>
                                                <div style={{backgroundColor: split.color, borderRadius: '3px', width: '10px', height: '10px'}} />
                                                <div>
                                                    <p className='text-xs text-ink-navy'>{split.label} - {split.percentage}%</p>
                                                    <p className='text-xs text-gray-300'>{split.amount}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full border border-gray-100'>
                            <div className='w-full p-2 bg-gray-300'>
                                <h3 className='text-ink-navy text-sm uppercase'>ORDER Source</h3>
                            </div>
                            <div className='w-full p-5'>
                                <p className='text-xs text-gray-400 mb-6'>How customers made a sale</p>
                                <div className='w-full block lg:flex flex-row gap-x-4 items-center'>
                                    <div className='w-full lg:w-9/12'>
                                        <Donut
                                            showLegend={false} 
                                            showDataLabels={true} 
                                            size="65%" 
                                            series={cleanUpChartData(orderSources).series} 
                                            colours={cleanUpChartData(orderSources).colours} 
                                            labels={cleanUpChartData(orderSources).labels}
                                        />
                                    </div>
                                    <div className='w-full lg:w-3/12'>
                                        {orderSources.map((source, sourceIndex)=>(
                                            <div className='flex w-full flex-row gap-x-3' key={sourceIndex}>
                                                <div style={{backgroundColor: source.color, borderRadius: '3px', width: '10px', height: '10px'}} />
                                                <div>
                                                    <p className='text-xs text-ink-navy'>{source.label} - {source.percentage}%</p>
                                                    <p className='text-xs text-gray-300'>{source.amount}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full border border-gray-100 mt-12'>
                        <div className='w-full p-2 bg-gray-300'>
                            <h3 className='text-ink-navy text-sm uppercase'>Delivery methods</h3>
                        </div>
                        <div className='w-full p-5'>
                            <p className='text-xs text-gray-400 mb-6'>Order Delivery Methods</p>
                            <DeliveryMethodsBar />
                        </div>
                    </div>
                </>
                }
            </div>
        </Fragment>
    )
}

export default SalesOverview
