import React, { Fragment, useState, useEffect } from 'react'
// import Layout from '../../../components/layout/Layout'
import SearchIcon from '../../../assets/images/icons/search.svg'
import AlertIcon from '../../../assets/images/icons/alert.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import HorizontalMenuIcon  from '../../../assets/images/icons/horizontal-menu-icon.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import { Link } from 'react-router-dom'
import { convertCamelCase, yesteryears } from '../../../utils'
// import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import DataTable from '../../../components/elements/DataTable'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../utils';
import Spinner from '../../../components/elements/icons/Spinner';
import UserIcon from '../../../assets/images/icons/user-icon.svg'

const CustomerOrders = ({customerId}) => {

    const [allOrders, setAllOrders] = useState([])

    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])

    const [ pagination, setPagination ] = useState({ 
        page: 1, 
        perPage: 25, 
        totalRecords: 0,
        nextPage: '',
        lastPage: '',
        previousPage: '',
        firstPage: ''
    })

    // const [ filter, setFilter ] = useState(null)

    useEffect(() => {
        const fetchOrders = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try { 
                let response = null 

                response = await axios.get(`/api/v1/admin/orders/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc&filter[customer_id]=${customerId}`, { headers })            

                // console.log(response)
                let apiOrders = response.data.data
                
                // apiOrders.forEach(async (order, orderIndex)=> {
                //     const customerDetails = await fetchCustomerDetails(order.customer_id)
                //     order.customerDetails = customerDetails
                // })
                // console.log(apiOrders)
                setAllOrders(apiOrders)

                setPagination({
                    page: pagination.page,
                    perPage: pagination.perPage,
                    totalRecords: response.data.total_records,
                    nextPage: response.data.next,
                    lastPage: response.data.last
                })

                setProcessing(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }
        fetchOrders()
    }, [cookies.accessToken, cookies.tokenType, customerId, pagination.page, pagination.perPage])


    const tableOptions = {
        selectable: true
    }

    const columnWidths = {
        id: 'w-full lg:w-1/12',
        // customer: 'w-full lg:w-4/12',
        value: 'w-full lg:w-2/12',
        payment: 'w-full lg:w-2/12',
        date: 'w-full lg:w-2/12',
        shipping: 'w-full lg:w-2/12',
        // device: 'w-full lg:w-1/12',
        status: 'w-full lg:w-2/12',
        actions: 'w-full lg:w-1/12',
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key
            if(key === 'actions') {
                sortable=false
            }
            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true

            if(index > 10) {
                fieldSelected = false
            }
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });
        return {headers, fields}
    }

    const OrderLink = ({orderId}) => {
        return (
            <Link to={`order/${orderId}`} className='font-medium text-ink-navy'>{orderId}</Link>
        )
    }

    const OrderCustomer = ({customerName, customerImage}) => {
        return (
            <div>
                <img src={customerImage} alt="" className="rounded-full w-5 h-5 inline-block mr-2" />
                {customerName}
            </div>
        )
    }

    const OrderValue = ({value}) => {
        return (
            <span>&pound;{value.toLocaleString()}</span>
        )
    }

    const OrderStatus = ({status}) => {
        return (
            <>
                {status === 'Awaiting Payment' && <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2 inline-block" />}
                {status === 'Dispatched' && <span className="w-2 h-2 bg-gray-400 rounded-full mr-2 inline-block" />}
                {status === 'Paid and Ready to Process' && <span className="w-2 h-2 bg-gray-200 rounded-full mr-2 inline-block" />}
                {status === 'Payment pending' && <span className="w-2 h-2 bg-gray-700 rounded-full mr-2 inline-block" />}
                {status === 'Processing' && <span className="w-2 h-2 bg-yellow-700 rounded-full mr-2 inline-block" />}
                {status === 'Deleted' && <span className="w-2 h-2 bg-red-500 rounded-full mr-2 inline-block" />}
                {status === 'Cancelled' && <span className="w-2 h-2 bg-red-700 rounded-full mr-2 inline-block" />}
                {status === 'Complete' && <span className="w-2 h-2 bg-green-700 rounded-full mr-2 inline-block" />}
                {status === 'Returned' && <span className="w-2 h-2 bg-purple-700 rounded-full mr-2 inline-block" />}
                {status === 'Ready for Collection' && <span className="w-2 h-2 bg-green-700 rounded-full mr-2 inline-block" />}
                {status}
            </>
        )
    }

    const ActionsButton = () => {
        return (
            <button className="">
                <img src={HorizontalMenuIcon} alt="" className="w-4" />
            </button> 
        )
    }

    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((item, itemIndex) => {

            const itemObject = {
                id: <OrderLink orderId={item.id} />,
                // customer: item.customerDetails ? <OrderCustomer customerName={item.customerDetails.user.first_name + ' ' + item.customerDetails.user.first_name} customerImage={UserIcon} /> : " ",
                value: <OrderValue value={+item.total} />,
                payment: " ",
                // payment: item.payment,
                date: new Date(item.created_at).toDateString(),
                shipping: <OrderValue value={+item.shipping_charge} />,
                status: <OrderStatus status={item.status} />,
                actions: <ActionsButton />
            }
            
            finalResult.push(itemObject)
        })
        return finalResult
    }

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

     const filters = [
        {
            optionName: "Brands",
            options: [
                {name: "RFR", selected: false},
                {name: "British League", selected: false},
                {name: "White", selected: false},
                {name: "Cube", selected: false},
            ]
        }, 
        {
            optionName: "Years",
            options: yesteryears(4),
        },
        {
            optionName: "Stock",
            options: [
                {name: "Available", selected: false},
                {name: "Out of Stock", selected: false},
            ],
        },
        {
            optionName: "Status",
            options: [
                {name: "Live", selected: false},
                {name: "Inactive", selected: false},
            ],
        },
        {
            optionName: "Filter options",
            options: [
                {name: "Option 1", selected: false},
                {name: "Option 2", selected: false},
                {name: "Option 3", selected: false},
            ]
        }
    ]

    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0)

    const [allFilters, setAllFilters] = useState(filters)
    const toggleFilterSelection = (optionIndex, filterIndex) => {
        let newFilters = JSON.parse(JSON.stringify(allFilters))
        newFilters[optionIndex].options[filterIndex].selected = !newFilters[optionIndex].options[filterIndex].selected
        setAllFilters(newFilters)
        setSelectedFiltersCount(countSelectedFilters(newFilters))
    }

    const countSelectedFilters = (filters) => {
        let count = 0
        filters.forEach((option) => {
            option.options.forEach((filter) => {
                if(filter.selected) {
                    count++
                }
            })
        })
        return count
    }

    const clearFilters = () => {
        let newFilters = JSON.parse(JSON.stringify(allFilters))
        newFilters.forEach((option) => {
            option.options.forEach((filter) => {
                filter.selected = false
            })
        })
        setAllFilters(newFilters)
        setSelectedFiltersCount(countSelectedFilters(newFilters))
    }

    // const [activeTab, setActiveTab] = useState(tabs[0].tabId)
    // let changeActiveTab = (tab) => {
    //     console.log(tab)
    //     if (tab.tabId === 'all') {
    //         setFilter(null)
    //     } else {
    //         setFilter({
    //             key: 'status',
    //             value: tab.tabLabel
    //         })
    //     }
    //     setActiveTab(tab.tabId)
    // }

    const [selectedOrdersCount, setSelectedOrdersCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedOrdersCount(count)
    }

    return (
        <Fragment>
            {/* Tabs */}
            {/* <div className="hidden w-full lg:flex flex-row gap-x-2 border-b border-gray-300">
                {tabs.slice(0, 6).map((tab, tabIndex) =>(
                    <button 
                        key={tabIndex} 
                        className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                        onClick={()=>{changeActiveTab(tab)}}>
                        {tab.tabLabel} 
                            {!processing && activeTab === tab.tabId && <span className="rounded-full px-2 py-1 bg-ink-navy text-white ml-2">
                                {pagination.totalRecords}
                            </span>}
                    </button>
                ))}
                <DropdownMenu 
                    buttonIcon={HorizontalMenuIcon} 
                    buttonType='icon' 
                    buttonText="" 
                    menuItems={getTabsDropdownItems(6)} 
                    itemAction={changeActiveTab}
                    iconClasses="w-5" 
                    buttonClasses="text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200 bg-gray-100" />
            </div> */}

            {/* <div className="w-full lg:hidden flex-row items-center justify-between mt-5">
                <label className="text-gray-400 text-xs mb-2 block mt-2">Select Category</label>
                <div className="">
                    <Listbox value={selected} onChange={setSelected}>
                        <div className="relative mt-1">
                        <Listbox.Button className={`relative w-full text-left text-xs rounded border border-gray-300 text-ink-navy font-medium font-sofia-pro inline-block px-3 py-2 transition duration-200`}>
                            <span className="block truncate text-sm lg:text-xs">{selected}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                                className="w-4 h-4 text-gray-400"
                                aria-hidden="true"
                            />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {tabs.map((option, optionIndex) => (
                                <Listbox.Option
                                    key={optionIndex}
                                    className={({ active }) =>
                                        `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                            cursor-default select-none relative py-2 pl-10 pr-4`
                                    }
                                    value={option.tabLabel}
                                    
                                    >
                                    {({ selected, active }) => (
                                        <>
                                        <span
                                            className={`${
                                            selected ? 'font-medium' : 'font-normal'
                                            } block truncate text-sm`}
                                            onClick={()=>{changeActiveTab(option)}}
                                        >
                                            {option.tabLabel}
                                        </span>
                                        {selected ? (
                                            <span
                                            className={`${
                                                active ? 'text-amber-600' : 'text-amber-600'
                                            }
                                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                            >
                                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                            </Listbox.Options>
                        </Transition>
                        </div>
                    </Listbox>
                </div>
            </div> */}

            {/* Tab area */}
            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between">
                    <div className="flex flex-row justify-between items-center w-full mb-4 lg:mb-0 mt-3 mb:mt-0">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full lg:w-10/12 mr-1">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search orders" />
                        </div>
                        <div className="relative">
                            <div className="flex flex-row gap-x-2">
                                <button 
                                    className={`rounded py-3 px-4 text-xs flex flex-row items-center border ${filtersActive ? 'text-gray-800 border-gray-800' : 'text-gray-400 border-gray-200'}`} 
                                    onClick={()=>{toggleFilters()}} 
                                >
                                    <img src={FilterIcon} alt="search" className="w-4 mr-2" /> <span className='hidden md:inline'>Filters</span> {selectedFiltersCount > 0 && <span className={`text-xs text-white rounded px-1 ml-1 mr-3 ${filtersActive ? 'bg-ink-navy' : 'bg-gray-400'}`}>{selectedFiltersCount}</span>}
                                </button>
                                <button className="w-full lg:w-max">
                                    <img src={AlertIcon} alt="search" className="w-5" />
                                </button>
                            </div>
                            {/* Filters popover */}
                            {filtersActive && 
                                <div className="absolute shadow-lg bg-white top-16 border border-gray-100 z-10" style={{width: '800px', left: '-200px',}}>
                                    <div className="w-full flex flex-row items-center justify-between border-b p-4">
                                        <p className="font-medium text-ink-navy text-xs">Filters</p>
                                        <button className="font-sofia-pro underline text-blue-700 text-xs" onClick={()=>{clearFilters()}}>Clear filters</button>
                                    </div>
                                    <div className="w-full flex flex-row justify-between border-b p-8">
                                        {allFilters.map((filterOption, optionIndex) => (
                                            <div key={optionIndex}>
                                                <p className="font-medium text-xs mb-3 text-ink-navy">{filterOption.optionName}</p>
                                                {filterOption.optionName === 'Brands' &&
                                                    <input type="text" placeholder="Search" className="py-2 px-3 w-full border rounded border-gray-300 text-xs" />
                                                }
                                                {filterOption.options.map((filter, filterIndex) => (
                                                    <div className="w-2/10 mt-1" key={filterIndex}>
                                                        <input type="checkbox" onChange={()=>toggleFilterSelection(optionIndex, filterIndex)} checked={filter.selected} className="mr-2 mt-3" />
                                                        <p className="text-xs inline-block mt-3">{filter.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            
                        </div>
                    </div>
                    {/* </div> */}
                    <div className="flex flex-row-reverse gap-x-2 w-full items-center">
                         <button className="rounded border-gray-800 py-3 px-4 text-xs mt-3 text-gray-800 flex flex-row items-center border w-full lg:w-max">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden xl:inline" /> Export
                        </button>
                        {selectedOrdersCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                    </div>
                </div>

                <div>
                    {!processing && error === '' ?
                        <DataTable
                            tableHeaders={tableHeadersFields(cleanUpData(allOrders)[0]).headers} 
                            tableData={cleanUpData(allOrders)} 
                            columnWidths={columnWidths}
                            columnDataStyles={columnDataStyles}
                            allFields={tableHeadersFields(cleanUpData(allOrders)[0]).fields}
                            onSelectItems={getSelectionCount}
                            tableOptions={tableOptions}
                            pagination={pagination}
                            paginate={setPagination}
                        />
                      :
                        <div className='w-full flex flex-col items-center justify-center mt-10'>
                            <Spinner />
                            <p className='text-sm text-gray-500 mt-5'>Fetching orders...</p>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}
export default CustomerOrders
