import React, {Fragment, useState} from 'react'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../../assets/images/icons/bulk-items.svg'
import HorizontalMenuIcon  from '../../../../assets/images/icons/horizontal-menu-icon.svg'
import DropdownMenu from '../../../../components/elements/DropdownMenu'
import DataTable from '../../../../components/elements/DataTable'
import { Link } from 'react-router-dom'
import { convertCamelCase } from "../.././../../utils";
import EditIcon  from '../../../../assets/images/icons/edit.svg'

const Postage = () => {
    const rules = [
        {
            id: "1",
            label: "UK Next Day",
            location: "Europe, GB",
            basketValue: "£0.00 to £10,000,000,000.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£10.00",
            actions: ""
        },
        {
            id: "2",
            label: "Express 3-5 days",
            location: "Americas",
            basketValue: "£0.00 to £0.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£45.00",
            actions: ""
        },
        {
            id: "3",
            label: "Standard 4-10 days",
            location: "Asia",
            basketValue: "£0.00 to £0.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£35.00",
            actions: ""
        },
        {
            id: "4",
            label: "Free 4-7 days",
            location: "Asia",
            basketValue: "600.00 to £5,000.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£0.00",
            actions: ""
        },
        {
            id: "5",
            label: "Express 4-7 days",
            location: "Asia",
            basketValue: "600.00 to £5,000.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£35.00",
            actions: ""
        },
        {
            id: "1",
            label: "UK Next Day",
            location: "Europe, GB",
            basketValue: "£0.00 to £10,000,000,000.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£10.00",
            actions: ""
        },
        {
            id: "2",
            label: "Express 3-5 days",
            location: "Americas",
            basketValue: "£0.00 to £0.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£45.00",
            actions: ""
        },
        {
            id: "3",
            label: "Standard 4-10 days",
            location: "Asia",
            basketValue: "£0.00 to £0.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£35.00",
            actions: ""
        },
        {
            id: "4",
            label: "Free 4-7 days",
            location: "Asia",
            basketValue: "600.00 to £5,000.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£0.00",
            actions: ""
        },
        {
            id: "5",
            label: "Express 4-7 days",
            location: "Asia",
            basketValue: "600.00 to £5,000.00",
            majorMinior: "",
            productGroup: "2021-04-08",
            price: "£35.00",
            actions: ""
        }
    ]
    
    const couriers = [
        {
            name: "Royal Mall",
            trackingUrl: "https://www.royalmall.com/track-your-item/tracking-results/{{tracking_id}}",
            actions: ""
        },
        {
            name: "Parcel Force",
            trackingUrl: "https://www.parcelforce.com/track-trace?tracking_number={{tracking_id}}",
            actions: ""
        },
        {
            name: "DPD",
            trackingUrl: "https://www.dpd.co.uk/tracking/trackingSearch.do?search.searchType=0$search,parcelNumber={{tracking_id}}",
            actions: ""
        },
        {
            name: "DHL",
            trackingUrl: "https://www.dhl.co.uk/content/gb/en/express/tracking/tracking-results/{{tracking_id}}",
            actions: ""
        },
        {
            name: "DX",
            trackingUrl: "https://www.royalmall.com/track-your-item/tracking-results/{{tracking_id}}",
            actions: ""
        },
        {
            name: "UkMall",
            trackingUrl: "https://www.royalmall.com/track-your-item/tracking-results/{{tracking_id}}",
            actions: ""
        },
        {
            name: "FedEx",
            trackingUrl: "https://www.royalmall.com/track-your-item/tracking-results/{{tracking_id}}",
            actions: ""
        },
        {
            name: "UPS",
            trackingUrl: "https://www.royalmall.com/track-your-item/tracking-results/{{tracking_id}}",
            actions: ""
        },
        {
            name: "Hermes",
            trackingUrl: "https://www.royalmall.com/track-your-item/tracking-results/{{tracking_id}}",
            actions: ""
        },
        {
            name: "APC",
            trackingUrl: "https://www.royalmall.com/track-your-item/tracking-results/{{tracking_id}}",
            actions: ""
        },
    ]

    const tabs = [
        {
            tabId: "rules",
            tabLabel: "Rules",
            count: rules.length
        },
        {
            tabId: "couriers",
            tabLabel: "Couriers (for tracking)",
            count: couriers.length
        }
    ]

    const [activeTab, setActiveTab] = useState(tabs[0].tabId)
    const [showTable, setShowTable] = useState(true)

    let changeActiveTab = (tab) => {
        setShowTable(false)
        setActiveTab(tab.tabId)
        setTimeout(() => {
            setShowTable(true)
        }, 200);
    }


    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const tableOptions = {
        selectable: true
    }
    
    const couriersTableOptions = {
        selectable: true,
        sortable: true
    }

    const rulesColumnWidths = {
        label: 'w-full lg:w-2/12',
        location: 'w-full lg:w-1/12',
        basketValue: 'w-full lg:w-3/12',
        majorMinior: 'w-full lg:w-2/12',
        productGroup: 'w-full lg:w-2/12',
        price: 'w-full lg:w-1/12',
        actions: 'w-full lg:w-1/12'
    }

    const courierColumnWidths = {
        name: 'w-full lg:w-2/12',
        trackingUrl: 'w-full lg:w-8/12',
        actions: 'w-full lg:w-2/12'
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]


    const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedReviewsCount(count)
    }

    const CourierActions = ({courierIndex}) => {
        return (
            <div className='w-full flex justify-between flex-row'>
                <Link to={`postage/couriers/${courierIndex}`} className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
                
                <button className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Delete <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></button>
            </div>
        )
    }

    const CourierLink = ({courierIndex, name}) => {
        return (
            <Link to={`postage/couriers/${courierIndex}`} className='text-ink-navy font-bold text-xs whitespace-normal'>{name}</Link>
        )
    }
    
    const RuleLink = ({ruleIndex, name}) => {
        return (
            <Link to={`postage/${ruleIndex}`} className='text-ink-navy font-bold text-xs'>{name}</Link>
        )
    }

    const ActionsButton = ({menuItems}) => {
        return (
            <img alt="" src={HorizontalMenuIcon} className="w-4 h-4" />
            // <DropdownMenu 
            //         buttonIcon={HorizontalMenuIcon} 
            //         buttonType='icon' 
            //         buttonText="" 
            //         menuItems={menuItems} 
            //         iconClasses="w-3" 
            //         buttonClasses="text-xs rounded-tr-lg font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200" />
        )
    }

    const cleanUpData = (dataFor, dataArray) => {
        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            let requestObject = {}
            if(dataFor === 'rules') {
                requestObject = {
                    // id: object.id,
                    label: <RuleLink ruleIndex={objectIndex} name={object.label} />,
                    location: object.location,
                    basketValue: object.basketValue,
                    majorMinior: object.majorMinior,
                    productGroup: object.productGroup,
                    price: object.price,
                    actions: <ActionsButton />
                }
            }

            if(dataFor === 'couriers') {
                requestObject = {
                    name: <CourierLink courierIndex={objectIndex} name={object.name} />,
                    trackingUrl: object.trackingUrl,
                    actions: <CourierActions courierIndex={objectIndex} />
                }
            }
            finalResult.push(requestObject)
        })
        return finalResult
    }
    

    return (
        <Fragment>
            {/* Tabs */}
            <div className="flex flex-row gap-x-2 border-b border-gray-300">
                {tabs.slice(0, 6).map((tab, tabIndex) =>(
                    <button 
                        key={tabIndex} 
                        className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                        onClick={()=>{changeActiveTab(tab)}}>
                        {tab.tabLabel} 
                            <span className="rounded-full px-2 py-1 bg-ink-navy text-white ml-2">
                                {tab.tabId === 'rules' ? rules.length : couriers.length}
                            </span>
                    </button>
                ))}
            </div>
            {/* Tab area */}
            <div className="w-full">
                {activeTab==='rules' && <div className="w-full block lg:flex flex-row justify-between pt-12">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search rules" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-3 lg:mt-0 flex-wrap">
                        {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Help
                        </button>
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden lg:inline" /> Export
                        </button>
                        <Link to="postage/new-postage-rule" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            New postage rule
                        </Link>
                    </div>
                </div>}

                {activeTab==='couriers' && <div className="w-full block lg:flex flex-row justify-between pt-12">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search couriers" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row flex-wrap gap-x-2 mt-2 lg:mt-0">
                        {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Help
                        </button>
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Export
                        </button>
                        <Link to="postage/new-courier" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            New courier rule
                        </Link>
                    </div>
                </div>}

                {/* Filters */}
                {/* <div>
                </div> */}

                {/* Table */}

                {showTable && activeTab==='rules' && <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData('rules', rules)[0]).headers} 
                    tableData={cleanUpData('rules', rules)} 
                    columnWidths={rulesColumnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpData('rules', rules)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />}

                {showTable && activeTab==='couriers' && <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData('couriers', couriers)[0]).headers} 
                    tableData={cleanUpData('couriers', couriers )} 
                    columnWidths={courierColumnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpData('couriers', couriers)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={couriersTableOptions}
                />}
                
            </div>
        </Fragment>
    )
}

export default Postage
