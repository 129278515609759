import React from 'react'
import Layout from '../../components/layout/Layout'
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    // Link,
    // useParams,
    useRouteMatch,
    Redirect,
} from "react-router-dom";
import BestPromotions from './reports/BestPromotions';
import SalesOverview from './reports/SalesOverview';
import ProductSales from './reports/ProductSales';
import AllPopularProducts from './reports/AllPopularProducts';
import ProductDataQuality from './reports/ProductDataQuality';

const Reports = () => {
    let { 
        path, 
        // url 
    } = useRouteMatch();
    
    const sidebarLinks = [
        {
            label: "Product Data Quality",
            location: "/admin/reports/product-data-quality",
            subLinks: []
        },
        {
            label: "Popular Products",
            location: "/admin/reports/popular-products",
            subLinks: []
        },
        {
            label: "Best Promotions",
            location: "/admin/reports/best-promotions",
            subLinks: []
        },
        {
            label: "Sales Overview",
            location: "/admin/reports/sales-overview",
            subLinks: []
        },
        {
            label: "Product Sales",
            location: "/admin/reports/product-sales",
            subLinks: []
        },
    ]

    return (
        <Layout pageTitle="Reports" sidebarLinks={sidebarLinks}>
            <Switch>
                <Route exact path={path} render={() => {
                    return (
                        <Redirect to="/admin/reports/product-data-quality" />
                    )
                }} />
                <Route path={`${path}/product-data-quality`} exact>
                    <ProductDataQuality />
                </Route>
                <Route path={`${path}/popular-products`} exact>
                    <AllPopularProducts />
                </Route>
                <Route path={`${path}/best-promotions`} exact>
                    <BestPromotions />
                </Route>
                <Route path={`${path}/sales-overview`} exact>
                    <SalesOverview />
                </Route>
                <Route path={`${path}/product-sales`} exact>
                    <ProductSales />
                </Route>
            </Switch>
        </Layout>
    )
}
    

export default Reports
