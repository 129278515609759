import React, {Fragment, useState} from 'react'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
import { Link } from 'react-router-dom'
import SliderTile from '../../../../components/partials/SliderTile'

const MainSlider = () => {
    const sliders = [
        {
            name: "Mountain Slider",
            images: [
                "https://images.pexels.com/photos/90454/pexels-photo-90454.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
                "https://images.pexels.com/photos/38296/cycling-bicycle-riding-sport-38296.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
                "https://images.pexels.com/photos/5807586/pexels-photo-5807586.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            ]
        },
        {
            name: "Emonda",
            images: [
                "https://images.pexels.com/photos/38296/cycling-bicycle-riding-sport-38296.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
                "https://images.pexels.com/photos/90454/pexels-photo-90454.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
                "https://images.pexels.com/photos/5807586/pexels-photo-5807586.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            ]
        },
        {
            name: "Kaneva",
            images: [
                "https://images.pexels.com/photos/5807586/pexels-photo-5807586.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
                "https://images.pexels.com/photos/90454/pexels-photo-90454.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
                "https://images.pexels.com/photos/38296/cycling-bicycle-riding-sport-38296.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            ]
        },
    ]

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }


    return (
        <Fragment>
            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search sliders" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap">
                        {/* {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2 hidden lg:inline" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        } */}
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <span className='p-px rounded-full border border-ink-navy mr-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                           </span>
                            Reset default
                        </button>
                        {/* <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={UpRightArrow} alt="search" className="w-3 mr-2 hidden lg:inline" /> Downloads page
                        </button> */}
                        <Link to="./new-slider" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            New slider
                        </Link>
                    </div>
                </div>

                <div className='w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-10'>
                    {sliders.map((slider, sliderIndex)=>(
                        <SliderTile key={sliderIndex} slider={slider} />
                    ))}
                    <div className='w-full transition duration-200 hover:shadow-md cursor-pointer'>
                        <div className='w-full h-40 border border-gray-300 flex flex-row items-center justify-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                        </div>
                        <div className='border-l border-r border-b border-gray-300 rounded-b-md p-3 text-center'>
                            <p className='text-xs text-ink-navy'>New Slide</p>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MainSlider
