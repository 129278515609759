import React, { useState } from 'react'
import Wysiwyg from '../../elements/Wysiwyg'

const ProductDescription = ({productDescription}) => {
    const [description, setDescription] = useState(productDescription)

    const updateDescription = (value) => {
        setDescription(value)
    }


    return (
        <div className='py-4 w-full lg:w-10/12'>
            <Wysiwyg fieldTitle="Product Description" initialValue={description} updateValue={updateDescription}  />
        </div>
    )
}

export default ProductDescription
