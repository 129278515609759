import React, { Fragment, useState, useEffect } from 'react'
import WhiteLogo from '../assets/images/push-light.svg'
// import Logo from '../assets/images/logo.png'
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import ErrorMessage from '../components/elements/ErrorMessage'
import Spinner from '../components/elements/icons/Spinner';
import axios from "axios"
// import { baseUrl } from '../utils';
import { useCookies } from 'react-cookie';
import queryString from 'query-string'

const LoginPage = () => {
    const history = useHistory();
    let location = useLocation();
    const queryValues = queryString.parse(location.search)
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookie, removeCookie ] = useCookies(['user']);

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (queryValues.expiredToken && queryValues.expiredToken === 'true') {
            removeCookie('accessToken', [])
            removeCookie('tokenType', [])
            removeCookie('details', [])
            setError('Sorry, your login session expired. Log in again to continue')
        }
    }, [location, queryValues, queryValues.expiredToken, removeCookie]);

    const [ credentials, setCredentials ] = useState({})
    const [ validationErrors, setValidationErrors ] = useState({})
    const [ processing, setProcessing ] = useState(false)
    const [ error, setError ] = useState('')  
    // eslint-disable-next-line no-unused-vars

    const validateForm = () => {
        let errors = {}
        if (!credentials.email || credentials.email === '') {
            errors.email = true
        }
        if (!credentials.password || credentials.password === '') {
            errors.password = true
        }

        setValidationErrors(errors)
        return errors
    }

    const getCSRFToken = async () => {
        await axios.get('/sanctum/csrf-cookie');
    };

    const logInUser = async () => {
        if (Object.values(validateForm()).includes(true)) {
            setError('Please check the highlighted fields above')
            return
        }
        
        await getCSRFToken()
        
        setProcessing(true)

        const requestPayload = {
            email: credentials.email,
            password: credentials.password,
        }

        try {
            const response = await axios.post(`/api/v1/admin/auth/login`, requestPayload)
                        
            // // Set cookies first, then push
            setCookie('accessToken', response.data.data.auth.token)
            setCookie('tokenType', response.data.data.auth.token_type)
            setCookie('details', response.data.data.auth.user)

            if(queryValues.returnUrl && queryValues.returnUrl !== '') {
                history.push(queryValues.returnUrl)
            } else {
                history.push("/admin");
            }

        } catch (error) {
            // setError(error.response.data.developer_message)
            console.log(error.response)
            if (error.response.data.developer_message.exception === 'AlreadyAuthenticatedException') {
                history.push("/admin");
            }
            setError(error.response.data.developer_message.errors)
            setProcessing(false)
        }
    }

    return (
        <Fragment>
            <div className="w-full block lg:flex flex-row items-center">
                <div className="h-32 lg:h-screen w-full bg-ink-navy flex flex-col justify-between items-center py-24">
                    <div className="w-36">
                        <img src={WhiteLogo} alt="push retail logo" />
                    </div>
                    <div className="hidden h-12 lg:flex flex-col justify-center items-center">
                        <div className="flex flex-row justify-between gap-x-2">
                            <span className="h-2 w-2 rounded-full bg-white" />
                            <span className="h-2 w-2 rounded-full bg-white" />
                            <span className="h-2 w-5 rounded-full bg-white" />
                        </div>
                        <p className="text-sm text-white mt-12">www.pushretail.io</p>
                    </div>
                </div>
                <div className="h-screen w-full bg-white flex flex-col justify-center px-6 lg:px-44">
                    <div>
                        <div className="w-full mb-10">
                            <p className="relative text-ink-navy text-xl">Login to Cycle Concept 
                            {/* <span className="relative"><img src={Logo} alt="PushRetail" className="inline ml-1 w-32" /><span className="text-sm absolute -bottom-7 right-0 font-sofia-pro">Cycle Concept</span></span> */}
                            </p>
                        </div>

                        <label className={`text-sm text-ink-navy transition duration-200 ${validationErrors.email && validationErrors.email === true ? 'text-red-500' : ''}`}>Email address</label> 
                        <input 
                            type="email" 
                            placeholder="Your email address" 
                            className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.email && validationErrors.email === true ? 'text-red-500 border-red-500' : ''}`}
                            onChange={(e) => {setCredentials({ ...credentials, ...{email: e.target.value}})}} /> 

                        <label className={`text-sm text-ink-navy transition duration-200 ${validationErrors.email && validationErrors.email === true ? 'text-red-500' : ''}`}>Password</label> 
                        <input 
                            type="password" 
                            placeholder="Your password" 
                            className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.passsword && validationErrors.password === true ? 'text-red-500 border-red-500' : ''}`} 
                            onChange={(e) => {setCredentials({ ...credentials, ...{password: e.target.value}})}} />  
                        
                        <div className="w-full text-right">
                            <Link to="/password-reset-request" className="text-xs text-ink-navy" href="#">Forgot Password?</Link>                     
                        </div>
                        
                        <div className="w-full mt-5 text-center">

                        <button className="flex flex-row gap-x-3 items-center justify-center rounded bg-ink-navy text-sm hover:bg-blue-900 transition duration-200 w-full py-3 text-center text-white focus:border-transparent focus:outline-none" onClick={()=>{
                                if(!processing) { logInUser() }
                                    }}>{processing ? <><Spinner />Logging in to your account...</> : 'Log in'}</button>

                        {/* <p className="mt-3 text-sm">Don't have an account? <Link to="/register" className="text-blue-800 hover:text-blue-900">Register</Link></p> */}

                        {error && error!=='' &&  
                            <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                        } 
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default LoginPage
