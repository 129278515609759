import React from 'react'

const OrdersOverview = () => {
    return (
        <div>
            Orders Overview Page Content
        </div>
    )
}

export default OrdersOverview
