import React, { useState } from 'react'

const ProductsIcon = () => {
    const [color, setColor] = useState("#B8C0D5")

    return (
        <svg onMouseEnter={()=>{setColor("#ffffff")}} onMouseLeave={()=>{setColor("#B8C0D5")}} id="_3pbdht.tif" data-name="3pbdht.tif" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20.611 20.421">
            <g id="Group_5" data-name="Group 5">
                <path id="Path_12" data-name="Path 12" d="M115.437,394.844c.718.392,1.387.754,2.053,1.121q3.317,1.828,6.634,3.657c.154.085.277.149.276.374-.012,2.927-.011,5.855-.015,8.783,0,.042-.008.084-.017.17-.121-.053-.226-.1-.327-.145q-4.166-2.008-8.334-4.012a.434.434,0,0,1-.289-.461c.009-3.067.006-6.135.007-9.2C115.424,395.055,115.43,394.982,115.437,394.844Z" transform="translate(-115.417 -388.528)" fill="#adb5d4" />
                <path id="Path_13" data-name="Path 13" d="M303.777,395.061v.542q0,4.472,0,8.944a.388.388,0,0,1-.235.428q-4.245,2.027-8.481,4.071c-.075.036-.154.061-.259.1-.009-.109-.022-.19-.022-.271q0-4.311,0-8.621a.393.393,0,0,1,.222-.409q4.258-2.328,8.509-4.669C303.587,395.137,303.666,395.11,303.777,395.061Z" transform="translate(-283.17 -388.731)" fill="#adb5d4" />
                <path id="Path_14" data-name="Path 14" d="M140.146,301.418c.106-.053.2-.1.291-.146q4.079-1.966,8.162-3.926a.577.577,0,0,1,.437-.016q4.162,1.976,8.312,3.976a2.313,2.313,0,0,1,.211.134l-3.164,1.734c-1.771.971-3.541,1.946-5.317,2.908a.532.532,0,0,1-.431.028q-4.218-2.3-8.422-4.627A.783.783,0,0,1,140.146,301.418Z" transform="translate(-138.545 -297.294)" fill={color} />
            </g>
        </svg>

    )
}

export default ProductsIcon
