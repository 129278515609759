import React, {Fragment, useEffect, useState} from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
// import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import DataTable from '../../../components/elements/DataTable'
import { useCookies } from 'react-cookie';
import axios from "axios"
import Spinner from '../../../components/elements/icons/Spinner'
import { useHistory, useLocation } from 'react-router-dom';
import { baseUrl, convertCamelCase } from '../../../utils';
import CloseIcon from '../../elements/icons/CloseIcon'
import ProductReview from './ProductReview'
import RatingStars from '../../elements/RatingStars'

const ProductReviews = ({productDetails}) => { 
    const [allReviews, setAllReviews] = useState(null)
    const [fetchingReviews, setFetchingReviews] = useState(true)
    const [ cookies ] = useCookies(['user'])
    const [error, setError] = useState(null)
    const history = useHistory();
    const location = useLocation()

    useEffect(() => {
    const getProductReviews = async () => {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        setFetchingReviews(true)
        try {
            const response = await axios.get(`/api/v1/products/${productDetails.id}/reviews`, { headers })       
            setAllReviews(response.data.data)
            setFetchingReviews(false)
        } catch (error) {
        console.log(error.response)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setFetchingReviews(false)
            }
        }
    }

    getProductReviews()

    return () => {
        
    }
    }, [cookies.accessToken, cookies.tokenType, history, location.pathname, productDetails.id])


    const tableOptions = {
    selectable: true
    }

    const columnWidths = {
        rating: "w-full lg:w-2/12",
        review: "w-full lg:w-6/12",
        date: "w-full lg:w-2/12",
        status: "w-full lg:w-2/12",
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) return
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
    {
        label: "Change Status",
        icon: null,
        link: "/",
        action: null
    },
    {
        label: "delete",
        icon: null,
        link: "/",
        action: null
    },
    ]

    const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

    const getSelectionCount = (count) => {
    return setSelectedReviewsCount(count)
    }

    const ReviewStatus = ({status}) => {
        return (
            <div className="flex flex-row items-center gap-x-2">
                <span className={`w-2 h-2 rounded-full ${status === "Approved" ? "bg-green-300" : "bg-gray-300"}`} /> 
                <span className="capitalize font-sofia-pro text-xs">{status.replace(/-/g, ' ')}</span>
            </div>
        )
    }

    const cleanUpReviews = (reviewsArray) => {
        let finalResult = [] 
        reviewsArray.forEach((review, reviewIndex) => {
            const reviewObject = {
                rating: <RatingStars rating={review.rating} reviewId={review.id} />,
                review:<button className='text-ink-navy font-medium' onClick={()=>{openReview(review.id)}}><p className='truncate ...'>{review.review}</p></button>,
                date: new Date(review.created_at).toDateString(),
                status: <ReviewStatus status={review.status} />,
            }
            finalResult.push(reviewObject)
        })
        return finalResult
    }

    const [reviewOpen, setReviewOpen] = useState(false)
    const [activeReviewId, setActiveReviewId] = useState(null)

    const openReview = (reviewId) => {
        setActiveReviewId(reviewId)
        setTimeout(() => {
            setReviewOpen(true)
        }, 100);
    }

    const closeReview = () => {
        setReviewOpen(false)
        setTimeout(() => {
            setActiveReviewId(null)
        }, 100);
    }

    return (
    <div className="relative">
        {reviewOpen && reviewOpen === true && activeReviewId !== null && <div className='w-full absolute min-h-screen top-0 left-0 bg-white z-40'>
            <button className='absolute top-2 right-2 text-black transition duration-200 hover:text-gray-300' onClick={()=>{closeReview()}}>
                <CloseIcon classes="w-6 h-6" />
            </button>

            <ProductReview productDetails={productDetails} reviewId={activeReviewId} />
        </div>
        }
        <div className="w-full mb-12 z-10">
            <div className="w-full block lg:flex flex-row justify-between pt-6">
                <div className="flex flex-row items-center w-full lg:w-3/5">
                    <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm mt-1">
                                <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                            </span>
                        </div>
                        <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search reviews" />
                    </div>
                </div>
                <div className="flex flex-row gap-x-2 mt-3 lg:mt-0">
                    {selectedReviewsCount > 1 && 
                        <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                    }
                    <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                        <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Export
                    </button>
                </div>
            </div>

            {fetchingReviews ?
                <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <Spinner />
                    <p className='text-sm text-gray-500 mt-5'>Fetching Product Reviews...</p>
                </div>
                
                :

                <>
                    {!error || error === '' ? 
                    <>

                        <DataTable 
                            tableHeaders={tableHeadersFields(cleanUpReviews(allReviews)[0])?.headers} 
                            tableData={cleanUpReviews(allReviews)} 
                            columnWidths={columnWidths}
                            columnDataStyles={columnDataStyles}
                            allFields={tableHeadersFields(cleanUpReviews(allReviews)[0])?.fields}
                            onSelectItems={getSelectionCount}
                            tableOptions={tableOptions}
                        />
                    </>
                    :
                    <div className='w-full flex flex-col items-center justify-center mt-10'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                        <p className='text-sm text-gray-600 mt-1'>{error}</p>
                    </div>
                    }
                </>
            }
        </div>

    </div>

    )
}



export default ProductReviews