import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

const DisclosureList = ({listTitle, listLinks}) => {
    const location = useLocation();
    const checkLocation = (pathName) => {
        console.log(location.pathname)
        // const fullRoute = location.pathname.split('/')
        if(location.pathname.includes(pathName)) {
            return true
        } 
        return false
    }

    const checkSectionActive = () => {
        let sectionActive = false
        listLinks.forEach(element => {
            if(element.location.includes(location.pathname)) {
                sectionActive = true
            }
        });

        return sectionActive
    }

    return (
        <div className="w-full">
            <div className="w-full max-w-md py-2 mx-auto">
                <Disclosure>
                    {({ open }) => (
                    <>
                        <Disclosure.Button className={`flex justify-between w-full bg-transparent text-sm text-left text-gray-500 focus:outline-none focus-visible:ring-opacity-75 hover:text-ink-navy ${checkSectionActive() ? 'text-ink-navy' : 'text-gray-500'}`}>
                            <span>{listTitle}</span>
                            <ChevronUpIcon
                                className={`${
                                open ? '' : 'transform rotate-180'
                                } w-5 h-5 text-gray-500`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-2 pb-0 text-sm text-gray-500">
                            {listLinks.map((link, linkIndex) => (
                                <Link to={link.location} key={linkIndex} className={`block py-1 hover:text-ink-navy ${checkLocation(link.location) ? 'text-ink-navy' : 'text-gray-500'}`}>{link.label}</Link>  
                            ))}
                        </Disclosure.Panel>
                    </>
                    )}
                </Disclosure>
            </div>
        </div>
    )
}

export default DisclosureList
