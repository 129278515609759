import React, { useState, useEffect } from 'react'
import DataTable from '../../../components/elements/DataTable'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import SearchIcon from '../../../assets/images/icons/search.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import HorizontalMenuIcon  from '../../../assets/images/icons/horizontal-menu-icon.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../utils';
import Spinner from '../../../components/elements/icons/Spinner';
import UserIcon from '../../../assets/images/icons/user-icon.svg'

export const customers = [
    {
        id: "201",
        name: "Mike Lewis",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "mikelewis@gmail.com",
        location: "Gwent",
        postCode: "143678",
        status: "dormant",
        actions: []
    },
    {
        id: "202",
        name: "Joel Derbyshire",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "info@joelderbyshire.digital",
        location: "Avon",
        postCode: "874478",
        status: "dormant",
        actions: []
    },
    {
        id: "203",
        name: "H Hill",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "chris.timecycle@gmail.com",
        location: "Avon",
        postCode: "087623",
        status: "active",
        actions: []
    },
    {
        id: "204",
        name: "Lucy Wheelie",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "testcustomer4@gmail.com",
        location: "Avon",
        postCode: "978723",
        status: "dormant",
        actions: []
    },
    {
        id: "205",
        name: "Belinda McRidley",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "testcustomer3@gmail.com",
        location: "North Somerset",
        postCode: "978723",
        status: "dormant",
        actions: []
    },
    {
        id: "206",
        name: "John Anderson",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "jo@gpushsales.co.uk",
        location: "Avon",
        postCode: "000023",
        status: "dormant",
        actions: []
    },
    {
        id: "207",
        name: "Keith Southam",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "keith@gpushsales.co.uk",
        location: "Alabama",
        postCode: "400023",
        status: "dormant",
        actions: []
    },
    {
        id: "208",
        name: "Julia Jefferson",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "julia@gpushsales.co.uk",
        location: "Gwent",
        postCode: "980023",
        status: "dormant",
        actions: []
    },
    {
        id: "209",
        name: "Podolski Alfamitana",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "chris.timecycle@gmail.com",
        location: "Gwent",
        postCode: "980023",
        status: "active",
        actions: []
    },
    {
        id: "210",
        name: "Podolski Alfamitana",
        image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        email: "chris.timecycle@gmail.com",
        location: "Gwent",
        postCode: "980023",
        status: "active",
        actions: []
    },
] 

const AllCustomers = () => {

    const [customers, setCustomers ] = useState([])  


    const columnWidths = {
        id: "w-full lg:w-1/12",
        customer: "w-full lg:w-3/12",
        email: "w-full lg:w-2/12",
        location: "w-full lg:w-2/12",
        postCode: "w-full lg:w-2/12",
        status: "w-full lg:w-1/12",
        actions: "w-full lg:w-1/12"
    }

    const columnDataStyles = {
        status: {},
        actions: "text-right"
    }

    const convertCamelCase = (camelCaseText) => {
        const text = camelCaseText;
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult
    }

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key

            if (key==='actions' || key === 'postCode') {
                sortable = false
            }
            
            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true

            if(index > 10) {
                fieldSelected = false
            }
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });
        return {headers, fields}
    }

    const [ processing, setProcessing ] = useState(false)
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])

    const [ pagination, setPagination ] = useState({ 
        page: 1, 
        perPage: 25, 
        totalRecords: 0,
        nextPage: '',
        lastPage: '',
        previousPage: '',
        firstPage: ''
    })

    useEffect(() => {
        const fetchCustomers = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/admin/customers/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc`, { headers })            
                console.log(response)
                setCustomers(response.data.data)
                setPagination({
                    page: pagination.page,
                    perPage: pagination.perPage,
                    totalRecords: response.data.total_records,
                    nextPage: response.data.next,
                    lastPage: response.data.last
                })
                setProcessing(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }
        fetchCustomers()
    }, [cookies.accessToken, cookies.tokenType, pagination.page, pagination.perPage])


    const Customer = ({customerImage, customerName}) => {
        return (
            <div className="flex flex-row items-center gap-x-3">
                <img alt="" src={customerImage} className="w-7 h-7 rounded-full border border-gray-200 border-4" />
                <span className="font-sofia-pro text-xs">{customerName}</span>
            </div>
        )
    }

    const ActionsButton = ({menuItems}) => {
        return (
            <img alt="" src={HorizontalMenuIcon} className="w-4 h-4" />
            // <DropdownMenu 
            //         buttonIcon={HorizontalMenuIcon} 
            //         buttonType='icon' 
            //         buttonText="" 
            //         menuItems={menuItems} 
            //         iconClasses="w-3" 
            //         buttonClasses="text-xs rounded-tr-lg font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200" />
        )
    }

    const CustomerStatus = ({customerStatus}) => {
        return (
            <div className="flex flex-row items-center gap-x-2">
                <span className={`w-2 h-2 rounded-full ${customerStatus === "dormant" ? "bg-gray-300" : "bg-green-300"}`} /> <span className="capitalize font-sofia-pro text-xs">{customerStatus}</span>
            </div>
        )
    }

    const cleanUpCustomers = (customers) => {
        let finalResult = [] 
        customers.forEach((customer, customerIndex) => {
            const object = {
                id: <Link to={`all-customers/${customer.id}`} className='text-ink-navy font-medium text-xs'>{customer.user_id} </Link>,
                customer: <Customer 
                    customerName={`${customer.first_name} ${customer.last_name}`} 
                    customerImage={UserIcon} 
                />,
                email: customer.email,
                location: customer.city,
                postCode: customer.post_code,
                status: <CustomerStatus customerStatus={customer.status} />,
                actions: <ActionsButton menuItems={customer.actions} />
            }
            finalResult.push(object)
        })        
        return finalResult
    }

    const [selectedCustomersCount, setSelectedCustomersCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedCustomersCount(count)
    }

    const tableOptions = {
        selectable: true
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

    return (
        <div className="w-full">
            <div className="w-full block md:flex flex-row justify-between">
                <div className="flex flex-row justify-between items-center w-full md:w-5/12">
                    <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full md:w-9/12">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm mt-1">
                                <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                            </span>
                        </div>
                        <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search customers" />
                    </div>
                </div>
                <div className="w-full md:w-7/12 flex flex-row-reverse gap-x-1 mt-5 md:mt-0">
                    <button className="inline-flex justify-center text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light">
                        <img alt="" src={ExportIcon} className="w-4 h-4 transform -rotate-90 mr-2" />
                        Export
                    </button>
                    {selectedCustomersCount > 1 && 
                        <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                    }
                </div>
            </div>
            <div>
                {!processing && error === '' ?
                    <DataTable 
                        tableHeaders={tableHeadersFields(cleanUpCustomers(customers)[0]).headers} 
                        tableData={cleanUpCustomers(customers)} 
                        columnWidths={columnWidths}
                        columnDataStyles={columnDataStyles}
                        allFields={tableHeadersFields(cleanUpCustomers(customers)[0]).fields}
                        onSelectItems={getSelectionCount}
                        tableOptions={tableOptions}
                        pagination={pagination}
                        paginate={setPagination}
                    />
                    :
                    <div className='w-full flex flex-col items-center justify-center mt-10'>
                        <Spinner />
                        <p className='text-sm text-gray-500 mt-5'>Fetching customers...</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default AllCustomers
