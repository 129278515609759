import React, { useState } from 'react'

const DashboardIcon = () => {
    const [color, setColor] = useState("#B8C0D5")
    return (
        <svg onMouseEnter={()=>{setColor("#ffffff")}} onMouseLeave={()=>{setColor("#B8C0D5")}} id="dashboard" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
            <path id="Path_8" data-name="Path 8" d="M1.455,0H6.545A1.455,1.455,0,0,1,8,1.455V6.545A1.455,1.455,0,0,1,6.545,8H1.455A1.455,1.455,0,0,1,0,6.545V1.455A1.455,1.455,0,0,1,1.455,0Z" fill={color}/>
            <rect id="Rectangle_12" data-name="Rectangle 12" width="8" height="8" rx="2" transform="translate(0 10)" fill="#b8c0d5"/>
            <rect id="Rectangle_13" data-name="Rectangle 13" width="8" height="8" rx="2" transform="translate(10)" fill="#b8c0d5"/>
            <rect id="Rectangle_14" data-name="Rectangle 14" width="8" height="8" rx="2" transform="translate(10 10)" fill="#b8c0d5"/>
        </svg>
     )
}

export default DashboardIcon
