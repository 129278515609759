import React, {Fragment, useEffect, useState} from 'react'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
import BulkActionsIcon from '../../../../assets/images/icons/bulk-items.svg'
import DropdownMenu from '../../../../components/elements/DropdownMenu'
import DataTable from '../../../../components/elements/DataTable'
import { Link } from 'react-router-dom'
import { convertCamelCase } from "../../../../utils";
import EditIcon  from '../../../../assets/images/icons/edit.svg'
import DeleteIcon from '../../../../assets/images/icons/delete.svg'
import Spinner from '../../../../components/elements/icons/Spinner';
import axios from "axios"
import { baseUrl } from '../../../../utils';
import { useCookies } from 'react-cookie';
import { Dialog, Transition } from '@headlessui/react'
import { Switch } from '@headlessui/react'
import ErrorMessage from '../../../../components/elements/ErrorMessage'

const AdBlocks = () => {
//   const blocks = [
//     {
//       id: 1,
//       name: "Logo",
//       enabled: 0,
//       position: "page",
//       actions: ""
//     },
//     {
//       id: 2,
//       name: "Logo_1080x960",
//       enabled: 1,
//       position: "category",
//       actions: ""
//     }
// ]

let [isOpen, setIsOpen] = useState(false)
const [error, setError] = useState('')
const [ cookies ] = useCookies(['user']);

const closeModal = () => {
  setIsOpen(false)
}

const [filtersActive, setFiltersActive] = useState(false)
const toggleFilters = () => {
    setFiltersActive(!filtersActive)
}

const tableOptions = {
    selectable: false
}

const columnWidths = {
    id: 'w-full lg:w-1/12',
    name: 'w-full lg:w-4/12',
    enabled: 'w-full lg:w-2/12',
    position: 'w-full lg:w-3/12',
    actions: 'w-full lg:w-2/12'
}

const columnDataStyles = {}

const tableHeadersFields = (sampleObject) => {
  if(!sampleObject) { return }

    const headers = []
    const fields = []
    Object.keys(sampleObject).forEach((key, index)=>{
        let columnDataType = 'text'
        let forPopover = false
        let columnDisplayName = convertCamelCase(key)
        let sortable = true

        if(key === 'actions' || key === 'status') {
            sortable = false
        }

        let column = key

        if(key !== 'selected') {
            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true

            if(index > 10) {
                fieldSelected = false
            }
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        }
    });
    return {headers, fields}
}

const bulkActions = [
    {
        label: "Change Status",
        icon: null,
        link: "/",
        action: null
    },
    {
        label: "delete",
        icon: null,
        link: "/",
        action: null
    },
]


const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

const getSelectionCount = (count) => {
    return setSelectedReviewsCount(count)
}

const EditDeleteButton = ({block}) => {
    const [ deleting, setDeleting ] = useState(null)
    
    const deleteAdBlock = async (adBlock) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm("Are you sure you want to delete this widget?")) {
            const headers = {
                'Content-Type': 'application/json', 
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }

            setDeleting(adBlock.id)

            try {
                const response = await axios.delete(`/api/v1/admin/ad_blocks/${adBlock.id}`, { headers })            
                console.log(response)
                setDeleting(null)

                const tempAdBlocks = [ ...adBlocks ]
                const filtered = tempAdBlocks.filter((deletedBlock) => {
                    return adBlock.id !== deletedBlock.id
                })
                setAdBlocks(filtered)

                setProcessing(true)
                setTimeout(() => {
                    setProcessing(false)
                }, 1000);

            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setDeleting(false)
            }
        }
    }
    
    return (
        <div className='flex flex-row gap-x-2'>
            <Link to={`stores/detail/${block.id}`} className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
            
            <button className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center" 
            onClick={()=>{deleteAdBlock(block)}}>
              {deleting === block.id ? 
                <Spinner />
                :
                <>
                  Delete <img src={DeleteIcon} alt="" className="w-3 ml-2 -mt-1 inline" />
                </>
                // Delete <img src={DeleteIcon} alt="" className="w-3 ml-2 -mt-1 inline" />
              }
            </button>
        </div>
    )
}

const EnabledCheck = ({check}) => {
  return (
      <span className={`px-2 py-1 rounded-full flex flex-row items-center gap-x-1 text-xs font-sofia-pro pr-4 ${check===true || check===1 ? 'bg-green-400 bg-opacity-20 text-green-400' : 'bg-red-500 bg-opacity-20 text-red-500'}`}>
          {
              check === true || check === 1
              ?
              <>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Enabled
              </>
              :
              <>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  Disabled
              </>
          }
      </span>
  )
}

const cleanUpData = (dataArray) => {
  if(!dataArray) { return }
    let finalResult = [] 
    dataArray.forEach((object, objectIndex) => {
        const requestObject = {
            id: <Link to={`/admin/navigations/ad-blocks/${object.id}/ads`}>{object.id}</Link>,
            name: object.name,
            enabled: <EnabledCheck check={object.enabled} />,
            position: object.position,
            actions:<EditDeleteButton block={object} />
        }
        finalResult.push(requestObject)
    })
    return finalResult
}

const [ validationErrors, setValidationErrors ] = useState({})

const validateBrandForm  = () => {
    let errors = {}
    
    // if(!brandTitle || brandTitle === '') {
    //     errors.brandTitle = true
    // }

    // if(!brandContent || brandContent === '') {
    //     errors.brandContent = true
    // }
    
    // if(!brandSlug || brandSlug === '') {
    //     errors.brandSlug = true
    // }

    setValidationErrors(errors)
    return errors
}

const [adBlocks, setAdBlocks] = useState([])

const [newBlockEnabled, setNewBlockEnabled] = useState(false)
const [creating, setCreating] = useState(false)
const [processing, setProcessing] = useState(true)
const [categories, setCategories] = useState([])

const [ pagination, setPagination ] = useState({ 
    page: 1, 
    perPage: 25, 
    totalRecords: 0,
    nextPage: '',
    lastPage: '',
    previousPage: '',
    firstPage: ''
})

useEffect(() => {
  const getAdBlocks = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
    }

    try {        
        const response = await axios.get(`/api/v1/admin/ad_blocks/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc`, { headers })            
        console.log('===> widgets response ===>', response)
        setAdBlocks(response.data.data)
        setPagination({
            page: pagination.page,
            perPage: pagination.perPage,
            totalRecords: response.data.total_records,
            nextPage: response.data.next,
            lastPage: response.data.last
        })
        await getCategories()
    } catch (error) {
        console.error(error)
        setError(error.response.data.msg)
        setProcessing(false)
    }
  }

  const getCategories = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
    }

    try {
        const response = await axios.get(`/api/v1/categories/nested/?page[offset]=1&page[limit]=1000000&page[order]=desc`, { headers })            
        console.log(response)
        setCategories(response.data.data)
        setProcessing(false)
    } catch (error) {
        console.error(error)
        setError(error.response.data.msg)
        setProcessing(false)
    }
  }

  getAdBlocks()

}, [cookies.accessToken, cookies.tokenType, pagination.page, pagination.perPage])

const [newBlockName, setNewBlockName] = useState(null)
const [newBlockPosition, setNewBlockPosition] = useState(null)
const [newBlockPositionIds, setNewBlockPositionIds] = useState([])
// const [positionsForDisplay, setPositionsForDisplay] = useState([])

const createAdBlock = async () => {
  const headers = {
      'Content-Type': 'application/json', 
      'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
  }

  if (Object.values(validateBrandForm  ()).includes(true)) {
      setError('Please check the highlighted fields')
      return
  }
  
  const requestPayload = {
    "name" : newBlockName,
    "enabled" : newBlockEnabled,
    "position" : newBlockPosition,
  }

  if (newBlockPosition === "category" && newBlockPositionIds.length > 0) {
    requestPayload.category_ids = newBlockPositionIds
  }

  setCreating(true)

  try {
      const response = await axios.post(`/api/v1/admin/ad_blocks`, requestPayload, { headers })            
      console.log(response.data.data)

      const tempAdBlocks = [ ...adBlocks ]
      tempAdBlocks.unshift(response.data.data[0])
      setAdBlocks(tempAdBlocks)

      setProcessing(true)
      setTimeout(() => {
          setProcessing(false)
      }, 1000);
      closeModal()
      setCreating(false)

  } catch (error) {
      console.error(error)
      setError(error.response.data.user_message)
      setCreating(false)
  }
}

const [position, setPosition] = useState('')

const setBlockPosition = () => {
    if(!position || position === '' || newBlockPositionIds.includes(position) ) {
        return
    }
    setNewBlockPositionIds([...newBlockPositionIds, ...[position]])
    console.log(newBlockPositionIds)
}

const categoryName = (id) => {
    let name = ""
    categories.forEach((category) =>  {
        if (category.id.toString() === id) {
            name = category.category
        }
    }) 
    return name
}

return (
    <Fragment>

        <div className="w-full">
            <div className="w-full block lg:flex flex-row justify-between pt-5">
                <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/6">
                    <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm mt-1">
                                <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                            </span>
                        </div>
                        <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search widgets" />
                    </div>
                    <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                        <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                    </button>
                </div>
                <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap lg:flex-nowrap">
                    {selectedReviewsCount > 1 && 
                      <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2 hidden lg:inline" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                    }
                    
                    <button  onClick={() => {setIsOpen(true)}} className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg> 
                        New widgets
                    </button>
                </div>
            </div>

            {/* Table */}

            {processing ? 
              <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                <p className='text-sm text-gray-500 mt-5'>Fetching widgets...</p>
              </div>
              :
              <DataTable 
                tableHeaders={tableHeadersFields(cleanUpData(adBlocks)[0]).headers} 
                tableData={cleanUpData(adBlocks)} 
                columnWidths={columnWidths}
                columnDataStyles={columnDataStyles}
                allFields={tableHeadersFields(cleanUpData(adBlocks)[0]).fields}
                onSelectItems={getSelectionCount}
                tableOptions={tableOptions}
            />
          }
        </div>

        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <Dialog.Overlay className="fixed inset-0  bg-black bg-opacity-40" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                    >
                    &#8203;
                    </span>
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title
                        as="h3"
                        className="text-md font-medium leading-6 text-ink-nvy"
                        >  
                          Create a New Widget
                        </Dialog.Title>
                        <div className="mt-2">
                            {/* <p className="text-sm text-gray-500">
                                Your payment has been successfully submitted. We’ve sent you
                                an email with all of the details of your order.
                            </p> */}
                            <div className='w-full flex flex-row gap-x-2 mb-3 mt-3'>
                                <div className='w-full'>
                                    <label className={`text-xs text-sm block mb-2 ${validationErrors.name && validationErrors.name === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                        Widget Name
                                    </label>
                                    <input 
                                        type="text" 
                                        className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.name && validationErrors.name === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: Red Trailer" 
                                        value={newBlockName}
                                        onChange={(e)=>{setNewBlockName(e.target.value)}} 
                                    />
                                </div>
                                <div>
                                    <label className={`text-xs text-sm block mb-2`}>
                                        Enabled
                                    </label>
                                    <Switch
                                        checked={newBlockEnabled}
                                        onChange={setNewBlockEnabled}
                                        className={`${
                                          newBlockEnabled ? 'bg-ink-navy' : 'bg-gray-300'
                                        } relative inline-flex items-center h-7 rounded-full w-12 mt-2`}
                                        >
                                        <span className="sr-only">Enabled</span>
                                        <span
                                            className={`transform transition ease-in-out duration-200 ${
                                              newBlockEnabled ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block w-5 h-5 transform bg-white rounded-full`}
                                        />
                                    </Switch>
                                </div>
                            </div>

                            <div className='w-full flex flex-row gap-x-2 mb-3 mt-3'>
                                <div className='w-full'>
                                    <label className={`text-xs text-sm block mb-2 ${validationErrors.position && validationErrors.position === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                        Position
                                    </label>
                                    <div 
                                        className={`select-wrapper border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.position && validationErrors.position === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: red_trailer"
                                        // value={brandSlug}
                                        // onChange={(e)=>{setBrandSlug(e.target.value)}}
                                    >
                                      <select className='w-full outline-none' value={newBlockPosition} onChange={(e) => {setNewBlockPosition(e.target.value)}}>
                                        <option value="">Select position</option>
                                        <option value="home">Home Page</option>
                                        <option value="category">Category</option>
                                      </select>
                                    </div>
                                </div>
                            </div>

                            {newBlockPosition === 'category' && 
                            <>
                                <div className='w-full flex flex-row gap-x-2 mb-3 mt-3 items-center relative'>
                                    <div className='w-4/5'>
                                        <label className={`text-xs text-sm block mb-2 ${validationErrors.brandTitle && validationErrors.brandTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                            Categories
                                        </label>
                                        <label className={`text-xs text-sm block mb-2 text-gray-400`}>
                                            Select a category and click on "Add" to add multiple categories.
                                        </label>
                                        <div 
                                            className={`select-wrapper border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: red_trailer"
                                            // value={brandSlug}
                                            // onChange={(e)=>{setBrandSlug(e.target.value)}}
                                        >
                                            <select className='w-full outline-none' onChange={(e)=>{setPosition(e.target.value)}}>
                                                <option value="">Select category</option>
                                                {categories.map((category, categoryIndex) => (<option key={categoryIndex} value={category.id}>{category.category}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                        type="button"
                                        className="bottom-2 absolute right-1 inline-flex justify-center gap-x-2 items-center px-4 py-2 text-sm font-medium text-ink-navy bg-ink-navy bg-opacity-10 transition duration-200 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 hover:bg-opacity-30"
                                        onClick={()=>{setBlockPosition()}}
                                        >
                                            Add
                                        </button>
                                        
                                    </div>
                                </div>

                                <div className='w-full flex items-center gap-4 flex-wrap'>
                                    {newBlockPositionIds.map((category, categoryIndex) => (<span key={categoryIndex} className='px-3 py-2 rounded bg-gray-100 text-gray-500 text-sm'>{categoryName(category)}</span>))}
                                </div>
                            </>
                            }

                            {/* <div className='w-full mt-3'>
                                <label className={`text-xs text-sm block mb-2 ${validationErrors.brandContent && validationErrors.brandContent === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                    Content
                                </label>

                                <textarea value={brandContent || ''} className={`font-sofia-pro border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 resize-none h-24 ${validationErrors.brandContent && validationErrors.brandContent === true ? 'border-red-500' : 'border-gray-0'} `}  placeholder='some brand content...' onChange={(e)=>{setBrandContent(e.target.value)}}/>
                            </div> */}

                        </div>

                        <div className='w-full'>
                            <div className="mt-8 w-full flex flex-row-reverse gap-x-3">
                                <button
                                    type="button"
                                    className="inline-flex justify-center gap-x-2 items-center px-4 py-2 text-sm font-medium text-white bg-ink-navy transition duration-200 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={()=>{createAdBlock()}}
                                    disabled={creating}
                                >
                                    {creating ? <span className='flex items-center gap-x-2'><Spinner /> Creating widget... </span> : 'Create widget' }
                                </button>
                                
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 transition duration-200 text-sm font-medium text-gray-500 bg-transparent border border-transparent rounded-md hover:bg-blue-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </button>
                            </div>

                            <div className='mt-3'>
                                {error && error!=='' &&  
                                    <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                                } 
                            </div>
                        </div>
                    </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
        

    </Fragment> 
  )
}

export default AdBlocks