import React, {useState, Fragment} from 'react'
import DropdownMenu from '../../../../components/elements/DropdownMenu'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../../assets/images/icons/bulk-items.svg'
// import { Link } from 'react-router-dom'
import { convertCamelCase } from '../../../../utils'
import DataTable from '../../../../components/elements/DataTable'

const CourierRule = () => {
    const tabs = [
        {
            tabId: "settings",
            tabLabel: "Settings",
        },
        {
            tabId: "usage",
            tabLabel: "Usage",
        }
    ]

    const courierUsage = [
        {
            id: "",
            customer: "",
            value: "",
            delivery: "",
            payment: "",
            date: "",
            device: "",
            source: "",
            status:""
        }
    ]

    const tableOptions = {
        selectable: true
    }

    const columnWidths = {
        id: "w-1/12",
        customer: "w-3/12",
        value: "w-1/12",
        delivery: "w-1/12",
        payment: "w-1/12",
        date: "w-1/12",
        device: "w-1/12",
        source: "w-2/12",
        status:"w-1/12"
    }

    const columnDataStyles = {}

    const courierDetails = {
        name: "Parcel Force",
        trackingUrl: "https://www.parcelforce.com/track-trace?tracking_number={{tracking_id}}",
    }

    const [activeTab, setActiveTab] = useState(tabs[0].tabId)

    let changeActiveTab = (tab) => {
        setActiveTab(tab.tabId)
    }

    const [selectionCount, setSelectionCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectionCount(count)
    }

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) { return }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const cleanUpData = (dataFor, dataArray) => {
        if(!dataArray || dataArray.length === 0){ return }

        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            let requestObject = {}
            requestObject = {
                id: object.id,
                customer: object.customer,
                value: object.value,
                delivery: object.delivery,
                payment: object.payment,
                date: object.date,
                device: object.device,
                source: object.source,
                status: object.status
            }

            finalResult.push(requestObject)
        })
        return finalResult
    }

    return (
        <Fragment>
            {/* Tabs */}
            <div className="flex flex-row gap-x-2 border-b border-gray-300">
                {tabs.slice(0, 6).map((tab, tabIndex) =>(
                    <button 
                        key={tabIndex} 
                        className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                        onClick={()=>{changeActiveTab(tab)}}>
                        {tab.tabLabel} 
                    </button>
                ))}
            </div>

            {/* Tab area */}
            <div className='p-5 mt-5'>
                {activeTab==='settings' && 
                    <div className='w-full'>
                        <h3 className='font-medium text-md text-ink-navy mb-4'>Courier Details</h3>
            
                        <div className='w-full flex flex-row gap-x-8 my-5'>
                            <div className='w-2/3'>
                                <label className="block text-xs text-gray-400 mb-2">Courier Name</label>
                                <input type="text" value={courierDetails.name} className="w-full px-4 py-3 border border-gray-400 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" placeholder="Name of courier" />
                            </div>
                        </div>
            
                        <div className='w-full flex flex-row gap-x-8 my-5'>
                            <div className='w-2/3'>
                                <label className="block text-xs text-gray-400 mb-2">Tracking URL</label>
                                <input type="text" value={courierDetails.trackingUrl} className="w-full px-4 py-3 border border-gray-400 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" placeholder="include {{tracking_id}} where tracking id should be inserted" />
                            </div>
                        </div>
            
                        <div className='w-full flex flex-row gap-x-8 my-5'>
                            <div className='w-1/3'>
                                <label className="block text-xs text-gray-400 mb-2">Sort Order</label>
                                <select className='w-full px-4 py-3 border border-gray-400 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500'>
                                    <option></option>
                                </select>
                                <label className="block text-xs text-gray-400 mt-1 mb-2">Greater numbers appear at the bottom</label>
                            </div>
                        </div>
            
                        <div className='flex flex-row-reverse gap-x-3'>
                            <button className='px-5 py-3 border border-blue-700 bg-blue-700 text-white rounded transition duration-200 text-xs'>
                                Save
                            </button>
                            <button className='px-5 py-3 border border-ink-navy text-ink-navy rounded transition duration-200 text-xs'>
                                Cancel
                            </button>
                        </div>
                    </div>
                }

                {activeTab==='usage' && 
                    <>
                        <div className="w-full flex flex-row justify-between pt-12">
                            <div className="flex flex-row gap-x-4 items-center w-3/5">
                                <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-gray-500 sm:text-sm mt-1">
                                            <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                        </span>
                                    </div>
                                    <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search rules" />
                                </div>
                                <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                                    <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                                </button>
                            </div>
                            <div className="flex flex-row gap-x-2">
                                {selectionCount > 1 && 
                                    <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                                }
                                <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                                    <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Export
                                </button>
                            </div>
                        </div>

                        <DataTable
                            tableHeaders={tableHeadersFields(courierUsage[0]).headers} 
                            tableData={cleanUpData(courierUsage )} 
                            columnWidths={columnWidths}
                            columnDataStyles={columnDataStyles}
                            allFields={tableHeadersFields(courierUsage[0]).fields}
                            onSelectItems={getSelectionCount}
                            tableOptions={tableOptions} 
                        />
                    </>
                }
            </div>
        </Fragment>
    )
}

export default CourierRule
