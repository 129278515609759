/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import UpRightArrow from '../../../components/elements/icons/UpRightArrow'
import { ChevronUpIcon } from '@heroicons/react/solid'
import UserIcon  from '../../../assets/images/icons/user-icon.svg'

const NewBooking = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true)
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen)
    }

    const orderNotes = [
       
    ]

    const [file, setFile] = useState('')

    const onImageChange = (event, imageFor) => {
        if (event.target.files && event.target.files[0]) {
            setFile(URL.createObjectURL(event.target.files[0]))
        }
    }

    const [notifyCustomer, setNotifyCustomer] = useState(false)
    const toggleNotufyCustomer = () => {
        setNotifyCustomer(!notifyCustomer)
    }
    return (
        <div className='w-full px-5 md:px-16'>
            <div className="w-full block lg:flex gap-x-8 pt-7">
                <div className='w-full lg:w-8/12'>
                    <div className='w-full px-5 py-3 flex justify-between bg-gray-100 items-center'>
                        <h3 className='text-ink-navy font-medium text-md'>Booking #000023458</h3>
                        <a href="#" target="_blank" className='text-sm text-ink-navy hover:text-opacity-50 transition duration-200 flex flex-row gap-x-2'>
                            Public Link 
                            <span className='pt-1'>
                                <UpRightArrow color='#05194C' width={8} height={8} />
                            </span>
                        </a>
                    </div>
                </div>
                <div className='w-full lg:w-4/12 flex flex-row gap-x-2 items-center mt-5 lg:mt-0'>
                    <button className='px-4 py-2 w-5/12 rounded-md text-green-400 border border-green-400 flex flex-row items-center text-xs'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-green-400 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                        Mark as Complete
                    </button>
                    <button className='px-4 py-2 w-5/12 rounded-md text-yellow-400 border border-yellow-400 flex flex-row items-center text-xs'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-yellow-400 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        Mark as Complete
                    </button>
                    <button className='p-1 rounded-full w-8 h-8 bg-gray-200 text-gray-500'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                    </button>
                    <button className='p-1 rounded-full w-8 h-8 bg-gray-200 text-gray-500'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                        </svg>
                    </button>
                </div>
            </div>

            <div className="w-full block lg:flex gap-x-8 pt-7">
                <div className='w-full lg:w-8/12'>
                    <div className='w-full flex flex-row items-center gap-x-4'>
                        <div className="w-full md:w-8/12">
                            <label className='block text-xs text-gray-400 mb-2'>Customer</label>
                            <input type="text" placeholder="Full name of the customer" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" />
                            <label className='block text-xs text-gray-400 mb-2 mt-2'>Search for an existing customer by email, phone and surname </label>
                        </div>
                    </div>   
                    <div className='w-full block md:flex flex-row items-center gap-x-4 my-4'>
                        <div className="w-full">
                            <label className='block text-xs text-gray-400 mb-2'>Mechanic</label>
                            <select className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500">
                                <option>Mike Ross</option>
                                <option>Matt Damon</option>
                                <option>Tim Horton</option>
                            </select>
                        </div>
                        <div className="w-full mt-4 md:mt-0">
                            <label className='block text-xs text-gray-400 mb-2'>Date</label>
                            <input type="date" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" />
                        </div>
                    </div>

                    <div className='border-t border-b border-gray-200 py-4'>
                        <h3 className='text-ink-navy fnt-medium text-sm mb-4'>Product</h3>
                        <div className='block md:flex flex-row gap-x-4 w-full'>
                            <div className='w-full border-dashed border-2 py-4 px-3 rounded-md text-center flex items-center justify-center border-gray-300'>
                                <p className='text-gray-400 text-xs'>No products selected. <button className='text-blue-700'>Add Product</button></p>
                            </div>
                            <textarea className="w-full px-4 h-20 resize-none rounded-md py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500 mt-4 md:mt-0" placeholder='Your product description'></textarea>
                        </div>
                        <div className='mt-4 text-right'>
                            <button className='px-4 py-2 border-ink-navy border text-ink-navy rounded bg-transparent text-xs'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                Add product
                            </button>
                        </div>
                    </div>   

                    <div className='border-t border-b border-gray-200 py-4'>
                        <h3 className='text-ink-navy fnt-medium text-sm mb-4'>Service</h3>
                        <div className='flex flex-row gap-x-4 w-full'>
                            <div className='w-full border-dashed border-2 py-4 px-3 rounded-md text-center flex items-center justify-center border-gray-300'>
                                <p className='text-gray-400 text-xs'>No services selected. <button className='text-blue-700'>Add Service</button></p>
                            </div>
                        </div>
                        <div className='mt-4 text-right'>
                            <button className='px-4 py-2 border-ink-navy border text-ink-navy rounded bg-transparent text-xs'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                Add Service
                            </button>
                        </div>
                    </div>   

                    <div className='border-t border-b border-gray-200 py-4'>
                        <h3 className='text-ink-navy fnt-medium text-sm mb-4'>Parts list</h3>
                        <div className='flex flex-row gap-x-4 w-full'>
                            <div className='w-full border-dashed border-2 py-4 px-3 rounded-md text-center flex items-center justify-center border-gray-300'>
                                <p className='text-gray-400 text-xs'>No parts selected. <button className='text-blue-700'>Add Part</button></p>
                            </div>
                        </div>
                        <div className='mt-4 text-right'>
                            <button className='px-4 py-2 border-ink-navy border text-ink-navy rounded bg-transparent text-xs'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                Add Service
                            </button>
                        </div>
                    </div>   
                </div>

                <div className='w-full lg:w-4/12'>
                    <div className="px-6 py-6 bg-gray-100 border rounded-md border border-gray-300">
                        <div className="flex justify-between">
                            <p className="font-light text-md text-gray-600">Notes {orderNotes.length> 0 && <span className="font-sofia-pro text-sm text-white border rounded bg-ink-navy px-1 py-0">{orderNotes.length}</span>}</p>
                            <button className="cursor-pointer p-2">
                                <ChevronUpIcon
                                    className={`${
                                    sidebarOpen ? '' : 'transform rotate-180'
                                    } w-5 h-5 text-gray-500`}
                                    onClick={()=>{toggleSidebar()}}
                                />
                            </button>
                        </div>
                            
                        <div className={`py-4 pb-8 border-gray-300 ${!sidebarOpen ? 'hidden' : ''} ${orderNotes.length > 0 ? 'border-b-2' : ''}`}>
                            <textarea className="w-full px-4 py-2 bg-white border border-gray-400 border-2 mb-4 rounded-md h-24  w-full font-sofia-pro" placeholder="Add new note..." />
                            <div className='flex flex-row justify-between py-2'>
                                <label className="">
                                    <span className="font-sofia-pro text-xs px-3 py-2 bg-gray-200 hover:bg-gray-300 hover:text-gray-600 text-gray-400 border border-gray-400 transition duration-200 rounded text-xs text-white cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                        </svg> 
                                        Upload file
                                    </span>
                                    <input type="file" className="hidden" onChange={(e)=>onImageChange(e, 'printLogo')} />
                                </label>

                                <label className="text-xs text-gray-600 mb-3">
                                    <input type="checkbox" checked={notifyCustomer} onChange={toggleNotufyCustomer} className="mt-3 mr-3" />
                                    Notify Customers 
                                </label>
                            </div>
                            <div className="w-full text-right">
                                <button className="w-full font-sofia-pro px-4 py-2 bg-ink-navy text-white text-sm rounded">Add Note</button>
                            </div>
                        </div>

                        <div className={`${!sidebarOpen ? 'hidden' : ''}`}>
                            {orderNotes.map((orderNote, noteIndex) => (
                                <div key={noteIndex} className="border-b border-gray-300 py-6 flex flex-row justify-between">
                                    <div className="w-8/12">
                                        <p className="font-medium text-ink-navy text-sm mb-0">{orderNote.note}</p>
                                        <p className="text-gray-400 font-thin text-xs">{orderNote.date}</p>
                                    </div>
                                    <div className="w-3/12">
                                        <p className="text-gray-400 font-thin text-xs">
                                            <img alt="" src={UserIcon} className="inline mr-2" />
                                            {orderNote.noteBy}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewBooking
