import React from 'react'

const EditIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.031" height="14.334" viewBox="0 0 13.031 14.334">
            <g id="edit" transform="translate(-21.333)">
                <g id="Group_642" data-name="Group 642" transform="translate(21.333 13.031)">
                <g id="Group_641" data-name="Group 641" transform="translate(0)">
                    <rect id="Rectangle_1134" data-name="Rectangle 1134" width="13.031" height="1.303" fill={color}/>
                </g>
                </g>
                <g id="Group_644" data-name="Group 644" transform="translate(22.636)">
                <g id="Group_643" data-name="Group 643" transform="translate(0)">
                    <path id="Path_142" data-name="Path 142" d="M72.04,0,64,8.04v3.688h3.688l8.04-8.04ZM67.147,10.425H65.3V8.581L72.04,1.844l1.844,1.844Z" transform="translate(-64)" fill={color}/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default EditIcon
