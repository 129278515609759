import React, { Fragment, useEffect, useState } from 'react'
import axios from "axios"
import { baseUrl, slugify } from '../../../../utils';
// import ErrorMessage from '../../../../components/elements/ErrorMessage'
import { useCookies } from 'react-cookie';
// import ChevronIcon from '../../../components/elements/icons/ChevronIcon';
import Spinner from '../../../../components/elements/icons/Spinner';
import ErrorMessage from '../../../../components/elements/ErrorMessage';
import SuccessMessage from '../../../../components/elements/SuccessMessage';
import { Link, useHistory } from "react-router-dom";
import { Switch } from '@headlessui/react';

const NewPromotion = () => {
    const history = useHistory();

    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')  
    const [ cookies ] = useCookies(['user']);

    // const [productDetails, setProductDetails] = useState({})

    const [name, setName] = useState('')
    const [group, setGroup] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [slug, setSlug] = useState('')
    const [description, setDescription] = useState('')
    const [fetching, setFetching] = useState(true)
    const [productGroups, setProductGroups] = useState([])
    const [fixedAmount, setFixedAmount] = useState('')
    const [percentage, setPercentage] = useState('')
    const [requiredPoints, setRequiredPoints] = useState('')
    const [discountType, setDiscountType] = useState('')
    const [active, setActive] = useState(false)


    useEffect(() => {
        const fetchProductGroups = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setFetching(true)
            try { 
                let response = null 

                response = await axios.get(`/api/v1/admin/product_groups?page[offset]=1&page[limit]=1000000&page[order]=desc`, { headers })            
                
                setProductGroups(response.data.data)

                setFetching(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setFetching(false)
            }
        }

        fetchProductGroups()
    }, [cookies.accessToken, cookies.tokenType])


    const [ validationErrors, setValidationErrors ] = useState({})
    const [discountError, setDiscountError] = useState('')

    const validateForm = () => {
        let errors = {}

        if(!name || name === '') {
            errors.name = true
        }

        if(!slug || slug === '') {
            errors.slug = true
        }

        if(!description || description === '') {
            errors.description = true
        }

        if(!discountType || discountType === '') {
            errors.discountType = true
        }

        if((!percentage || percentage === '') && (!fixedAmount || fixedAmount === '')) {
            errors.discount = true
            setDiscountError('Please Choose at least one discount type above')
        }

        if(!startDate || startDate === '') {
            errors.startDate = true
        }

        if(!endDate || endDate === '') {
            errors.endDate = true
        }

        setValidationErrors(errors)
        return errors
    }

    const [success, setSuccess] = useState(null)

    const createPromotion = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        if (Object.values(validateForm()).includes(true)) {
            setError('Please check the highlighted fields above')
            return
        }
        
        const requestPayload = {
            name,
            slug,
            // active,
            valid_from: startDate + ' 00:00:00',
            valid_to:endDate + ' 00:00:00',
            // description,
            discount_rule: {
                type: discountType,
                percentage_off: percentage === '' ? null : parseInt(percentage),
                fixed_cost: fixedAmount === '' ? null : parseInt(fixedAmount),
                X: null,
                for_X: null,
                required_loyalty_points: requiredPoints === '' ? parseInt(requiredPoints) : null
            },
            product_group_id: parseInt(group)
        }

        setProcessing(true)
        try {
            
            await axios.post(`/api/v1/admin/promotions`, requestPayload, { headers })            
            // setBrands(response.data.data)
            setSuccess('Promotion created successfully. redirecting to promotions page...')
            setTimeout(() => {
                history.push(`/admin/products/promotions/`);
            }, 3000);
            setProcessing(false)
        } catch (error) {
            console.error(error.response.data)
            setError(error.response.data.user_message)
            setProcessing(false)
        }
    }    

    return (
        <Fragment>
            {fetching ? 
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                {/* <p className='text-sm text-gray-500 mt-5'>Fetching brands...</p> */}
            </div>   
            :
            <div className="w-full xl:w-10/12 mx-auto block">
                <div className='w-full px-8 py-4'>
                    <p className="text-lg text-ink-navy block mb-8">Promotion Details</p>

                    <div className="w-full mb-5">
                        <div className="w-full mb-3">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.name && validationErrors.name === true ? 'text-red-500' : ''}`}>Name</label>
                            <input type="text" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.name && validationErrors.name === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="eg: Winter Sales" value={name} onChange={(e)=>{setName(e.target.value)}} onBlur={(e)=>{setSlug(slugify(e.target.value))}} />
                        </div>
                    </div>

                    <div className="w-full my-3">
                        <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.slug && validationErrors.slug === true ? 'text-red-500' : ''}`}>Slug</label>
                        <input type="text" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.slug && validationErrors.slug === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="eg: big-wheels" value={slug} onChange={(e)=>{setSlug(e.target.value)}} />
                    </div>

                    {slug && slug !== '' &&
                        <>
                            <label className={`text-xs text-gray-600 block mb-2}`}>Promotion URL</label>
                            <p className='text-sm text-ink-navy'>https://yourwebsite.co.uk/promotions/{slug}</p>
                        </>
                    }


                    <div className="w-full my-3">
                        <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.description && validationErrors.description === true ? 'text-red-500' : ''}`}>Description</label>
                        <textarea className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 resize-none h-32 ${validationErrors.description && validationErrors.description === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="Tell your customers what's what" value={description} onChange={(e)=>setDescription(e.target.value)} />
                    </div>

                    <div className="w-full flex gap-x-4 mt-6 mb-6">
                        <label className="text-xs text-gray-500 font-sofia-pro">Save Promotion as active </label>
                        <Switch
                            checked={active}
                            onChange={setActive}
                            className={`${
                                active ? 'bg-ink-navy' : 'bg-gray-200'
                            } relative inline-flex items-center h-5 rounded-full w-10`}
                            >
                            <span className="sr-only">Save as active</span>
                            <span
                                className={`transform transition ease-in-out duration-200 ${
                                    active ? 'translate-x-6' : 'translate-x-1'
                                } inline-block w-3 h-3 transform bg-white rounded-full`}
                            />
                        </Switch>
                    </div>


                    <div className="w-full my-3 flex flex-row gap-x-4">
                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.discountType && validationErrors.discountType === true ? 'text-red-500' : ''}`}>Discount type</label>
                            <div className={`select-wrapper border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.discountType && validationErrors.discountType === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`}>
                                <select className="outline-none w-full" value={group} onChange={(e)=>setDiscountType(e.target.value)}>
                                    <option value="">Select a discount type</option>
                                    <option value='percentage'>Percentage Discount</option>
                                    <option value='fixed'>Fixed Amount</option>

                                </select>
                            </div>
                        </div>

                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500' : ''}`}>Required loyalty points</label>
                            <input type="number" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={requiredPoints} onChange={(e)=>{setRequiredPoints(e.target.value)}} />
                        </div>
                    </div>

                    <div className="w-full my-3 flex flex-row gap-x-4">
                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500' : ''}`}>Percentage discount</label>

                            <input type="number" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={percentage} onChange={(e)=>{setPercentage(e.target.value)}} />
                        </div>
                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500' : ''}`}>Fixed amount</label>
                            <input type="number" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={fixedAmount} onChange={(e)=>{setFixedAmount(e.target.value)}} />
                        </div>
                    </div>
                    {discountError !== '' && <p className='text-xs text-red-500 border-red-500 w-full py-2 rounded -mt-8'>{discountError}</p>}

                    <div className="w-full my-3 flex flex-row gap-x-4">
                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.year && validationErrors.year === true ? 'text-red-500' : ''}`}>Start date</label>
                            <input type="date" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.name && validationErrors.name === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={startDate} onChange={(e)=>{setStartDate(e.target.value)}} />
                        </div>
                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.endDate && validationErrors.endDate === true ? 'text-red-500' : ''}`}>End date</label>
                            <input type="date" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.styleCode && validationErrors.styleCode === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={endDate} onChange={(e)=>{setEndDate(e.target.value)}} />
                        </div>
                    </div>

                    <div className="w-full my-3 flex flex-row gap-x-4">
                        <div className="w-full mb-5">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.group && validationErrors.group === true ? 'text-red-500' : ''}`}>Restrict to group of products</label>
                            <div className={`select-wrapper border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brand && validationErrors.brand === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`}>
                                <select className="outline-none w-full" value={group} onChange={(e)=>setGroup(e.target.value)}>
                                    <option value="">Select a product group</option>

                                    {productGroups.map((group, groupIndex) => (
                                        <option key={groupIndex} value={group.id}>{group.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="w-full mb-5">
                            <label className={`text-xs block mb-2 text-white`}>Edit groups</label>
                            <Link to="../groups" className="w-44 px-5 py-3 rounded text-ink-navy font-sofia-pro text-xs bg-white border border-ink-navy hover:bg-ink-navy hover:text-white transition duration-200 my-3 flex gap-x-2 items-center justify-center">Manage groups</Link>
                            
                        </div>
                    </div>

                    <button className="px-5 py-3 rounded text-white font-sofia-pro text-xs bg-blue-700 hover:bg-blue-500 transition duration-200 my-3 flex gap-x-2 items-center" onClick={()=>{
                            if(!processing) { createPromotion() }
                                }}>{processing ? <><Spinner />Saving Promotion...</> : 'Save Promotion'}</button>

                    {error && error!=='' &&  
                        <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                    } 

                    {success && success!=='' &&  
                        <SuccessMessage message={success} dismissHandler={()=>{setSuccess(false)}} />
                    } 

                    </div>
            </div>
            }
    </Fragment>
    )
}

export default NewPromotion