import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import ChevronDown from '../../assets/images/icons/chevron-down.svg'

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref, closeFunction) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            closeFunction()
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}


const AutocompleteSelect = ({selectOptions, placeholderText, displayImage, imageField, titleField, returnFieldValue, hasError}) => {
    const [activeValue, setActiveValue] = useState('')
    const [visibleOptions, setVisibleOptions] = useState(selectOptions)
    const [optionsOpen, setOptionsOpen] = useState(false)

    const openOptions = () => {
        setOptionsOpen(true)
    }

    const closeOptions = () => {
        setOptionsOpen(false)
    }

    const filterOptions = (term) => {
        const filtered = selectOptions.filter((option)=> {
            return option[titleField].toLowerCase().includes(term.toLowerCase())
        })
        setActiveValue(term)
        setVisibleOptions(filtered)
    }

    const changeActiveValue = (value, valueObject) => {
        setActiveValue(value)
        closeOptions()
        returnFieldValue(valueObject)
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, closeOptions);

    return (
        <div ref={wrapperRef} className='w-full relative'>
            
            {/* Text input */}
            <input 
                type="text" 
                placeholder={placeholderText} 
                className={`w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-sm text-gray-500 z-40 ${hasError ? 'border-red-600' : 'border-gray-300' }`}
                onClick={()=>{openOptions()}}  
                // onBlur={()=>{closeOptions()}} 
                onChange={(e)=>{filterOptions(e.target.value)}}
                value={activeValue} 
            />
            <img alt="" src={ChevronDown} className='absolute w-5 top-3 right-3' />
            
            {/* Options */}
            {optionsOpen &&
                <div className='absolute top-12 shadow-lg w-full left-0 p-3 bg-white rounded-md border border-gray-200 overflow-y-scroll h-96 pt-8 z-50'>
                    <button className='absolute top-3 right-3 text-gray-600 hover:text-gray-400 transition duration-200' onClick={()=>{closeOptions()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    {visibleOptions.map((option, optionIndex) => (
                        <button key={optionIndex} className='w-full px-2 py-3 flex flex-row gap-x-3 text-left text-xs text-gray-500 transition duration-200 hover:bg-gray-100' onClick={()=>{changeActiveValue(option[titleField], option)}}>
                            {displayImage && 
                                <img alt="" src={option[imageField]} className='w-12' />
                            }
                            {option[titleField]}
                        </button>
                    ))}
                </div>
            }
            
        </div>
    )
}

AutocompleteSelect.propTypes = {
    selectOptions: PropTypes.array.isRequired,
    placeholderText: PropTypes.string,
    titleField: PropTypes.string.isRequired,
    displayImage: PropTypes.bool.isRequired,
    imageField: PropTypes.string
};

export default AutocompleteSelect
