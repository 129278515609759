import React from 'react'
import Wysiwyg from '../../../../components/elements/Wysiwyg'

const ConditionalCOntentDetail = () => {
    return (
        <div className="w-full">
            <h3 className='font-medium text-md text-ink-navy mb-4'>Content Criteria</h3>

            <div className='w-full block md:flex flex-row gap-x-8 my-5'>
                <div className='w-full md:w-1/2'>
                    <label className="block text-xs text-gray-400 my-2">Brand </label>
                    <select type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500">
                        <option>All Brands</option>
                    </select>
                </div>
                <div className='w-full md:w-1/2'>
                    <label className="block text-xs text-gray-400 my-2">Category</label>
                    <select type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500">
                        <option>All Categories</option>
                    </select>
                </div>
            </div>

            <div className='w-full block md:flex flex-row gap-x-8 my-5'>
                <div className='w-full md:w-1/2'>
                    <label className="block text-xs text-gray-400 my-2">Publish Date</label>
                    <input type="date" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" />
                </div>
                <div className='w-full md:w-1/2' />
            </div>

            <h3 className='font-medium text-sm text-gray-500 mt-6 my-2'>Content</h3>

            <div className='w-full block md:flex flex-row gap-x-8 mt-1 mb-5'>
                <div className='w-full'>
                    <label className="block text-xs text-gray-400 mb-2">Title</label>
                    <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" />
            
                </div>
            </div>

            <div className="w-full py-4">
                <Wysiwyg fieldTitle="Size Guide (Paste from excel)" initialValue={''} />
                {/* <div className="flex flex-row justify-between mt-4">
                    <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Appears on top of the contact us page</label>
                </div> */}
            </div>

            <div className='flex flex-row-reverse gap-x-3'>
                <button className='px-5 py-3 border border-blue-700 bg-blue-700 text-white rounded transition duration-200 text-xs'>
                    Save
                </button>
                <button className='px-5 py-3 border border-ink-navy text-ink-navy rounded transition duration-200 text-xs'>
                    Cancel
                </button>
            </div>
    </div>
)
}

export default ConditionalCOntentDetail
