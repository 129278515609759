export const DispatchUrgency = ({status}) => {
    return (
        <>
            {status === 'priority' && <span className="w-1 h-1 bg-red-600 rounded-full mr-2 inline-block" />}
            {status === 'urgent' && <span className="w-1 h-1 bg-red-200 rounded-full mr-2 inline-block" />}
            {status === 'waiting' && <span className="w-1 h-1 bg-yellow-200 rounded-full mr-1 inline-block mt-1" />}
            <span className="capitalize text-xs font-sofia-pro">{status}</span>
            
        </>
    )
}