import React from 'react'

const ProductSpecifications = ({specifications}) => {
    return (
        <div className='w-10/12 py-4'>
            <h3 className='font-medium text-ink-navy text-md mb-6'>Product Specifications</h3>

            {specifications ? specifications.map((specification, specificationIndex)=> (
                <div key={specificationIndex} className="w-full flex items-center border border-gray-300">
                    <div className='w-full py-2 px-5 bg-gray-200'><p className='text-sm text-ink-navy font-medium'>{specification.label}</p></div>
                    <div className='w-full py-2 px-5 bg-white'><p className='text-sm text-gray-600 '>{specification.value}</p></div>
                </div>
            ))
            :
            <div className='w-full text-center p-5 bg-gray-200 rounded text-ink-navy font-sofia-pro text-xs'>No speceifications for this product</div>
        }
        </div>
    )
}

export default ProductSpecifications
