import React from 'react'

const ProductOptions = () => {
    return (
        <div>
            Product Options
        </div>
    )
}

export default ProductOptions
