import React, { useState, useRef } from 'react'
import { Switch } from '@headlessui/react'
import ChevronIcon from '../../../../components/elements/icons/ChevronIcon'
import { FileDrop } from 'react-file-drop'
import { isValidObject } from '../../../../utils'

const SingleAd = () => {
    const fileInputRef = useRef(null);

    const onFileInputChange = (event) => {
        const { files } = event.target;
        // do something with your files...
        handleFile(files)
    }

    const [showTitle, setShowTitle] = useState(false)
    const [textColour, setTextColour] = useState("#000000")
    const [backgroundColour, setBackgroundColour] = useState('#e3e3e3')
    const [imageFilter, setImageFilter] = useState('dark')
    const [bgSettingsOpen, setBgSettingsOpen] = useState(true)
    const [adPlacementOpen, setAdPlacementOpen] = useState(false)
    const [ coverImage, setCoverImage ] = useState('')

    const [ fileDetails, setFileDetails ] = useState(null)
    // const [processing, setProcessing] = useState(false)
    // const [ cookies ] = useCookies(['user']);
    // const [error, setError] = useState('')  

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleFile = (files) => {
        setFileDetails({
            name: files[0].name,
            size: `${Math.ceil(files[0].size/1000000).toString()} MB` ,
            type: files[0].type
        })
        let base64Image = '';

        getBase64(files[0], (result) => {
            base64Image = result;
            setCoverImage(base64Image)
        });
    }

    const toggleBgSettings = () => {
        setBgSettingsOpen(!bgSettingsOpen)
    }

    const toggleAdPlacement = () => {
        setAdPlacementOpen(!adPlacementOpen)
    }
    

    return (
        <div className='relative w-full'>
            <div className='w-full flex flex-row gap-x-8'>
                <div className='w-8/12 p-8'>
                    <div className="w-full mb-5">
                        <label className="text-xs text-gray-600 block mb-2">Title</label>
                        <input type="text" className="border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Title" />
                        <div className="w-full flex gap-x-4 mt-3">
                            <label className="text-sm text-gray-500 font-sofia-pro">Show Title </label>
                            <Switch
                                checked={showTitle}
                                onChange={setShowTitle}
                                className={`${
                                    showTitle ? 'bg-ink-navy' : 'bg-gray-200'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                <span className="sr-only">Show Title</span>
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        showTitle ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block w-3 h-3 transform bg-white rounded-full`}
                                />
                            </Switch>
                        </div>
                    </div>

                    <div className="w-full mb-5">
                        <label className="text-xs text-gray-600 block mb-2">Main Title</label>
                        <input type="text" className="border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Main Title (optional)" />
                    </div>

                    <div className="w-full mb-5">
                        <label className="text-xs text-gray-600 block mb-2">Link Text</label>
                        <input type="text" className="border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Link Text (optional)" />
                    </div>

                    <div className="w-full mb-5">
                        <label className="text-xs text-gray-600 block mb-2">Link URL</label>
                        <input type="text" className="border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Link URL" />
                    </div>

                    <div className="w-full mb-5 flex flex-row gap-x-4">
                        <div className='w-full'>
                            <label className="text-xs text-gray-400 text-sm block mt-4 mb-3">Text Colour</label>
                            <div className="flex flex-row">
                                <input type="color" className="border rounded-l-md bg-transparent border-gray-300 p-1 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={textColour} onChange={(e)=>{setTextColour(e.target.value)}} />
                                <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12   focus:outline-none focus:border-gray-300 transition duration-200" value={textColour} readOnly />

                            </div>
                        </div>
                        <div className='w-full'>
                            <label className="text-xs text-gray-400 text-sm block mt-4 mb-3">Background Colour</label>
                            <div className="flex flex-row">
                                <input type="color" className="border rounded-l-md bg-transparent border-gray-300 p-1 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={backgroundColour} onChange={(e)=>{setBackgroundColour(e.target.value)}} />
                                <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={backgroundColour} readOnly />
                            </div>
                        </div>
                    </div>

                    <div className='w-full pt-1'>
                        <label className="text-xs text-gray-400 text-sm block mt-4 mb-3">Image Filter (transparent wash over image)</label>
                        <div className="w-full mb-5 flex flex-row gap-x-4 items-center justify-between">

                            <div className='flex flex-row gap-x-4 items-center'>
                                <input checked={imageFilter === 'dark'} type="radio" id="dark" name="image_filter" value="dark" onChange={(e)=>{setImageFilter(e.target.value)}} />
                                <label htmlFor="dark" className='text-gray-400 text-xs'>Dark</label>
                            </div>

                            <div className='flex flex-row gap-x-4 items-center'>
                                <input checked={imageFilter === 'none'} type="radio" id="none" name="image_filter" value="none" onChange={(e)=>{setImageFilter(e.target.value)}} />
                                <label htmlFor="none" className='text-gray-400 text-xs'>None</label>
                            </div>

                            <div className='flex flex-row gap-x-4  items-center'>
                                <input checked={imageFilter === 'light'} type="radio" id="light" name="image_filter" value="light" onChange={(e)=>{setImageFilter(e.target.value)}} />
                                <label htmlFor="light" className='text-gray-400 text-xs'>Light</label>
                            </div>

                        </div>
                    </div>

                </div>



                {/* SIDEBAR */}

                <div className='w-3/12'>
                    <div className=' border border-gray-200'>
                        <div className='p-4 bg-gray-100 flex items-center justify-between'>
                            <h3 className='text-ink-navy text-sm font-medium'>Background Settings</h3>
                            <button onClick={()=>{toggleBgSettings()}}>
                                <ChevronIcon className={`text-ink-navy w-4 h-4 transition duration-200 transform ${bgSettingsOpen ? 'rotate-180' : ''}`} />
                            </button>
                        </div>
                        {bgSettingsOpen && <div className='w-full p-6'>
                            <div className='w-full'>
                                <input
                                    onChange={onFileInputChange}
                                    ref={fileInputRef}
                                    type="file"
                                    className="hidden"
                                    />
                                <FileDrop
                                    onTargetClick={onTargetClick}
                                    onFrameDragEnter={(event) => {}}
                                    onFrameDragLeave={(event) => {}}
                                    onFrameDrop={(event) => {}}
                                    onDragOver={(event) => {}}
                                    onDragLeave={(event) => {}}
                                    onDrop={(files, event) => handleFile(files, event)}
                                >
                                    <div className="flex flex-col items-center justify-center gap-y-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        <p className='text-gray-400 text-xs'>Drop yout image here<br /> or click to browse files</p>
                                    </div>
                                </FileDrop>

                                {isValidObject(fileDetails) && 
                                    <div className='w-full mt-3 mb-5 bg-gray-100 rounded p-5 flex flex-row gap-x-2'>
                                        <div className='w-2/5'>
                                            <img src={coverImage} alt="" />
                                        </div>
                                        <div>
                                            <p className='text-sm text-gray-600 mb-1'>{fileDetails.name}</p>
                                            <p className='text-xs text-gray-600'>{fileDetails.size} - <span className='text-gray-400'>{fileDetails.type}</span></p>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>}
                        <div className='p-4 bg-gray-100 flex items-center justify-between'>
                            <h3 className='text-ink-navy text-sm font-medium'>Ad Placement</h3>
                            <button onClick={()=>{toggleAdPlacement()}}>
                                <ChevronIcon className={`text-ink-navy w-4 h-4 transition duration-200 transform ${adPlacementOpen ? 'rotate-180' : ''}`} />
                            </button>
                        </div>
                         {adPlacementOpen && <div className='w-full p-6'>
                            <div className='w-full'>
                                <div type="text" className="border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Link Text (optional)">
                                    <select className="w-full outline-none h-full">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                    </select>
                                </div>
                            </div>
                       </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleAd