import React, { useState, useEffect } from 'react'
import AutocompleteSelect from '../../elements/AutocompleteSelect'
import Spinner from '../../elements/icons/Spinner'
import axios from 'axios'
import GlobalSearchV2 from '../../elements/GlobalSearchV2'
import { useHistory, useLocation } from 'react-router'
import { slugify } from '../../../utils'

const NewMenuItem = ({items, closeModal, navId, triggerReload}) => {
    const [validationErrors, setValidationErrors] = useState({})
    const [adding, setAdding] = useState(false)
    const [itemName, setItemName] = useState('');
    const [itemSlug, setItemSlug] = useState('')
    const [itemType, setItemType] = useState('');
    const [entityId, setEntityId] = useState('');
    const [entities, setEntities] = useState([])
    const [parent, setParent] = useState(null)

    const [error, setError] = useState(null);
    const [searchingBrands, setSearchingBrands] = useState(false);
    const [brands, setBrands] = useState(null);

    const history = useHistory();
    const location = useLocation()

    const updateMenuTitle = (title) => {
        setItemName(title)
        setItemSlug(slugify(title))
    }

    const searchForBrand = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        try { 
            setSearchingBrands(true)
            let response = null 

            response = await axios.get(`/api/v1/brands/?filter[%name%]=${term}`, { headers })                            
            setBrands(response.data.data)
            setSearchingBrands(false)
        } catch (error) {
            setBrands([])
            setSearchingBrands(false)
            console.error(error)
            setError(error.response.data.msg)
            setSearchingBrands(false)
        }
    }

    const [searchingCategories, setSearchingCategories] = useState(false);

    const [categories, setCategories] = useState(null);

    const searchForCategory = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        try { 
            setSearchingCategories(true)
            let response = null 

            response = await axios.get(`/api/v1/categories/?filter[%name%]=${term}`, { headers })                            
            setCategories(response.data.data)
            setSearchingCategories(false)
        } catch (error) {
            setCategories([])
            setSearchingCategories(false)
            console.error(error)
            setError(error.response.data.msg)
            setSearchingCategories(false)

        }
    }

    const [searchingProducts, setSearchingProducts] = useState(false);
    const [products, setProducts] = useState(null);

    const searchForProducts = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        try { 
            setSearchingProducts(true)
            let response = null 

            response = await axios.get(`/api/v1/admin/products/list?filter[%25name%25]=${term}`, { headers })                            
            setProducts(response.data.data)
            setSearchingProducts(false)
        } catch (error) {
            setSearchingProducts(false)
            console.error(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setSearchingProducts(false)
            }
        }
    }

    const [searchingForPages, setSearchingForPages] = useState(false);
    const [pages, setPages] = useState(null);

    const searchForPages = async (term) => {
        const headers = {
            'Content-Type': 'application/json'
        }
        setSearchingForPages(true)

        try {
            
            const response = await axios.get(`/api/v1/pages?filter[%title%25]=${term}`, { headers })            
            // const response = await axios.get(`/api/v1/pages`, { headers })            
            console.log(response)
            setPages(response.data.data)

            setSearchingForPages(false)
        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setSearchingForPages(false)
        }
    }

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [customMenuUrl, setCustomMenuUrl] = useState('');
    const doAddItem = () => {
        const itemPayload = {
            entity_id: 2,
            priority: 1,
            name: itemName,
            type: itemType,
            slug: itemSlug,
            // menu: []
        }

        if(itemType === 'product') {
            itemPayload.entity_id = selectedProduct.product_id
        } else if (itemType === 'brand') {
            itemPayload.entity_id = selectedBrand.id
        } else if (itemType === 'page') {
            itemPayload.entity_id = selectedPage.id
        } else if (itemType === 'brand') {
            itemPayload.entity_id = selectedCategory.id
        } else if (itemType === 'customer') {
            itemPayload.url = customMenuUrl
        }

        if(parent) {
            itemPayload.parent_id = parent
        }

        addMenuItem(itemPayload)
    }

    const addMenuItem = async (payload) => {
        const headers = {
            'Content-Type': 'application/json'
        }
        setAdding(true)
        try {
            // const itemPayload = {
            //     parent_id: 1,
            //     entity_id: 2,
            //     priority: 1,
            //     name: "Bikes",
            //     type: "category",
            //     slug: "bikes"
            // }

            const response = await axios.post(`/api/v1/admin/navigation/${navId}/item`, payload, { headers })            
            console.log(response)
            triggerReload()
            
            setAdding(false)
        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setAdding(false)
        }
    }

    return (
        <>
            <div className="mt-2" style={{minHeight: '450px', height: 'inherit'}}>
                {/* <p className="text-sm text-gray-500">
                    Your payment has been successfully submitted. We’ve sent you
                    an email with all of the details of your order.
                </p> */}
                <div className='flex flex-row gap-x-8'>
                    <div className='w-full'>
                        {/* <div className=' w-full mb-3'>
                            <label className={`text-xs block mb-2 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                Menu item type
                            </label>
                            <div className='select-wrapper select-wrapper border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200'>
                                <select className="outline-none w-full" value={itemType} onChange={(e)=>setItemType(e.target.value)}>
                                    <option value="">Select menu item type</option>
                                    <option value="category">Category</option>
                                    <option value="brand">Brand</option>
                                    <option value="page">Page</option>
                                    <option value="product">Product</option>
                                </select> 
                            </div>
                        </div> */}

                        <div className='w-full flex flex-row gap-x-2 mb-3 mt-3'>
                            <div className='w-full'>
                                <label className={`text-xs block mb-2 ${validationErrors.type && validationErrors.type === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                    Type
                                </label>
                                <div 
                                    className={`select-wrapper border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'border-red-500' : 'border-gray-0'} `} 
                                    value={``}
                                >
                                    <select className='w-full outline-none' onChange={(e)=>{setItemType(e.target.value)}}>
                                    <option value="">Select menu item type</option>
                                    <option value="title">Title</option>
                                    <option value="category">Category</option>
                                    <option value="brand">Brand</option>
                                    <option value="page">Page</option>
                                    {/* <option value="product">Product</option> */}
                                    <option value="custom">Custom</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {itemType && <div className=' w-full mb-3'>
                            {itemType !== 'title' && <label className={`text-xs block mb-2 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                Menu item {itemType === 'custom' && '(Your custom URL)'}
                            </label>}
                            {itemType === 'product' && <GlobalSearchV2 
                                performSearch={(term)=>{searchForProducts(term)}}
                                searchResults={products}
                                resultDisplayField={'name'}
                                returnSelectedResult={(index)=>{setSelectedProduct(products[index])}}
                                placeholderText={`Search for product`}
                                searchInProgress={searchingProducts}
                            />}
                            {itemType === 'brand' && <GlobalSearchV2 
                                performSearch={(term)=>{searchForBrand(term)}}
                                searchResults={brands}
                                resultDisplayField={'name'}
                                returnSelectedResult={(index)=>{setSelectedBrand(brands[index])}}
                                placeholderText={`Search for brand`}
                                searchInProgress={searchingBrands}
                            />}
                            {itemType === 'category' && <GlobalSearchV2 
                                performSearch={(term)=>{searchForCategory(term)}}
                                searchResults={categories}
                                resultDisplayField={'name'}
                                returnSelectedResult={(index)=>{setSelectedCategory(categories[index])}}
                                placeholderText={`Search for category`}
                                searchInProgress={searchingCategories}
                            />}
                            {itemType === 'page' && <GlobalSearchV2 
                                performSearch={(term)=>{searchForPages(term)}}
                                searchResults={pages}
                                resultDisplayField={'title'}
                                returnSelectedResult={(index)=>{setSelectedPage(pages[index])}}
                                placeholderText={`Search for page`}
                                searchInProgress={searchingForPages}
                            />}
                            {itemType === 'custom' && <input 
                                type="text" 
                                className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brandTitle && validationErrors.brandTitle === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: https://your-website.com/products/2785678" 
                                value={customMenuUrl}
                                onChange={(e)=>{setCustomMenuUrl(e.target.value)}} 
                            />}
                        </div>}

                        <div className=' w-full mb-3'>
                            <label className={`text-xs block mb-2 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                Parent Item 
                            </label>
                            <AutocompleteSelect 
                                placeholderText="Select an item" 
                                selectOptions={items[0].children}
                                titleField='name'
                                displayImage={true}
                                imageField='productImage'
                                returnFieldValue={(value) => {setParent(value.id)}}
                            />
                        </div>

                        <div className='w-full flex flex-row gap-x-2 mb-3 mt-3'>
                            <div className='w-full'>
                                <label className={`text-xs block mb-2 ${validationErrors.brandTitle && validationErrors.brandTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                    Menu item name
                                </label>
                                <input 
                                    type="text" 
                                    className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brandTitle && validationErrors.brandTitle === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: Red Trailer" 
                                    value={itemName}
                                    onChange={(e)=>{updateMenuTitle(e.target.value)}} 
                                />
                            </div>
                        </div>

                        {itemType !== 'title' && <div className='w-full flex flex-row gap-x-2 mb-3 mt-3'>
                            <div className='w-full'>
                                <label className={`text-xs block mb-2 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                    Slug
                                </label>
                                <input 
                                    type="text" 
                                    className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: red_trailer"
                                    value={itemSlug}
                                    onChange={(e)=>{}}
                                />
                            </div>
                        </div>}

                    </div>

                </div>
            </div>

            <div className="mt-4 w-full flex flex-row-reverse gap-x-3">
                <button
                    type="button"
                    className="inline-flex justify-center gap-x-2 items-center px-4 py-2 text-sm font-medium text-white bg-ink-navy transition duration-200 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={()=>{doAddItem()}}
                    disabled={adding}
                >
                    {adding ? <span className='flex items-center gap-x-2'><Spinner /> Adding menu item </span> : 'Add menu Item' }
                </button>

                <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 transition duration-200 text-sm font-medium text-gray-500 bg-transparent border border-transparent rounded-md hover:bg-blue-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                >
                    Cancel
                </button>
            </div>
        </>
    )
}

export default NewMenuItem