import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'; 
import HorizontalMenuIcon  from '../../assets/images/icons/horizontal-menu-icon.svg'
import { Link } from 'react-router-dom'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import CloseIcon from './icons/CloseIcon';
import { tableConfig } from '../../utils';
import ArrowIcon from './icons/ArrowIcon';
import ChevronIcon from './icons/ChevronIcon';

const DataTable = ({
    tableData, 
    tableHeaders, 
    columnWidths, 
    columnDataStyles, 
    allFields, 
    onSelectItems, 
    tableOptions,
    pagination,
    paginate,
    performColumnSearch,
    searchableColumns,
    tableName,
    sortingBy,
    updateSortBy,
    columnNameMap,
    currentSortingColumn,
    updateCurrentSortingColumn,
    sortOrder,
    updateSortOrder,
    bordered,
    expandedIndex,
    expansion,
    inheritHeight,
    selectAll
}) => {

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement)

    const [allItems, setAllItems] = useState(tableData)
    const [selectedItemsCount, setSelectedItemsCount] = useState(0)
    // const [selectedItems, setSelectedItems] = useState([])

    const storedTableFields = () => {
        const config = tableConfig()?.find((conf, confIndex)=>{
            return conf.table === tableName
        })
        return config?.fields
    }

    const [fields, setFields] = useState(storedTableFields() ? storedTableFields() : allFields)

    const [showSearchFor, setShowSearchFor] = useState(null);

    useEffect(() => {
        if(selectAll && selectAll === true) {
            toggleAllSelection()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectAll]);

    const toggleAllSelection = () => {
        let newItems = allItems
        newItems.forEach((singleItem, index) => {
            if (selectedItemsCount > 1) {
                singleItem.selected = false
            } else {
                singleItem.selected = true
            }
        })

        const itemsCount = newItems.filter((item) => {
            return item.selected;
        }).length

        setSelectedItemsCount(itemsCount)

        onSelectItems(itemsCount, newItems)
        setAllItems(newItems)
    }

    const toggleSelection = (itemIndex) => {
        let newItems = allItems
        newItems.forEach((singleItem, index) => {
            if (index === itemIndex ) {
                singleItem.selected = !singleItem.selected
            }
        })

        const itemsSelected = newItems.filter((item) => {
            return item.selected;
        })

        setSelectedItemsCount(itemsSelected.length)

        onSelectItems(itemsSelected.length, itemsSelected)
        setAllItems(newItems)
    }

    const fieldIsSelected = (fieldName) => {
        let isSelected = false
        fields?.forEach((field) => {
            if(field.name === fieldName && field.selected) {
                isSelected = true
            }
        })
        return isSelected
    }

    const [draggedOver, setDraggedOver] = useState('')
    const [activeDrag, setActiveDrag] = useState('')

    const allowDrop = (event) => {
        event.preventDefault();
    }

    const reSortData = () => {
        let newIndex = draggedOver
        let oldIndex = activeDrag

        if (!newIndex) {
            setActiveDrag('')
            setDraggedOver('')
        }

        if (newIndex >= tableData.length) {
            var k = newIndex - tableData.length + 1;
            while (k--) {
                tableData.push(undefined);
            }
        }
        tableData.splice(newIndex, 0, tableData.splice(oldIndex, 1)[0]);

        setActiveDrag('')
        setDraggedOver('')
        // return arr; // for testing
        // console.log('re-sorted......')
    }

    const updateTableConfig = () => {
        let configs = []
        if(tableConfig() !== null) {
            configs = tableConfig()
        }

        let indexOfExisting = null
        configs.map((conf, confIndex)=>{
            if(conf.table === tableName) {
                indexOfExisting = confIndex
            }
            return conf
        })

        const config = {
            table: tableName,
            fields: fields
        }

        if(indexOfExisting !== null) {
            configs[indexOfExisting] = config
        } else {
            configs.push(config)
        }

        localStorage.setItem("tableConfigs", JSON.stringify(configs));
    }

    const toggleFieldVisibility = (index) => {
        const tempFields = [...fields]
        const changed = tempFields.map((field, fieldIndex) => {
            if (fieldIndex === index) {
                field.selected = !field.selected
            }
            return field
        })

        setFields(changed)
        setTimeout(() => {
            updateTableConfig()
        }, 50);
    }

    // const lastPage = Math.ceil(pagination.totalRecords / pagination.perPage)

    const performRowAction = (index) => {
        if(!tableOptions.clickableRows || tableOptions.clickableRows === false) {
            return
        }
        tableOptions.rowAction(index)
    }

    const isSearchable = (column) => {
        let index = null
        const foundColumn = searchableColumns.find((col, columnIndex) => {
            if(col.column === column) {
                index=columnIndex
            }
            return col.column === column
        }) 

        return {
            searchable: foundColumn ? true : false, 
            index
        }
    }

    const changeSorting = (header) => {
        updateCurrentSortingColumn(header.column)
        updateSortBy(columnNameMap[header.column])
        if(sortOrder === 'asc') {
            updateSortOrder('desc')
        } else {
            updateSortOrder('asc')
        }
    }

    
    return (
        <Fragment>
            {/* Table */}
            {!tableData || tableData.length === 0 ? 
                <p className="p-5 w-full text-xs text-center bg-gray-100 text-gray-600 rounded-lg mt-8">Sorry, no data available at the moment</p>

                :
                <>
                <ul className="hidden lg:flex flex-row justify-between items-center w-full bg-gray-100 text-xs mt-5 px-8 py-4 font-sofia-pro text-ink-navy" style={{position: '',  zIndex: 99, width:'100%', maxWidth: '100%'}}>
                    {/* <li className="w-1/12" />  */}
                    {tableOptions.selectable && tableOptions.showCheckboxInHeader === true &&
                        <input type="checkbox" className="mr-2 -ml-5" onChange={()=>{toggleAllSelection()}} checked={tableData.length === selectedItemsCount} />
                    }
                    
                    {tableHeaders.map((header, headerIndex) => (
                        !header.forPopover && fieldIsSelected(header.columnDisplayName) && 
                        
                        <Fragment key={headerIndex}>
                            {showSearchFor === headerIndex && isSearchable(header.column)?.searchable === true ? 
                                <div className={`${columnWidths[header.column]} flex items-center gap-x-1`} style={{maxWidth: '80%'}}>
                                    <form onSubmit={(e)=>{
                                            e.preventDefault()
                                            performColumnSearch({term: e.target.value, column: header.column})
                                        }}
                                        className='relative w-max'
                                    >
                                        {searchableColumns[isSearchable(header.column)?.index]?.dataType === 'date' && 
                                            <input type='date' className='w-10/12 rounded text-xs px-2 py-1 border border-gray-300'/>
                                        } 
                                        {searchableColumns[isSearchable(header.column)?.index]?.dataType === 'text' && 
                                            <input type='text' placeholder={`search ${header.column}`} className='w-10/12 rounded text-xs px-2 py-1 border border-gray-300'/>
                                        } 
                                        {searchableColumns[isSearchable(header.column)?.index]?.dataType === 'select' && 
                                            <select className='w-10/12 rounded text-xs px-2 py-1 border border-gray-300'>
                                                <option>
                                                    search by {header.column}
                                                </option>
                                                {searchableColumns[isSearchable(header.column)?.index]?.options?.map((option, optionIndex)=>(
                                                    <option key={optionIndex} value={option}>{option}</option>
                                                ))}
                                            </select>
                                        } 
                                    </form>
                                    <button className='text-xs text-black' onClick={()=>{setShowSearchFor(null)}}>
                                        <CloseIcon classes='w-4 h-4' />
                                    </button>
                                </div>
                            : 
                                <li className={`${columnWidths[header.column]} flex flex-row items-center uppercase justify-between ml-2`}>
                                    <button onClick={()=>{setShowSearchFor(headerIndex)}} className="uppercase">{header.columnDisplayName}</button>
                                    {header.sortable && 
                                        <button onClick={()=>{changeSorting(header)}} className={`${currentSortingColumn===header.column ? 'text-ink-navy' : 'text-gray-400'}`}>
                                            <ArrowIcon className={`w-4 h-4 transform 
                                                ${((currentSortingColumn === header.column && sortOrder === 'asc') || (currentSortingColumn !== header.column)) && '-rotate-90'}
                                                ${currentSortingColumn === header.column && sortOrder === 'desc' && 'rotate-90'}
                                            `} />
                                        </button>
                                    }
                                </li> 
                            }
                        </Fragment>
                    ))}
                    <span className="absolute right-1">
                        <Popover className="relative">
                            <Popover.Button 
                                ref={setReferenceElement}
                            >
                                <img alt="" src={HorizontalMenuIcon} className="transform rotate-90 w-4" />
                            </Popover.Button>

                            <Popover.Panel 
                                ref={setPopperElement}
                                style={styles.popper}
                                {...attributes.popper} 
                                className="absolute z-10"
                            >
                                <div className="bg-white p-4 shadow-md border rounded border-gray-100 mt-3">
                                    {/* {allFields.map((field, fieldIndex) => )} */}
                                    <p className="font-medium text-gray-400 text-sm pb-2 mb-2 border-b border-gray-200 text-center">All Fields</p>

                                    {fields?.map((field, fieldIndex) => (
                                        <div className="flex flex-row justify-between w-36 items-center my-2" key={fieldIndex}>
                                            <p className="text-xs">{field.name} </p>
                                            <input type="checkbox" checked={field.selected} onChange={()=>{toggleFieldVisibility(fieldIndex)}} className="mr-2" />
                                        </div>
                                    ))}
                                </div>

                            </Popover.Panel>
                        </Popover>
                    </span>
                </ul>
                <div className="w-full" style={{
                    position: 'relative', 
                    height: inheritHeight && inheritHeight === true ? 'inherit' : '700px', 
                    overflowY: inheritHeight && inheritHeight === true ? 'hidden' : 'scroll', transform: 'scale(1)'
                }}>
                {/* <div className="w-full scrollbar-hide" style={{position: 'relative', height:'700px', overflowY: 'scroll', transform: 'scale(1)'}}> */}
                    {/* table header */}

                    {/* Table rows */}
                    {/* <div style={{paddingTop: '70px'}} /> */}
                    {allItems.map((data, dataIndex) => (
                        // {tableOptions.sortable && tableOp}
                        tableOptions.sortable && tableOptions.sortable === true ? 
                        <Fragment key={dataIndex}>
                            <div 
                                id={dataIndex} 
                                onDrop={(e)=>{reSortData(e)}} 
                                onDragOver={(e)=>{
                                    setDraggedOver(dataIndex)
                                    allowDrop(e)
                                }}
                                className={`py-1 w-full rounded transition duration-300 ${draggedOver === dataIndex ? 'h-12 border border-dashed border-blue-300 bg-blue-200 bg-opacity-20' : ''}`}
                            />
                            <ul
                                draggable="true" 
                                className={
                                    `block lg:flex flex-row items-center justify-between w-full bg-opacity-40 text-xs py-2 px-1 font-sofia-pro text-gray-500 relative 
                                    ${data.selected ? 'bg-blue-200' : ''}
                                    ${activeDrag === dataIndex ? 'bg-blue-100' : ''}
                                    `} 
                                // key={dataIndex}
                                onDragStart={()=>{setActiveDrag(dataIndex)}}
                                onDragEnd={()=>{
                                    // setActiveDrag('')
                                    // setDraggedOver('')
                                }}
                            >
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    className={`cursor-grab h-5 w-5 absolute -left-5 top-6 text-gray-200 hover:text-gray-400 transition duration-200 ${activeDrag === dataIndex ? 'text-blue-400' : ''}`} 
                                    fill="none" viewBox="0 0 24 24" 
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
                                </svg>
                                {tableOptions.selectable ? <input type="checkbox" onChange={()=>toggleSelection(dataIndex)} checked={data.selected} className="mr-2" /> : <span className="inline-block mr-5" />}
                                {tableHeaders.map((header, headerIndex) => (
                                    !header.forPopover && fieldIsSelected(header.columnDisplayName) &&                                  
                                    <li key={headerIndex} className={`${columnWidths[header.column]} flex flex-row items-center text-sm lg:text-xs my-2`} >
                                        <span className='text-gray-400 inline lg:hidden mr-2'>{header.columnDisplayName}: </span>
                                        <span className={columnDataStyles[header.column] && columnDataStyles[header.column].isConditional ? columnDataStyles[header.column].conditionals[data[header.column]] : columnDataStyles[header.column]}>
                                            {header.columnDataType === 'image' &&
                                            <img src={data[header.column]} alt="" className='h-24' />
                                            }

                                            {header.columnDataType === 'link' &&
                                            <Link to={data[header.column]} alt="" className="text-ink-navy font-medium"> {data[header.column]} </Link>
                                            }

                                            {header.columnDataType === 'text' &&
                                            <div> {data[header.column]} </div>
                                            }

                                            {header.columnDataType === 'JSX' &&
                                            <div> {data[header.column]} </div>
                                            }

                                            {header.columnDataType === 'popoverTrigger' &&
                                            <button> {data[header.column]} </button>
                                            }
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </Fragment>
                        :
                        <ul 
                            className={`
                                ${tableOptions.clickableRows ? 'cursor-pointer' : ''} block w-full bg-opacity-40 text-xs py-2 
                                ${tableOptions.selectable ? 'pl-10' : 'pl-4'} font-sofia-pro text-gray-500 transition duration-200relative 
                                ${data.selected ? 'bg-blue-200' : ''} 
                                ${tableOptions.expandable && tableOptions.expandable === true ? 'cursor-pointer transition duration-200 hover:bg-gray-100 hover:bg-opacity-50' : ''}
                                ${expandedIndex!== null && expandedIndex === dataIndex.toString() ? 'border-blue-700' : 'border-gray-200'} 
                                ${bordered && bordered===true && 'border-b border-gray-300'}
                        `} key={dataIndex}>
                        <div className='lg:flex flex-row items-center w-full relative'>
                        {tableOptions.selectable ? <input type="checkbox" onChange={()=>toggleSelection(dataIndex)} checked={data.selected} className="absolute -left-7" /> : <span className="inline-block mr-5" />}
                            <div onClick={()=>{performRowAction(dataIndex)}} className='lg:flex flex-row items-center w-full relative hover:bg-blue-100 hover:bg-opacity-60'>
                                
                                {tableHeaders.map((header, headerIndex) => (
                                    !header.forPopover && fieldIsSelected(header.columnDisplayName) &&                                  
                                    <li key={headerIndex} className={`${columnWidths[header.column]} flex flex-row items-center text-sm lg:text-xs my-2`} >
                                        <span className='text-gray-400 inline lg:hidden mr-2'>{header.columnDisplayName}: </span>
                                        <span className={columnDataStyles[header.column] && columnDataStyles[header.column].isConditional ? columnDataStyles[header.column].conditionals[data[header.column]] : columnDataStyles[header.column]}>
                                            {header.columnDataType === 'image' &&
                                            <img src={data[header.column]} alt="" />
                                            }

                                            {header.columnDataType === 'link' &&
                                            <Link to={data[header.column]} alt="" className="text-ink-navy font-medium"> {data[header.column]} </Link>
                                            }

                                            {header.columnDataType === 'text' &&
                                            <div> {data[header.column]} </div>
                                            }

                                            {header.columnDataType === 'JSX' &&
                                            <div> {data[header.column]} </div>
                                            }

                                            {header.columnDataType === 'popoverTrigger' &&
                                            <button> {data[header.column]} </button>
                                            }
                                        </span>
                                    </li>
                                ))}
                                {tableOptions.expandable && tableOptions.expandable === true && 
                                    <ChevronIcon className={`absolute right-2 top-6 w-4 h-4 transform transition duration-200 ${expandedIndex === dataIndex.toString() ? 'rotate-180 text-blue-700' : ''}`} 
                                />}
                            </div>
                            </div>
                            {/* Expansion */}
                            {expandedIndex!== null && expandedIndex === dataIndex.toString() &&
                                <>
                                    {expansion}
                                </>
                            }
                        </ul>
                    ))}


                    {/* Pagination */}
                </div>

                {pagination && 
                    <div className='flex flex-row justify-between w-full items-center mt-5'>
                        <div className='flex flex-row gap-x-2 items-center'>
                            <div className='px-6 py-2 border rounded border-gray-400 w-24'>
                                <select value={pagination.perPage} onChange={(e)=>paginate({...{pagination}.pagination, ...{perPage: e.target.value}})} className='default focus:outline-none w-full text-gray-400'>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={150}>150</option>
                                </select>
                            </div>
                            <span className='font-sofia-pro text-gray-400 text-xs'>
                                Showing 1-{
                                    pagination.page *
                                    pagination.totalRecords > pagination.perPage 
                                    ? 
                                    pagination.perPage 
                                    : 
                                    pagination.totalRecords} of {pagination.totalRecords} entries
                            </span>
                        </div>

                        <div className='flex flex-row gap-x-2'>
                            <button className='p-3 border rounded border-gray-400 text-gray-400 transition duration-200 hover:border-blue-400 hover:text-blue-400' onClick={()=>paginate({...{pagination}.pagination, ...{page: 1}})}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                                </svg>
                            </button>
                            <button disabled={pagination.page === 1} className='p-3 border rounded border-gray-400 text-gray-400 transition duration-200 hover:border-blue-400 hover:text-blue-400' onClick={()=>paginate({...{pagination}.pagination, ...{page: pagination.page - 1 }})}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                            <button disabled={pagination.page === Math.ceil(pagination.totalRecords / pagination.perPage)} className='p-3 border rounded border-gray-400 text-gray-400 transition duration-200 hover:border-blue-400 hover:text-blue-400' onClick={()=>paginate({...{pagination}.pagination, ...{page: pagination.page + 1}})}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                            <button className='p-3 border rounded border-gray-400 text-gray-400 transition duration-200 hover:border-blue-400 hover:text-blue-400' onClick={()=>paginate({...{pagination}.pagination, ...{page: Math.ceil(pagination.totalRecords / pagination.perPage)}})}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                }
                </>
            }
        </Fragment>
    )
}

DataTable.propTypes = {
    tableData: PropTypes.array.isRequired,
    tableHeaders: PropTypes.array.isRequired,
    allFields: PropTypes.array.isRequired,
    columnWidths: PropTypes.object.isRequired,
    columnDataStyles: PropTypes.object,
    tableOptions: PropTypes.object,
    pagination: PropTypes.object
    // element: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default DataTable
