import React, { useState, Fragment } from 'react'
import DateRangeStepper from '../../../../components/elements/DateRangeStepper'
import ProductImage  from '../../../../assets/images/bike-image.png'
import AutocompleteSelect from '../../../../components/elements/AutocompleteSelect'

const VoucherCode = () => {
    const tabs = [
        {
            tabId: "settings",
            tabLabel: "Settings",
            // count: rules.length
        },
        {
            tabId: "usage",
            tabLabel: "Usage",
            // count: couriers.length
        }
    ]

    const [activeTab, setActiveTab] = useState(tabs[0].tabId)

    let changeActiveTab = (tab) => {
        setActiveTab(tab.tabId)
    }

    const voucherCodeDetails = {
        code: "KLARNA_FIXED",
        startDate: "2021-04-08",
        expiryDate: "2021-10-07",
        discountPercentage: 20,
        discountFixed: 10,
        daysValid: 3,
        createdBy: "Admin",
        uses: 1,
        actions:''
    }

    let products = [
        {
            productId: "11143",
            productImage: ProductImage,
            productName: "Rfr pedals flat salmon sal",
            sampleSku: "14156",
            brand: "RFR",
            year: 2020,
            stock: 1,
            status: "live",
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            stockIncrease: true,
            actions: [],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            selected: false,
            featured: true,
            visibleOn: ""
        },
        {
            productId: "11144",
            productName: "Bella 650A/19 - 26",
            sampleSku: "6307190",
            brand: "British Eagle",
            status: "live",
            stock: 3,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: true,
            year: 2018,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11145",
            productName: "403 Granite V2",
            sampleSku: "W403V2-1",
            brand: "Whyte",
            status: "live",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11146",
            productName: "Cube acid 240 disc reefblue/kiwi/red…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11147",
            productName: "Cube cubie 120 green/blue 2020",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11148",
            productName: "Cube cubie 120 walk action team bl…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11149",
            productName: "Trek District+ 1 M Gy 300Wh",
            sampleSku: "32320024",
            brand: "RFR",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11143",
            productName: "Rfr pedals flat salmon sal",
            sampleSku: "14156",
            brand: "RFR",
            status: "live",
            stock: 1,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: true,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11144",
            productName: "Bella 650A/19 - 26",
            sampleSku: "6307190",
            brand: "British Eagle",
            status: "live",
            stock: 3,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: true,
            year: 2018,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11145",
            productName: "403 Granite V2",
            sampleSku: "W403V2-1",
            brand: "Whyte",
            status: "live",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11146",
            productName: "Cube acid 240 disc reefblue/kiwi/red…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11147",
            productName: "Cube cubie 120 green/blue 2020",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11148",
            productName: "Cube cubie 120 walk action team bl…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
        {
            productId: "11149",
            productName: "Trek District+ 1 M Gy 300Wh",
            sampleSku: "32320024",
            brand: "RFR",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false
        },
    ]

    const [reuse, setReuse] = useState(false)
    return (
        <Fragment>
            {/* Tabs */}
            <div className="flex flex-row gap-x-2 border-b border-gray-300 mb-10">
                {tabs.slice(0, 6).map((tab, tabIndex) =>(
                    <button 
                        key={tabIndex} 
                        className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                        onClick={()=>{changeActiveTab(tab)}}>
                        {tab.tabLabel} 
                    </button>
                ))}
            </div>
            {/* Tab area */}
            <div className="w-full">
                <h3 className='font-medium text-md text-ink-navy mb-4'>Voucher Code Details</h3>

                <div className='w-full my-5'>
                    <label className="block text-xs text-gray-400 my-2">Voucher Code *</label>
                    <input typ="text" value={voucherCodeDetails.code} className='w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500'/>
                </div>
                <div className='w-full block md:flex flex-row gap-x-8 my-5'>
                    <div className='w-full md:w-1/2'>
                        <label className="block text-xs text-gray-400 my-2">Percentage Discount </label>
                        <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={voucherCodeDetails.discountPercentage} />
                    </div>
                    <div className='w-full md:w-1/2'>
                        <label className="block text-xs text-gray-400 my-2">Fixed Amount</label>
                        <input type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={voucherCodeDetails.amountFixed} />
                    </div>
                </div>

                <div className='w-full block md:flex flex-row gap-x-8 my-5'>
                    <div className='w-full md:w-1/2'>
                        <label className="block text-xs text-gray-400 my-2">Number of Days Valid </label>
                        <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={voucherCodeDetails.daysValid} />
                    </div>
                    <div className='w-full md:w-1/2'>
                        <label className="block text-xs text-gray-400 my-2">Valid Dates</label>
                        <DateRangeStepper numberOfDays={voucherCodeDetails.daysValid} />
                    </div>
                </div>

                <div className='w-full block md:flex flex-row gap-x-8 my-5'>
                    <div className='w-full md:w-1/2'>
                        <label className="block text-xs text-gray-400 my-2">Restrict to group of products </label>
                        {/* <div className='select-wrapper'>
                            <select className='w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500'>
                                <option>All products</option>
                            </select>
                        </div> */}
                        <AutocompleteSelect 
                            placeholderText="Find a product..." 
                            selectOptions={products}
                            titleField='productName'
                            displayImage={true}
                            imageField='productImage'
                        />

                    </div>
                    <div className='w-full md:w-1/2'>
                        <label className="block text-xs text-white my-2">Manage groups</label>
                        <button className='px-5 py-3 border border-ink-navy text-ink-navy rounded transition duration-200 text-xs'>
                            Manage Groups
                        </button>
                    </div>
                </div>

                <div className='w-full block md:flex flex-row gap-x-4 my-5 items-center'>
                    <label className="text-xs text-gray-600 mb-3">
                        Reuse 
                        <input type="checkbox" checked={reuse} onChange={setReuse} className="mt-3 ml-3" />
                    </label>
                    <p className='text-xs text-gray-400'>Allow multiple uses of this voucher code</p>
                </div>

                <div className='flex flex-row-reverse gap-x-3'>
                    <button className='px-5 py-3 border border-blue-700 bg-blue-700 text-white rounded transition duration-200 text-xs'>
                        Save
                    </button>
                    <button className='px-5 py-3 border border-ink-navy text-ink-navy rounded transition duration-200 text-xs'>
                        Cancel
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

export default VoucherCode
