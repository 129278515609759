import React, { Fragment, useState, useEffect } from 'react'
// import Layout from '../../../components/layout/Layout'
import SearchIcon from '../../../assets/images/icons/search.svg'
// import AlertIcon from '../../../assets/images/icons/alert.svg'
// import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import HorizontalMenuIcon  from '../../../assets/images/icons/horizontal-menu-icon.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
// import { Link } from 'react-router-dom'
import { convertCamelCase } from '../../../utils'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import DataTable from '../../../components/elements/DataTable'
import { useCookies } from 'react-cookie';
import axios from "axios"
import Spinner from '../../../components/elements/icons/Spinner';
import UserIcon from '../../../assets/images/icons/user-icon.svg'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DispatchUrgency } from '../../../components/elements/DispatchUrgency'
import Filters from '../../../components/elements/Filters'
import OrderStatus from '../../../components/elements/OrderStatus'
import queryString from 'query-string'

const AllOrders = () => {
    const history = useHistory();
    const location = useLocation()
    const [allOrders, setAllOrders] = useState([])
    // const searchParams = new URLSearchParams(document.location.search)
    const params = useParams()


    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])

    const [ pagination, setPagination ] = useState({ 
        page: 1, 
        perPage: 25, 
        totalRecords: 0,
    })

    const [ filter, setFilter ] = useState(null)
    const [ statusCounts, setStatusCounts ] = useState(null)
    // const [ordersFetched, setOrdersFetched] = useState(false);

    const tabs = [
        {
            tabId: "all",
            tabLabel: "All",
            all: 2375
        },
        {
            tabId: "paid-ready-to-proccess",
            tabLabel: "Paid and Ready to Process",
            count: 22
        },
        {
            tabId: "payment-pending",
            tabLabel: "Payment Pending",
            count: 18
        },
        {
            tabId: "dispatched",
            tabLabel: "Dispatched",
            count: 208
        },
        {
            tabId: "complete",
            tabLabel: "Complete",
            count: 987
        },
        {
            tabId: "returned",
            tabLabel: "Returned",
            count: 870
        },
        {
            tabId: "cancelled",
            tabLabel: "Cancelled",
            count: 630
        },
        {
            tabId: "deleted",
            tabLabel: "Deleted",
            count: 90
        },
        {
            tabId: "awaiting-payment",
            tabLabel: "Awaiting Payment",
            count: 95
        },
    ]

    // const [activeTab, setActiveTab] = useState(tabs[0].tabId)

    const findTab = () =>  {
        const activeTab = tabs.find((tab)=> {return tab.tabId === params.status})

        if(params.status === 'all') {
            return ''
        } else {
            return activeTab.tabLabel
        }
        
    }

    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filterString, setFilterString] = useState('');
    
    const fetchOrders = async  () => {
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        // if(ordersFetched) return
        // setOrdersFetched(true)
        try { 
            let response = null 
            setProcessing(true)
            if(filter && filter !== '' ) {
                response = await axios.get(`/api/v1/admin/orders/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[sort]=${sortBy}&page[order]=${sortOrder}&filter[${filter.key}]=${filter.value}&${filterString}`, { headers })            
            } else if(findTab() !== '' ) {
                response = await axios.get(`/api/v1/admin/orders/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[sort]=${sortBy}&page[order]=${sortOrder}&&filter[status]=${findTab()}&${filterString}`, { headers })            
            } else {
                response = await axios.get(`/api/v1/admin/orders/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[sort]=${sortBy}&page[order]=${sortOrder}&${filterString}`, { headers })            

            }
            // console.log(response)
            let apiOrders = response.data.data
            setAllOrders(apiOrders)

            setPagination({
                page: pagination.page,
                perPage: pagination.perPage,
                totalRecords: response.data.total_records,
                nextPage: response.data.next,
                lastPage: response.data.last
            })

            fetchOrderStatusCounts()

        } catch (error) {
            console.error(error.response.data)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }
    }

    const fetchOrderStatusCounts = async  () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        try { 
            const response = await axios.get(`/api/v1/admin/orders/status_count`, { headers })            
            setStatusCounts(response.data.data)
            // fetchCustomers()

            // fetchCustomers()
            setProcessing(false)

        } catch (error) {
            console.error(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }
    }

    const { search } = useLocation()
    const queryValues = queryString.parse(search)

    useEffect(() => {
        if(queryValues.filter_string && queryValues.filter_string!==''){
            console.log(queryValues.filter_string)
        }

        fetchOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies.accessToken, cookies.tokenType, pagination.page, pagination.perPage, filter, history, location.pathname, params.status, sortBy, sortOrder, filterString])

    const tableOptions = {
        selectable: true,
        clickableRows: true,
        rowAction: (value) => { goToOrder(allOrders[value].order_uuid) }
    }

    const goToOrder = (uuid) => {
        history.push(`/admin/orders/order/${uuid}`)
    }

    const columnWidths = {
        id: 'w-full lg:w-1/12',
        customer: 'w-full lg:w-2/12',
        value: 'w-full lg:w-1/12',
        date: 'w-full lg:w-2/12',
        // guaranteedDispatch: 'w-full lg:w-2/12',
        urgency: 'w-full lg:w-1/12',
        deadline: 'w-full lg:w-2/12',
        fulfillment: 'w-full lg:w-1/12',
        // device: 'w-full lg:w-1/12',
        status: 'w-full lg:w-2/12',
        // actions: 'w-full lg:w-1/12',
    }

    const columnDataStyles = {}

    const searchableTableColumns = [
        {
            column: 'date',
            dataType: 'date'
        },
        {
            column: 'deadline',
            dataType: 'date'
        },
        {
            column: 'urgency',
            dataType: 'select',
            options: [
                'priority', 'urgent', 'waiting'
            ]
        },
        {
            column: 'status',
            dataType: 'select',
            options: [
                'Awaiting Payment', 'Payment Pending', 'Dispatched', 'Paid and Ready to Process', 'Payment pending', 'Processing', 'Deleted', 'Cancelled' , 'Complete', 'Returned', 'Ready for Collection'
            ]
        },
        {
            column: 'customer',
            dataType: 'text',
        }
    ]

    const columnNameMap = {
        id: 'id',
        customer: 'customer_id',
        value: 'total',
        date: 'created_at',
        urgency: 'dispatch_urgency_status',
    }

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key
            if(key === 'actions' || key === 'deadline' || key === 'fulfillment') {
                sortable=false
            }
            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true

            if(index > 10) {
                fieldSelected = false
            }
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });
        return {headers, fields}
    }

    const OrderLink = ({orderId, uuid}) => {
        return (
            <p className='font-medium text-ink-navy'>{orderId}</p>
        )
    }

    const OrderCustomer = ({id, customerName, customerImage}) => {
        return (
            // <Link to={`/admin/customers/all-customers/${id}`}>
            <>
                {/* <img src={customerImage} alt="" className="rounded-full w-5 h-5 inline-block mr-2" /> */}
                {customerName}
            </>
            // </Link>
        )
    }

    const OrderValue = ({value}) => {
        return (
            <span>&pound;{value.toLocaleString()}</span>
        )
    }

    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((item, itemIndex) => {

            const itemObject = {
                id: <OrderLink orderId={item.id} uuid={item.order_uuid} />,
                customer: <OrderCustomer id={item.customer_id} customerName={`${item.customer.user.first_name} ${item.customer.user.last_name}` } customerImage={UserIcon} />,
                value: <OrderValue value={+item.total} />,
                // date: formatDate(item.created_at),
                date: new Date(item.created_at).toDateString(),
                urgency: <DispatchUrgency status={item?.dispatch_urgency_status} />,
                // guaranteedDispatch: item?.shipping_variant?.guaranteed_dispatch_date,
                deadline: item?.shipping_variant?.estimated_delivery_date,
                // payment: item.payment,
                // date: new Date(item.created_at).toDateString(),
                // shipping: <OrderValue value={+item.shipping_charge} />,
                fulfillment: item?.shipping_variant?.display_name,
                status: <OrderStatus status={item.status} />,
                // actions: <ActionsButton />
            }
            
            finalResult.push(itemObject)
        })
        return finalResult
    }

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const bulkActions = [
        {
            label: "Mark as dispatched",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "Print selected orders",
            icon: null,
            link: "/",
            action: null
        },
    ]

     const filters = [
        {
            optionName: "Customer",
            value: 'customer_id',
            type: 'search',
            searchConfig: {
                searchPlaceholder: "Search Customer",
                searchReference: 'customers'
            }
        },
        {
            optionName: "Order Value",
            value: 'total',
            type: 'range'
        },
        {
            optionName: "Order Date",
            value: 'created_at',
            type: 'date-range'
        },
        {
            optionName: "Dispatch Urgency Status",
            value: 'dispatch_urgency_status',
            type: 'select',
            options: [
                {
                    name: "Priority", 
                    value: "priority", 
                    selected: false
                },
                {name: "Urgent", value: "urgent", selected: false},
                {name: "Waiting", value: "waiting", selected: false},

            ]
        }, 
    ]

    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0)

    const captureFilters = (filters) => {
        setSelectedFiltersCount(filters.length)
        let filterString = ''
        filters.forEach(element => {
            filterString += `filter[${element.filter}]${element.operator}${element.term}`
        });
        
        history.push({
            pathname: location.pathname,
            search: `filter_string=${filterString}`
        })

        setFilterString(filterString)
    }

    let changeActiveTab = (tab) => {
        history.push({
            pathname: `/admin/orders/all-orders/${tab.tabId}`
        })
    }

    const [selectedOrdersCount, setSelectedOrdersCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedOrdersCount(count)
    }
    const getTabsDropdownItems = (startIndex) => {
        let items = []
        tabs.forEach((tab, tabIndex) => {
            if(tabIndex >= startIndex) {
                items.push({
                    label: `${tab.tabLabel}`,
                    icon: null,
                    link: "/",
                    // action: ()=>{window.location.href=`admin/orders/all-orders/${tab.tabId}`},
                    action: ()=>{
                        history.push({
                            pathname: `/admin/orders/all-orders/${tab.tabId}`
                        })
                    },
                    fullObject: tab
                })
            }
        })
        return items
    }

    const [selected, setSelected] = useState(tabs[0].tabId)

    const findStatusCount = (label) =>{
        if(!statusCounts || statusCounts.length === 0) {
            return 0
        }
        const statusFound = statusCounts.find((status) => {
            return status.status === label
        })

        return statusFound.count
    }

    const statusTotal = () => {
        if(!statusCounts || statusCounts.length === 0) {
            return 0
        }
        const total = statusCounts.reduce((a, b) => ({count: a.count + b.count}))

        return total.count
    }

    const [currentSortingColumn, setCurrentSortingColumn] = useState('');

    return (
        <Fragment>
            {/* Tabs */}
            <div className="hidden w-full lg:flex flex-row gap-x-2 border-b border-gray-300">
                {/* {tabs.map((tab, tabIndex) =>( */}
                {tabs.slice(0, 3).map((tab, tabIndex) =>(
                    <button 
                        key={tabIndex} 
                        className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${params.status === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                        onClick={()=>{changeActiveTab(tab)}}>
                        {tab.tabLabel} 
                            <span className="rounded-full px-2 py-1 bg-ink-navy text-white ml-2">
                                {/* {tab.tabLabel === 'All' ? allOrders.length :
                                    allOrders.filter((order) => { return order.status === tab.tabLabel }).length} */}
                                {tab.tabLabel === 'All' ? statusTotal() : findStatusCount(tab.tabLabel) }
                            </span>
                    </button>
                ))}
                <DropdownMenu 
                    buttonIcon={HorizontalMenuIcon} 
                    buttonType='icon' 
                    buttonText="" 
                    menuItems={getTabsDropdownItems(4)} 
                    // itemAction={changeActiveTab}
                    iconClasses="w-5" 
                    buttonClasses="text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200 bg-gray-100" />
            </div>

            <div className="w-full lg:hidden flex-row items-center justify-between mt-5">
                <label className="text-gray-400 text-xs mb-2 block mt-2">Select Category</label>
                <div className="">
                    <Listbox value={selected} onChange={setSelected}>
                        <div className="relative mt-1">
                        <Listbox.Button className={`relative w-full text-left text-xs rounded border border-gray-300 text-ink-navy font-medium font-sofia-pro inline-block px-3 py-2 transition duration-200`}>
                            <span className="block truncate text-sm lg:text-xs">{selected}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                                className="w-4 h-4 text-gray-400"
                                aria-hidden="true"
                            />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {tabs.map((option, optionIndex) => (
                                <Listbox.Option
                                    key={optionIndex}
                                    className={({ active }) =>
                                        `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                            cursor-default select-none relative py-2 pl-10 pr-4`
                                    }
                                    value={option.tabLabel}
                                    
                                    >
                                    {({ selected, active }) => (
                                        <>
                                        <span
                                            className={`${
                                            selected ? 'font-medium' : 'font-normal'
                                            } block truncate text-sm`}
                                            onClick={()=>{changeActiveTab(option)}}
                                        >
                                            {option.tabLabel}
                                        </span>
                                        {selected ? (
                                            <span
                                            className={`${
                                                active ? 'text-amber-600' : 'text-amber-600'
                                            }
                                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                            >
                                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                            </Listbox.Options>
                        </Transition>
                        </div>
                    </Listbox>
                </div>
            </div>

            {/* Tab area */}
            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between">
                    <div className="flex flex-row justify-between items-center w-full mb-4 lg:mb-0 mt-5 mb:mt-0">
                        {/* <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full lg:w-10/12 mr-1">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search orders" />
                        </div> */}
                        <div className="relative">
                            <div className="flex flex-row gap-x-2">
                                <button 
                                    className={`rounded py-3 px-4 text-xs w-52 flex flex-row items-center border ${filtersActive ? 'text-gray-800 border-gray-800' : 'text-gray-400 border-gray-200'}`} 
                                    onClick={()=>{toggleFilters()}} 
                                >
                                    {/* <img src={FilterIcon} alt="search" className="w-4 mr-2" /> <span className='hidden md:inline'>Advanced Filters</span> {selectedFiltersCount > 0 && <span className={`text-xs text-white rounded px-1 ml-1 mr-3 ${filtersActive ? 'bg-ink-navy' : 'bg-gray-400'}`}>{selectedFiltersCount}</span>} */}
                                    <img src={SearchIcon} alt="search" className={`w-4 -mt-1 mr-3 ${filtersActive ? 'opacity-90' : 'opacity-30'}`} /> <span className='hidden md:inline'>Filters</span> {selectedFiltersCount > 0 && <span className={`text-xs text-white rounded px-1 ml-1 mr-3 ${filtersActive ? 'bg-ink-navy' : 'bg-gray-400'}`}>{selectedFiltersCount}</span>}
                                </button>
                                {/* <button className="w-full lg:w-max">
                                    <img src={AlertIcon} alt="search" className="w-5" />
                                </button> */}
                            </div>
                            {/* Filters popover */}
                            {filtersActive && 
                                <Filters
                                    closeFilters={()=>{setFiltersActive(false)}} 
                                    allFilters={filters} 
                                    clearFilters={()=>{
                                        setFilterString('')
                                        setSelectedFiltersCount(0)
                                    }}
                                    // selectFilterS={(filter)=>{toggleFilterSelection(filter.optionIndex, filter.filterIndex)}}
                                    selectFilters={(filters)=>{captureFilters(filters)}}
                                />
                            }
                            
                        </div>
                    </div>
                    {/* </div> */}
                    <div className="flex flex-row-reverse gap-x-2 w-full items-center">
                         <button className="rounded border-gray-800 py-3 px-4 text-xs mt-3 text-gray-800 flex flex-row items-center border w-full lg:w-max">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden xl:inline" /> Export
                        </button>
                        {selectedOrdersCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex mt-3 justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                    </div>
                </div>

                <div>
                    {!processing && error === '' ?
                        <DataTable
                            tableHeaders={tableHeadersFields(cleanUpData(allOrders)[0]).headers} 
                            tableData={cleanUpData(allOrders)} 
                            columnWidths={columnWidths}
                            columnDataStyles={columnDataStyles}
                            allFields={tableHeadersFields(cleanUpData(allOrders)[0]).fields}
                            onSelectItems={getSelectionCount}
                            tableOptions={tableOptions}
                            pagination={pagination}
                            paginate={setPagination}
                            searchableColumns={searchableTableColumns}
                            tableName={`allOrders`}
                            sortingBy={sortBy}
                            columnNameMap={columnNameMap}
                            currentSortingColumn={currentSortingColumn}
                            updateCurrentSortingColumn={(columnName)=>{setCurrentSortingColumn(columnName)}}
                            updateSortBy={(field)=>{setSortBy(field)}}
                            sortOrder={sortOrder}
                            updateSortOrder={(order)=>{setSortOrder(order)}}
                        />
                      :
                        <div className='w-full flex flex-col items-center justify-center mt-10'>
                            <Spinner />
                            <p className='text-sm text-gray-500 mt-5'>Fetching orders...</p>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default AllOrders
