import React, { useState, Fragment } from 'react'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import TimerIcon from '../../../assets/images/icons/timer-icon.svg'
import MaintenanceIcon from '../../../assets/images/icons/maintenance-icon.svg'
import HorizontalMenuIcon  from '../../../assets/images/icons/horizontal-menu-icon.svg'
import SearchIcon from '../../../assets/images/icons/search.svg'
import DataTable from '../../../components/elements/DataTable'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

export const bookings = [
    {
        jobNo: "111123",
        customerName: "Mary Hare",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2021-04-08",
        workshop: "Clevedon",
        statusOfProduct: "Awaiting Check-in",
        status: "pending",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            },
        ]
    },
    {
        jobNo: "111124",
        customerName: "Bella Alubo",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2021-04-07",
        workshop: "Clevedon",
        statusOfProduct: "Awaiting Check-in",
        status: "pending",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            },
        ]
    },
    {
        jobNo: "111125",
        customerName: "Granit Xhaka",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2021-04-07",
        workshop: "Clevedon",
        statusOfProduct: "Awaiting Check-in",
        status: "paused",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            },
        ]
    },
    {
        jobNo: "111126",
        customerName: "Reuben Neves",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2021-12-10",
        workshop: "Clevedon",
        statusOfProduct: "Awaiting Check-in",
        status: "complete",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            },
        ]
    },
    {
        jobNo: "111127",
        customerName: "Max Payne",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2021-12-27",
        workshop: "Clevedon",
        statusOfProduct: "Awaiting Check-in",
        status: "paused",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            },
        ]
    },
    {
        jobNo: "111128",
        customerName: "Terry Very Merry",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2021-08-16",
        workshop: "Bristol",
        statusOfProduct: "Awaiting Check-in",
        status: "pending",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            }
        ]
    },
    {
        jobNo: "111129",
        customerName: "Mary Jayne",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2021-08-16",
        workshop: "Clevedon",
        statusOfProduct: "Awaiting Check-in",
        status: "cancelled",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            },
        ]
    },
    {
        jobNo: "111129",
        customerName: "Olegunnar Solskjaer",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2020-08-22",
        workshop: "Bristol",
        statusOfProduct: "Awaiting Check-in",
        status: "pending",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            },
        ]
    },
    {
        jobNo: "111129",
        customerName: "Podolski Alfamitano",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2021-01-14",
        workshop: "Bristol",
        statusOfProduct: "Awaiting Check-in",
        status: "complete",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            },
        ]
    },
    {
        jobNo: "111129",
        customerName: "Brandy Candy",
        customerImage: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
        date: "2021-01-14",
        workshop: "Bristol",
        statusOfProduct: "Awaiting Check-in",
        status: "complete",
        actions: [
            {
                label: "Change Status",
                icon: null,
                link: "/",
                action: null
            },
            {
                label: "delete",
                icon: null,
                link: "/",
                action: null
            },
        ]
    },
]
const AllBookings = () => {
    const [selectedBookingsCount, setSelectedBookingsCount] = useState(0)

    const tabs = [
        {
            tabId: "all",
            tabLabel: "All",
            all: 2375
        },
        {
            tabId: "pending",
            tabLabel: "Pending",
            count: 95
        },
        {
            tabId: "paused",
            tabLabel: "Paused",
            count: 22
        },
        {
            tabId: "complete",
            tabLabel: "Complete",
            count: 18
        },
        {
            tabId: "closed",
            tabLabel: "Closed",
            count: 208
        },
        {
            tabId: "cancelled",
            tabLabel: "Cancelled",
            count: 630
        }
    ]
    const [activeTab, setActiveTab] = useState(tabs[0].tabId)

    const [showTable, setShowTable] = useState(true)
    const [activeBookings, setActiveBookings] = useState(bookings)

    let changeActiveTab = (tab) => {
        setShowTable(false)
        if (tab.tabId === 'all') {
            setActiveBookings(bookings)
        } else {
            const filtered = bookings.filter((booking) => {
                return booking.status === tab.tabId;
            })
            // console.log(filtered)
            setActiveBookings(filtered)
        }
        setActiveTab(tab.tabId)
        setTimeout(() => {
            setShowTable(true)
        }, 200);
    }

    const getTabsDropdownItems = (startIndex) => {
        let items = []
        tabs.forEach((tab, tabIndex) => {
            if(tabIndex >= startIndex) {
                items.push({
                    label: `${tab.tabLabel} (${tab.count})`,
                    icon: null,
                    link: "/",
                    action: null,
                    // action: changeActiveTab(tab),
                })
            }
        })
        return items
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

    const tableOptions = {
        selectable: true
    }

    const columnWidths = {
        jobNo: "w-full lg:w-1/12",
        customer: "w-full lg:w-4/12",
        date: "w-full lg:w-1/12",
        workshop: "w-full lg:w-2/12",
        productStatus: "w-full lg:w-2/12",
        status: "w-full lg:w-1/12",
        actions: "w-full lg:w-1/12"
    }

    const columnDataStyles = {
        status: {
            isConditional: true,
            conditionals: {
                pending: "capitalize px-3 py-1 capitalize rounded-full bg-gray-100 text-gray-400",
                paused: "capitalize px-3 py-1 capitalize rounded-full bg-yellow-700 bg-opacity-20 text-gray-400",
                complete: "capitalize px-3 py-1 capitalize rounded-full bg-green-200 bg-opacity-60 text-gray-400",
                cancelled: "capitalize px-3 py-1 capitalize rounded-full bg-red-200 text-gray-400"
            }
        },
        // actions: "text-center"
    }

    const convertCamelCase = (camelCaseText) => {
        const text = camelCaseText;
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult
    }

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            let column = key
            if(key === "customerName") {
                column = "customer"
                columnDisplayName = "Customer"
            }
            if(key === "statusOfProduct") {
                column = "productStatus"
                columnDisplayName = "Produst Status"
            }
            if(column !== "customerImage") {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })
            }

            let fieldSelected = true

            if(index > 10) {
                fieldSelected = false
            }
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });
        return {headers, fields}
    }

    const getSelectionCount = (count) => {
        return setSelectedBookingsCount(count)
    }

    const ActionsButton = ({menuItems}) => {
        return (
            <img alt="" src={HorizontalMenuIcon} className="w-4 h-4" />
            // <DropdownMenu 
            //         buttonIcon={HorizontalMenuIcon} 
            //         buttonType='icon' 
            //         buttonText="" 
            //         menuItems={menuItems} 
            //         iconClasses="w-3" 
            //         buttonClasses="text-xs rounded-tr-lg font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200" />
        )
    }

    const Customer = ({customerImage, customerName}) => {
        return (
            <div className="flex flex-row items-center gap-x-3">
                <img alt="" src={customerImage} className="w-7 h-7 rounded-full" />
                <span className="font-sofia-pro text-xs">{customerName}</span>
            </div>
        )
    }

    const ProductStatus = ({productStatus}) => {
        return (
            <div className="flex flex-row items-center gap-x-2">
                <span className={`w-2 h-2 rounded-full ${productStatus === "Awaiting Check-in" ? "bg-yellow-500" : ""}`} /> <span className="font-sofia-pro text-xs">{productStatus}</span>
            </div>
        )
    }

    const cleanUpBookings = (bookings) => {
        console.log(bookings)
        let finalResult = [] 
        bookings.forEach((booking, bookingIndex) => {
            const object = {
                jobNo: booking.jobNo,
                customer: <Customer customerName={booking.customerName} customerImage={booking.customerImage} />,
                date: booking.date,
                workshop: booking.workshop,
                productStatus: <ProductStatus productStatus={booking.statusOfProduct} />,
                status: booking.status,
                actions: <ActionsButton menuItems={booking.actions} />
            }

            finalResult.push(object)
        })
        
        return finalResult
    }

    const [selected, setSelected] = useState(tabs[0].tabId)
    
    return (
        <div className="py-10 px-10 md:px-8">
        {/* Tabs */}
            <div className="hidden lg:flex flex-row gap-x-2 border-b border-gray-300">
                {/* {tabs.map((tab, tabIndex) =>( */}
                {tabs.slice(0, 6).map((tab, tabIndex) =>(
                    <button 
                        key={tabIndex} 
                        className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                        onClick={()=>{changeActiveTab(tab)}}>
                        {tab.tabLabel} 
                            <span className="rounded-full px-2 py-1 bg-ink-navy text-white ml-2">
                                {tab.tabLabel === 'All' ? bookings.length :
                                    bookings.filter((booking) => { return booking.status === tab.tabId }).length}
                            </span>
                    </button>
                ))}
                {tabs.length > 6 && 
                    <DropdownMenu 
                        buttonIcon={HorizontalMenuIcon} 
                        buttonType='icon' 
                        buttonText="" 
                        menuItems={getTabsDropdownItems(6)} 
                        iconClasses="w-5" 
                        buttonClasses="text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200 bg-gray-100" />
                }
            </div>

            <div className="w-full lg:hidden flex-row items-center justify-between mt-5">
                    <label className="text-gray-400 text-xs mb-2 block mt-2">Select Category</label>
                    <div className="">
                        <Listbox value={selected} onChange={setSelected}>
                            <div className="relative mt-1">
                            <Listbox.Button className={`relative w-full text-left text-xs rounded border border-gray-300 text-ink-navy font-medium font-sofia-pro inline-block px-3 py-2 transition duration-200`}>
                                <span className="block truncate text-sm lg:text-xs">
                                <span className='mr-2'>{selected}</span>
                                ({selected === 'all' ? bookings.length :
                                    bookings.filter((booking) => { return booking.status === selected }).length}
                                )
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                    className="w-4 h-4 text-gray-400"
                                    aria-hidden="true"
                                />
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {tabs.map((option, optionIndex) => (
                                    <Listbox.Option
                                        key={optionIndex}
                                        className={({ active }) =>
                                            `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                                cursor-default select-none relative py-2 pl-10 pr-4`
                                        }
                                        value={option.tabLabel}
                                        
                                        >
                                        {({ selected, active }) => (
                                            <>
                                            <span
                                                className={`${
                                                selected ? 'font-medium' : 'font-normal'
                                                } block truncate text-sm`}
                                                onClick={()=>{changeActiveTab(option)}}
                                            >
                                                <span className='mr-2'>{option.tabLabel}</span>
                                                ({option.tabLabel === 'All' ? bookings.length :
                                                        bookings.filter((booking) => { return booking.status === option.tabId }).length}
                                                )
                                            </span>
                                            {selected ? (
                                                <span
                                                className={`${
                                                    active ? 'text-amber-600' : 'text-amber-600'
                                                }
                                                        absolute inset-y-0 left-0 flex items-center pl-3`}
                                                >
                                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                                </Listbox.Options>
                            </Transition>
                            </div>
                        </Listbox>
                    </div>
                </div>
        {/* Tab area */}
        <div className="w-full">
            <div className="w-full block md:flex flex-row justify-between items-center pt-12">
                <div className="flex flex-row justify-between items-center w-full md:w-6/12">
                    <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full mb-4">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm mt-1">
                                <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                            </span>
                        </div>
                        <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search orders" />
                    </div>
                </div>
                <div className="block md:flex flex-row gap-x-2 items-center">
                    {selectedBookingsCount > 1 && 
                        <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-2 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                    }
                    <button className="rounded border-gray-800 py-3 px-2 text-xs text-gray-800 flex flex-row items-center border mt-3 md:mt-0">
                        <img src={TimerIcon} alt="search" className="w-5 mr-2 -mt-1" />
                        Mechanic Time Overrides
                    </button>
                    <button className="rounded border-gray-800 py-3 px-2 text-xs text-gray-800 flex flex-row items-center border mt-3 md:mt-0">
                        <img src={MaintenanceIcon} alt="search" className="w-5 mr-2 -mt-1" />
                        Preset Services
                    </button>
                    <button className="rounded border-blue-700 bg-blue-700 py-3 px-2 text-xs text-white flex flex-row items-center border mt-3 md:mt-0">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>
                        New Booking
                    </button>
                </div>
            </div>

            {/* Table */}
            <div>
            {showTable && <DataTable 
                tableHeaders={tableHeadersFields(cleanUpBookings(activeBookings)[0]).headers} 
                tableData={cleanUpBookings(activeBookings)} 
                columnWidths={columnWidths}
                columnDataStyles={columnDataStyles}
                allFields={tableHeadersFields(cleanUpBookings(activeBookings)[0]).fields}
                onSelectItems={getSelectionCount}
                tableOptions={tableOptions}
            />}
            </div>
        </div>
    </div>
    )
}

export default AllBookings
