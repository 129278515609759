import React from 'react'

const AdminErrorPage = () => {
    return (
        <div className="p-10">
            Admin section 404
        </div>
    )
}

export default AdminErrorPage
