import React, { useRef, useState, useEffect } from 'react'
import { FileDrop } from 'react-file-drop'
import Wysiwyg from '../../../../components/elements/Wysiwyg'
import { isValidObject } from '../../../../utils'
// import PasteFromExcelWysiwyg from '../../../../components/elements/PasteFromExcelWysiwyg'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from '../../../../components/elements/icons/Spinner';
import AutocompleteSelect from '../../../../components/elements/AutocompleteSelect';
import CloseIcon from '../../../../components/elements/icons/CloseIcon';
import ErrorMessage from '../../../../components/elements/ErrorMessage';
import GlobalSearchV2 from '../../../../components/elements/GlobalSearchV2';

const NewSizeGuide = () => {
    // const [pasted, setPasted] = useState(false)
    // const [value, setValue] = useState('')

    // const parseCsvToTable = () => {
    //     var excelData = document.getElementById('csv').value;
    //         console.log(excelData)
        
    //         // split into rows
    //         let excelRow = excelData.split('\n');
    //         console.log('Row -> ', excelRow)
    //         for (let i=0; i<excelRow.length; i++) {
    //             excelRow[i] = excelRow[i].split(String.fromCharCode(9));
    //         }
        
    //         // start to create the HTML table
    //         var myTable = "<table border='1'>"
    //         var myTbody = "<tbody>"
        
    //         // Loop over the rows
    //         for (let i=0; i<excelRow.length - 1; i++) {
    //             if(i===0) {
    //                 var tHeader = "<thead><tr>" 
    //                 for (let j=0; j < excelRow[i].length; j++) {
    //                     tHeader += "<th>" + excelRow[i][j]+ "</th>"
    //                 }
    //                 tHeader += "</tr></thead>"
    //                 myTable += tHeader
    //             } 

    //             var myRow = "<tr>"
    //             // Loop over the columns and add TD to the TR
    //             for (let j=0; j < excelRow[i].length; j++) {
    //                 myRow += "<td>" + excelRow[i][j]+ "</td>"
    //             }
    //             myRow += "</tr>"
    //             myTbody += myRow
    //         }

    //         myTbody += "</tbody>"
    //         myTable += myTbody
    //         myTable += "</table>"

    //         console.log('===>======>', myTable)

    //         // setTimeout(() => {
    //         // }, 200);
    //         // setValue(myTable)
    //         const content = document.getElementById('content')
    //         content.innerHTML = myTable;
    //         setValue(myTable)

    //         console.log('===>', content)
    //         // setPasted(true)
    // }
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])
    const history = useHistory();
    const location = useLocation()

    const [brands, setBrands] = useState([])
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedBrands, setSelectedBrands] = useState([])
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    // const [processing, setProcessing] = useState(true)

    useEffect(() => {
        // const fetchProducts = async  () => {
        //     const headers = {
        //         'Content-Type': 'application/json',
        //         'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        //     }
        //     setProcessing(true)
        //     try { 
        //         let response = null 

        //         response = await axios.get(`/api/v1/products/?page[offset]=1&page[limit]=1000000000&page[order]=desc`, { headers })                            
        //         setProducts(response.data.data)
        //         await fetchBrands()
        //         await fetchCategories()

        //         setProcessing(false)
        //     } catch (error) {
        //         console.error(error)
        //         if(error.response.data.developer_message.exception === "AuthenticationException") {
        //             window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
        //         } else {
        //             setError(error.response.data.developer_message.errors)
        //             setProcessing(false)
        //         }
        //     }
        // }

        // const fetchBrands = async  () => {
        //     const headers = {
        //         'Content-Type': 'application/json',
        //         'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        //     }
        //     try { 
        //         let response = null 

        //         response = await axios.get(`/api/v1/brands/?page[offset]=1&page[limit]=1000000000&page[order]=desc`, { headers })                            
        //         setBrands(response.data.data)

        //     } catch (error) {
        //         console.error(error)
        //         setError(error.response.data.msg)
        //         setProcessing(false)
        //     }
        // }

        // const fetchCategories = async  () => {
        //     const headers = {
        //         'Content-Type': 'application/json',
        //         'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        //     }
        //     try { 
        //         let response = null 

        //         response = await axios.get(`/api/v1/categories/?page[offset]=1&page[limit]=1000000000&page[order]=desc`, { headers })                            
        //         setCategories(response.data.data)

        //     } catch (error) {
        //         console.error(error)
        //         setError(error.response.data.msg)
        //         setProcessing(false)
        //     }
        // }

        // fetchProducts()

    }, [cookies.accessToken, cookies.tokenType, history, location.pathname])



    const selectBrand = () => {
        if(!selectedBrand || selectedBrand === '') {
            return
        }
    
        const tempSelectedBrands = [...selectedBrands]

        if(tempSelectedBrands.includes(selectedBrand.id)) {
            return
        }

        tempSelectedBrands.push(selectedBrand.id)
        setSelectedBrands(tempSelectedBrands)
        setSelectedBrand('')
    }

    const brandName = (brandId) => {
        let brandSelected = brands.filter((brand)=> {
            return brand.id === brandId
        })

        return brandSelected[0].name
    }

    const selectCategory = () => {
        if(!selectedCategory || selectedCategory === '') {
            return
        }

        const tempSelectedCategories = [...selectedCategories]

        if(tempSelectedCategories.includes(selectedCategory.id)) {
            return
        }

        tempSelectedCategories.push(selectedCategory.id)
        setSelectedCategories(tempSelectedCategories)
        setSelectedCategory('')
    }

    const categoryName = (categoryId) => {
        let categorySelected = categories.filter((category)=> {
            return category.id === categoryId
        })

        return categorySelected[0].category
    }

    const selectProduct = () => {
        if(!selectedProduct || selectedProduct === '') {
            return
        }

        const tempSelectedProducts = [...selectedProducts]

        if(tempSelectedProducts.includes(selectedProduct.id)) {
            return
        }

        tempSelectedProducts.push(selectedProduct.id)
        setSelectedProducts(tempSelectedProducts)
        setSelectedProduct('')
    }

    const productName = (productId) => {
        let productSelected = products.filter((product)=> {
            return product.id === productId
        })

        return productSelected[0].name
    }

    const removeBrand = (brandId) => {
        const tempSelectedBrands = [...selectedBrands]
        let deleted = tempSelectedBrands.filter((brand)=> {
            return brand !== brandId
        })
        setTimeout(() => {
            setSelectedBrands(deleted)
        }, 50);
    }

    const removeCategory = (categoryId) => {
        const tempSelectedCategories = [...selectedCategories]
        let deleted = tempSelectedCategories.filter((category)=> {
            return category !== categoryId
        })

        setSelectedCategories(deleted)
    }

    const removeProduct = (productId) => {
        const tempSelectedProducts = [...selectedProducts]
        let deleted = tempSelectedProducts.filter((product)=> {
            return product !== productId
        })

        setSelectedProducts(deleted)
    }
    

    const [ fileDetails, setFileDetails ] = useState(null)
    const [activeGuideThumbnail, setActiveGuideThumbnail] = useState(null)
    const fileInputRef = useRef(null);

    const onFileInputChange = (event) => {
        const { files } = event.target;
        // do something with your files...
        handleFile(files)
    }

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleFile = (files) => {
        setFileDetails({
            name: files[0].name,
            size: `${Math.ceil(files[0].size/1000000).toString()} MB` ,
            type: files[0].type
        })
        let base64Image = '';

        getBase64(files[0], (result) => {
            base64Image = result;
            setActiveGuideThumbnail(base64Image)
        });
    }

    const [sizeGuideContent, setSizeGuideContent] = useState('')
    const [creating, setCreating] = useState(false)

    const createSizeGuide = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        const requestPayload = {
            product_ids: selectedProducts,
            category_ids: selectedCategories,
            brand_ids: selectedBrands,
            content: sizeGuideContent,
            image_path_data: activeGuideThumbnail
        }
        setCreating(true)
        try { 
            const response = await axios.post(`/api/v1/admin/size_guides`, requestPayload, { headers })                            
            setCreating(false)
            history.push({
                pathname: `/admin/orders/size-guides/detail/${response.data.data[0].id}`,
            })
        } catch (error) {
            console.error(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(JSON.stringify(error.response.data.developer_message.errors))
                setCreating(false)
            }
        }
    }

    const [searchingBrands, setSearchingBrands] = useState(false);

    const searchForBrand = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        try { 
            setSearchingBrands(true)
            let response = null 

            response = await axios.get(`/api/v1/brands/?filter[%name%]=${term}`, { headers })                            
            setBrands(response.data.data)
            setSearchingBrands(false)

        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setSearchingBrands(false)
        }
    }

    const [searchingCategories, setSearchingCategories] = useState(false);

    const searchForCategory = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        try { 
            setSearchingCategories(true)
            let response = null 

            response = await axios.get(`/api/v1/categories/?filter[%25name%25]=${term}`, { headers })                            
            setCategories(response.data.data)
            setSearchingCategories(false)

        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setSearchingCategories(false)

        }
    }

    const [searchingProducts, setSearchingProducts] = useState(false);

    const searchForProducts = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        try { 
            setSearchingProducts(true)
            let response = null 

            response = await axios.get(`/api/v1/products/?filter[%name%]=${term}`, { headers })                            
            setProducts(response.data.data)
            setSearchingProducts(false)
        } catch (error) {
            console.error(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setSearchingProducts(false)
            }
        }
    }


    return (
        <>
            {/* {!processing ? */}
            

            <div className="w-full md:w-8/12 lg:w-6/12 mx-auto">
                <h3 className='font-medium text-md text-ink-navy mb-4'>Size Guide Details</h3>

                    <div>
                        <label className="block text-xs text-gray-400 my-2">Brand </label>
                        <div className='w-full mb-5 flex items-center justify-between gap-x-2'>
                            {/* <select type="text" className="w-9/12 px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500">
                                <option>All Brands</option>
                            </select> */}
                            <div className='w-9/12'>
                                {/* <AutocompleteSelect 
                                    placeholderText="Find a brand..." 
                                    selectOptions={brands}
                                    titleField='name'
                                    displayImage={false}
                                    imageField=''
                                    returnFieldValue={(value) => {setSelectedBrand(value)}}
                                /> */}
                                <GlobalSearchV2 
                                    performSearch={(term)=>{searchForProducts(term)}}
                                    searchResults={products}
                                    resultDisplayField={'name'}
                                    returnSelectedResult={(index)=>{setSelectedProduct(products[index])}}
                                    placeholderText={`Search for product`}
                                    searchInProgress={searchingProducts}
                                />
                            </div>
                            <button className='bg-blue-100 text-ink-navy px-4 py-3 text-xs rounded transition duration-200 hover:bg-blue-700 hover:text-white w-3/12' onClick={()=>{selectBrand()}}>
                                Add Brand
                            </button>
                        </div>
                        <label className="block text-xs text-ink-navy my-2">Add brands for this style guide by selecting a brand above and clicking the "Add Brand" button. You can add multiple brands </label>

                        {selectedBrands && selectedBrands.length > 0 &&
                            <div className="my-5">
                                {selectedBrands.map((brand, brandIndex)=>(
                                    <span key={brandIndex} className="px-2 py-1 inline mb-2 text-xs mr-4 text-gray-500 bg-gray-100 rounded">
                                        {brandName(brand)} 
                                        <button onClick={()=>{removeBrand(brand)}}><CloseIcon classes="w-4 h-4 ml-2 pt-1" /></button>
                                    </span>
                                ))}
                            </div>
                        }

                    </div>

                    <div>
                        <label className="block text-xs text-gray-400 my-2">Categories </label>
                        <div className='w-full mb-5 flex items-center justify-between gap-x-2'>
                            <div className='w-9/12'>
                                {/* <AutocompleteSelect 
                                    placeholderText="Find a category..." 
                                    selectOptions={categories}
                                    titleField='category'
                                    displayImage={false}
                                    imageField=''
                                    returnFieldValue={(value) => {setSelectedCategory(value)}}
                                /> */}

                                <GlobalSearchV2 
                                    performSearch={(term)=>{searchForCategory(term)}}
                                    searchResults={categories}
                                    resultDisplayField={'category'}
                                    returnSelectedResult={(index)=>{setSelectedCategory(categories[index])}}
                                    placeholderText={`Search for category`}
                                    searchInProgress={searchingCategories}
                                />
                            </div>
                            {/* <select type="text" className="w-9/12 px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500">
                                <option>All Brands</option>
                            </select> */}
                            <button className='bg-blue-100 text-ink-navy px-4 py-3 text-xs rounded transition duration-200 hover:bg-blue-700 hover:text-white w-3/12'
                            onClick={()=>{selectCategory()}}>
                                Add Category</button>
                        </div>
                        <label className="block text-xs text-ink-navy my-2">Add categories for this style guide by selecting a brand above and clicking the "Add Category" button. You can add multiple categories </label>

                        {selectedCategories && selectedCategories.length > 0 &&
                            <div className="my-5">
                                {selectedCategories.map((category, categoryIndex)=>(
                                    <span key={categoryIndex} className="px-2 py-1 inline mb-5 text-xs mr-4 text-gray-500 bg-gray-100 rounded">
                                        {categoryName(category)} 
                                        <button  onClick={()=>{removeCategory(category)}}><CloseIcon classes="w-4 h-4 ml-2 pt-1" /></button>
                                    </span>
                                ))}
                            </div>
                        }

                    </div>

                    <div>
                        <label className="block text-xs text-gray-400 my-2">Products </label>
                        <div className='w-full mb-5 flex items-center justify-between gap-x-2'>
                            {/* <select type="text" className="w-9/12 px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500">
                                <option>All Brands</option>
                            </select> */}
                            <div className='w-9/12'>
                                {/* <AutocompleteSelect 
                                    placeholderText="Find a product..." 
                                    selectOptions={products}
                                    titleField='name'
                                    displayImage={false}
                                    imageField=''
                                    returnFieldValue={(value) => {setSelectedProduct(value)}}
                                /> */}
                                <GlobalSearchV2 
                                    performSearch={(term)=>{searchForBrand(term)}}
                                    searchResults={brands}
                                    resultDisplayField={'name'}
                                    returnSelectedResult={(index)=>{setSelectedBrand(brands[index])}}
                                    placeholderText={`Search for brand`}
                                    searchInProgress={searchingBrands}
                                />
                            </div>
                            <button className='bg-blue-100 text-ink-navy px-4 py-3 text-xs rounded transition duration-200 hover:bg-blue-700 hover:text-white w-3/12'
                            onClick={()=>{selectProduct()}}
                            >
                                Add Product</button>
                        </div>
                        <label className="block text-xs text-ink-navy my-2">Add products for this style guide by selecting a brand above and clicking the "Add Product" button. You can add multiple brproductsands </label>

                        {selectedProducts && selectedProducts.length > 0 &&
                            <div className="my-5">
                                {selectedProducts.map((product, productIndex)=>(
                                    <span key={productIndex} className="px-2 py-1 inline-block mb-2 text-xs text-gray-500 bg-gray-100 rounded">
                                        {productName(product)} 
                                        <button  onClick={()=>{removeProduct(product)}}><CloseIcon classes="ml-2 w-4 h-4" /></button>
                                    </span>
                                ))}
                            </div>
                        }
                    </div>

                    {/* <div className='w-full block md:flex flex-row gap-x-8 my-5'>
                        <div className='w-full md:w-1/2'>
                            <label className="block text-xs text-gray-400 my-2">Publish Date</label>
                            <input type="date" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" />
                        </div>
                    </div> */}

                    <h3 className='font-medium text-sm text-gray-500 mt-6 my-2'>Content</h3>

                    {/* <div className='w-full block md:flex flex-row gap-x-8 mt-1 mb-5'>
                        <div className='w-full'>
                            <label className="block text-xs text-gray-400 mb-2">Title</label>
                            <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" />
                        </div>
                    </div> */}
                        <div className="w-full py-4">
                            <Wysiwyg
                                initialValue='' updateValue={(value)=>{setSizeGuideContent(value)}}
                            />
                        </div>

                        <div className='w-full'>
                            <label className={`text-sm block mb-5 text-gray-400`}>
                                Size Guide Image
                            </label>

                            <div className='w-full'>
                                <input
                                    onChange={onFileInputChange}
                                    ref={fileInputRef}
                                    type="file"
                                    className="hidden"
                                />
                            { 
                                    !activeGuideThumbnail ?
                                    <FileDrop
                                        onTargetClick={onTargetClick}
                                        onFrameDragEnter={(event) => {}}
                                        onFrameDragLeave={(event) => {}}
                                        onFrameDrop={(event) => {}}
                                        onDragOver={(event) => {}}
                                        onDragLeave={(event) => {}}
                                        onDrop={(files, event) => handleFile(files, event)}
                                    >
                                        <div className="flex flex-col items-center justify-center gap-y-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                            </svg>
                                            <p className='text-gray-400 text-xs'>Drop an image</p>
                                        </div>
                                    </FileDrop>
                                    : 
                                    <div className='relative w-full mb-3'>
                                        <div className='absolute top-0 pt-2 pr-2 left-0 w-full flex flex-row-reverse z-50'>
                                            <button 
                                                className='text-white flex items-center justify-center bg-black transition duration-200 bg-opacity-60 rounded w-10 h-10 hover:bg-opacity-90' 
                                                onClick={()=>{
                                                    setActiveGuideThumbnail(null)
                                                    setFileDetails(null)
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                                                </svg>
                                            </button>
                                        </div>
                                        <img src={activeGuideThumbnail} alt="" className='w-full' />
                                    </div>
                                }

                                {isValidObject(fileDetails) && 
                                    <div>
                                        <p className='text-sm text-gray-600 mb-1'>{fileDetails.name}</p>
                                        <p className='text-xs text-gray-600'>{fileDetails.size} - <span className='text-gray-400'>{fileDetails.type}</span></p>
                                    </div>
                                }
                            </div>
                            
                        </div>


                    <div className='flex flex-row-reverse gap-x-3'>
                        <button className='flex gap-x-2 items-center px-5 py-3 border border-blue-700 bg-blue-700 text-white rounded transition duration-200 text-xs'
                        onClick={()=>{if(!creating) { createSizeGuide() }
                                    }}>{creating ? <><Spinner />Saving Size Guide...</> : 'Save Size Guide'}
                        </button>
                        {/* <button className='px-5 py-3 border border-ink-navy text-ink-navy rounded transition duration-200 text-xs'>
                            Cancel
                        </button> */}
                    </div>

                    {error && error!=='' &&  
                        <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                    } 
            </div>
            :
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <Spinner />
                    <p className='text-sm text-gray-500 mt-5'>Fetching resources...</p>
                </div>
            {/* } */}
        </>
    )
}

export default NewSizeGuide
