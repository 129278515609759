import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import ArrowIcon from '../../elements/icons/ArrowIcon'

const people = [
  { name: 'High', slug: 'high' },
  { name: 'Medium', slug: 'medium' },
  { name: 'Low', slug: 'low' }
]

export default function PrioritiesSelect() {
  const [selected, setSelected] = useState(people[1])

  return (
    <div className="relative w-full">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded bg-white py-2 border rounded pl-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 pr-10 text-left focus:outline-none">
            <div className='flex items-center gap-x-2'>
                <p className={`capitalize font-medium ${selected.slug === 'high' && 'text-red-600'} ${selected.slug === 'medium' && 'text-yellow-600'} ${selected.slug === 'low' && 'text-ink-navy'}`}>{selected.slug}</p>
                <ArrowIcon className={`w-4 h-4 transform 
                    ${selected.slug === 'high' && 'text-red-600 -rotate-90'} 
                    ${selected.slug === 'medium' && 'text-yellow-600 -rotate-45'} 
                    ${selected.slug === 'low' && 'text-ink-navy rotate-90'}`} 
                />
            </div>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {people.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {person.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
