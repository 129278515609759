import React from 'react'
import Layout from '../../components/layout/Layout'
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
} from "react-router-dom";
import AdminSettings from './settings/AdminSettings';
import UserManagement from './settings/UserManagement';
import RolesPermissions from './settings/RolesPermissions';
import EditUser from './settings/EditUser';
import NewUser from './settings/NewUser';
import ReviewMetaRatingTypes from './settings/ReviewMetaRatingTypes';

const Settings = () => {
    let { 
        path, 
        // url 
    } = useRouteMatch();
    const sidebarLinks = [
        {
            label: "Admin Settings",
            location: "/admin/settings/admin-settings",
            subLinks: []
        },
        {
            label: "User Management",
            location: "/admin/settings/user-management",
            subLinks: []
        },
        {
            label: "Roles & Permissions",
            location: "/admin/settings/roles-permissions",
            subLinks: []
        },
        {
            label: "Review Meta Rating Types",
            location: "/admin/settings/review-meta-rating-types",
            subLinks: []
        }
    ]
    return (
        <Layout pageTitle="Settings" sidebarLinks={sidebarLinks} noPadding={true}>
             <Switch>
                <Route
                    path={path}
                    exact
                    render={() => {
                        return (
                        <Redirect to="/admin/settings/admin-settings" />
                        )
                    }}
                />

                <Route path={`${path}/admin-settings`} exact>
                    <AdminSettings />
                </Route>
                <Route path={`${path}/roles-permissions`} exact>
                    <RolesPermissions />
                </Route>
                <Route path={`${path}/user-management`} exact>
                    <UserManagement />
                </Route>
                <Route path={`${path}/review-meta-rating-types`} exact>
                    <ReviewMetaRatingTypes />
                </Route>
                <Route path={`${path}/user-management/new-user`} exact>
                    <NewUser />
                </Route>
                <Route path={`${path}/user-management/:userId`} exact>
                    <EditUser />
                </Route>
            </Switch>
        </Layout>
    )
}

export default Settings
