import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom';
// import DropdownMenu from '../../../components/elements/DropdownMenu'
import HorizontalMenuIcon  from '../../../assets/images/icons/horizontal-menu-small.svg'
import PrintIcon  from '../../../assets/images/icons/print.svg'
import MailIcon  from '../../../assets/images/icons/mail.svg'
import UserIcon  from '../../../assets/images/icons/user-icon.svg'
import { ChevronUpIcon } from '@heroicons/react/solid'
import OrderOverview from '../../../components/partials/OrderOverview';
import OrderPaymentDetails from '../../../components/partials/OrderPaymentDetails';
import OrderHistory from '../../../components/partials/OrderHistory';
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../utils';
import Spinner from '../../../components/elements/icons/Spinner';
import SuccessMessage from '../../../components/elements/SuccessMessage';
import ErrorMessage from '../../../components/elements/ErrorMessage';
import DropdownMenu from '../../../components/elements/DropdownMenu';
import { Dialog, Transition } from '@headlessui/react'
import Wysiwyg from '../../../components/elements/Wysiwyg';
// import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import LoadingDots from '../../../components/elements/LoadingDots';
import { DispatchUrgency } from '../../../components/elements/DispatchUrgency';
import OrderStatus from '../../../components/elements/OrderStatus';

const OrderDetail = (props) => {
    const history = useHistory();
    const location = useLocation()
    const [sidebarOpen, setSidebarOpen] = useState(true)
    
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen)
    }

    const [ cookies ] = useCookies(['user'])
    const { id } = useParams()
    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ orderDetails, setOrderDetails ] = useState(null)
    const [ customerDetails, setCustomerDetails ] = useState(null)
    
    const tabs = [
        {
            tabId: "overview",
            tabLabel: "Overview",
        },
        {
            tabId: "payment-details",
            tabLabel: "Payment Details",
        },
        {
            tabId: "history",
            tabLabel: "History",
        }
    ]

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        bodyClass: 'print-class',
        documentTitle: `PushRetail Order - #${orderDetails?.id} - ${new Date(orderDetails?.created_at).toDateString()}`,
        removeAfterPrint:true
    });

    const [printWithNotes, setPrintWithNotes] = useState(false);

    const printOptions = [
        {
            label: "Print",
            icon: null,
            link: null,
            action: () => {
                setPrintWithNotes(false)
                setTimeout(() => {
                    handlePrint()
                }, 200);
            },
            fullObject:{}
        },
        {
            label: "Print with notes",
            icon: null,
            link: "/",
            action: () => {
                setPrintWithNotes(true)
                setTimeout(() => {
                    handlePrint()
                }, 200);
                setTimeout(() => {
                    setPrintWithNotes(false)
                }, 200);
            },
            fullObject:{}
        }
    ]

    // const PrintButton = ({}) => {
    //     // return <button>Print Order</button>
    //     return <ReactToPrint 
    //         trigger={()=>{
    //             return <button>Print this order</button>
    //         }}
    //         content={()=>{componentRef}}
    //     />
    // }

    const orderOptions = [
        {
            label: "Cancel",
            icon: null,
            link: "/",
            action: ()=>{updateOrderStatus('Cancelled')},
            fullObject:{}
        },
        {
            label: "Return",
            icon: null,
            link: "/",
            action: ()=>{updateOrderStatus('Returned')},
            fullObject:{}
        },
        {
            label: "Delete",
            icon: null,
            link: "/",
            action: ()=>{updateOrderStatus('Deleted')},
            fullObject:{}
        }
    ]

    const statusOptions = [
        {
            label: "Payment Pending",
            icon: null,
            link: "/",
            action: ()=>{updateOrderStatus('Payment Pending')},
            fullObject:{}
        },
        {
            label: "Paid and Ready to Process",
            icon: null,
            link: "/",
            action: ()=>{updateOrderStatus('Paid and Ready to Process')},
            fullObject:{}
        },
        {
            label: "Processing",
            icon: null,
            link: "/",
            action: ()=>{updateOrderStatus('Processing')},
            fullObject:{}
        },
        {
            label: "Ready for Collection",
            icon: null,
            link: "/",
            action: ()=>{updateOrderStatus('Ready for Collection')},
            fullObject:{}
        },
        {
            label: "Dispatched",
            icon: null,
            link: "/",
            action: ()=>{updateOrderStatus('Dispatched')},
            fullObject:{}
        }
    ]

    const [activeTab, setActiveTab] = useState(tabs[0].tabId)
    let changeActiveTab = (tab) => {
        setActiveTab(tab.tabId)
    }

    const [sameAddresses, setSameAddresses] = useState(false)
    const [orderNotes, setOrderNotes] = useState([])
    const [users, setUsers] = useState([])

    const [reloadData, setReloadData] = useState(true);

    useEffect(()=>{
        const fetchOrderDetails = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/admin/orders/${id}`, { headers })            
                setOrderDetails(response.data)

                setOrderEmailSubject(`Regarding your order #${response.data.id}`)

                if(response.data.shipping_address_id === response.data.billing_address_id) {
                    setSameAddresses(true)
                }

                await fetchCustomerDetails(response.data.customer_id)
                await fetchOrderNotes(response.data.id)

                // setProcessing(false)
            } catch (error) {
                console.log(error)
                setError(error?.response?.data?.developer_message?.errors)
                setProcessing(false)

                if(error?.response?.data?.developer_message?.exception === "AuthenticationException") {
                    window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
                } else {
                    setError(error?.response?.data?.msg)
                    setProcessing(false)
                }
            }
        }

        const fetchCustomerDetails = async (customerId) => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/admin/customer/${customerId}`, { headers })            
                setCustomerDetails(response.data.data)
                
            } catch (error) {
                console.log(error)
                setError(error?.response?.data?.developer_message?.errors)
                setProcessing(false)
            }
        }

        const fetchOrderNotes = async (orderId) => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/admin/notes?filter[order_id]=${orderId}`, { headers })     
                setOrderNotes(response.data.data)
                await fetchAllUsers()
                
            } catch (error) {
                console.log(error)
                setError(error?.response?.data?.developer_message?.errors)
                setProcessing(false)
            }
        }

        const fetchAllUsers = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/admin/users?page[offset]=1&page[limit]=200000000`, { headers })     
                setUsers(response.data.data)
                setReloadData(false)
                setProcessing(false)
            } catch (error) {
                console.log(error)
                setError(error?.response?.data?.developer_message?.errors)
                setProcessing(false)
            }
        }

        if(reloadData === true) {
            fetchOrderDetails()
        }

        
    }, [cookies.accessToken, cookies.tokenType, history, id, location.pathname, reloadData])

    const getUser = (id) => {
        const user = users.filter((user)=>{
            return user.id === id
        })

        return user[0]
    }

    const [newNote, setNewNote] = useState('')
    const [noteSuccess, setNoteSuccess] = useState('')
    const [addingNote, setAddingNote] = useState(false)
    const [newNoteError, setNewNoteError] = useState('')

    const createOrderNote = async () => {
        if(!newNote || newNote === '') {
            return
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        const payload ={
            message: newNote,
            order_id: orderDetails.id
        }
        setAddingNote(true)
        try {
            await axios.post(`/api/v1/admin/notes`, payload, { headers })     
            setNoteSuccess('Note was successfully added.')
            setAddingNote(false)
            setReloadData(true)

        } catch (error) {
            console.log(error.response.data.developer_message.errors)
            setNewNoteError(JSON.stringify(error.response.data.developer_message.errors))
            setAddingNote(false)
        }
    }

    const [sendEmailOpen, setSendEmailOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const [orderEmailBody, setOrderEmailBody] = useState('');
    const [orderEmailSubject, setOrderEmailSubject] = useState(``);
    const [sendOrderWithEmail, setSendOrderWithEmail] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState('');
    const [emailError, setEmailError] = useState('');

    const closeModal = () => {
        setSendEmailOpen(false)
    }

    const sendOrderEmail = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        const payload ={
            ticket_subject:orderEmailSubject,
            message: orderEmailBody,
            order_id: orderDetails.id,
            append_order: sendOrderWithEmail
        }

        setSending(true)

        try {
            await axios.post(`/api/v1/admin/notes`, payload, { headers })     
            setEmailSuccess('Email was sent successfully.')
            setSending(false)
            setReloadData(true)
        } catch (error) {
            console.log(error.response.data.developer_message.errors)
            setEmailError(JSON.stringify(error.response.data.developer_message.errors))
            setSending(false)
        }
    }

    const [statusUpdating, setStatusUpdating] = useState(false);

    const updateOrderStatus = async (status) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        const payload ={
            status: status
        }

        setStatusUpdating(true)

        try {
            await axios.patch(`/api/v1/admin/orders/${orderDetails.order_uuid}`, payload, { headers })     
            setEmailSuccess('Email was sent successfully.')
            setStatusUpdating(false)
            setReloadData(true)
        } catch (error) {
            console.log(error.response.data.developer_message.errors)
            setEmailError(JSON.stringify(error.response.data.developer_message.errors))
            setStatusUpdating(false)
        }
    }

    const overrideDispatchUrgency = async (status) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        const payload ={
            dispatch_urgency_status_override: status
        }

        setStatusUpdating(true)

        try {
            await axios.patch(`/api/v1/admin/orders/${orderDetails.order_uuid}`, payload, { headers })     
            setEmailSuccess('Email was sent successfully.')
            setStatusUpdating(false)
            setReloadData(true)
        } catch (error) {
            console.log(error.response.data.developer_message.errors)
            setEmailError(JSON.stringify(error.response.data.developer_message.errors))
            setStatusUpdating(false)
        }
    }

    const openEmailModal = () => {
        setOrderEmailSubject('')
        setOrderEmailBody('')
        setSendOrderWithEmail(false)
        setTimeout(() => {
            setSendEmailOpen(true)
        }, 200);
    }

    const OrderOverviewWrapper = React.forwardRef((props, ref) => {
        return (
            <div ref={componentRef}>
                <div className="w-full hidden flex flex-row gap-x-8 items-center print-flex-row">
                    <h3 className="font-medium text-lg text-ink-navy mt-2">Order - #{orderDetails.id}</h3>
                    <h3 className="font-medium text-lg text-ink-navy mt-2">{new Date(orderDetails.created_at).toDateString()}</h3>
                    <div className="px-3 py-2 text-sm mt-2 rounded-full bg-ink-navy bg-opacity-10 flex flex-row items-center text-ink-navy font-sofia-pro print-white-bg">
                        Order status: 
                        {(orderDetails.status === 'Awaiting Payment' || orderDetails.status === 'Payment Pending') && <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2 inline-block" />}
                        {orderDetails.status === 'Dispatched' && <span className="w-2 h-2 bg-gray-400 rounded-full mr-2 inline-block" />}
                        {orderDetails.status === 'Paid and Ready to Process' && <span className="w-2 h-2 bg-gray-300 rounded-full mr-2 inline-block" />}
                        {orderDetails.status === 'Payment pending' && <span className="w-2 h-2 bg-gray-700 rounded-full mr-2 inline-block" />}
                        {orderDetails.status === 'Processing' && <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2 inline-block" />}
                        {orderDetails.status === 'Deleted' && <span className="w-2 h-2 bg-red-500 rounded-full mr-2 inline-block" />}
                        {orderDetails.status === 'Cancelled' && <span className="w-2 h-2 bg-red-700 rounded-full mr-2 inline-block" />}
                        {orderDetails.status === 'Complete' && <span className="w-2 h-2 bg-green-700 rounded-full mr-2 inline-block" />}
                        {orderDetails.status === 'Returned' && <span className="w-2 h-2 bg-purple-700 rounded-full mr-2 inline-block" />}
                        {orderDetails.status === 'Ready for Collection' && <span className="w-2 h-2 bg-green-700 rounded-full mr-2 inline-block" />}
                        {orderDetails.status} 
                    </div>
                </div>

                <OrderOverview order={props.orderDetails} customer={props.customerDetails} sameAddress={props.sameAddresses}  />

                {printWithNotes && 
                    props.orderNotes && props.orderNotes.length > 0 && 
                    <div className='px-4'>
                        <p className='mt-5 mb-3'>Order Notes</p>
                        
                        {props.orderNotes?.map((orderNote, noteIndex) => (
                            <div key={noteIndex} className="border-b border-gray-300 py-6 text-sm text-ink-navy">
                                <div className="w-full">
                            {/* {orderNote.comments[0].comment_message.charAt(0) === '<' ?  */}
                                    <div dangerouslySetInnerHTML={{__html: orderNote.comments[0].comment_message}} />
                                    
                                {/* : */}
                                    {/* <p className="font-medium text-ink-navy text-sm mb-0">{orderNote.comments[0].comment_message}</p>
                            } */}
                                    <p className="text-gray-400 font-thin text-xs">{orderNote.date}</p>
                                </div>
                                <div className="w-full mt-3">
                                    <p className="text-gray-400 font-thin text-xs">
                                        <img alt="" src={UserIcon} className="inline mr-2" />
                                        {getUser(orderNote.comments[0].comment_created_by_user_id).first_name} {getUser(orderNote.comments[0].comment_created_by_user_id).last_name}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        )
    })

    return (
        <Fragment>
            {processing ?
                <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                <p className='text-sm text-gray-500 mt-5'>Fetching order details...</p>
            </div>
            :
            
            <>
            {error === '' ? 
            <>
                <div className="xl:flex flex-row items-center justify-between mb-8">
                    <div className="w-full xl:w-1/3">
                        <div className="flex flex-row gap-x-2 border-b border-gray-300">
                            {/* {tabs.map((tab, tabIndex) =>( */}
                            {tabs.slice(0, 6).map((tab, tabIndex) =>(
                                <button 
                                    key={tabIndex} 
                                    className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                                    onClick={()=>{changeActiveTab(tab)}}>
                                    {tab.tabLabel} 
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="w-full xl:w-1/2 flex flex-row flex-row-reverse items-center gap-x-2 mt-2">
                        
                        {/* <button className="p-3 ml-2 ml-2 w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center hover:bg-blue-100 transition duration-200">
                            <img src={PrintIcon} alt="" className="w-4" />
                        </button> */}

                        {/* <button className="p-3 ml-2 w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center hover:bg-blue-100 transition duration-200">
                            <img src={HorizontalMenuIcon} alt="" className="" />
                        </button> */}
                        {statusUpdating ? <div className='p-3 ml-2 w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center hover:bg-blue-100 transition duration-200'>
                            <LoadingDots />
                        </div>
                        :
                        <DropdownMenu 
                            buttonIcon={HorizontalMenuIcon} 
                            buttonType='icon' 
                            menuItems={orderOptions}
                            iconClasses="w-6" 
                            buttonClasses="p-3 ml-2 w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center hover:bg-blue-100 transition duration-200" 
                        />}
                        
                        <DropdownMenu 
                            buttonIcon={PrintIcon} 
                            buttonType='icon' 
                            menuItems={printOptions}
                            iconClasses="w-6" 
                            buttonClasses="p-3 ml-2 ml-2 w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center hover:bg-blue-100 transition duration-200" 
                        />

                        <button onClick={()=>{openEmailModal()}} className="p-3 ml-2 ml-2 w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center hover:bg-blue-100 transition duration-200">
                            <img src={MailIcon} alt="" className="w-4" />
                        </button>

                        {/* <button className="border border-red-700 text-red-700 w-24 font-sofia-pro text-xs rounded py-2 px-3">
                            Delete
                        </button> */}

                        {/* <button className="border border-gray-400 text-gray-400 w-24 font-sofia-pro text-xs rounded py-2 px-3">
                            Cancel
                        </button> */}

                        {/* <button className="border border-blue-700 text-blue-700 w-24 font-sofia-pro text-xs rounded py-2 px-3">
                            Set to
                        </button> */}

                        <DropdownMenu 
                            buttonIcon=''
                            buttonText='Set to' 
                            buttonType='text' 
                            menuItems={statusOptions}
                            iconClasses="w-6" 
                            buttonClasses="border border-blue-700 text-blue-700 w-24 font-sofia-pro text-xs rounded py-2 px-3 flex flex-row items-center text-center" 
                        />

                        {/* <button className="border border-yellow-500 text-yellow-500 w-24 font-sofia-pro text-xs rounded py-2 px-3">
                            Return
                        </button> */}
                        
                        {/*  */}
                    </div>
                </div>

                <div className="xl:flex flex-row gap-x-3">
                    {/* Main area */}
                        <div className="w-full xl:w-8/12">
                            <div className="w-max flex flex-row gap-x-8 items-center">
                                <h3 className="font-medium text-sm text-ink-navy mt-2">Order - #{orderDetails.id}</h3>
                                <h3 className="font-medium text-sm text-ink-navy mt-2">{new Date(orderDetails.created_at).toDateString()}</h3>
                                <div className="px-3 py-2 text-xs mt-2 rounded-full bg-ink-navy bg-opacity-10 flex-row items-center text-ink-navy inline font-sofia-pro">
                                    {(orderDetails.status === 'Awaiting Payment' || orderDetails.status === 'Payment Pending') && <span className="w-1 h-1 bg-yellow-500 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status === 'Dispatched' && <span className="w-1 h-1 bg-gray-400 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status === 'Paid and Ready to Process' && <span className="w-1 h-1 bg-gray-300 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status === 'Payment pending' && <span className="w-1 h-1 bg-gray-700 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status === 'Processing' && <span className="w-1 h-1 bg-yellow-500 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status === 'Deleted' && <span className="w-1 h-1 bg-red-500 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status === 'Cancelled' && <span className="w-1 h-1 bg-red-700 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status === 'Complete' && <span className="w-1 h-1 bg-green-700 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status === 'Returned' && <span className="w-1 h-1 bg-purple-700 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status === 'Ready for Collection' && <span className="w-1 h-1 bg-green-700 rounded-full mr-2 inline-block" />}
                                    {orderDetails.status} 
                                </div>
                                <div className='flex flex-row gap-x-3'>
                                    <div className="px-3 py-2 text-sm mt-2 rounded-lg bg-ink-navy bg-opacity-10 flex flex-row items-center text-ink-navy font-sofia-pro">
                                        {/* <DispatchUrgency status={orderDetails.dispatch_urgency_status} /> */}

                                        <div className='flex gap-x-1 items-center'>
                                            <p className='text-xs font-thin'>Dispatch Urgency Status:</p>
                                            {/* <p className='text-xs font-medium capitalize'>{orderDetails.dispatch_urgency_status}</p> */}
                                            <select onChange={(e)=>{overrideDispatchUrgency(e.target.value)}} className='default px-2 border py-1 bg-transparent text-xs rounded border-gray-600' value={orderDetails.dispatch_urgency_status}>
                                                <option value='priority'>Priority</option>
                                                <option value='urgent'>Urgent</option>
                                                <option value='waiting'>Waiting</option>
                                            </select>

                                            {/* <button className='text-xs border border-gray-600 px-2 py-1 rounded text-white bg-gray-600'>Override</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {activeTab === 'overview' && 
                                 <OrderOverviewWrapper orderDetails={orderDetails} customerDetails={customerDetails} sameAddresses={sameAddresses} orderNotes={orderNotes}  />
                            }
                            {activeTab === 'payment-details' && 
                                <OrderPaymentDetails order={orderDetails}  />
                            }
                            {activeTab === 'history' && 
                                <OrderHistory order={orderDetails} />
                            }
                        </div>      
                    {/* Sidebar */}
                        <div className="w-full xl:w-4/12 mt-16 pt-2">
                            <div className="px-6 py-6 bg-gray-100 border rounded-md border border-gray-300">
                                <div className="flex justify-between items-center">
                                    <p className="font-light text-md text-gray-600">Order Notes <span className="font-sofia-pro text-sm text-white border rounded bg-ink-navy px-1 py-0">{orderNotes.length}</span></p>
                                    <button className="cursor-pointer p-2">
                                        <ChevronUpIcon
                                            className={`${
                                            sidebarOpen ? '' : 'transform rotate-180'
                                            } w-5 h-5 text-gray-500`}
                                            onClick={()=>{toggleSidebar()}}
                                        />
                                    </button>
                                </div>
                                    
                                <div className={`py-4 pb-8 border-b-2 border-gray-300 ${!sidebarOpen ? 'hidden' : ''}`}>
                                    <textarea onChange={(e)=>{setNewNote(e.target.value)}} className="w-full px-4 py-2 bg-white border border-gray-400 border-2 mb-4 rounded-md h-24 w-full font-sofia-pro" placeholder="Add new note..." />
                                    <div className="w-full text-right">
                                        <button disabled={addingNote} onClick={()=>{createOrderNote()}} className="mb-3 font-sofia-pro px-4 py-2 bg-ink-navy text-white text-sm rounded">
                                            {addingNote ? 'Adding note...' : 'Add note'}
                                        </button>
                                        {noteSuccess && noteSuccess !== '' &&
                                            <SuccessMessage message={noteSuccess} dismissHandler={()=>{setNoteSuccess('')}} />
                                        }
                                        {newNoteError && newNoteError !== '' &&
                                            <ErrorMessage message={newNoteError} dismissHandler={()=>{setNewNoteError('')}} />
                                        }
                                    </div>
                                </div>

                                <div className={`${!sidebarOpen ? 'hidden' : ''}`}>
                                    {orderNotes && orderNotes.length > 0 ? orderNotes.map((orderNote, noteIndex) => (
                                        <div key={noteIndex} className="border-b border-gray-300 py-6 text-sm text-ink-navy">
                                            <div className="w-full">
                                           {/* {orderNote.comments[0].comment_message.charAt(0) === '<' ?  */}
                                                <div dangerouslySetInnerHTML={{__html: orderNote.comments[0].comment_message}} />
                                                
                                            {/* : */}
                                                {/* <p className="font-medium text-ink-navy text-sm mb-0">{orderNote.comments[0].comment_message}</p>
                                           } */}
                                                <p className="text-gray-400 font-thin text-xs">{orderNote.date}</p>
                                            </div>
                                            <div className="w-full mt-3">
                                                <p className="text-gray-400 font-thin text-xs">
                                                    <img alt="" src={UserIcon} className="inline mr-2" />
                                                    {getUser(orderNote.comments[0].comment_created_by_user_id).first_name} {getUser(orderNote.comments[0].comment_created_by_user_id).last_name}
                                                </p>
                                            </div>
                                        </div>
                                    )) :
                                    <div className='w-full rounded-sm border border-ink-navy bg-gray-200 p-4'>
                                        <p className='text-xs text-ink-navy'>No notes for this order yet. You can use the field above to leave a note</p>
                                    </div>
                                
                                }
                                </div>
                            </div>

                        </div>
                </div>
            </>
            :
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                <p className='text-sm text-gray-600 mt-1'>{error}</p>
            </div>
            }
            </>
        }


        <Transition appear show={sendEmailOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0  bg-black bg-opacity-40" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                            <Dialog.Title
                            as="h3"
                            className="text-md font-medium leading-6 text-ink-nvy"
                            >
                                Email Order Details {customerDetails && customerDetails.user && <> to <span className='text-blue-600'>{customerDetails.user.email}</span></>}
                            </Dialog.Title>

                            <div className='my-4'>
                                {emailSuccess !== '' && <SuccessMessage message={emailSuccess} dismissHandler={()=>{setEmailSuccess('')}} />}
                                {emailError !== '' && <ErrorMessage message={emailError} dismissHandler={()=>{setEmailError('')}} />}
                                <div className='my-2'>
                                    <label className={`text-xs text-sm block mb-2 text-ink-navy block`}>
                                        Email Subject
                                    </label>
                                    <input type="text"className={`border border-gray-200 rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-400 transition duration-200`} placeholder="Eg: red_trailer" value={orderEmailSubject} onChange={(e)=>{setOrderEmailSubject(e.target.value)}} />
                                </div>
                                <div className='long-text my-2'>
                                    <Wysiwyg fieldTitle="Email Content" initialValue={orderEmailBody || ''} updateValue={(value)=>{setOrderEmailBody(value)}} />
                                </div>
                                <div className="w-full mt-2">
                                    <input type="checkbox" 
                                        onChange={()=>{setSendOrderWithEmail(!sendOrderWithEmail)}} 
                                        checked={sendOrderWithEmail} className="mr-2 mt-3" 
                                    />
                                    <p className="text-xs inline-block mt-3">Send order details with this email</p>
                                </div>
                            </div>

                            <div className="mt-4 w-full flex flex-row-reverse gap-x-3">
                                <button
                                    type="button"
                                    className="inline-flex justify-center gap-x-2 items-center px-4 py-2 text-sm font-medium text-white bg-ink-navy transition duration-200 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={()=>{sendOrderEmail()}}
                                    disabled={sending}
                                >
                                    {sending ? <span className='flex items-center gap-x-2'><Spinner /> Sending email </span> : 'Send email' }
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 transition duration-200 text-sm font-medium text-gray-500 bg-transparent border border-transparent rounded-md hover:bg-blue-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
                </Dialog>
            </Transition>

        </Fragment>
    )
}

export default OrderDetail
