import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TrashIcon from '../elements/icons/TrashIcon'

const SliderTile = ({key, slider}) => {
    const [backgroundImage, setBackgroundImage] = useState(slider.images[0])
    return (
        <Link to='slide/1' key={key} className='w-full transition duration-200 hover:shadow-md cursor-pointer'>
            <div 
                className='w-full h-40 relative' 
                style={{backgroundImage: `url("${backgroundImage}")`, backgroundSize: 'cover'}} 
                onMouseOver={()=>{setBackgroundImage(slider.images[1])}} 
                onMouseOut={()=>{setBackgroundImage(slider.images[0])}} 
            >
                <button className='absolute top-4 right-4 rounded-full p-1 bg-black bg-opacity-30 hover:bg-opacity-50 text-white duration-200 transition'>
                    <TrashIcon classes='w-5 h-5' />
                </button>
            </div>
            <div className='border-l border-r border-b border-gray-300 rounded-b-md p-3'>
                <p className='text-xs text-ink-navy'>{slider.name}</p>
            </div>
        </Link>
    )
}

export default SliderTile
