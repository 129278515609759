import React, { Fragment, useState } from 'react'
import WhiteLogo from '../assets/images/logo-w.png'
import Spinner from '../components/elements/icons/Spinner';
import axios from "axios"
import { baseUrl } from '../utils';
import ErrorMessage from '../components/elements/ErrorMessage'
import SuccessMessage from '../components/elements/SuccessMessage';
import { useCookies } from 'react-cookie';

const PasswordResetRequestPage = ( {match} ) => {
    const [ processing, setProcessing ] = useState(false)
    const [ error, setError ] = useState('')  
    const [ success, setSuccess ] = useState('')  
    // eslint-disable-next-line no-unused-vars
    const [ cookies, setCookie, removeCookie ] = useCookies(['password-reset']);
    const [ email, setEmail ] = useState('')

    const requestReset = async () => {
        const headers = {
            'Content-Type': 'application/json'
        }
        
        if (!email || email === '') {
            return
        }

        setProcessing(true)

        const requestPayload = {
            email: email,
            reset_url: "https://ecom-admin.objective1.co.uk/password-reset"
        }

        try {
            await axios.post(`/api/v1/auth/password-reset/create`, requestPayload, { headers }) 
            setCookie('email', requestPayload.email)
            setSuccess("we've sent you a reset link, please check your email")
            setProcessing(false)
        } catch (error) {
            setError(error.response.data.developer_message)
            setProcessing(false)
        }
    }

    return (
        <Fragment>
            <div className="w-full block lg:flex flex-row items-center">
                <div className="h-32 lg:h-screen w-full bg-ink-navy flex flex-col gap-y-8 items-center py-24">
                    <div className="w-36">
                        <img src={WhiteLogo} alt="push retail logo" />
                    </div>
                    <div className='rounded-lg w-4/5 md:w-3/5 lg:w-2/6 bg-white shadow-lg p-10 flex flex-col'>
                        <div className="w-full mb-5 ">
                            <p className="relative text-ink-navy text-xl">Lost your password?</p>
                            <p> No problem, just provide your email and we'll get you a new one</p>
                        </div>

                        <label className="text-sm text-ink-navy text-left transition duration-200">Email address</label> 
                        <input 
                            type="email" 
                            placeholder="Your email address" 
                            className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 `}
                            onChange={(e) => {setEmail( e.target.value )}}
                        /> 
                        <button 
                            className="flex flex-row gap-x-3 items-center justify-center rounded bg-ink-navy text-sm hover:bg-blue-900 transition duration-200 w-full py-3 text-center text-white focus:border-transparent focus:outline-none" 
                            onClick={()=>{
                            if(!processing) { requestReset() }
                                }}>{processing ? <><Spinner />Sending request...</> : 'Request password reset'}
                        </button>

                        {error && error!=='' &&  
                            <ErrorMessage message={error} dismissHandler={()=>{setError('')}} />
                        } 
                        
                        {success && success!=='' &&  
                            <SuccessMessage message={success} dismissHandler={()=>{setSuccess('')}} />
                        } 
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default PasswordResetRequestPage
