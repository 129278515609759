import React, {Fragment, useState} from 'react'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../../assets/images/icons/bulk-items.svg'
import DropdownMenu from '../../../../components/elements/DropdownMenu'
import DataTable from '../../../../components/elements/DataTable'
import { Link } from 'react-router-dom'
import { convertCamelCase } from "../.././../../utils";
// import EditIcon  from '../../../../assets/images/icons/edit.svg'
import HorizontalMenuIcon  from '../../../../assets/images/icons/horizontal-menu-icon.svg'

const ConditionalContent = () => {
    const conditionalContent = [
        {
            title: "test conditional",
            brand: "All",
            type: "",
            category: "All",
            publishDate: "2021-10-07",
            lastUpdate: "",
            user: "Joel",
            actions:''
        }
    ]

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const tableOptions = {
        selectable: true
    }

    const columnWidths = {
        title: "w-full lg:w-3/12",
        brand: "w-full lg:w-1/12",
        type: "w-full lg:w-2/12",
        category: "w-full lg:w-2/12",
        publishDate: "w-full lg:w-1/12",
        lastUpdate: "w-full lg:w-1/12",
        user: "w-full lg:w-1/12",
        actions:'w-full lg:w-1/12'
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]


    const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedReviewsCount(count)
    }

    // const EditButton = ({voucherIndex}) => {
    //     return (
    //         <Link to={`voucher-codes/detail/${voucherIndex}`} className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
    //     )
    // }

    const ContentLink = ({contentIndex, contentTitle}) => {
        return (
            <Link to={`conditional-page-content/detail/${contentIndex}`} className='text-ink-navy font-bold text-xs'>{contentTitle}</Link>
        )
    }

    const ActionsButton = ({menuItems}) => {
        return (
            <img alt="" src={HorizontalMenuIcon} className="w-4 h-4" />
            // <DropdownMenu 
            //         buttonIcon={HorizontalMenuIcon} 
            //         buttonType='icon' 
            //         buttonText="" 
            //         menuItems={menuItems} 
            //         iconClasses="w-3" 
            //         buttonClasses="text-xs rounded-tr-lg font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200" />
        )
    }
    
    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            const requestObject = {
                title: <ContentLink contentIndex={objectIndex} contentTitle={object.title} />,
                brand: object.brand,
                type: object.typer,
                category: object.category,
                publishDate: object.publishDate,
                lastUpdate: object.lastUpdate,
                user: object.user,
                actions: <ActionsButton />
            }
            finalResult.push(requestObject)
        })
        return finalResult
    }
    

    return (
        <Fragment>

            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search voucher codes" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 flex-wrap mt-3 lg:mt-0">
                        {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Export
                        </button>
                        <Link to="conditional-page-content/new-conditional-page-content" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            New Conditional Content
                        </Link>
                    </div>
                </div>

                {/* Filters */}
                {/* <div>
                </div> */}

                {/* Table */}

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData(conditionalContent)[0]).headers} 
                    tableData={cleanUpData(conditionalContent)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpData(conditionalContent)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />
                
            </div>
        </Fragment>
    )

}

export default ConditionalContent
