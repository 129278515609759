import React, {Fragment, useState} from 'react'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../../assets/images/icons/bulk-items.svg'
import DropdownMenu from '../../../../components/elements/DropdownMenu'
import DataTable from '../../../../components/elements/DataTable'
import { Link } from 'react-router-dom'
import { convertCamelCase } from "../../../../utils";
import EditIcon  from '../../../../assets/images/icons/edit.svg'

const Stores = () => {
    const stores = [
        {
            id: "1",
            name: "Clevedon",
            address: "Weavers Houes, Garden Road",
            url: "http://cycleconcept.co.uk/clevedon-store",
            actions: ""
        },
        {
            id: "2",
            name: "Hertfordshire Demo Center",
            address: "Somewhere in deepest Hertfortshire",
            url: "http://cycleconcept.co.uk/hertfordshire-demo-center-store",
            actions: ""
        },
        {
            id: "3",
            name: "Bristol Temple Meads",
            address: "Redcliffe, Bristol",
            url: "http://cycleconcept.co.uk/redcliffe-demo-center-store",
            actions: ""
        },
        {
            id: "4",
            name: "Weston Super Mare",
            address: "weston-super-mare BS23 1XY",
            url: "http://cycleconcept.co.uk/WSM-demo-center-store",
            actions: ""
        },
        
    ]

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const tableOptions = {
        selectable: true
    }

    const columnWidths = {
        id: "w-full lg:w-1/12",
        name: "w-full lg:w-3/12",
        address: "w-full lg:w-3/12",
        url: "w-full lg:w-4/12",
        actions:'w-full lg:w-1/12'
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]


    const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedReviewsCount(count)
    }

    const EditButton = ({voucherIndex}) => {
        return (
            <Link to={`stores/detail/${voucherIndex}`} className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
        )
    }

    const StoreLink = ({storeIndex, storeId}) => {
        return (
            <Link to={`stores/detail/${storeIndex}`} className='text-ink-navy font-bold text-xs'>{storeId}</Link>
        )
    }
    
    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            const requestObject = {
                id: <StoreLink storeId={object.id} storeIndex={objectIndex} />,
                name: object.name,
                address: object.address,
                url: object.url,
                actions:<EditButton voucherIndex={objectIndex} />
            }
            finalResult.push(requestObject)
        })
        return finalResult
    }

    return (
        <Fragment>

            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search stores" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap">
                        {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2 hidden lg:inline" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Help
                        </button>
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden lg:inline" /> Export
                        </button>
                        <Link to="stores/new-store" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            Add New Store
                        </Link>
                    </div>
                </div>

                {/* Table */}

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData(stores)[0]).headers} 
                    tableData={cleanUpData(stores)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpData(stores)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />
                
            </div>
        </Fragment>
    )
}

export default Stores
