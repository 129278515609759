import React, { useState } from 'react'

const NavigationsIcon = () => {
    const [color, setColor] = useState("#B8C0D5")

    return (
        <svg onMouseEnter={()=>{setColor("#ffffff")}} onMouseLeave={()=>{setColor("#B8C0D5")}} id="Component_1_11" data-name="Component 1 – 11" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
            <rect id="Rectangle_17" data-name="Rectangle 17" width="20" height="4" rx="1.2" fill="#adb5d4"/>
            <rect id="Rectangle_18" data-name="Rectangle 18" width="13" height="13" rx="1.3" transform="translate(7 7)" fill="#adb5d4"/>
            <rect id="Rectangle_19" data-name="Rectangle 19" width="5" height="13" rx="1.2" transform="translate(0 7)" fill={color}/>
        </svg>
    )
}

export default NavigationsIcon
