import React, { Fragment } from 'react'

const BusinessProfile = () => {
    return (
        <Fragment>
            <div className="w-full pb-10">
                <h3 className="font-medium text-ink-navy mb-7 text-lg">Business Profile</h3>
                <div className="block md:flex flex-row items-center gap-x-4 mb-4">
                    <div className="w-full mb-3">
                        <label className="text-xs text-gray-600 block mb-3">Store Name *</label>
                        <input type="text" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Cycle Concept" />
                    </div>
                    <div className="w-full mb-3">
                        <label className="text-xs text-gray-600 block mb-3">Default Meta Description *</label>
                        <input type="text" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="PushRetail Demo Store" />
                    </div>
                </div>
                <div className="block md:flex flex-row items-center gap-x-4 mb-4">
                    <div className="w-full mb-3">
                        <label className="text-xs text-gray-600 block mb-3">Strapline *</label>
                        <input type="text" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Cycle Concept" />
                    </div>
                    <div className="w-full mb-3">
                        <label className="text-xs text-gray-600 block mb-3">Default Location *</label>
                        <input type="text" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="PushRetail Demo Store" />
                    </div>
                </div>
                <div className="block md:flex flex-row items-center gap-x-4 mb-4">
                    <div className="w-full mb-3">
                        <label className="text-xs text-gray-600 block mb-3">Phone *</label>
                        <input type="text" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Cycle Concept" />
                    </div>
                    <div className="w-full mb-3">
                        <label className="text-xs text-gray-600 block mb-3">Currency Symbol *</label>
                        <input type="text" className="border rounded border-gray-00 px-3 py-2 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200" placeholder="PushRetail Demo Store" />
                    </div>
                </div>
                <div className="block md:flex flex-row items-center gap-x-4 mb-4">
                    <div className="w-full mb-3">
                        <label className="text-xs text-gray-600 block mb-3">Store address *</label>
                        <textarea type="text" className="border rounded border-gray-200 px-3 py-2 text-xs w-full h-28 resize-none focus:outline-none focus:border-gray-300 transition duration-200" placeholder="Cycle Concept" />
                    </div>
                    <div className="w-full mb-3">
                        <label className="text-xs text-gray-600 block mb-3">Opening Times *</label>
                        <textarea type="text" className="border rounded border-gray-00 px-3 py-2 text-xs w-full h-28 resize-none focus:outline-none focus:border-gray-300 transition duration-200" placeholder="PushRetail Demo Store" />
                    </div>
                </div>
            </div>
            <div className="border-t border-gray-200 py-10 flex flex-row-reverse gap-x-3">
                <button className="px-4 py-2 bg-blue-600 text-white font-sofia-pro text-xs rounded">Save</button>
                <button className="px-4 py-2 bg-white border text-ink-navy text-xs border-ink-navy font-sofia-pro rounded">Cancel</button>
            </div>

        </Fragment>
    )
}

export default BusinessProfile
