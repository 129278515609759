import React, {useState, useEffect} from 'react'
import Spinner from '../../../../components/elements/icons/Spinner'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import GlobalSearchV2 from '../../../../components/elements/GlobalSearchV2';
import SuccessMessage from '../../../../components/elements/SuccessMessage';

const NewBikeFitRequest = () => {
    const history = useHistory();
    const location = useLocation()
    const [ cookies ] = useCookies(['user'])
    const { requestNumber } = useParams()

    const [error, setError] = useState(null)

    useEffect(() => {


    // fetchRequestDetails()
    }, [cookies.accessToken, cookies.tokenType, history, location.pathname, requestNumber])

    const [bikeFitDetails, setBikeFitDetails] = useState({});

    const [searchingBrands, setSearchingBrands] = useState(false);
    const [brands, setBrands] = useState([]);

    const searchForBrand = async  (term) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        try { 
            setSearchingBrands(true)
            let response = null 

            response = await axios.get(`/api/v1/admin/brands/?filter[%name%]=${term}`, { headers })                            
            setBrands(response.data.data)
            setSearchingBrands(false)

        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setSearchingBrands(false)
        }
    }

    const [customers, setCustomers] = useState(null);
    const [searchingCustomers, setSearchingCustomers] = useState(false);

    const searchForCustomer = async (term, index) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        try { 
            let response = null 
            setSearchingCustomers(true)
            response = await axios.get(`/api/v1/admin/customers/?filter[%first_name%]=${term}`, { headers })            
            setCustomers(response.data.data)
            setSearchingCustomers(false)
          } catch (error) {
            setCustomers([])
            setSearchingCustomers(false)
            console.error(error.response.data)
            // setError(error.response.data.msg)
            setSearchingCustomers(false)
        }
    }

    const [newCustomer, setNewCustomer] = useState(false);

    const selectCustomer = (customer) => {
      setBikeFitDetails({...bikeFitDetails, ...{
        customer_name: customer.full_name,
        phone: customer.tel_code + customer.tel_number,
        email: customer.email,
        customer_id: customer.id,
      }})
    }

    const [creatingRequest, setCreatingRequest] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const createRequest = async () => {
      const headers = {
        'Content-Type': 'application/json',
      }

      try { 
        const payload = {
          name: bikeFitDetails.customer_name,
          phone: bikeFitDetails.phone,
          email: bikeFitDetails.email,
          age: bikeFitDetails.age,
          weight: bikeFitDetails.weight,
          average_weekly_mileage: bikeFitDetails.avgWeeklyMileage,
          bike_type: bikeFitDetails.bikeType,
          bike_brand: bikeFitDetails.bikeBrand,
          bike_model: bikeFitDetails.bikeModel,
          bike_size: bikeFitDetails.bikeSize,
          issues_with_current_fit: bikeFitDetails.currentFitIssues,
          goals_from_bike_fit: bikeFitDetails.bikeFitGoals,
          // bike_fit_num: null,
          ticket_subject: 'Bike fit request for ' + bikeFitDetails.customer_name,
          message: bikeFitDetails.comments,
          customer_id: bikeFitDetails.customer_id,
          assigned_to_user_id: 1,
          // order_id: 3,
          // watchers: []
      }
          setCreatingRequest(true)
          await axios.post(`/api/v1/admin/bike_fits`, payload, { headers })            
          setCreatingRequest(false)
          setSuccessMessage('Bike fit request created successfully')
          setBikeFitDetails({})
        } catch (error) {
          setCreatingRequest(false)
          console.error(error.response.data)
          // setError(error.response.data.msg)
          setSearchingCustomers(false)
      }
    }

    const [otherBrand, setOtherBrand] = useState(false);
    return (
      <>

        <div className='w-full px-4 lg:w-10/12 xl:w-8/12 2xl:w-7/12 mx-auto pb-12'>
          <h3 className='text-ink-navy font-medium text-zxl mb-8'>Create a new Bike fit request</h3>

          <h3 className='text-gray-600 text-md font-medium'>Customer Details</h3>
          <p className='text-sm mb-3'>Please provide details of the customer/bike owner below</p>

          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">{newCustomer ? 'Customer name' : 'Customer'} </label>
              <div className="">
                {newCustomer ?
                  <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{customer_name: e.target.value}})}} value={bikeFitDetails.phone} />
                :
                  <GlobalSearchV2 
                    performSearch={(term)=>{searchForCustomer(term)}}
                    searchResults={customers}
                    resultDisplayField={'full_name'}
                    returnSelectedResult={(index)=>{selectCustomer(customers[index])}}
                    placeholderText={`Search for customer`}
                    searchInProgress={searchingCustomers}
                  />}
              </div>

              <div className=' text-right w-full mt-3'>
                <button onClick={()=>{setNewCustomer(!newCustomer)}} className='text-ink-navy text-sm font-medium'>{newCustomer ? 'Search existing customers' : 'New customer'}</button>
              </div>

          </div>
          
          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Phone Number </label>
              <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{phone: e.target.value}})}} value={bikeFitDetails.phone} />
          </div>
          
          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Email Address </label>
              <input type="email" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={bikeFitDetails.email} />
          </div>
          
          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Age </label>
              <input type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={bikeFitDetails.age} />
          </div>
          
          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Weight (in Kg) </label>
              <input type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={bikeFitDetails.weight} />
          </div>

          <h3 className='text-gray-600 text-md font-medium mt-12'>Bike Details</h3>
          <p className='text-sm mb-3'>Please provide details of the bike to be fitted</p>

          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Bike Brand </label>
              <div className="">
                {otherBrand ?
                  <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{bikeBrand: e.target.value}})}} value={bikeFitDetails.bikeBrand} />
                :
                  <GlobalSearchV2 
                  performSearch={(term)=>{searchForBrand(term)}}
                  searchResults={brands}
                  resultDisplayField={'name'}
                  returnSelectedResult={(index)=>{setBikeFitDetails({...bikeFitDetails, ...{bikeBrand: brands[index].name}})}}
                  placeholderText={`Search for brand`}
                  searchInProgress={searchingBrands}
                />}
              </div>
          </div>

          <div className=' text-right w-full mt-3'>
            <button onClick={()=>{setOtherBrand(!otherBrand)}} className='text-ink-navy text-sm font-medium'>{otherBrand ? 'Search existing brands' : 'Other brand'}</button>
          </div>
          
          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Bike type </label>
              {/* <div className="select-wrapper w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" >
                <select className="w-full outline-none">
                  <option>-- Select type --</option>
                  <option>City Bike</option>
                  <option>Mountain Bike</option>
                  <option>Road Bike</option>
                </select>
              </div> */}
              <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{bikeType: e.target.value}})}} value={bikeFitDetails.bikeType} />
          </div>

          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Average weekly mileage </label>
              <input type="number" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{avgWeeklyMileage: e.target.value}})}} value={bikeFitDetails.avgWeeklyMileage} />
          </div>

          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Bike Size </label>
              <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{bikeSize: e.target.value}})}} value={bikeFitDetails.bikeSize} />
          </div>

          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Bike Model </label>
              <input type="text" className="w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{bikeModel: e.target.value}})}} value={bikeFitDetails.bikeModel} />
          </div>

          <h3 className='text-gray-600 text-md font-medium mt-12'>Bike Fit Details</h3>
          <p className='text-sm mb-3'>Why is this fit needed</p>

          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Issues with current fit </label>
              <textarea type="text" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{currentFitIssues: e.target.value}})}} className=" h-24 w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={bikeFitDetails.currentFitIssues} />
          </div>

          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Goals from bike fit </label>
              <textarea type="text" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{bikeFitGoals: e.target.value}})}} className=" h-24 w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={bikeFitDetails.bikeFitGoals} />
          </div>

          <div className='w-full my-4'>
              <label className="block text-xs text-gray-400 my-2">Other comments </label>
              <textarea type="text" onChange={(e)=>{setBikeFitDetails({...bikeFitDetails, ...{comments: e.target.value}})}} className=" h-24 w-full px-4 py-3 border border-gray-300 text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500" value={bikeFitDetails.comments} />
          </div>
          
          <div className='w-full mt-6'>
            <button onClick={()=>{createRequest()}} disabled={creatingRequest} className='px-4 py-3 text-sm font-medium text-white bg-blue-700 bg-ink-navy rounded transition duration-200 hover:bg-blue-700 mb-6'>
              {creatingRequest? 'Creating request...' : 'Create Bike Fit Request'}
            </button>

            {successMessage !== '' && <SuccessMessage message={successMessage} dismissHandler={()=>{setSuccessMessage('')}} />}

          </div>
        </div>

      </>
   
    )
}

export default NewBikeFitRequest