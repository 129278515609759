import React, { Fragment } from 'react'
import ProductImage  from '../../../assets/images/bike-image.png'
import { Link } from 'react-router-dom'
import DataTable from '../../../components/elements/DataTable'
import { convertCamelCase } from '../../../utils'


const CustomerInsights = () => {
    const products = [
        {
            productId: "11143",
            productImage: ProductImage,
            productName: "Rfr pedals flat salmon sal",
            sampleSku: "14156",
            brand: "RFR",
            year: 2020,
            stock: 1,
            status: "live",
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            stockIncrease: true,
            actions: [],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            selected: false,
            featured: true,
            visibleOn: "",
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11144",
            productName: "Bella 650A/19 - 26",
            sampleSku: "6307190",
            brand: "British Eagle",
            status: "live",
            stock: 3,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: true,
            year: 2018,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11145",
            productName: "403 Granite V2",
            sampleSku: "W403V2-1",
            brand: "Whyte",
            status: "live",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11146",
            productName: "Cube acid 240 disc reefblue/kiwi/red…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11147",
            productName: "Cube cubie 120 green/blue 2020",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11148",
            productName: "Cube cubie 120 walk action team bl…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11149",
            productName: "Trek District+ 1 M Gy 300Wh",
            sampleSku: "32320024",
            brand: "RFR",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11143",
            productName: "Rfr pedals flat salmon sal",
            sampleSku: "14156",
            brand: "RFR",
            status: "live",
            stock: 1,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: true,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11144",
            productName: "Bella 650A/19 - 26",
            sampleSku: "6307190",
            brand: "British Eagle",
            status: "live",
            stock: 3,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: true,
            year: 2018,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11145",
            productName: "403 Granite V2",
            sampleSku: "W403V2-1",
            brand: "Whyte",
            status: "live",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11146",
            productName: "Cube acid 240 disc reefblue/kiwi/red…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11147",
            productName: "Cube cubie 120 green/blue 2020",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11148",
            productName: "Cube cubie 120 walk action team bl…",
            sampleSku: "32320024",
            brand: "Cube",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
        {
            productId: "11149",
            productName: "Trek District+ 1 M Gy 300Wh",
            sampleSku: "32320024",
            brand: "RFR",
            status: "inactive",
            stock: 0,
            stockAvailability: [
                {
                    location: "Leisure lakes - Daventry",
                    quantity: 1
                },
                {
                    location: "Rutland cycling - Bristol",
                    quantity: 2
                }
            ],
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
            categories: ['Bikes', 'Electrical', 'Urban'],
            colors: ['gray', 'red', 'green', 'black'],
            stockIncrease: false,
            year: 2020,
            actions: [],
            productImage: ProductImage,
            selected: false,
            date: "2021-12-21",
            price: 6740.50
        },
    ]
    const tableOptions = {
        selectable: false
    }

    const columnWidths = {
        image: "w-full lg:w-2/12",
        product: "w-full lg:w-5/12",
        date: "w-full lg:w-3/12",
        price: "w-full lg:w-2/12",
    }

    const columnDataStyles = { }



    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            let column = key
            if(key !== 'stockIncrease' && key !== 'selected' && key !== 'description' && key !== 'categories' && key !== 'colors') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const ProductDetail = ({productIndex, name, brand, description, colors, categories}) => {
        return (
            <div className="relative">
                <Link to={`/admin/products/${productIndex}`} className="inline-block -ml-5 w-full cursor-pointer">
                    <label className="font-medium text-sm text-ink-navy mt-5">{name}</label>
                    <p className="mb-3 whitespace-normal text-xs text-gray-500">{description}</p>
                </Link>
            </div>
        )
    }

    const cleanUpProducts = (productsArray) => {
        let finalResult = [] 
        productsArray.forEach((product, productIndex) => {
            const item = {
                image: <img src={product.productImage} alt="" className='w-24' />,
                product : <ProductDetail 
                    productIndex={productIndex} 
                    name={product.productName} 
                    brand={product.brand} 
                    description={product.description}
                    colors={product.colors} 
                    categories={product.categories} />,
                date: product.date,
                price: `£${product.price}`
            }
        finalResult.push(item)
        })
        return finalResult
    }

    return (
        <Fragment>
            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between">
                    <h3 className="mt-6 text-ink-navy font-medium text-md">Customer Product Insights</h3>
                </div>

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpProducts(products)[0]).headers} 
                    tableData={cleanUpProducts(products)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpProducts(products)[0]).fields}
                    onSelectItems={()=>{}}
                    tableOptions={tableOptions}
                />
            </div>
        </Fragment>
    )
}


export default CustomerInsights
