import React, { useState, useRef, useEffect } from 'react'
// import { Switch } from '@headlessui/react'
import ChevronIcon from '../../../../components/elements/icons/ChevronIcon'
import { FileDrop } from 'react-file-drop'
import { isValidObject, slugify } from '../../../../utils'
import Wysiwyg from '../../../../components/elements/Wysiwyg'
// import HorizontalMenuAction from '../../../../components/elements/icons/HorizontalMenuAction'
// import CodeIcon from '../../../../components/elements/icons/CodeIcon'
// import MobilePhoneIcon from '../../../../components/elements/icons/MobilePhoneIcon'
import { useCookies } from 'react-cookie';
import axios from 'axios'
import { baseUrl } from '../../../../utils';
import Spinner from '../../../../components/elements/icons/Spinner'
import ErrorMessage from '../../../../components/elements/ErrorMessage'
import SuccessMessage from '../../../../components/elements/SuccessMessage'
import { useParams } from 'react-router-dom'
const BlogArticle = () => {
      const fileInputRef = useRef(null);
      const { articleId } = useParams()

    const onFileInputChange = (event) => {
        const { files } = event.target;
        // do something with your files...
        handleFile(files)
    }

    const [bgSettingsOpen, setBgSettingsOpen] = useState(true)
    const [adPlacementOpen, setAdPlacementOpen] = useState(true)
    const [ coverImage, setCoverImage ] = useState('')

    const [ fileDetails, setFileDetails ] = useState(null)
    // const [processing, setProcessing] = useState(false)
    // const [ cookies ] = useCookies(['user']);
    // const [error, setError] = useState('')  

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleFile = (files) => {
        setFileDetails({
            name: files[0].name,
            size: `${Math.ceil(files[0].size/1000000).toString()} MB` ,
            type: files[0].type
        })
        let base64Image = '';

        getBase64(files[0], (result) => {
            base64Image = result;
            setCoverImage(base64Image)
        });
    }

    const toggleBgSettings = () => {
        setBgSettingsOpen(!bgSettingsOpen)
    }

    const toggleAdPlacement = () => {
        setAdPlacementOpen(!adPlacementOpen)
    }

    const [postTitle, setPostTitle] = useState('')
    const [content, setContent] = useState('')
    const [articleSlug, setArticleSlug] = useState('')
    const [ metaTitle, setMetaTitle ] = useState('')
    const [ metaDescription, setMetaDescription ] = useState('')

    const [success, setSuccess] = useState(null)

    const loadWysiwygValue = (value) => {
        setContent(value)
    }

    const [ processing, setProcessing ] = useState(false)
    const [ cookies ] = useCookies(['user']);
    const [error, setError] = useState('')  
    
    const [ validationErrors, setValidationErrors ] = useState({})

    const validateArticleForm  = () => {
        let errors = {}
        
        if(!postTitle || postTitle === '') {
            errors.postTitle = true
        }

        if(!articleSlug || articleSlug === '') {
            errors.articleSlug = true
        }
        
        if(!content || content === '') {
            errors.content = true
        }

        setValidationErrors(errors)
        return errors
    }

    useEffect(() => {        
        const getPost = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            // setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/articles/slug/${articleId}`, { headers })            
                console.log(response)
                setPostTitle(response.data.data[0].title)
                setContent(response.data.data[0].content)
                setMetaDescription(response.data.data[0].seo_meta_description)
                setMetaTitle(response.data.data[0].seo_meta_title)
                setArticleSlug(response.data.data[0].slug)
                setCoverImage(`https://obj1-dev.s3.eu-west-2.amazonaws.com${response.data.data[0].image_path}`)
                
                setProcessing(false)

                
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }

        getPost()

    }, [cookies.accessToken, cookies.tokenType])

    const savePost = async () => {
        const headers = {
            'Content-Type': 'application/json', 
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        if (Object.values(validateArticleForm  ()).includes(true)) {
            setError('Please check the highlighted fields')
            return
        }
        
        const requestPayload = {
            title: postTitle,
            title_image_url: coverImage,
            slug: articleSlug,
            content: content,
            seo_meta_description : metaDescription,
            seo_meta_title: metaTitle
        }

        console.log(requestPayload)

        setProcessing(true)

        try {
            await axios.patch(`/api/v1/admin/articles${articleId}`, requestPayload, { headers })            
            setSuccess('Article updated Successfully! You can create another article by filling the form again')


            setProcessing(false)
        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setProcessing(false)
        }
    }

    const updateArticleTitle = (title) => {
        setPostTitle(title)
        setArticleSlug(slugify(title))
    }

    return (
        <div className='relative w-full'>
            <div className='w-full flex flex-row gap-x-8'>
                <div className='w-8/12 px-8'>

                    <div className="w-full mb-5">
                        <label className={`text-xs text-sm block mb-2 ${validationErrors.postTitle && validationErrors.postTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>Title</label>
                        <input 
                            type="text" 
                            className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.postTitle && validationErrors.postTitle === true ? 'border-red-500' : 'border-gray-0'} `}
                            placeholder="Article Title" 
                            value={postTitle}
                            // onChange={(e)=>{setPostTitle(e.target.value)}}
                            onChange={(e)=>{updateArticleTitle(e.target.value)}}
                        />
                    </div>

                    <div className="w-full mt-4">
                        <label className={`text-xs text-sm block mb-2 ${validationErrors.articleSlug && validationErrors.articleSlug === true ? 'text-red-500' : 'text-ink-navy'}`}>Article Slug</label>
                        <input 
                            type="text" 
                            className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.articleSlug && validationErrors.articleSlug === true ? 'border-red-500' : 'border-gray-0'} `} 
                            placeholder="article_title" 
                            value={articleSlug}
                            onChange={(e)=>{setArticleSlug(e.target.value)}}
                            />
                    </div>

                    {/* <div className="w-full mb-5">
                        <label className="text-sm text-gray-500 block mb-2">Summary</label>
                        <textarea type="text" className="border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 resize-none h-32" placeholder="Optionally displayed underneath the title" />
                    </div> */}

                    <div className={`w-full border rounded mt-5 ${validationErrors.content && validationErrors.content === true ? 'border-red-500' : 'border-gray-200'} `} style={{minHeight: '500px'}}>
                        <div className='w-full px-4 p-3 bg-gray-100 text-sm'>
                            <p className='text-ink-navy text-sm'>Content</p>
                        </div>

                        <div className='borderless px-6 long-text'>
                            <Wysiwyg 
                                fieldTitle=""
                                initialValue={content || ''}
                                updateValue={loadWysiwygValue}
                            />
                        </div>

                        {/* <div className='p-6'>
                            <div className='flex flex-row items-center gap-x-4'>
                                <button className='rounded-full p-2 bg-gray-100 transition duration-200 hover:bg-gray-200'>
                                    <MobilePhoneIcon className="h-6 w-6 text-gray-400" />
                                </button>
                                <button className='rounded-full p-2 bg-gray-100 transition duration-200 hover:bg-gray-200'>
                                    <CodeIcon className="h-5 w-5 text-gray-400" />
                                </button>
                                <button className='rounded-full p-2 bg-gray-100 transition duration-200 hover:bg-gray-200'>
                                    <HorizontalMenuAction className="h-5 w-5 text-gray-400" />
                                </button>
                            </div>
                        </div> */}

                    </div>
                </div>

                {/* SIDEBAR */}

                <div className='w-4/12'>
                    <div className=' border border-gray-200'>
                        <div className='p-4 bg-gray-100 flex items-center justify-between'>
                            <h3 className='text-ink-navy text-sm font-medium'>Article Settings</h3>
                            <button onClick={()=>{toggleAdPlacement()}}>
                                <ChevronIcon className={`text-ink-navy w-4 h-4 transition duration-200 transform ${adPlacementOpen ? 'rotate-180' : ''}`} />
                            </button>
                        </div>
                         {adPlacementOpen && <div className='w-full p-6'>
                            <div className='w-full'>
                                <p className='text-ink-navy text-sm mb-5'>UNPUBLISHED</p>

                                <label className='block mb-2 text-gray-400 text-sm'>Visit page</label>
                                <a href="https://www.google.com" className='text-blue-400 text-sm'>https://cycleconcept.co.uk/articles/untitled-article</a>

                                <div className="w-full my-5">
                                    <label className="text-xs text-gray-500 text-sm block mb-2">SEO Meta Title</label>
                                    <input type="text" 
                                        className="border rounded border-gray-400 px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200" 
                                        value={metaTitle}
                                        placeholder="About Cycle Concept" onChange={(e)=>{setMetaTitle(e.target.value)}} />
                                </div> 

                                <div className="w-full my-5">
                                    <label className="text-xs text-gray-500 text-sm block mb-2">SEO Meta Description</label>
                                    <input type="text" className="border rounded border-gray-400 px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200" 
                                        placeholder="About Cycle Concept" 
                                        value={metaDescription}
                                        onChange={(e)=>{setMetaDescription(e.target.value)}} />
                                </div> 
                            </div>
                       </div>}
                        <div className='p-4 bg-gray-100 flex items-center justify-between'>
                            <h3 className='text-ink-navy text-sm font-medium'>Image Settings</h3>
                            <button onClick={()=>{toggleBgSettings()}}>
                                <ChevronIcon className={`text-ink-navy w-4 h-4 transition duration-200 transform ${bgSettingsOpen ? 'rotate-180' : ''}`} />
                            </button>
                        </div>
                        {bgSettingsOpen && <div className='w-full'>
                            <div className='w-full py-3 px-5'>
                                <p className='text-ink-navy text-xs mt-3 mb-4'>Current Post Image</p>
                                <div className='w-full'>
                                {coverImage === '' ?
                                    <>
                                        <input
                                            onChange={onFileInputChange}
                                            ref={fileInputRef}
                                            type="file"
                                            className="hidden"
                                            />
                                        <FileDrop
                                            onTargetClick={onTargetClick}
                                            onFrameDragEnter={(event) => {}}
                                            onFrameDragLeave={(event) => {}}
                                            onFrameDrop={(event) => {}}
                                            onDragOver={(event) => {}}
                                            onDragLeave={(event) => {}}
                                            onDrop={(files, event) => handleFile(files, event)}
                                        >
                                            <div className="flex flex-col items-center justify-center gap-y-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                </svg>
                                                <p className='text-gray-400 text-xs'>Drop an image here or click to browse files</p>
                                            </div>
                                        </FileDrop>
                                    </>
                                    : 
                                    <div className='relative w-full mb-3'>
                                        <div className='absolute top-0 pt-2 pr-2 left-0 w-full flex flex-row-reverse z-50'>
                                            <button 
                                                className='text-white flex items-center justify-center bg-black transition duration-200 bg-opacity-60 rounded w-10 h-10 hover:bg-opacity-90' 
                                                onClick={()=>{
                                                    setCoverImage('')
                                                    setFileDetails(null)
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                                                </svg>
                                            </button>
                                        </div>
                                        <img src={coverImage} alt="" className='w-full' />
                                    </div>
                                }

                                    {isValidObject(fileDetails) && 
                                        <div className='w-full mt-3 mb-5 bg-gray-100 rounded p-5 flex flex-row gap-x-2'>
                                            <div className='w-2/5'>
                                                <img src={coverImage} alt="" />
                                            </div>
                                            <div>
                                                <p className='text-sm text-gray-600 mb-1'>{fileDetails.name}</p>
                                                <p className='text-xs text-gray-600'>{fileDetails.size} - <span className='text-gray-400'>{fileDetails.type}</span></p>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* {!changingBgMobile && 
                                    <div className='flex items-center justify-between'>
                                        <button className='px-4 py-2 rounded border border-ink-navy text-ink-navy bg-transparent text-xs'>
                                            Replace Image
                                        </button>
                                        <button className='text-xs text-red-600 py-3'>Remove Image</button>
                                    </div>
                                } */}

                            </div>
                        </div>}

                        <div className='flex flex-row-reverse items-center gap-x-4 p-6 mt-4'>
                            {/* <button 
                                className='px-4 py-2 rounded border border-blue-700 text-white bg-blue-700 bg-transparent text-xs'
                                onClick={()=>{savePost()}}
                            >
                                Save Post
                            </button> */}
                            {/* <button className='px-4 py-2 rounded border border-ink-navy text-ink-navy bg-transparent text-xs'>
                                Cancel
                            </button> */}

                            <button className="flex flex-row gap-x-3 items-center justify-center rounded bg-ink-navy text-sm hover:bg-blue-900 transition duration-200 w-full py-3 text-center text-white focus:border-transparent focus:outline-none" onClick={()=>{
                                if(!processing) { savePost() }
                                    }}>{processing ? <><Spinner />Saving Post...</> : 'Save Post'}
                            </button>

                        </div>
                        <div className='p-6'>
                            {error && error!=='' &&  
                                <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                            } 

                            {success && success!=='' &&  
                                <SuccessMessage message={success} dismissHandler={()=>{setSuccess(false)}} />
                            } 
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogArticle

