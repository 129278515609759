import React, {Fragment, useState} from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import DataTable from '../../../components/elements/DataTable'
import { Link } from 'react-router-dom'
import { convertCamelCase } from "../../../utils";
import EditIcon  from '../../../assets/images/icons/edit.svg'
import UpRightArrow from '../../../assets/images/icons/arrow-up-right-black.svg'

const Colours = () => {
    const colours = [
        {
            name: "Zeroblack",
            swatchType: "solid",
            colors: ["#000000"],
            actions: ""
        },        
        {
            name: "Radioactive red to navy to teal fade",
            swatchType: "gradient",
            colors: ["#ff0000", "#000080", "#008080"],
            actions: ""
        },        
        {
            name: "Satin Marigold",
            swatchType: "solid",
            colors: ["#FFB801"],
            actions: ""
        },        
        {
            name: "Gravel",
            swatchType: "solid",
            colors: ["#D7D0D3"],
            actions: ""
        },        
        {
            name: "Matte Carbon Smoke/Gloss Trek, Black",
            swatchType: "solid",
            colors: ["#3E3E3E"],
            actions: ""
        },        
        {
            name: "Black Olive/Carbon Smoke",
            swatchType: "gradient",
            colors: ["#000000", "#BAB86C"],
            actions: ""
        },
        {
            name: "Carbon Blue Smoke/Trek Black",
            swatchType: "gradient",
            colors: ["#3E3E3E", "#000080"],
            actions: ""
        },
        {
            name: "Pink Frosting",
            swatchType: "solid",
            colors: ["#E08CC3"],
            actions: ""
        },
        {
            name: "Pink Frosting",
            swatchType: "solid",
            colors: ["#E08CC3"],
            actions: ""
        },
        {
            name: "Lithium Grey/Carbon Red",
            swatchType: "gradient",
            colors: ["#5b1b1b", '#808080'],
            actions: ""
        },
        {
            name: "Diablo Red",
            swatchType: "solid",
            colors: ["#7C1112"],
            actions: ""
        },
        {
            name: "Nautical Navy/Miami Green",
            swatchType: "gradient",
            colors: ["#113258", "#3DC6AB"],
            actions: ""
        },
        {
            name: "Slate/Miami Green",
            swatchType: "gradient",
            colors: ["#C0C2C9", "#3DC6AB"],
            actions: ""
        },
    ]

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const tableOptions = {
        selectable: false
    }

    const columnWidths = {
        colourName: 'w-full lg:w-6/12',
        swatch: 'w-full lg:w-3/12',
        actions:'w-full lg:w-3/12'
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]


    const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedReviewsCount(count)
    }

    const EditButton = ({colourIndex}) => {
        return (
            <Link to={`stores/detail/${colourIndex}`} className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
        )
    }

    const ColourSwatch = ({swatchType, colours}) => {

        const setColours = () => {
            const coloursDirectionArray = ['0.25turn']
            let background = null

            if (swatchType === 'gradient') {
                colours.forEach((colour)=> {
                    coloursDirectionArray.push(colour)
                })
                background = `linear-gradient(${coloursDirectionArray.join(', ')})`
            } else {
                background = colours[0]
            }

            return background
        } 

        return (
            <div style={{background: setColours(), width: '60px', height: '20px'}} />
        )
    }
    
    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            const requestObject = {
                colourName: object.name,
                swatch: <ColourSwatch swatchType={object.swatchType} colours={object.colors}  />,
                actions:<EditButton colourIndex={objectIndex} />
            }
            finalResult.push(requestObject)
        })
        return finalResult
    }

    return (
        <Fragment>

            <div className="w-full">
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/6">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search stores" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap lg:flex-nowrap">
                        {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2 hidden lg:inline" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <span className='p-px rounded-full border border-ink-navy mr-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                           </span>
                            Update default logos
                        </button>
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={UpRightArrow} alt="search" className="w-3 mr-2 hidden lg:inline" /> Downloads page
                        </button>
                        <Link to="stores/new-store" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            Add Download
                        </Link>
                    </div>
                </div>

                {/* Table */}

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData(colours)[0]).headers} 
                    tableData={cleanUpData(colours)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpData(colours)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />
                
            </div>
        </Fragment>
    )
}

export default Colours
