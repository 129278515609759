import React, { Fragment, useState } from 'react'
import WhiteLogo from '../assets/images/logo-w.png'
import { Link, useLocation } from "react-router-dom";
import Spinner from '../components/elements/icons/Spinner';
import axios from "axios"
import { baseUrl } from '../utils';
import ErrorMessage from '../components/elements/ErrorMessage'
import SuccessMessage from '../components/elements/SuccessMessage';
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';

const PasswordResetPage = ( {match} ) => {
    const history = useHistory();
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')  
    const [success, setSuccess] = useState('')  

    const [ newPassword, setNewPassword ] = useState('')
    const [ retypedNewPassword, setRetypedNewPassword ] = useState('')
    const [ validationErrors, setValidationErrors ] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [ cookies, setCookie, removeCookie ] = useCookies(['passwordReset']);

    const validateForm = () => {
        let errors = {}
        if(!newPassword || newPassword === '') {
            errors.newPassword = true
        }
        if(!retypedNewPassword || retypedNewPassword === '') {
            errors.retypedNewPassword = true
        }
        if(newPassword !== retypedNewPassword) {
            errors.retypedNewPassword = true
        }

        setValidationErrors(errors)
        return errors
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    const resetPassword = async () => {
        const headers = {
            'Content-Type': 'application/json'
        }
        
        if (Object.values(validateForm()).includes(true)) {
            setError('Please check the highlighted fields above')
            return
        }

        setProcessing(true)
        const token = query.get('token');
        const requestPayload = {
            token: token,
            email: cookies.email,
            password: newPassword,
            password_confirmation: retypedNewPassword
        }

        try {
            const response = await axios.post(`/api/v1/auth/password-reset/reset`, requestPayload, { headers })            
            console.log(response)
            setSuccess("Awesome! your password has been reset. Redirecting to log in...")
            setTimeout(() => {
                history.push("/");
            }, 3000);
            setProcessing(false)
        } catch (error) {
            setError(error.response.data.user_message)
            setProcessing(false)
        }
    }

    return (
        <Fragment>
            <div className="w-full block lg:flex flex-row items-center">
                <div className="h-32 lg:h-screen w-full bg-ink-navy flex flex-col gap-y-8 items-center py-24">
                    <div className="w-36">
                        <img src={WhiteLogo} alt="push retail logo" />
                    </div>
                    <div className='rounded-lg w-4/5 md:w-3/5 lg:w-2/5 bg-white shadow-lg p-10 flex flex-col'>

                        <div className="w-full mb-10">
                            <p className="relative text-ink-navy text-xl">Set a new password</p>
                            {/* <p> No problem, just provide your email and we'll get you a new one</p> */}
                        </div>

                        <label className={`text-sm transition duration-200 ${validationErrors.newPassword && validationErrors.newPassword === true ? 'text-red-500' : 'text-ink-navy'}`}>New Password </label> 
                        <input 
                            type="password" 
                            placeholder="Your new password" 
                            className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.newPassword && validationErrors.newPassword === true ? 'text-red-500 border-red-500' : ''}`}
                            onChange={(e) => {setNewPassword( e.target.value )}}
                        /> 
                        <label className={`text-sm transition duration-200 ${validationErrors.retypedNewPassword && validationErrors.retypedNewPassword === true ? 'text-red-500' : 'text-ink-navy'}`}>Re-Type New Password </label> 
                        <input 
                            type="password" 
                            placeholder="Re-type your new password" 
                            className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.retypedNewPassword && validationErrors.retypedNewPassword === true ? 'text-red-500 border-red-500' : ''}`}
                            onChange={(e) => {setRetypedNewPassword( e.target.value )}}
                        /> 
                        <button 
                            className="flex flex-row gap-x-3 items-center justify-center rounded bg-ink-navy text-sm hover:bg-blue-900 transition duration-200 w-full py-3 text-center text-white focus:border-transparent focus:outline-none mt-3" 
                            onClick={()=>{
                            if(!processing) { resetPassword() }
                                }}>{processing ? <><Spinner />Resetting password...</> : 'Reset password'}
                        </button>

                        <p className="mt-3 text-sm">Done with this? <Link to="/" className="text-blue-800 hover:text-blue-900">Go to log in</Link></p>


                        {error && error!=='' &&  
                            <ErrorMessage message={error} dismissHandler={()=>{setError('')}} />
                        } 
                        
                        {success && success!=='' &&  
                            <SuccessMessage message={success} dismissHandler={()=>{setSuccess('')}} />
                        } 
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default PasswordResetPage
