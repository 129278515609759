import React, { Fragment, useState } from 'react'
import UserIcon from '../../../assets/images/icons/user-line-icon.svg'
import SocialMarketingIcon from '../../../assets/images/icons/social-marketing-icon.svg'
import CommunicationIcon from '../../../assets/images/icons/communication-icon.svg'
import WebStyleIcon from '../../../assets/images/icons/web-style-icon.svg'
import MessagingIcon from '../../../assets/images/icons/messaging-icon.svg'
import IntegrationIcon from '../../../assets/images/icons/integration-icon.svg'
import CustomCssIcon from '../../../assets/images/icons/custom-css-icon.svg'
import BusinessProfile from '../../../components/partials/settings/BusinessProfile'
import SocialMarketing from '../../../components/partials/settings/SocialMarketing'
import Communication from '../../../components/partials/settings/Communication'
import WebStyle from '../../../components/partials/settings/WebStyle'
import OnsiteMessaging from '../../../components/partials/settings/OnsiteMessaging'
import Integration from '../../../components/partials/settings/Integration'
import CustomCss from '../../../components/partials/settings/CustomCss'

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

const AdminSettings = () => {
    const tabs = [
        {
            label: "Business Profile",
            description: "Adjust your business name, address, currency, domain name etc.",
            icon: UserIcon
        },
        {
            label: "Social Marketing",
            description: "Add links to your social media and connect social apps",
            icon: SocialMarketingIcon
        },
        {
            label: "Contact/Communication",
            description: "Manage all contact touch points and choose how/what displays",
            icon: CommunicationIcon
        },
        {
            label: "Web Style",
            description: "Make quick changes to the appearance of web elements",
            icon: WebStyleIcon
        },
        {
            label: "On-site Messaging",
            description: "Adjust on-site messaging for your customers, eg. out of stock",
            icon: MessagingIcon
        },
        {
            label: "Integration",
            description: "Connect third-party applications to the PushRetail platfom",
            icon: IntegrationIcon
        },
        {
            label: "Custon CSS",
            description: "For advanced users looking to change the style of their website",
            icon: CustomCssIcon
        },
    ]
    
    const [activeTab, setActiveTab] = useState(0)
    const [selected, setSelected] = useState(tabs[0])

    const changeSelected = (tab) => {
        console.log('....', tab)
        setActiveTab(tabs.indexOf(tab))
        setSelected(tab)
    }

    return (
        <Fragment>
            <div className="flex flex-row"> 
                <div className="hidden lg:block min-h-screen bg-white w-1/4 border-l border-r border-gray-200 ml-1 text-left">
                    {tabs.map(( tab, tabIndex) => (
                        <div key={tabIndex} className={`p-8 cursor-pointer border-b border-gray-200 ${activeTab === tabIndex ? 'bg-blue-100 bg-opacity-50' : ''}`} onClick={()=>{setActiveTab(tabIndex)}}>
                            <div className="flex flex-row">
                                <div className="w-2/12 mr-2">
                                    <img src={tab.icon} className="w-4" alt="" />
                                </div>
                                <div>
                                    <p className={`text-sm ${activeTab === tabIndex ? 'text-ink-navy font-medium' : 'text-gray-600'}`}>{tab.label}</p>
                                    <p className="text-gray-400 mt-2 text-xs">{tab.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}                    
                </div>

                <div className="min-h-screen bg-white w-full lg:w-3/4 p-10">
                    <div className="lg:hidden block mb-6">
                        <label className='text-sm font-medium text-ink-navy block mb-3'>Settings Menu</label>
                        <Listbox value={selected} onChange={changeSelected}>
                            <div className="w-full relative  mt-1">
                                <Listbox.Button className={`relative w-full text-left block`}>
                                    {/* <span className="block truncate text-sm lg:text-xs">{selected.name}</span> */}
                                    <div className="w-full border-gray-200 rounded-lg shadow p-3 bg-white flex flex-row gap-x-4">
                                        <img src={selected.icon} alt="" className="w-5" />
                                        <span className="text-sm bottom-0 font-sofia-pro text-ink-navy">{selected.label}</span>
                                    </div>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon
                                            className="w-6 h-6 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {tabs.map((option, optionIndex) => (
                                        <Listbox.Option
                                            key={optionIndex}
                                            className={({ active }) =>
                                                `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                                    cursor-default select-none relative py-2 pl-10 pr-4`
                                            }
                                            value={option}
                                            // onClick={()=>{setActiveTab(optionIndex)}}
                                            >
                                            {({ selected, active }) => (
                                                <>
                                                <span
                                                    className={`${
                                                        selected ? 'font-medium' : 'font-normal'
                                                        } block truncate`
                                                    }
                                                >
                                                    <div className="w-full border-gray-200 rounded-lg shadow p-3 bg-white flex flex-row items-center gap-x-4">
                                                        <img src={option.icon} alt="Logo" className="w-5" />
                                                        <span className="text-sm bottom-0 font-sofia-pro text-gray-500">{option.label}</span>
                                                    </div>
                                                </span>
                                                {selected ? (
                                                    <span
                                                    className={`${
                                                        active ? 'text-amber-600' : 'text-amber-600'
                                                    } absolute inset-y-0 left-0 flex items-center pl-3`}
                                                    >
                                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>      
                    </div>

                    {tabs[activeTab].label === "Business Profile" && <BusinessProfile />}                
                    {tabs[activeTab].label === "Social Marketing" && <SocialMarketing />}                
                    {tabs[activeTab].label === "Contact/Communication" && <Communication />}                
                    {tabs[activeTab].label === "Web Style" && <WebStyle />}                
                    {tabs[activeTab].label === "On-site Messaging" && <OnsiteMessaging />}                
                    {tabs[activeTab].label === "Integration" && <Integration />}                
                    {tabs[activeTab].label === "Custon CSS" && <CustomCss />}                
                </div>
            </div>
        </Fragment>
    )
}

export default AdminSettings
