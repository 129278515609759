import React, { useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
// import SettingsIcon from '../../assets/images/icons/settings.svg'
// import PopularProductsIcon from '../../assets/images/icons/popular-products.svg'
import DashboardIcon from '../elements/icons/DashboardIcon'
import OrdersIcon from '../elements/icons/OrdersIcon'
import ProductsIcon from '../elements/icons/ProductsIcon'
import NavigationsIcon from '../elements/icons/NavigationsIcon'
import BookingsIcons from '../elements/icons/BookingsIcon'
import CustomersIcon from '../elements/icons/CustomersIcon'
import PopularProductsIcon from '../elements/icons/PopularProductsIcon'
import SettingsIcon from '../elements/icons/SettingsIcon'
import SupportIcon from '../elements/icons/SupportIcon'

const MobileMenu = ({toggleMenu}) => {
    const drawers = {
        dashboard: [],
        orders: [
            {
                label: "All Orders",
                location: "/admin/orders/all-orders/all",
                subLinks: []
            },
            {
                label: "Special Requests",
                location: "/admin/orders/special-requests",
                subLinks: []
            },
            {
                label: "Reviews",
                location: "/admin/orders/reviews",
                sublinks: []
            },
            {
                label: "Bike Fit Requests",
                location: "/admin/orders/bike-fit-requests",
                sublinks: []
            },
            {
                label: "Postage",
                location: "/admin/orders/postage",
                sublinks: []
            },
            {
                label: "Size Guide",
                location: "/admin/orders/size-guide",
                sublinks: []
            },
            {
                label: "Conditional Content",
                location: "/admin/orders/conditional-page-content",
                sublinks: []
            },
            {
                label: "Voucher Codes",
                location: "/admin/orders/voucher-codes",
                sublinks: []
            },
            {
                label: "Stores",
                location: "/admin/orders/stores",
                sublinks: []
            },
        ],
        products: [
            {
                label: "All products",
                location: "/admin/products/all-products",
                subLinks: []
            },
            {
                label: "Groups",
                location: "/admin/products/groups",
                sublinks: []
            },
            {
                label: "Suppler Data (SIM)",
                location: "/admin/products/supplier-data",
                sublinks: []
            },
            {
                label: "Suppler Stock Feeds",
                location: "/admin/products/supplier-stock-feeds",
                sublinks: []
            }
        ],
        content: [
            {
                label: "Navigations",
                location: "/admin/navigations/mega-nav",
                subLinks: []
            },
            {
                label: "Pages",
                location: "/admin/navigations/pages",
                sublinks: []
            },
            {
                label: "Brands",
                location: "/admin/navigations/brands",
                sublinks: []
            },
            {
                label: "Colours",
                location: "/admin/navigations/colours",
                sublinks: []
            },
            {
                label: "Categories",
                location: "/admin/navigations/categories",
                sublinks: []
            },
            {
                label: "Downloads",
                location: "/admin/navigations/downloads",
                sublinks: []
            },
            {
                label: "Main Slider",
                location: "/admin/navigations/sliders/main-slider",
                subLinks: []
            },
            {
                label: "Sale Slider",
                location: "/admin/navigations/sliders/sale-slider",
                subLinks: []
            },
            {
                label: "Ads Block",
                location: "/admin/navigations/ads-block",
                sublinks: []
            },
            {
                label: "Blog/Articles",
                location: "/admin/navigations/blog-articles",
                sublinks: []
            }
        ],
        bookings: [
            {
                label: "Bookings Calendar",
                location: "/admin/bookings/bookings-calendar",
                subLinks: []
            },
            {
                label: "All Bookings",
                location: "/admin/bookings/all-bookings",
                sublinks: []
            }
        ],
        reports: [
            {
                label: "Product Data Quality",
                location: "/admin/reports/product-data-quality",
                subLinks: []
            },
            {
                label: "Popular Products",
                location: "/admin/reports/popular-products",
                subLinks: []
            },
            {
                label: "Best Promotions",
                location: "/admin/reports/best-promotions",
                subLinks: []
            },
            {
                label: "Sales Overview",
                location: "/admin/reports/sales-overview",
                subLinks: []
            },
            {
                label: "Product Sales",
                location: "/admin/reports/product-sales",
                subLinks: []
            },
        ],
        settings: [
            {
                label: "Admin Settings",
                location: "/admin/settings/admin-settings",
                subLinks: []
            },
            {
                label: "User Management",
                location: "/admin/settings/user-management",
                subLinks: []
            }
        ],
        support: [
            {
                label: "All tickets",
                location: "/admin/support/all-tickets"
            },
            {
                label: "Unassigned",
                location: "/admin/support/unassigned"
            },
            {
                label: "Assigned to me",
                location: "/admin/support/my-tickets"
            },
            {
                label: "Bike fit requests",
                location: "/admin/settings/admin-settings",
                subLinks: []
            },
            {
                label: "Special requests",
                location: "/admin/settings/user-management",
                subLinks: []
            },
            {
                label: "Notes",
                location: "/admin/settings/roles-permissions",
                subLinks: []
            },
            {
                label: "FAQs",
                location: "/admin/settings/roles-permissions",
                subLinks: []
            }
        ]
    }

    // const [drawerActive, setDrawerActive] = useState(false)
    const [activeDrawer, setActiveDrawer] = useState(null)

    const openDrawer = (drawer) => {
        setActiveDrawer(drawer)
    }

    const history = useHistory()
    const location = useLocation()

    const navigateToTarget = (target) => {
        toggleMenu()
        history.push(target)
    }

    return (
        <div className='fixed top-2 left-2 w-10/12 md:w-6/12 z-40 bg-ink-navy rounded-xl p-8 min-h-screen shadow-md border-2 border-white'>
            <button className="text-white absolute top-3 right-3" onClick={()=>{toggleMenu()}}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <NavLink to="/admin/dashboard" className="px-4 py-2 border-l-2 border-transparent text-white flex flex-row gap-x-3 items-center text-sm" activeClassName="border-white">
                {/* <img src={DashboardIcon} alt="dashboard" className="w-5" /> */}
                <DashboardIcon /> Dashboard
            </NavLink>


            <button 
                className={`${location.pathname.includes('orders') ? 'border-white' : ''} px-4 py-2 border-l-2 border-transparent text-white flex flex-row gap-x-3 items-center text-sm`}
                onClick={()=>{openDrawer('orders')}}
            >
                <OrdersIcon /> Orders
            </button>
            {(activeDrawer === 'orders' || location.pathname.includes('orders')) && 
                <div className='p-3 ml-3'>
                    {drawers.orders.map((item, itemIndex) => (
                        <button onClick={()=>{navigateToTarget(item.location)}} key={itemIndex} className={`${location.pathname === item.location ? 'bg-white bg-opacity-70 text-ink-navy' : ''} text-left py-2 px-4 text-white text-sm text-opacity-60 block w-full rounded`}>{item.label}</button>
                    ))}
                </div>
            }

            <button 
                className={`${location.pathname.includes('admin/products') ? 'border-white' : ''} px-4 py-2 border-l-2 border-transparent text-white flex flex-row gap-x-3 items-center text-sm`}
                onClick={()=>{openDrawer('products')}}
            >
                <ProductsIcon /> Products
            </button>
            {(activeDrawer === 'products' || location.pathname.includes('admin/products')) && 
                <div className='p-3 ml-3'>
                    {drawers.products.map((item, itemIndex) => (
                        <button onClick={()=>{navigateToTarget(item.location)}} key={itemIndex} className={`${location.pathname === item.location ? 'bg-white bg-opacity-70 text-ink-navy' : ''} text-left py-2 px-4 text-white text-sm text-opacity-60 block w-full rounded`}>{item.label}</button>
                    ))}
                </div>
            }


            <button 
                className={`${location.pathname.includes('navigations') ? 'border-white' : ''} px-4 py-2 border-l-2 border-transparent text-white flex flex-row gap-x-3 items-center text-sm`}
                onClick={()=>{openDrawer('navigations')}}
            >
                <NavigationsIcon /> Content
            </button>
            {(activeDrawer === 'navigations' || location.pathname.includes('navigations')) && 
                <div className='p-3 ml-3'>
                    {drawers.navigations.map((item, itemIndex) => (
                        <button onClick={()=>{navigateToTarget(item.location)}} key={itemIndex} className={`${location.pathname === item.location ? 'bg-white bg-opacity-70 text-ink-navy' : ''} text-left py-2 px-4 text-white text-sm text-opacity-60 block w-full rounded`}>{item.label}</button>
                    ))}
                </div>
            }

            
            <button 
                className={`${location.pathname.includes('bookings') ? 'border-white' : ''} px-4 py-2 border-l-2 border-transparent text-white flex flex-row gap-x-3 items-center text-sm`}
                onClick={()=>{openDrawer('bookings')}}
            >
                <BookingsIcons /> Bookings
            </button>
            {(activeDrawer === 'bookings' || location.pathname.includes('bookings')) && 
                <div className='p-3 ml-3'>
                    {drawers.bookings.map((item, itemIndex) => (
                        <button onClick={()=>{navigateToTarget(item.location)}} key={itemIndex} className={`${location.pathname === item.location ? 'bg-white bg-opacity-70 text-ink-navy' : ''} text-left py-2 px-4 text-white text-sm text-opacity-60 block w-full rounded`}>{item.label}</button>
                    ))}
                </div>
            }


            <NavLink to="/admin/customers" className="px-4 py-2 border-l-2 border-transparent text-white flex flex-row gap-x-3 items-center text-sm" activeClassName="border-white">
                <CustomersIcon /> Customers
            </NavLink>


            <button 
                className={`${location.pathname.includes('reports') ? 'border-white' : ''} px-4 py-2 border-l-2 border-transparent text-white flex flex-row gap-x-3 items-center text-sm`}
                onClick={()=>{openDrawer('reports')}}
            >
                <PopularProductsIcon /> Reports
            </button>
            {(activeDrawer === 'reports' || location.pathname.includes('reports')) && 
                <div className='p-3 ml-3'>
                    {drawers.reports.map((item, itemIndex) => (
                        <button onClick={()=>{navigateToTarget(item.location)}} key={itemIndex} className={`${location.pathname === item.location ? 'bg-white bg-opacity-70 text-ink-navy' : ''} text-left py-2 px-4 text-white text-sm text-opacity-60 block w-full rounded`}>{item.label}</button>
                    ))}
                </div>
            }

            <button 
                className={`${location.pathname.includes('settings') ? 'border-white' : ''} px-4 py-2 border-l-2 border-transparent text-white flex flex-row gap-x-3 items-center text-sm`}
                onClick={()=>{openDrawer('settings')}}
            >
                <SettingsIcon /> Settings
            </button>
            {(activeDrawer === 'settings' || location.pathname.includes('settings')) && 
                <div className='p-3 ml-3'>
                    {drawers.settings.map((item, itemIndex) => (
                        <button onClick={()=>{navigateToTarget(item.location)}} key={itemIndex} className={`${location.pathname === item.location ? 'bg-white bg-opacity-70 text-ink-navy' : ''} text-left py-2 px-4 text-white text-sm text-opacity-60 block w-full rounded`}>{item.label}</button>
                    ))}
                </div>
            }

            <button 
                className={`${location.pathname.includes('support') ? 'border-white' : ''} px-4 py-2 border-l-2 border-transparent text-white flex flex-row gap-x-3 items-center text-sm`}
                onClick={()=>{openDrawer('support')}}
            >
                <SupportIcon /> Support
            </button>
            {(activeDrawer === 'support' || location.pathname.includes('support')) && 
                <div className='p-3 ml-3'>
                    {drawers.support.map((item, itemIndex) => (
                        <button onClick={()=>{navigateToTarget(item.location)}} key={itemIndex} className={`${location.pathname === item.location ? 'bg-white bg-opacity-70 text-ink-navy' : ''} text-left py-2 px-4 text-white text-sm text-opacity-60 block w-full rounded`}>{item.label}</button>
                    ))}
                </div>
            }
        </div>
    )
}

export default MobileMenu
