import React, { useState, Fragment, useEffect } from 'react'
import Address from '../../../components/partials/customer/Address'
import ContactDetails from '../../../components/partials/customer/ContactDetails'
import CustomerBookings from '../../../components/partials/customer/CustomerBookings'
import CustomerInsights from '../../../components/partials/customer/CustomerInsights'
import CustomerOrders from '../../../components/partials/customer/CustomerOrders'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../utils';
import Spinner from '../../../components/elements/icons/Spinner';
import { useParams } from 'react-router'

const CustomerDetail = () => {
    const tabs = [
        {
            tabId: "contact-details",
            tabLabel: "Contact Details",
            all: 2375
        },
        {
            tabId: "address",
            tabLabel: "Address",
            count: 95
        },
        {
            tabId: "orders",
            tabLabel: "Orders",
            count: 22
        },
        // {
        //     tabId: "bookings",
        //     tabLabel: "Bookings",
        //     count: 18
        // },
        // {
        //     tabId: "insight",
        //     tabLabel: "Insight",
        //     count: 208
        // }
    ]
    const [activeTab, setActiveTab] = useState(tabs[0].tabId)
    let changeActiveTab = (tab) => {
        setActiveTab(tab.tabId)
    }

    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])
    const [ customerDetails, setCustomerDetails ] = useState({})
    // const [ customerAddresses, setCustomerAddresses ] = useState([])
    const { id } = useParams()


    useEffect(() => {
        const fetchCustomerDetails = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try {
                
                const response = await axios.get(`/api/v1/admin/customer/${id}`, { headers })            
                console.log(response)
                setCustomerDetails(response.data.data)
                
                setProcessing(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }
        fetchCustomerDetails()
    }, [ cookies.accessToken, cookies.tokenType, id ])

    return (
        <Fragment>
        {/* Tabs */}
        <div className="w-full flex flex-row items-center justify-between">
            <div className="flex flex-row gap-x-1 border-b border-gray-300 w-4/5">
                {/* {tabs.map((tab, tabIndex) =>( */}
                {tabs.slice(0, 6).map((tab, tabIndex) =>(
                    <button 
                        key={tabIndex} 
                        className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                        onClick={()=>{changeActiveTab(tab)}}>
                        {tab.tabLabel} 
                    </button>
                ))}
            </div>
            {/* <button className="px-5 py-3 rounded text-white font-sofia-pro text-xs bg-blue-700 hover:bg-blue-500 transition duration-200 flex items-center"> 
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                New Booking
            </button> */}
        </div>
        {/* Tab area */}
        {!processing && error === '' ?
            <div className="w-full py-7">
                {activeTab === 'contact-details' && 
                    <ContactDetails details={{
                        firstName: customerDetails.user.first_name,
                        lastName: customerDetails.user.last_name,
                        email: customerDetails.user.email,
                        phone: customerDetails.mobile_code && customerDetails.mobile_number ? `${customerDetails.mobile_code} ${customerDetails.mobile_number}` : '',
                        passwordPlaceholder: "iweuryt489853"
                    }} />
                }
                {activeTab === 'address' && 
                    <Address addresses={customerDetails.addresses} shipping={customerDetails.default_shipping_address_id} billing={customerDetails.default_billing_address_id} />
                }
                {activeTab === 'orders' && <CustomerOrders customerId={customerDetails.id}/>}
                {activeTab === 'bookings' && <CustomerBookings />}
                {activeTab === 'insight' && <CustomerInsights />}
            </div>
            // <p>loaded</p>
            :
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                <p className='text-sm text-gray-500 mt-5'>Fetching customer details...</p>
            </div>
        }
    </Fragment>
    )
}

export default CustomerDetail
