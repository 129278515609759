import React, { Fragment, useState } from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
import EditIcon  from '../../../assets/images/icons/edit.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import DataTable from '../../../components/elements/DataTable'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import Increase  from '../../../assets/images/icons/arrow-increase.svg'
import Reduce  from '../../../assets/images/icons/arrow-reduce.svg'
import { Link } from 'react-router-dom'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

const bulkActions = [
    {
        label: "Change Status",
        icon: null,
        link: "/",
        action: null
    },
    {
        label: "delete",
        icon: null,
        link: "/",
        action: null
    },
]

const products = [
    {
        id: "192590",
        image: "https://media.alltricks.com/hd/55795dd2bd487.jpg",
        name: "IsoZone Handlebar Plugs",
        description: "Bontrager / 2021",
        price: 2.99,
        sale: 0,
        views: 168,
        stock: 8,
        stockIncrease: true,
        supStock: 15,
        supStockIncrease: true,
        category: "Component", 
        childCategory: "Bars",
        grandChildCategory: "",
        action: ""
    },
    {
        id: "192591",
        image: "https://trek.scene7.com/is/image/TrekBicycleProducts/AdventureFrameBag_25829_A_Primary?wid=1200",
        name: "Adventure Frame Bag",
        description: "Bontrager / 45cm (l) x 5cm (w) x 12cm (h) / 2021",
        price: 66.99,
        sale: 0,
        views: 274,
        stock: 1,
        stockIncrease: false,
        supStock: 20,
        upStockIncrease: false,
        category: "Accessories", 
        childCategory: "Bags",
        grandChildCategory: "",
        action: ""
    },
    {
        id: "192592",
        image: "https://trek.scene7.com/is/image/TrekBicycleProducts/BontragerSatellitePlusIsoZoneBarEndAdapter_11155_A_Primary?wid=1200",
        name: "Satellite Plus IsoZone Bar End Adapter",
        description: "Bontrager 2021",
        price: 12.99,
        sale: 0,
        views: 246,
        stock: 1,
        stockIncrease: true,
        supStock: 20,
        upStockIncrease: true,
        category: "Component", 
        childCategory: "Bars",
        grandChildCategory: "",
        action: ""
    },
    {
        id: "19593",
        image: "https://trek.scene7.com/is/image/TrekBicycleProducts/1548000_2018_A_1_Kickster?wid=1200",
        name: "Kickster",
        description: "Trek / 12 / 2022",
        price: 600,
        sale: 0,
        views: 146,
        stock: 1,
        stockIncrease: false,
        supStock: 0,
        upStockIncrease: false,
        category: "Bikes", 
        childCategory: "Kids",
        grandChildCategory: "",
        action: ""
    },
    {
        id: "19594",
        image: "https://trek.scene7.com/is/image/TrekBicycleProducts/DualSport2Womens_21_32890_A_Primary?$responsive-pjpg$&cache=on,on&wid=800&hei=600",
        name: "Dual Sport 2 Women’s",
        description: "Trek / L / 2021",
        price: 220,
        sale: 0,
        views: 170,
        stock: 1,
        stockIncrease: false,
        supStock: 20,
        upStockIncrease: true,
        category: "Bikes", 
        childCategory: "Hybrid",
        grandChildCategory: "",
        action: ""
    },
    {
        id: "19595",
        image: "https://trek.scene7.com/is/image/TrekBicycleProducts/BontragerXXXWaterBottleCage_25488_A_Primary?$responsive-pjpg$&cache=on,on&wid=800&hei=600",
        name: "XXX Water Bottle Cage",
        description: "Bontrager / 2022",
        price: 49.99,
        sale: 0,
        views: 691,
        stock: 0,
        stockIncrease: false,
        supStock: 0,
        upStockIncrease: false,
        category: "Accessories", 
        childCategory: "Bottles & Cages",
        grandChildCategory: "",
        action: ""
    },
]

const AllPopularProducts = () => {
    const categories = [
        {
            displayName: "Bikes",
            categoryId: "bikes"
        },
        {
            displayName: "E-Bikes",
            categoryId: "e-bikes",
        },
        {
            displayName: "Helmets",
            categoryId: "helmets",
        },
        {
            displayName: "Clothing",
            categoryId: "clothing",
        },
        {
            displayName: "Accessories",
            categoryId: "accessories",
        },
        {
            displayName: "Component",
            categoryId: "component",
        },
    ]

    const [activeTab, setActiveTab] = useState(0)
    let changeActiveTab = (categoryIndex) => {
        // if (tab.tabId === 'all') {
        //     setAllOrders(orders)
        // } else {
        //     const filtered = orders.filter((order) => {
        //         return order.status === tab.tabLabel;
        //     })
        //     setAllOrders(filtered)
        // }

        setActiveTab(categoryIndex)
    }

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const convertCamelCase = (camelCaseText) => {
        const text = camelCaseText;
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult
    }

    const tableOptions = {
        selectable: true
    }

    const columnWidths = {
        id: "w-full lg:w-1/12",
        image: "w-4/12 lg:w-1/12",
        product: "w-full lg:w-3/12",
        section: "w-full lg:w-2/12",
        price: "w-full lg:w-1/12",
        sale: "w-full lg:w-1/12",
        views: "w-full lg:w-1/12",
        stock: "w-full lg:w-1/12",
        supStock: "w-full lg:w-1/12",
        action: "w-full lg:w-1/12",
    }

    const columnDataStyles = {
        image: 'w-4/6' 
    }

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key

            if(key === 'id') {
                columnDisplayName = '#ID'
                columnDataType = 'link'
            }

            if(key === 'image') {
                columnDataType = 'image'
                sortable = false
            }
            
            if(key === 'action') {
                sortable = false
            }

            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });
        return {headers, fields}
    }

    const EditProductButton = ({productId}) => {
        return (
            <Link to={`product/${productId}`} className="font-sofia-pro rounded py-4 px-3 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
        )
    }

    const ProductColumn = ({productName, productDescription}) => {
        return (
            <>
                <p className="font-medium text-ink-navy text-xs">{productName}</p>
                <p className="font-medium text-gray-500 text-xs">{productDescription}</p>
            </>
        )
    }
    
    const CategoryColumn = ({category, childCategory, grandChildCategory}) => {
        return (
            <div className="flex flex-row gap-x-2 items-center">
               <span className="text-ink-navy text-xs font-sofia-pro">
                    {category}
               </span>
               {childCategory !== "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>}
               <span className="text-ink-navy text-xs font-sofia-pro">
                    {childCategory}
               </span>
               {grandChildCategory !== "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>}
               <span className="text-ink-navy text-xs font-sofia-pro">
                    {grandChildCategory}
               </span>
            </div>
        )
    }

    const ProductStock = ({stock, stockIncrease}) => {
        return (
            <div className="relative">
                <span >
                    {stock} <img alt="" src={stockIncrease ? Increase : Reduce} className="inline ml-1 w-2" />
                </span>
            </div>
        )
    }

    const cleanUpProducts = (products) => {
        let finalResult = [] 
        products.forEach((product, productIndex) => {
            const newProductObject = {
                id: product.id,
                image: product.image,
                product: <ProductColumn productName={product.name} productDescription={product.description} />,
                section: <CategoryColumn category={product.category} childCategory={product.childCategory} grandChildCategory={product.grandChildCategory} />,
                price: `₤${product.price}`,
                sale: product.sale,
                views: product.views,
                stock: <ProductStock 
                    stock={product.stock} 
                    stockIncrease={product.stockIncrease} 
                />,
                supStock: <ProductStock 
                    stock={product.supStock} 
                    stockIncrease={product.supStockIncrease} 
                />,
                action: <EditProductButton productId={product.productId} />,
            }
            finalResult.push(newProductObject)
        })
        console.log(finalResult)
        return finalResult
    }

    const [selectedProductsCount, setSelectedProductsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedProductsCount(count)
    }

    const [selected, setSelected] = useState(categories[0].categoryId)

    return (
        <Fragment>
            <div className="w-full">
                
                {/* Tabs */}
                <div className="w-full hidden lg:flex flex-row items-center justify-between mt-5">
                    <div className="flex flex-row gap-x-1 border-b border-gray-300 w-full">
                        {/* {tabs.map((tab, tabIndex) =>( */}
                        {categories.map((category, categoryIndex) =>(
                            <button 
                                key={categoryIndex} 
                                className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === categoryIndex ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                                onClick={()=>{changeActiveTab(categoryIndex)}}>
                                {category.displayName} 
                            </button>
                        ))}
                    </div>
                </div>

                <div className="w-full lg:hidden flex-row items-center justify-between mt-5">
                    <label className="text-gray-400 text-xs mb-2 block mt-2">Select Category</label>
                    <div className="">
                        <Listbox value={selected} onChange={setSelected}>
                            <div className="relative mt-1">
                            <Listbox.Button className={`relative w-full text-left text-xs rounded border border-gray-300 text-ink-navy font-medium font-sofia-pro inline-block px-3 py-2 transition duration-200`}>
                                <span className="block truncate text-sm lg:text-xs">{selected}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                    className="w-4 h-4 text-gray-400"
                                    aria-hidden="true"
                                />
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {categories.map((option, optionIndex) => (
                                    <Listbox.Option
                                        key={optionIndex}
                                        className={({ active }) =>
                                            `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                                cursor-default select-none relative py-2 pl-10 pr-4`
                                        }
                                        value={option.displayName}
                                        
                                        >
                                        {({ selected, active }) => (
                                            <>
                                            <span
                                                className={`${
                                                selected ? 'font-medium' : 'font-normal'
                                                } block truncate text-sm`}
                                                onClick={()=>{changeActiveTab(option)}}
                                            >
                                                {option.displayName}
                                            </span>
                                            {selected ? (
                                                <span
                                                className={`${
                                                    active ? 'text-amber-600' : 'text-amber-600'
                                                }
                                                        absolute inset-y-0 left-0 flex items-center pl-3`}
                                                >
                                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                                </Listbox.Options>
                            </Transition>
                            </div>
                        </Listbox>
                    </div>
                </div>


                <div className="w-full block md:flex flex-row justify-between pt-12">
                    <div className="flex flex-row gap-x-4 items-center w-full md:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search orders" />
                        </div>
                        <button className="rounded border-gray-200 py-4 md:py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4" /> <span className='hidden md:block ml-2'>Filters</span>
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-4 md:mt-0">
                        {selectedProductsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 transform -rotate-90" /> Export
                        </button>
                    </div>
                </div>

                {/* Tab area */}
                <div className="w-full">
                    <DataTable 
                        tableHeaders={tableHeadersFields(cleanUpProducts(products)[0]).headers} 
                        tableData={cleanUpProducts(products)} 
                        columnWidths={columnWidths}
                        columnDataStyles={columnDataStyles}
                        allFields={tableHeadersFields(cleanUpProducts(products)[0]).fields}
                        onSelectItems={getSelectionCount}
                        tableOptions={tableOptions}
                    />                   
                </div>
            </div>
        </Fragment>
    )
}

export default AllPopularProducts
