import React, { Fragment, useState, useEffect, useCallback } from 'react'
import SearchIcon from '../../../assets/images/icons/search.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import Increase  from '../../../assets/images/icons/arrow-increase.svg'
import Reduce  from '../../../assets/images/icons/arrow-reduce.svg'
import EditIcon  from '../../../assets/images/icons/edit.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import { Link } from 'react-router-dom'
import DataTable from '../../../components/elements/DataTable'
import { convertCamelCase, yesteryears } from '../../../utils'
import { useCookies } from 'react-cookie';
import axios from "axios"
import Spinner from '../../../components/elements/icons/Spinner';
import ProductTableExpansion from '../../../components/partials/products/ProductTableExpansion'
import Filters from '../../../components/elements/Filters'
import CloseIcon from '../../../components/elements/icons/CloseIcon'

const AllProducts = () => {
    const tableOptions = {
        selectable: true,
        showCheckboxInHeader: true,
        // selectAll
        expandable: false,
        clickableRows: true,
        rowAction: ()=>{}
        // rowAction: (value)=>{toggleRowOpen(value)}
    }

    const [rowOpen, setRowOpen] = useState(null);

    const toggleRowOpen = (rowIndex) => {
        if(rowOpen === null) {
            setRowOpen(rowIndex.toString())
        } else {
            setRowOpen(null)
        }
    } 

    const columnWidths = {
        sku: "w-full lg:w-1/12",
        productImage: "w-full lg:w-1/12",
        productName: "w-full lg:w-3/12",
        category: "w-full lg:w-1/12",
        size: "w-full lg:w-2/12",
        Stock: "w-full lg:w-1/12",
        price: "w-full lg:w-1/12",
        colour: "w-full lg:w-1/12",
        year: "w-full lg:w-1/12",
    }

    // const columnWidths = {
    //     productId: "w-full lg:w-1/12",
    //     productName: "w-full lg:w-4/12",
    //     sampleSku: "w-full lg:w-2/12",
    //     productImage: "w-full lg:w-1/12",
    //     brand: "w-full lg:w-2/12",
    //     // status: "w-full lg:w-1/12",
    //     // stock: "w-full lg:w-1/12",
    //     // displayStock: "w-full lg:w-1/12",
    //     price: "w-full lg:w-1/12",
    //     year: "w-full lg:w-1/12",
    // }

    const columnDataStyles = {
        status: {
            isConditional: true,
            conditionals: {
                live: "px-3 py-1 capitalize rounded-full bg-green-100 text-green-400",
                inactive: "px-3 py-1 capitalize rounded-full bg-gray-200 text-gray-400"
            }
        },
    }

    const [allProducts, setAllProducts] = useState([])

    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])

    const [ pagination, setPagination ] = useState({ 
        page: 1, 
        perPage: 25, 
        totalRecords: 0,
    })

    const [triggerFetch, setTriggerFetch] = useState(1)

    const [brands, setBrands] = useState([])
    const [sortBy, setSortBy] = useState('year');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filterString, setFilterString] = useState('');

    useEffect(() => {
        const fetchOrders = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try { 
                let response = null 

                response = await axios.get(`/api/v1/admin/products/list?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[sort]=${sortBy}&page[order]=${sortOrder}&${filterString}`, { headers })            
                // console.log(response)
                let apiProducts = response.data.data
                
                setAllProducts(apiProducts)

                setPagination({
                    page: pagination.page,
                    perPage: pagination.perPage,
                    totalRecords: response.data.total_records,
                    nextPage: response.data.next,
                    lastPage: response.data.last
                })

                // await fetchBrands()

                setProcessing(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }
        // const fetchBrands = async () => {
        //     const headers = {
        //         'Content-Type': 'application/json',
        //         'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        //     }
        //     try {
                
        //         const response = await axios.get(`/api/v1/brands//?page[offset]=1&page[limit]=10000000&page[order]=desc`, { headers })            
        //         console.log(response)
        //         setBrands(response.data.data)
        //         setProcessing(false)
             
        //     } catch (error) {
        //         console.error(error)
        //         setError(error.response.data.msg)
        //         setProcessing(false)
        //     }
        // }
        fetchOrders()
    }, [cookies.accessToken, cookies.tokenType, pagination.page, pagination.perPage, triggerFetch, sortBy, sortOrder, filterString])

    const searchableTableColumns = [
        {
            column: 'date',
            dataType: 'date'
        },
        {
            column: 'deadline',
            dataType: 'date'
        },
        {
            column: 'urgency',
            dataType: 'select',
            options: [
                'priority', 'urgent', 'waiting'
            ]
        },
        {
            column: 'status',
            dataType: 'select',
            options: [
                'Awaiting Payment', 'Payment Pending', 'Dispatched', 'Paid and Ready to Process', 'Payment pending', 'Processing', 'Deleted', 'Cancelled' , 'Complete', 'Returned', 'Ready for Collection'
            ]
        },
        {
            column: 'customer',
            dataType: 'text',
        }
    ]

    const columnNameMap = {
        productId: 'id',
        productName: 'name',
        brand: 'brand_id',
        year: 'year',
    }

    const [currentSortingColumn, setCurrentSortingColumn] = useState('');

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'image')

            if(key === 'productId') {
                columnDisplayName = '#ID'
                // columnDataType = 'link'
            }

            if(key === 'actions') {
                sortable = false
            }
            
            if(key === 'productImage') {
                columnDisplayName = 'Image'
            }

            if(key === 'productImage') {
                columnDataType = 'JSX'
            }

            if(key === 'stockAvailability') {
                forPopover = true
            }

            let column = key
            if(key === 'stock') {
                column = 'displayStock'
                columnDisplayName = 'stock'
                columnDataType = 'popoverTrigger'
            }
            if(key !== 'stockIncrease' && key !== 'selected' && key !== 'description' && key !== 'categories' && key !== 'colors' && key !== 'displayStock') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        console.log('brands -> ', brands)
        console.log('all filters -> ', filters)
        setFiltersActive(!filtersActive)
    }

    const bulkActions = [
        {
            label: "Set Category",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "Set to live",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "Set inactive",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "Set featured",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "Unset featured",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "Enable on merchant feed",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "Disable on merchant feed",
            icon: null,
            link: "/",
            action: null
        },
    ]

    const filters = [
        {
            optionName: "Years",
            value: 'year',
            type: 'select',
            columnWidth: 'w-full',
            optionSelector: 'year',
            options: yesteryears(4),
        },
        {
            optionName: "Created Date",
            value: 'created_at',
            type: 'date-range'
        },
        {
            optionName: "Product Price",
            value: 'price',
            type: 'range'
        },
        {
            optionName: "Brand",
            value: 'brand_id',
            type: 'search',
            columnWidth: 'w-1/2',
            searchConfig: {
                searchPlaceholder: "Search Brand",
                searchReference: 'brands'
            }
        },

    ]


    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0)

    const captureFilters = (filters) => {
        setSelectedFiltersCount(filters.length)
        let filterString = ''
        filters.forEach(element => {
            filterString += `filter[${element.filter}]${element.operator}${element.term}`
        });
        setFilterString(filterString)
    }

    const [searchInProgress, setSearchInProgress] = useState(false);

    const debounce = (func) => {
        let timer;
        return function (...args) {
          const context = this;
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, 3000);
        };
    };
    

    const performSearch = (searchTerm) => {
        setSearchInProgress(true)
        setFilterString(`filter[%name%]=${searchTerm}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const captureSearchTerm = useCallback(debounce(performSearch), []);

    const clearSearch = () => {
        captureSearchTerm('')
        setSearchInProgress(false)
    }

    const [allFilters, setAllFilters] = useState(filters)

    const toggleFilterSelection = (optionIndex, filterIndex) => {
        let newFilters = JSON.parse(JSON.stringify(allFilters))
        newFilters[optionIndex].options[filterIndex].selected = !newFilters[optionIndex].options[filterIndex].selected
        setAllFilters(newFilters)
        setSelectedFiltersCount(countSelectedFilters(newFilters))
    }

    const countSelectedFilters = (filters) => {
        let count = 0
        filters.forEach((option) => {
            option.options.forEach((filter) => {
                if(filter.selected) {
                    count++
                }
            })
        })
        return count
    }

    const clearFilters = () => {
        let newFilters = JSON.parse(JSON.stringify(allFilters))
        newFilters.forEach((option) => {
            option.options.forEach((filter) => {
                filter.selected = false
            })
        })
        setAllFilters(newFilters)
        setSelectedFiltersCount(countSelectedFilters(newFilters))
    }

    const EditProductButton = ({productId}) => {
        return (
            <Link to={`product/${productId}`} className="font-sofia-pro rounded py-4 px-3 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
        )
    }
    
    

    const ProductDetail = ({productIndex, name, brand, description, colors, categories}) => {
        const [activeFlyout, setActiveFlyout] = useState(null)
        const backgroundClass = (categoryIndex) => {
           if (categoryIndex === 0) {
               return 'bg-gray-500'
           }
           if (categoryIndex === 1) {
               return 'bg-gray-400'
           }
           if (categoryIndex === 2) {
               return 'bg-gray-300'
           }
            
        }
        return (
            <div className="relative">
                <span className="block w-full cursor-pointer" onMouseEnter={()=>{setActiveFlyout(productIndex)}} onMouseLeave={()=> {setActiveFlyout(null)}}>
                    {name}
                </span>
                <div className={`border rounded absolute top-0 left-full bg-white px-4 py-3 w-96 z-20 ${activeFlyout === productIndex ? "block" : "hidden"}`}>
                    <div className="text-center w-full py-3 border-b border-gray-200">
                        <h3 className="text-sm font-medium text-center">{name}</h3>
                    </div>
                    <div className="flex flex-row items-center gap-x-3 my-3">
                        {categories.map((category, categoryIndex) => (
                            <span key={categoryIndex} className={`${backgroundClass(categoryIndex)} text-xs font-sofia-pro px-3 py-2 text-white rounded`}>
                                {category}
                            </span>
                        ))}
                    </div>

                    <div className="mt-3">
                        <label className="font-medium text-xs text-ink-navy">Brand</label>
                        <p className="mb-3">{brand}</p>

                        <label className="font-medium text-xs text-ink-navy mt-5">Description</label>
                        <p className="mb-3">{description}</p>

                        <label className="font-medium text-xs text-ink-navy">Colours</label>
                        <div className="w-full flex flex-row gap-x-3 pb-5 pt-3">
                            {colors.map((color, colorIndex) => (
                                <span key={colorIndex} style={{backgroundColor: color}} className={`w-5 h-5 rounded-full`} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ProductStock = ({stock, stockIncrease, stockAvailability, productIndex}) => {
        const [activeFlyout, setActiveFlyout] = useState(null)
        return (
            <div className="relative">
                <span onMouseEnter={()=>{setActiveFlyout(productIndex)}} onMouseLeave={()=> {setActiveFlyout(null)}}>
                    {stock} <img alt="" src={stockIncrease ? Increase : Reduce} className="inline ml-1 w-2" />
                </span>
                <div className={`border rounded absolute top-0 left-full bg-white px-4 py-3 w-52 z-20 ${activeFlyout === productIndex ? "block" : "hidden"}`}>
                    {stock > 0 && 
                    <>
                        <div className="w-full text-center">Available ({stock})</div>
                        <div className="w-full text-left">
                        {stockAvailability.map((availability, availabilityIndex)=>(
                            <p key={availabilityIndex} className="block text-xs my-2 relative text-gray-400">{availability.location} 
                                <span className="bg-gray-500 px-1 text-white text-xs absolute right-1">{availability.quantity}</span>
                            </p>
                        ))}
                        </div>
                    </>
                    }
                    {stock === 0 && 
                        <p className="text-xs text-gray-500">Out of stock</p>
                    }
                </div>
            </div>
        )
    }

    const getProductDescriptions = (descriptionsArray) => {
        const colours = []
        const images = []
        const sizes = []
        let sampleSku = '' 
        let stock = {
            total: 0, 
            locations: []
        }
        
        if (!descriptionsArray || descriptionsArray.length === 0 ) {
            return {colours, images, sizes, sampleSku, stock}
        }

        descriptionsArray.forEach((desc) => {
            colours.push(desc.colour)
            if(desc.assets && desc.assets.length > 0) {
                desc.assets.forEach((asset) => {
                    if(asset.type === 'image') {
                        // images.push(asset.path)
                        images.push(`https://obj1-dev.s3.eu-west-2.amazonaws.com${asset.path}`)
                    }
                })
            }
            if(desc.sizes && desc.sizes.length > 0) {
                desc.sizes.forEach((size, sizeIndex) => {
                    if(sizeIndex === 0 && size.sku ) {
                        sampleSku = size.sku
                    }
                    sizes.push(size.size)
                    if(size.stock && size.stock.length > 0) {
                        size.stock.forEach((stockItem) => {
                            stock.total += stockItem.stock_qty
                            stock.locations.push({
                                location: stockItem.stock_location_name,
                                quantity: stockItem.stock_qty
                            })
                        })
                    }
                })
            }
        })
        return {colours, images, sizes, sampleSku, stock}
    }

    const getProductCategoryNames = (categoriesArray) => {
        const categoryArray = []
        if(!categoriesArray) {
            return categoryArray
        }
        categoriesArray.forEach((category) => {
            categoryArray.push(category.category)
            if (category.children && category.children.length > 0) {
                categoryArray.push(category.children[0].category)
                if (category.children[0].children && category.children[0].children.length > 0) {
                    categoryArray.push(category.children[0].children[0].category)
                }
            }
        })

        return categoryArray
    }

    // const ProductImage = ({image}) => {
    //     console.log('an image', image)
    //     return (
    //         <img src={image} alt="" />
    //     )
    // }

    const s3 = (src, width, height, fit) => {
		
        const imageRequest = {
            bucket: 'obj1-dev',
            key: src,
            edits: {
                resize: {
                    width: width ? width : null,
                    height: height ? height : null,
                    withoutEnlargement: false,
                    fit: fit ? fit : 'cover',
                    background: '#FFF'
                }
            }
        };
    
        return `https://d1xye2lc5rbt2w.cloudfront.net/${Buffer.from(JSON.stringify(imageRequest)).toString('base64')}`;
    }

    const productImages = (assets) => {
        if(!assets || assets.length === 0) return
        const images = []
        assets.forEach((asset) => {
            if(asset.type === 'image') {
                // images.push(asset.path)
                images.push(`https://obj1-dev.s3.eu-west-2.amazonaws.com${asset.path}`)
            }
        })

        return images
    }

    const cleanUpProducts = (products) => {

        let finalResult = [] 
        products.forEach((product, productIndex) => {
            const productObject = {
                productImage: <img src={productImages(product.assets)[0]} alt="" className='h-12' />,
                // productImage: <img src={s3(productDescriptions.images[0], 75, 75)} alt="" className='h-12' />,
                sku: <Link to={`product/${product.product_id}`}>{product.sku}</Link>,
                productName: product.name,
                category: product.categories[0].category,
                size: product.size,
                Stock: product.stock,
                price: `${product.currency_symbol}${product.price}`,
                colour: product.colour,
                year: product.year,
                // actions: <EditProductButton productId={product.id} />,                
            }
            console.log(productObject)
            finalResult.push(productObject)
        })
        // products.forEach((product, productIndex) => {
        //     const productDescriptions = getProductDescriptions(product.colours)
        //     const productObject = {
        //         productId: <Link to={`product/${product.id}`}>{product.id}</Link>,
        //         productImage: <img src={productDescriptions.images[0]} alt="" className='h-12' />,
        //         productName: <ProductDetail 
        //             productIndex={productIndex} 
        //             name={product.name} 
        //             brand={product.brand_name} 
        //             description={product.description}
        //             colors={productDescriptions.colours} 
        //             categories={getProductCategoryNames(product.categories)}  />,
        //         // displayStock: <ProductStock 
        //         //     stock={productDescriptions.stock.total} 
        //         //     stockIncrease={true} 
        //         //     stockAvailability={productDescriptions.stock.locations} 
        //         //     productIndex={productIndex} />,
        //         sampleSku: productDescriptions.sampleSku,
        //         brand: product.brand_name,
        //         // status: "live",
        //         // stock: productDescriptions.stock.total,
        //         price: `${product.currency_symbol}${product.price}`,
        //         year: product.year,
        //         // actions: <EditProductButton productId={product.id} />,                
        //     }
        //     finalResult.push(productObject)
        // })

        return finalResult
    }

    const [selectedProductsCount, setSelectedProductsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedProductsCount(count)
    }

    const [importing, setImporting] = useState(false)

    const importProducts = async () =>{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        setImporting(true)
        try { 
            await axios.get(`/api/v1/admin/products/import/available_categories`, { headers })
            setTriggerFetch(triggerFetch+1)

            setImporting(false)
        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setImporting(false)
        }
    }

    return (
        <Fragment>
            <div className="w-full">
            <>
                <div className="w-full block lg:flex flex-row justify-between">
                    <div className="flex flex-row justify-between items-center w-full lg:w-5/12 mb-4 lg:mb-0 relative">
                        <div className="flex flex-row gap-x-2">
                            <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full lg:w-full mr-1">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm mt-1">
                                        {!searchInProgress ? <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" /> :
                                        <button onClick={()=>{clearSearch()}} className="cursor-pointer"><CloseIcon classes="w-4 h-4 text-black" /></button>}
                                    </span>
                                </div>
                                <input onChange={(e)=>{captureSearchTerm(e.target.value)}} type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search products" />
                            </div>
                            <button 
                                className={`rounded py-3 px-4 text-xs w-44 flex flex-row items-center border ${filtersActive ? 'text-gray-800 border-gray-800' : 'text-gray-400 border-gray-200'}`} 
                                onClick={()=>{toggleFilters()}} 
                            >
                                <img src={FilterIcon} alt="search" className={`w-4 -mt-1 mr-3 ${filtersActive ? 'opacity-90' : 'opacity-30'}`} /> <span className='hidden md:inline'>Filters</span> {selectedFiltersCount > 0 && <span className={`text-xs text-white rounded px-1 ml-1 mr-3 ${filtersActive ? 'bg-ink-navy' : 'bg-gray-400'}`}>{selectedFiltersCount}</span>}
                            </button>
                        </div>
                        {filtersActive && 
                            <Filters
                                closeFilters={()=>{setFiltersActive(false)}} 
                                allFilters={filters} 
                                selectFilters={(filters)=>{captureFilters(filters)}}
                            />
                        }
                    </div>
                    <div className="flex flex-row gap-x-2 justify-between">
                        {selectedProductsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        }
                        <button disabled={importing} onClick={()=>{importProducts()}} className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border w-full lg:w-max">
                            {importing ? <Spinner /> : <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden xl:inline" />} Import Products
                        </button>
                        {/* <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border w-full lg:w-max">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden xl:inline" /> Export
                        </button> */}
                        <Link to="new-product" 
                            className="rounded border-blue-800 bg-blue-800 py-3 px-4 text-xs text-white flex flex-row items-center border w-full lg:w-max" 
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden xl:inline" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                            </svg>
                            Add Product
                        </Link>
                    </div>
                </div>
            {!processing && error === '' ? 
                
                <div className='mb-96'>
                        <DataTable 
                            tableHeaders={tableHeadersFields(cleanUpProducts(allProducts)[0]).headers} 
                            tableData={cleanUpProducts(allProducts)} 
                            columnWidths={columnWidths}
                            columnDataStyles={columnDataStyles}
                            allFields={tableHeadersFields(cleanUpProducts(allProducts)[0]).fields}
                            onSelectItems={getSelectionCount}
                            tableOptions={tableOptions}
                            pagination={pagination}
                            paginate={setPagination}
                            tableName={`allProducts`}
                            searchableColumns={searchableTableColumns}
                            sortingBy={sortBy}
                            columnNameMap={columnNameMap}
                            currentSortingColumn={currentSortingColumn}
                            updateCurrentSortingColumn={(columnName)=>{setCurrentSortingColumn(columnName)}}
                            updateSortBy={(field)=>{setSortBy(field)}}
                            sortOrder={sortOrder}
                            updateSortOrder={(order)=>{setSortOrder(order)}}
                            // expandedIndex={rowOpen || ''}
                            // expansion={<ProductTableExpansion productDetails={allProducts[rowOpen]} />}
                        />
                        {/* <DataTable 
                            tableHeaders={tableHeadersFields(cleanUpProducts(allProducts)[0]).headers} 
                            tableData={cleanUpProducts(allProducts)} 
                            columnWidths={columnWidths}
                            columnDataStyles={columnDataStyles}
                            allFields={tableHeadersFields(cleanUpProducts(allProducts)[0]).fields}
                            onSelectItems={getSelectionCount}
                            tableOptions={tableOptions}
                            pagination={pagination}
                            paginate={setPagination}
                            tableName={`allProducts`}
                            searchableColumns={searchableTableColumns}
                            sortingBy={sortBy}
                            columnNameMap={columnNameMap}
                            currentSortingColumn={currentSortingColumn}
                            updateCurrentSortingColumn={(columnName)=>{setCurrentSortingColumn(columnName)}}
                            updateSortBy={(field)=>{setSortBy(field)}}
                            sortOrder={sortOrder}
                            updateSortOrder={(order)=>{setSortOrder(order)}}
                            expandedIndex={rowOpen || ''}
                            expansion={<ProductTableExpansion productDetails={allProducts[rowOpen]} />}
                        /> */}
                </div>
                :
                <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <Spinner />
                    <p className='text-sm text-gray-500 mt-5'>Fetching products...</p>
                </div>
            }
                </>
            </div>
        </Fragment>
    )
}

export default AllProducts
