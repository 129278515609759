import React, { useState } from 'react'

const WebStyle = () => {
    const [storeMainColour, setStoreMainColour] = useState("#01afef")
    const [favicon, setFavicon] = useState('')
    const [webLogo, setWebLogo] = useState('')
    const [printLogo, setPrintLogo] = useState('')

    const onImageChange = (event, imageFor) => {
        if (event.target.files && event.target.files[0]) {

            switch (imageFor) {
                case 'favicon':
                    setFavicon(URL.createObjectURL(event.target.files[0]))
                    break;
                case 'webLogo':
                    setWebLogo(URL.createObjectURL(event.target.files[0]))
                    break;
                case 'printLogo':
                    setPrintLogo(URL.createObjectURL(event.target.files[0]))
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div className="w-full pb-10">
            <h3 className="font-medium text-ink-navy mb-7 text-lg">Web Style</h3>

            <div className="my-8">
                <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Store Logo Main Header</label>
                <div className="block md:flex flex-row gap-x-10 items-center">
                    <div className="w-full md:w-32 border border-gray-300 rounded p-4 mb-5">
                        <img alt="" src={webLogo}/>
                    </div>
                    <label className="mr-6">
                        <span className="font-sofia-pro text-xs px-3 py-2 bg-gray-200 hover:bg-gray-300 hover:text-gray-600 text-gray-400 border border-gray-400 transition duration-200 rounded text-xs text-white cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg> 
                            Upload file
                        </span>
                        <input type="file" className="hidden" onChange={(e)=>onImageChange(e, 'webLogo')} />
                    </label>

                    <button className="text-gray-400 text-xs" onClick={()=>setWebLogo('')}>Remove file</button>
                </div>
            </div>

            <div className="my-8">
                <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Store Logo (Email & Print)</label>
                <div className="block md:flex flex-row gap-x-10 items-center">
                    <div className="w-full md:w-32 border border-gray-300 rounded p-4 mb-5">
                        <img alt="" src={printLogo}/>
                    </div>
                    <label className="mr-6">
                        <span className="font-sofia-pro text-xs px-3 py-2 bg-gray-200 hover:bg-gray-300 hover:text-gray-600 text-gray-400 border border-gray-400 transition duration-200 rounded text-xs text-white cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg> 
                            Upload file
                        </span>
                        <input type="file" className="hidden" onChange={(e)=>onImageChange(e, 'printLogo')} />
                    </label>

                    <button className="text-gray-400 text-xs" onClick={()=>setPrintLogo('')}>Remove file</button>
                </div>
            </div>

            <div className="my-8">
                <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Favicon</label>
                <div className="block md:flex flex-row gap-x-10 items-center">
                    <div className="w-full md:w-32 rounded p-4 mb-5">
                        <img alt="" src={favicon}/>
                    </div>
                    <label className="mr-6">
                        <span className="font-sofia-pro text-xs px-3 py-2 bg-gray-200 hover:bg-gray-300 hover:text-gray-600 text-gray-400 border border-gray-400 transition duration-200 rounded text-xs text-white cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg> 
                            Upload file
                        </span>
                        <input type="file" className="hidden" onChange={(e)=>onImageChange(e, 'favicon')} />
                    </label>

                    <button className="text-gray-400 text-xs" onClick={()=>setFavicon('')}>Remove file</button>
                </div>
            </div>

            <div className="my-8 w-full lg:w-1/3">
                <label className="text-xs text-gray-400 text-sm block mt-4 mb-3">Store Main Colour (Legacy)</label>
                <div className="flex flex-row">
                    <input type="color" className="border rounded-l-md bg-transparent border-gray-300 p-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={storeMainColour} onChange={(e)=>{setStoreMainColour(e.target.value)}} />
                    <input type="text" className="border rounded-r-md w-full text-gray-600 bg-transparent border-gray-300 px-3 py-2 text-xs w-14 h-12 focus:outline-none focus:border-gray-300 transition duration-200" value={storeMainColour} readOnly />
                </div>
            </div>

        </div>
    )
}

export default WebStyle
