import React, {Fragment, useState, useEffect, useRef} from 'react'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
// import ExportIcon from '../../../../assets/images/icons/export.svg'
// import BulkActionsIcon from '../../../../assets/images/icons/bulk-items.svg'
// import DropdownMenu from '../../../../components/elements/DropdownMenu'
import DataTable from '../../../../components/elements/DataTable'
// import { Link } from 'react-router-dom'
import { convertCamelCase, isValidObject, slugify } from "../.././../../utils";
import EditIcon  from '../../../../assets/images/icons/edit.svg'
import DeleteIcon  from '../../../../assets/images/icons/delete.svg'
import Spinner from '../../../../components/elements/icons/Spinner';
import axios from "axios"
import { baseUrl } from '../../../../utils';
// import ErrorMessage from '../../../../components/elements/ErrorMessage'
import { useCookies } from 'react-cookie';
import { Dialog, Transition } from '@headlessui/react'
import { Switch } from '@headlessui/react'
import { FileDrop } from 'react-file-drop'
import Wysiwyg from '../../../../components/elements/Wysiwyg';

const Brands = () => {
    let [isOpen, setIsOpen] = useState(false)
    const [ activeBrand, setActiveBrand ] = useState(null)
    const [ activeBrandFeatured, setActiveBrandFeatured ] = useState(false)
    const [ activeBrandThumbnail, setActiveBrandThumbnail ] = useState(null)
    const [ brandTitle, setBrandTitle ] = useState('')
    const [ brandSlug, setBrandSlug ] = useState('')
    const [ brandContent, setBrandContent ] = useState('')

    const closeModal = () => {
        setActiveBrand('')
        setBrandTitle('')
        setBrandSlug('')
        setActiveBrandFeatured(false)
        setActiveBrandThumbnail(null)
        setBrandContent('')
        setIsOpen(false)
    }

    const openBrand = (brand) => {
        setActiveBrand(brand)
        setBrandTitle(brand.name)
        setBrandSlug(brand.slug)
        setActiveBrandFeatured(brand.featured)
        if(brand.thumbnail_path) {
            setActiveBrandThumbnail(`https://obj1-dev.s3.eu-west-2.amazonaws.com${brand.thumbnail_path}`)
        }
        setBrandContent(brand.content)
        setIsOpen(true)
    }

    const openNewBrand = () => {
        setIsOpen(true)
    }

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    const tableOptions = {
        selectable: false
    }

    const columnWidths = {
        brandName: 'w-full lg:w-6/12',
        thumbnail: 'w-full lg:w-5/12',
        actions:'w-full lg:w-1/12'
    }

    const columnDataStyles = {
    }

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key !== 'brandName') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    // const bulkActions = [
    //     {
    //         label: "Change Status",
    //         icon: null,
    //         link: "/",
    //         action: null
    //     },
    //     {
    //         label: "delete",
    //         icon: null,
    //         link: "/",
    //         action: null
    //     },
    // ]


    // const [selectedReviewsCount, setSelectedReviewsCount] = useState(0)

    const getSelectionCount = (count) => {
        // return setSelectedReviewsCount(count)
    }

    const [ brands, setBrands ] = useState([])
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')  
    // const [ deleting, setDeleting ] = useState('')
    const [ cookies ] = useCookies(['user']);
    const [ pagination, setPagination ] = useState({ 
        page: 1, 
        perPage: 25, 
        totalRecords: 0,
        nextPage: '',
        lastPage: '',
        previousPage: '',
        firstPage: ''
    })

    const [reloadData, setReloadData] = useState(true);

    useEffect(() => {
        const fetchBrands = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try {
                const response = await axios.get(`/api/v1/brands//?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc`, { headers })            
                console.log(response)
                setBrands(response.data.data)
                setPagination({
                    page: pagination.page,
                    perPage: pagination.perPage,
                    totalRecords: response.data.total_records,
                    nextPage: response.data.next,
                    lastPage: response.data.last
                })
                setProcessing(false)
                setReloadData(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
                setReloadData(false)
            }
        }

        if(reloadData === true) {
            fetchBrands()
        }

    }, [cookies.accessToken, cookies.tokenType, pagination.page, pagination.perPage, reloadData])

    const [deleting, setDeleting] = useState(false)
    const deleteBrand = async (brand) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm("Are you sure you want to delete this brand?")) {
            const headers = {
                'Content-Type': 'application/json', 
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
    
            setDeleting(true)
    
            try {
                const response = await axios.delete(`/api/v1/admin/brands/${brand.id}`, { headers })            
                console.log(response)
                setDeleting(false)

                // setBrands(brands.filter((listedBrand) => {
                //     return brand.id !== listedBrand._id
                // }))

                const tempBrands = [ ...brands ]
                const filtered = tempBrands.filter((deletedBrand) => {
                    return brand.id !== deletedBrand.id
                })
                setBrands(filtered)
                setReloadData(true)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setDeleting(false)
            }
        }
    }

    const Actions = ({brand}) => {
        return (
            <div className='w-full flex items-center gap-x-4'>
                <button className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center" onClick={()=>{openBrand(brand)}}>
                    Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" />
                </button>
                <button className="font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center" onClick={()=>{deleteBrand(brand)}}>
                    Delete <img src={DeleteIcon} alt="" className="w-3 ml-2 -mt-1 inline" />
                </button>
            </div>
        )
    }

    const BrandThumbnail = ({image}) => {
        return (
            <img alt="" src={`https://obj1-dev.s3.eu-west-2.amazonaws.com${image}`} className='w-full lg:w-3/12' />
        )
    }
    
    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            const requestObject = {
                brandName: object.name,
                thumbnail: <BrandThumbnail image={object.thumbnail_path} />,
                actions: <Actions brand={object} />
            }
            finalResult.push(requestObject)
        })
        return finalResult
    }

    const [ gridView, setGridView ] = useState(true)
    const [ validationErrors, setValidationErrors ] = useState({})

    const validateBrandForm  = () => {
        let errors = {}
        
        if(!brandTitle || brandTitle === '') {
            errors.brandTitle = true
        }

        if(!brandContent || brandContent === '') {
            errors.brandContent = true
        }
        
        if(!brandSlug || brandSlug === '') {
            errors.brandSlug = true
        }

        setValidationErrors(errors)
        console.log('Validation',errors)
        return errors
    }

    const [ fileDetails, setFileDetails ] = useState(null)
    const fileInputRef = useRef(null);

    const onFileInputChange = (event) => {
        const { files } = event.target;
        // do something with your files...
        handleFile(files)
    }

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleFile = (files) => {
        setFileDetails({
            name: files[0].name,
            size: `${Math.ceil(files[0].size/1000000).toString()} MB` ,
            type: files[0].type
        })
        let base64Image = '';

        getBase64(files[0], (result) => {
            base64Image = result;
            setActiveBrandThumbnail(base64Image)
        });
    }

    const [updating, setUpdating] = useState(false)

    const updateBrand = async () => {
        console.log('...', brandContent)

        const headers = {
            'Content-Type': 'application/json', 
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
    
        if (Object.values(validateBrandForm  ()).includes(true)) {
            setError('Please check the highlighted fields')
            return
        }
        
        const requestPayload = {
            name : brandTitle,
            slug: brandSlug,
            featured : activeBrandFeatured,
            thumbnail_path_data : activeBrandThumbnail,
            content: brandContent
        }
    
        console.log(requestPayload)
    
        setUpdating(true)
    
        try {
            const response = await axios.patch(`/api/v1/admin/brands/${activeBrand.id}`, requestPayload, { headers })            
            console.log(response)
            closeModal()
            setUpdating(false)
            setReloadData(true)
        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setUpdating(false)
        }
    }

    const [creating, setCreating] = useState(false)

    const createBrand = async () => {
        const headers = {
            'Content-Type': 'application/json', 
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        if (Object.values(validateBrandForm  ()).includes(true)) {
            setError('Please check the highlighted fields')
            return
        }
        
        const requestPayload = {
            name : brandTitle,
            slug: brandSlug,
            featured : activeBrandFeatured,
            thumbnail_path_data : activeBrandThumbnail,
            content: brandContent
        }

        console.log(JSON.stringify(requestPayload))

        setCreating(true)

        try {
            const response = await axios.post(`/api/v1/admin/brands`, requestPayload, { headers })            
            console.log(response.data.data)

            const tempBrands = [ ...brands ]
            tempBrands.unshift(response.data.data[0])
            setBrands(tempBrands)

            closeModal()
            setCreating(false)

        } catch (error) {
            console.error(error)
            setError(error.response.data.msg)
            setCreating(false)
        }
    }

    const updateBrandTitle = (title) => {
        setBrandTitle(title)
        setBrandSlug(slugify(title))
    }
    

    return (
        <Fragment>

            <div className="w-full">
                <div className="w-full block lg:flex flex-row items-center gap-x-2 pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search brands" />
                        </div>
                        <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row mt-2 lg:mt-0">
                        <button className={`border rounded p-2 ${gridView ? 'border-blue-500 text-blue-500' : 'border-gray-200 text-gray-300'}`} onClick={()=>{setGridView(true)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                            </svg>
                        </button>
                        <button className={`border rounded p-2 ${gridView ? 'border-gray-200 text-gray-300' : 'border-blue-500 text-blue-500'}`} onClick={()=>{setGridView(false)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex flex-row-reverse gap-x-2 mt-2 lg:mt-0 flex-wrap w-full">
                        {/* {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2 hidden lg:inline" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        } */}
                        {/* <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Help
                        </button>*/}
                        <button className="rounded bg-blue-700 border-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border" 
                            onClick={() => {openNewBrand()}}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                            Create new brand
                        </button> 
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                           <span className='p-px rounded-full border border-ink-navy mr-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                           </span>
                           Update Default Logos
                        </button> 
                    </div>
                </div>

                {/* Filters */}
                {/* <div>
                </div> */}

                {/* Table */}

                {!processing && error === '' ?
                    <>
                        {gridView 
                            ?    
                            <>
                                <div className="grid mt-6 grid-cols-2 lg:grid-cols-5 gap-6">
                                    {brands.map((brand, brandIndex)=>(
                                        <div key={brandIndex} className='w-full cursor-pointer hover:shadow-lg px-3 py-5 transition duration-200 text-center border border-gray-200 relative'>
                                            <button className="absolute top-3 right-3 font-sofia-pro rounded p-2 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100" onClick={()=>{deleteBrand(brand)}}>
                                                <img src={DeleteIcon} alt="" className="w-3 inline" />
                                            </button>
                                            {brand.thumbnail_path ?
                                            <img alt="" src={`https://obj1-dev.s3.eu-west-2.amazonaws.com${brand.thumbnail_path}`} className='h-20 mx-auto mt-4 mb-4' /> :
                                            <div className='flex items-center justify-center p-8'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                </svg>
                                            </div>
                                            }
                                            <p onClick={()=>{openBrand(brand)}} className='text-ink-navy text-md font-medium'>{brand.name}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className='flex flex-row justify-between w-full items-center mt-5'>
                                <div className='flex flex-row gap-x-2 items-center'>
                                    <div className='select-wrapper px-6 py-2 border rounded border-gray-400'>
                                        <select value={pagination.perPage} onChange={(e)=>setPagination({...{pagination}.pagination, ...{perPage: e.target.value}})} className='focus:outline-none w-full text-gray-400'>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={150}>150</option>
                                        </select>
                                    </div>
                                    <span className='font-sofia-pro text-gray-400 text-xs'>
                                        Showing 1-{
                                            pagination.page *
                                            pagination.totalRecords > pagination.perPage 
                                            ? 
                                            pagination.perPage 
                                            : 
                                            pagination.totalRecords} of {pagination.totalRecords} entries
                                    </span>
                                </div>

                                <div className='flex flex-row gap-x-2'>
                                    <button className='p-3 border rounded border-gray-400 text-gray-400 transition duration-200 hover:border-blue-400 hover:text-blue-400' onClick={()=>setPagination({...{pagination}.pagination, ...{page: 1}})}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                                        </svg>
                                    </button>
                                    <button disabled={pagination.page === 1} className='p-3 border rounded border-gray-400 text-gray-400 transition duration-200 hover:border-blue-400 hover:text-blue-400' onClick={()=>setPagination({...{pagination}.pagination, ...{page: pagination.page - 1 }})}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M15 19l-7-7 7-7" />
                                        </svg>
                                    </button>
                                    <button disabled={pagination.page === Math.ceil(pagination.totalRecords / pagination.perPage)} className='p-3 border rounded border-gray-400 text-gray-400 transition duration-200 hover:border-blue-400 hover:text-blue-400' onClick={()=>setPagination({...{pagination}.pagination, ...{page: pagination.page + 1}})}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </button>
                                    <button className='p-3 border rounded border-gray-400 text-gray-400 transition duration-200 hover:border-blue-400 hover:text-blue-400' onClick={()=>setPagination({...{pagination}.pagination, ...{page: Math.ceil(pagination.totalRecords / pagination.perPage)}})}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            </>
                            :
                            <DataTable 
                                tableHeaders={tableHeadersFields(cleanUpData(brands)[0]).headers} 
                                tableData={cleanUpData(brands)} 
                                columnWidths={columnWidths}
                                columnDataStyles={columnDataStyles}
                                allFields={tableHeadersFields(cleanUpData(brands)[0]).fields}
                                onSelectItems={getSelectionCount}
                                tableOptions={tableOptions}
                                pagination={pagination}
                                paginate={setPagination}
                            />
                        }
                    </>

                    : 

                    <div className='w-full flex flex-col items-center justify-center mt-10'>
                        <Spinner />
                        <p className='text-sm text-gray-500 mt-5'>Fetching brands...</p>
                    </div>
                }
                    
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0  bg-black bg-opacity-40" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                        <div className="inline-block w-full max-w-6xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                            <Dialog.Title
                            as="h3"
                            className="text-md font-medium leading-6 text-ink-nvy"
                            >
                                {activeBrand && activeBrand !== '' 
                                ?
                                    "Update brand"
                                :
                                    "Create a New Brand"
                                }
                            </Dialog.Title>
                            <div className="mt-2">
                                {/* <p className="text-sm text-gray-500">
                                    Your payment has been successfully submitted. We’ve sent you
                                    an email with all of the details of your order.
                                </p> */}
                                <div className='flex flex-row gap-x-8'>
                                    <div className='w-full lg:w-5/12'>
                                        <div className='w-full flex flex-row gap-x-2 mb-3 mt-3'>
                                            <div className='w-full'>
                                                <label className={`text-xs text-sm block mb-2 ${validationErrors.brandTitle && validationErrors.brandTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                                    Brand Title
                                                </label>
                                                <input 
                                                    type="text" 
                                                    className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brandTitle && validationErrors.brandTitle === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: Red Trailer" 
                                                    value={brandTitle}
                                                    onChange={(e)=>{updateBrandTitle(e.target.value)}} 
                                                />
                                            </div>
                                            <div>
                                                <label className={`text-xs text-sm block mb-2 text-ink-navy`}>
                                                    Featured
                                                </label>
                                                <Switch
                                                    checked={activeBrandFeatured}
                                                    onChange={setActiveBrandFeatured}
                                                    className={`${
                                                        activeBrandFeatured ? 'bg-ink-navy' : 'bg-gray-300'
                                                    } relative inline-flex items-center h-7 rounded-full w-12 mt-2`}
                                                    >
                                                    <span className="sr-only">Featured</span>
                                                    <span
                                                        className={`transform transition ease-in-out duration-200 ${
                                                            activeBrandFeatured ? 'translate-x-6' : 'translate-x-1'
                                                        } inline-block w-5 h-5 transform bg-white rounded-full`}
                                                    />
                                                </Switch>
                                            </div>
                                        </div>

                                        <div className='w-full flex flex-row gap-x-2 mb-3 mt-3'>
                                            <div className='w-full'>
                                                <label className={`text-xs text-sm block mb-2 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                                    Slug
                                                </label>
                                                <input 
                                                    type="text" 
                                                    className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: red_trailer"
                                                    value={brandSlug}
                                                    onChange={(e)=>{setBrandSlug(e.target.value)}}
                                                />
                                            </div>
                                        </div>

                                        <div className='w-full flex flex-row gap-x-2 mb-3 mt-3'>
                                            <div className='w-full'>
                                                <label className={`text-xs text-sm block mb-2 text-ink-navy`}>
                                                    Brand Thumbnail
                                                </label>

                                                <div className='w-full'>
                                                    <input
                                                        onChange={onFileInputChange}
                                                        ref={fileInputRef}
                                                        type="file"
                                                        className="hidden"
                                                    />
                                                { 
                                                        !activeBrandThumbnail ?
                                                        <FileDrop
                                                            onTargetClick={onTargetClick}
                                                            onFrameDragEnter={(event) => {}}
                                                            onFrameDragLeave={(event) => {}}
                                                            onFrameDrop={(event) => {}}
                                                            onDragOver={(event) => {}}
                                                            onDragLeave={(event) => {}}
                                                            onDrop={(files, event) => handleFile(files, event)}
                                                        >
                                                            <div className="flex flex-col items-center justify-center gap-y-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                                </svg>
                                                                <p className='text-gray-400 text-xs'>Drop an image</p>
                                                            </div>
                                                        </FileDrop>
                                                        : 
                                                        <div className='relative w-full mb-3'>
                                                            <div className='absolute top-0 pt-2 pr-2 left-0 w-full flex flex-row-reverse z-50'>
                                                                <button 
                                                                    className='text-white flex items-center justify-center bg-black transition duration-200 bg-opacity-60 rounded w-10 h-10 hover:bg-opacity-90' 
                                                                    onClick={()=>{
                                                                        setActiveBrandThumbnail(null)
                                                                        setFileDetails(null)
                                                                    }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            <img src={activeBrandThumbnail} alt="" className='w-full' />
                                                        </div>
                                                    }

                                                    {isValidObject(fileDetails) && 
                                                        <div>
                                                            <p className='text-sm text-gray-600 mb-1'>{fileDetails.name}</p>
                                                            <p className='text-xs text-gray-600'>{fileDetails.size} - <span className='text-gray-400'>{fileDetails.type}</span></p>
                                                        </div>
                                                    }
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-full lg:w-7/12 mt-3'>
                                        {/* <label className={`text-xs text-sm block mb-2 ${validationErrors.brandContent && validationErrors.brandContent === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                            Content
                                        </label> */}
                                        <div className='long-text'>
                                            <Wysiwyg fieldTitle="Content" initialValue={brandContent || ''} updateValue={(value)=>{setBrandContent(value)}} />
                                        </div>

                                        {/* <textarea value={brandContent || ''} className={`font-sofia-pro border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 resize-none h-24 ${validationErrors.brandContent && validationErrors.brandContent === true ? 'border-red-500' : 'border-gray-0'} `}  placeholder='some brand content...' onChange={(e)=>{setBrandContent(e.target.value)}}/> */}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 w-full flex flex-row-reverse gap-x-3">
                            {activeBrand && activeBrand !== '' 
                                ?
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-ink-navy transition duration-200 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={()=>{updateBrand()}}
                                    >
                                        {/* Update Brand */}
                                        {updating ? <span className='flex items-center gap-x-2'><Spinner /> Updating brand </span> : 'Update brand' }

                                    </button>
                                :
                                    <button
                                        type="button"
                                        className="inline-flex justify-center gap-x-2 items-center px-4 py-2 text-sm font-medium text-white bg-ink-navy transition duration-200 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={()=>{createBrand()}}
                                        disabled={creating}
                                    >
                                        {creating ? <span className='flex items-center gap-x-2'><Spinner /> Creating brand </span> : 'Create brand' }
                                    </button>
                            }
                            <button
                                type="button"
                                className="inline-flex justify-center px-4 py-2 transition duration-200 text-sm font-medium text-gray-500 bg-transparent border border-transparent rounded-md hover:bg-blue-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            </div>
                        </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}

export default Brands
