import React, { useState } from 'react'

const CustomersIcon = () => {
    const [color, setColor] = useState("#B8C0D5")

    return (
        <svg onMouseEnter={()=>{setColor("#ffffff")}} onMouseLeave={()=>{setColor("#B8C0D5")}} id="friends" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21.192 16.462">
            <g id="Group_8" data-name="Group 8" transform="translate(2.561)">
                <g id="Group_7" data-name="Group 7" transform="translate(0 0)">
                <path id="Path_21" data-name="Path 21" d="M65.741,57.142a4.108,4.108,0,1,0,3.868,4.1A3.994,3.994,0,0,0,65.741,57.142Z" transform="translate(-61.873 -57.142)" fill="#adb5d4"/>
                </g>
            </g>
            <g id="Group_10" data-name="Group 10" transform="translate(11.905 0.587)">
                <g id="Group_9" data-name="Group 9" transform="translate(0 0)">
                <path id="Path_22" data-name="Path 22" d="M290.955,71.321h-.026a3.209,3.209,0,0,0-2.354,1.064,3.663,3.663,0,0,0,.041,4.939,3.21,3.21,0,0,0,2.345,1.025h.027a3.209,3.209,0,0,0,2.354-1.064,3.6,3.6,0,0,0,.937-2.477A3.427,3.427,0,0,0,290.955,71.321Z" transform="translate(-287.637 -71.321)" fill={color}/>
                </g>
            </g>
            <g id="Group_12" data-name="Group 12" transform="translate(11.174 8.287)">
                <g id="Group_11" data-name="Group 11" transform="translate(0 0)">
                <path id="Path_23" data-name="Path 23" d="M274.173,257.358h-.321a5.784,5.784,0,0,0-3.892,1.5,7.468,7.468,0,0,1,2.916,5.541h7.1v-1.236A5.812,5.812,0,0,0,274.173,257.358Z" transform="translate(-269.96 -257.358)" fill={color}/>
                </g>
            </g>
            <g id="Group_14" data-name="Group 14" transform="translate(0 9.486)">
                <g id="Group_13" data-name="Group 13" transform="translate(0 0)">
                <path id="Path_24" data-name="Path 24" d="M12.846,292.161a6.235,6.235,0,0,0-6.223-5.843H6.236A6.236,6.236,0,0,0,0,292.554v.74H12.858v-.74Q12.858,292.356,12.846,292.161Z" transform="translate(0 -286.318)" fill="#adb5d4"/>
                </g>
            </g>
        </svg>
    )
}

export default CustomersIcon
