import React, {Fragment, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import EditIcon  from '../../../assets/images/icons/edit.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu'
import DataTable from '../../../components/elements/DataTable'
import SearchIcon from '../../../assets/images/icons/search.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../utils';
import Spinner from '../../../components/elements/icons/Spinner';

const ProductGroups = () => {

    const tableOptions = {
        selectable: true
    }

    const columnWidths = {
        id: "w-full lg:w-1/12",
        label: "w-full lg:w-3/12",
        parentId: "w-full lg:w-1/12",
        displayOrder: "w-full lg:w-1/12",
        displayFormat: "w-full lg:w-2/12",
        andOr: "w-full lg:w-1/12",
        created: "w-full lg:w-2/12",
        actions: "w-full lg:w-1/12"
    }

    const columnDataStyles = {
        id: "text-ink-navy",
        label: "text-ink-navy",
        rules: "text-ink-navy",
        products: "text-ink-navy",
        date: "text-ink-navy",
        actions: "text-ink-navy"
    }

    const convertCamelCase = (camelCaseText) => {
        const text = camelCaseText;
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult
    }

    const tableHeadersFields = (sampleObject) => {
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key

            if(key === 'actions') {
                sortable = false
            }
            
            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

    const [productGroups, setProductGroups] = useState([])

    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])

    const [ pagination, setPagination ] = useState({ 
        page: 1, 
        perPage: 25, 
        totalRecords: 0,
        nextPage: '',
        lastPage: '',
        previousPage: '',
        firstPage: ''
    })

    useEffect(() => {
        const fetchProductGroups = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try { 
                let response = null 

                response = await axios.get(`/api/v1/admin/product_groups?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc`, { headers })            
                
                setProductGroups(response.data.data)

                setPagination({
                    page: pagination.page,
                    perPage: pagination.perPage,
                    totalRecords: response.data.total_records,
                    nextPage: response.data.next,
                    lastPage: response.data.last
                })

                setProcessing(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }

        fetchProductGroups()
    }, [cookies.accessToken, cookies.tokenType, pagination.page, pagination.perPage])
    

    const EditGroupButton = ({groupId}) => {
        return (
            <Link to={`groups/${groupId}`} className="font-sofia-pro rounded py-2 px-3 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
        )
    }

    const GroupLink = ({groupId, label}) => {
        return (
            <Link to={`groups/${groupId}`} className="">{label}</Link>
        )
    }

    const cleanUpProductGroups = (groupsArray) => {
        let finalResult = [] 
        groupsArray.forEach((group, groupIndex) => {
            const groupObject = {
                id: group.id,
                label: <GroupLink groupId={group.id} label={group.label} />,
                parentId: group.parent_id,
                displayOrder: group.display_order,
                displayFormat: group.display_format,
                andOr: group.and_or,
                created: group.created_at,
                actions: <EditGroupButton groupId={group.id} />,
            }
            finalResult.push(groupObject)
        })

        // {
        //     "id": 1,
        //     "label": "Finance Group 1 - Bikes between 3k and 15k",
        //     "parent_id": null,
        //     "display_order": "Newest",
        //     "display_format": "Category View",
        //     "and_or": "and",
        //     "created_at": "2022-03-22 10:50:22",
        //     "updated_at": "2022-03-22 10:50:22"
        // }

        return finalResult
    }

    const [selectedProductsCount, setSelectedProductsCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectedProductsCount(count)
    }

    return (
        <Fragment>
        <div className="w-full">
            <div className="w-full block lg:flex lg:flex-row lg:justify-between">
                <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full lg:w-10/12 mr-1 mb-4 lg:mb-0">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm mt-1">
                            <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                        </span>
                    </div>
                    <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search" />
                </div>

                <div className="flex flex-row gap-x-2">
                    {selectedProductsCount > 1 && 
                        <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                    }

                    <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border w-full lg:w-max">
                        <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Export
                    </button>
                    <Link to="new-product-group" 
                        className="rounded border-blue-800 bg-blue-800 py-3 px-4 text-xs text-white flex flex-row items-center border w-full lg:w-max" 
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>
                        Create Group
                    </Link>
                </div>
            </div>

            {!processing && error === '' ?
                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpProductGroups(productGroups)[0]).headers} 
                    tableData={cleanUpProductGroups(productGroups)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpProductGroups(productGroups)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                />
                :
                <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <Spinner />
                    <p className='text-sm text-gray-500 mt-5'>Fetching product groups...</p>
                </div>
            }
        </div>
    </Fragment>
    )
}

export default ProductGroups
