import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../utils';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Spinner from '../../../components/elements/icons/Spinner';
import ErrorMessage from '../../../components/elements/ErrorMessage';
import SuccessMessage from '../../../components/elements/SuccessMessage';
// import AutocompleteSelect from '../../../components/elements/AutocompleteSelect';
// import CloseIcon from '../../../components/elements/icons/CloseIcon';

const EditUser = () => {

    const [ processing, setProcessing ] = useState(true)
    const [ roles, setRoles ] = useState([])
    const [ error, setError ] = useState('')
    const [ success, setSuccess ] = useState('')
    const [ creating, setCreating ] = useState(false)
    const [ cookies ] = useCookies(['user'])
    const history = useHistory();
    const location = useLocation()
    const { userId } = useParams()

    const [userDetails, setUserDetails] = useState({})

    useEffect(() => {
        
      const fetchUserDetails = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        setProcessing(true)
        try { 
            let response = null 

            response = await axios.get(`/api/v1/admin/users/${userId}`, { headers })                            
            setUserDetails(response.data.data)
            await fetchRoles()

            setProcessing(false)
        } catch (error) {
            console.error(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setProcessing(false)
            }
        }
      }

      const fetchRoles = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        try { 
            let response = null 

            response = await axios.get(`/api/v1/admin/roles/?page[offset]=1&page[limit]=1000000000&page[order]=desc`, { headers })                            
            setRoles(response.data.data)
        } catch (error) {
            console.error(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setProcessing(false)
            }
        }
      }

      fetchUserDetails()

    }, [cookies.accessToken, cookies.tokenType, history, location.pathname, userId])
    

    // const [userFeatures, setUserFeatures] = useState(features)

    const toggleFeature = (featureIndex) => {
        let tempFeatures = JSON.parse(JSON.stringify(roles))

        tempFeatures[featureIndex].active = !tempFeatures[featureIndex].active
        
        setRoles(tempFeatures)
    }

    // const getSelectedRoles = () => {
    //     return roles.filter((role)=>{return role.active === true})
    // }

    // const [selectedPermission, setSelectedPermission] = useState('')
    // const [selectedPermissions, setSelectedPermissions] = useState([])

    // const selectPermission = () => {
    //     if(!selectedPermission || selectedPermission === '') {
    //         return
    //     }
    
    //     const tempSelectedBrands = [...selectedPermissions]

    //     if(tempSelectedBrands.includes(selectedPermission.id)) {
    //         return
    //     }

    //     tempSelectedBrands.push(selectedPermission.id)
    //     setSelectedPermissions(tempSelectedBrands)
    //     setSelectedPermission('')
    // }

    // const removePermission = (permission) => {
    //     const tempSelectedPermissions = [...selectedPermissions]
    //     let deleted = tempSelectedPermissions.filter((seletedPermission)=> {
    //         return seletedPermission !== permission
    //     })
    //     setTimeout(() => {
    //         setSelectedPermissions(deleted)
    //     }, 50);
    // }

    // const permissionName = (permissionId) => {
    //     let permissionSelected = roles.filter((permission)=> {
    //         return permission.id === permissionId
    //     })

    //     return permissionSelected[0].name
    // }

    const [validationErrors, setValidationErrors] = useState({})

    const validateForm = () => {
        let errors = {}
        if (!userDetails.email || userDetails.email === '') {
            errors.email = true
        }
        if (!userDetails.first_name || userDetails.first_name === '') {
            errors.firstName = true
        }
        if (!userDetails.last_name || userDetails.last_name === '') {
            errors.lastName = true
        }

        setValidationErrors(errors)
        return errors
    }

    const updateUser = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        if (Object.values(validateForm()).includes(true)) {
            setError('Please check the highlighted fields above')
            return
        }

        const requestPayload = userDetails
        setCreating(true)
        try { 
            // const response =
            await axios.post(`/api/v1/admin/users/invite`, requestPayload, { headers })                            
            setCreating(false)

            setSuccess('user details updated successfully')
        } catch (error) {
            console.error(error.response)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(JSON.stringify(error.response.data.developer_message.errors))
                setCreating(false)
            }
            setCreating(false)
        }
    }
    
    return (
        <>
            {!processing ?
                <div className="px-16 py-8 mx-auto  w-full md:w-8/12 lg:w-6/12">
                    <h3 className="text-md text-ink-navy font-medium">User Details</h3>

                    <div className="w-full flex flex-row gap-x-4 my-4">
                            <div className="w-full">
                                <label className="block mb-2 text-gray-400 text-sm">First Name</label>
                                <input type="text" value={userDetails?.first_name} onChange={(e)=>{setUserDetails({...userDetails, ...{first_name: e.target.value}})}} placeholder="User's first name" className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.firstName && validationErrors.firstName === true ? 'text-red-500 border-red-500' : ''}`} />
                            </div>               
                            {/* <div className="w-1/2">
                                <label className="block mb-2 text-gray-400 text-sm">Last Name</label>
                                <input type="text" placeholder="User's first name" className="px-3 py-2 w-full text-sm border rounded border-gray-300 transition duration-200 focus:border-gray-500 focus:outline-none" />
                            </div>                */}
                    </div>
                    <div className="w-full flex flex-row gap-x-4 my-4">
                            <div className="w-full">
                                <label className="block mb-2 text-gray-400 text-sm">Last Name</label>
                                <input type="text" value={userDetails?.last_name} onChange={(e)=>{setUserDetails({...userDetails, ...{last_name: e.target.value}})}} placeholder="User's last name" className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.lastName && validationErrors.lastName === true ? 'text-red-500 border-red-500' : ''}`} />
                            </div>               
                            {/* <div className="w-1/2">
                                <label className="block mb-2 text-gray-400 text-sm">Last Name</label>
                                <input type="text" placeholder="User's first name" className="px-3 py-2 w-full text-sm border rounded border-gray-300 transition duration-200 focus:border-gray-500 focus:outline-none" />
                            </div>                */}
                    </div>

                    <div className="w-full flex flex-row gap-x-4 my-4">
                            {/* <div className="w-1/2">
                                <label className="block mb-2 text-gray-400 text-sm">Username</label>
                                <input type="text" placeholder="User's first name" className="px-3 py-2 w-full text-sm border rounded border-gray-300 transition duration-200 focus:border-gray-500 focus:outline-none" />
                            </div>                */}
                            <div className="w-full">
                                <label className="block mb-2 text-gray-400 text-sm">Email</label>
                                <input type="email"value={userDetails?.email} onChange={(e)=>{setUserDetails({...userDetails, ...{email: e.target.value}})}} placeholder="User's email address" className={`w-full border-gray-400 border rounded px-4 py-3 text-sm my-3 focus:outline-none focus:shadow-md focus:border-gray-200 transition duration-200 ${validationErrors.email && validationErrors.email === true ? 'text-red-500 border-red-500' : ''}`} />
                            </div>               
                    </div>

                    {/* <div className="w-full flex flex-row gap-x-4 my-4">
                            <div className="w-1/2">
                                <label className="block mb-2 text-gray-400 text-sm">Phone</label>
                                <input type="text" placeholder="User's phone number" className="px-3 py-2 w-full text-sm border rounded border-gray-300 transition duration-200 focus:border-gray-500 focus:outline-none" />
                            </div>                      
                            <div className="w-1/2"></div>
                    </div> */}

                    <h3 className="text-md text-ink-navy font-medium my-5">User Roles</h3>

                    <div className="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-4">
                        {roles.map((role, roleIndex) => (                    
                        <div key={roleIndex} className="">
                            <input className="mr-3" type="checkbox" checked={role.active} onChange={()=>{toggleFeature(roleIndex)}} />
                            <label className="text-ink-navy text-sm">{role.name}</label>
                        </div>
                        ))}
                    </div>
                    {/* <div className='w-full mb-5 flex items-center justify-between gap-x-2'>

                            <div className='w-9/12'>
                                <AutocompleteSelect 
                                    placeholderText="Find a product..." 
                                    selectOptions={roles}
                                    titleField='name'
                                    displayImage={false}
                                    imageField=''
                                    returnFieldValue={(value) => {setSelectedPermission(value)}}
                                />
                            </div>
                            <button className='bg-blue-100 text-ink-navy px-4 py-3 text-xs rounded transition duration-200 hover:bg-blue-700 hover:text-white w-3/12'
                            onClick={()=>{selectPermission()}}
                            >
                                Add Permission</button>
                        </div>
                        <label className="block text-xs text-ink-navy my-2">Add products for this style guide by selecting a brand above and clicking the "Add Product" button. You can add multiple brproductsands </label> */}


                    {/* <h3 className="text-md text-ink-navy font-medium my-5">Password</h3>

                    <div className="w-full flex flex-row gap-x-4 my-4">
                            <div className="w-1/2">
                                <label className="block mb-2 text-gray-400 text-sm">New Password</label>
                                <input type="password" placeholder="Create a new password" className="px-3 py-2 w-full text-sm border rounded border-gray-300 transition duration-200 focus:border-gray-500 focus:outline-none" />
                            </div>               
                            <div className="w-1/2">
                                <label className="block mb-2 text-gray-400 text-sm">Confirm Password</label>
                                <input type="password" placeholder="Re-type password to confirm" className="px-3 py-2 w-full text-sm border rounded border-gray-300 transition duration-200 focus:border-gray-500 focus:outline-none" />
                            </div>               
                    </div>

                    <div className="w-full flex flex-row gap-x-4 my-4">
                            <div className="w-1/2">
                                <label className="block mb-2 text-gray-400 text-sm">API Key</label>
                                <input type="text" placeholder="User's API Key" className="px-3 py-2 w-full text-sm border rounded border-gray-300 transition duration-200 focus:border-gray-500 focus:outline-none" />
                            </div>    
                            <div className="w-1/2"></div>
                    </div> */}

                    <div className='flex flex-row-reverse gap-x-3 mt-12'>
                        <button className='flex gap-x-2 items-center px-5 py-3 border border-blue-700 bg-blue-700 text-white rounded transition duration-200 text-xs'
                        onClick={()=>{if(!creating) { updateUser() }
                                    }}>{creating ? <><Spinner />Updating User...</> : 'Update User'}
                        </button>
                    </div>

                    {error && error!=='' &&  
                        <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                    } 

                    {success && success!=='' &&  
                        <SuccessMessage message={success} dismissHandler={()=>{setSuccess(false)}} />
                    } 

                </div>
                :
                <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <Spinner />
                    <p className='text-sm text-gray-500 mt-5'>Fetching user details...</p>
                </div>
            }
        </>
    )
}

export default EditUser
