import React, { useState } from 'react'
import MailchimpLogo from '../../../../assets/images/icons/mailchimp.svg'
import CopyIcon from '../../../../assets/images/icons/copy-icon.svg'
import { Switch } from '@headlessui/react'

const Mailchimp = () => {
    const [enabled, setEnabled] = useState(true)

    return (
        <div className="w-full border bg-white border-gray-200 rounded-lg">
            <div className="p-4 flex flex-row justify-between items-center">
                <div className="flex items-center gap-x-4">
                    <img src={MailchimpLogo} alt="" className="w-12" />
                    <h3 className="font-medium text-sm text-gray-500">Mailchimp Sign-up</h3>
                </div>
                <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={`${
                        enabled ? 'bg-ink-navy' : 'bg-gray-200'
                    } relative inline-flex items-center h-5 rounded-full w-10`}
                    >
                    <span className="sr-only">Enable Mailchimp</span>
                    <span
                        className={`transform transition ease-in-out duration-200 ${
                        enabled ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-3 h-3 transform bg-white rounded-full`}
                    />
                </Switch>
            </div>
            <div className="px-6 py-2">
                <p className="text-gray-400 text-xs mb-4">
                    Connect your mailchimp account to all email registration forms. <button className="text-ink-navy font-medium">Find out more</button>
                </p>

                <div className="flex flex-row py-1 items-center">
                    <div className="w-3/12">
                        <label className="text-gray-300 text-xs font-sofia-pro">API Key:</label>
                    </div>
                    <div className="w-9/12">
                        <input className="w-full text-xs bg-gray-100 bg-opacity-40 border border-gray-200 rounded px-2 py-1 focus:outline-none" type="text" />
                    </div>
                    <div className="w-1/12">
                        <button className="ml-2 pt-2">
                            <img alt="" src={CopyIcon} />
                        </button>
                    </div>
                </div>
                <div className="flex flex-row py-2 items-center">
                    <div className="w-3/12">
                        <label className="text-gray-300 text-xs font-sofia-pro">Audience ID:</label>
                    </div>
                    <div className="w-9/12">
                        <input className="w-full text-xs bg-gray-100 bg-opacity-40 border border-gray-200 rounded px-2 py-1 focus:outline-none" type="text" />
                    </div>
                    <div className="w-1/12">
                        <button className="ml-2 pt-2">
                            <img alt="" src={CopyIcon} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="px-4 py-3 mt-3 border-t border-gray-200 text-right">
                <button className="border-ink-navy rounded border text-ink-navy text-xs px-3 py-2">Save</button>
            </div>
        </div>
    )
}

export default Mailchimp
