import React, { useState } from 'react'

const OrdersIcon = () => {
    const [color, setColor] = useState("#B8C0D5")

    return (
        <svg onMouseEnter={()=>{setColor("#ffffff")}} onMouseLeave={()=>{setColor("#B8C0D5")}} id="ERsbyD.tif" xmlns="http://www.w3.org/2000/svg" width="15" height="14.99" viewBox="0 0 20 19.993">
            <g id="Group_4" data-name="Group 4">
                <path id="Path_9" data-name="Path 9" d="M65.626,335.807a2.438,2.438,0,0,1-2.582,1.751,2.5,2.5,0,0,1-2.4-1.8,2.423,2.423,0,0,1-2.62,1.791,2.375,2.375,0,0,1-2.38-1.82,4.687,4.687,0,0,1-.164.455,2.363,2.363,0,0,1-1.966,1.352,2.521,2.521,0,0,1-2.52-1.043,2.721,2.721,0,0,1-.329-.816.661.661,0,0,1,.044-.408c.46-1.086.934-2.166,1.395-3.253a.237.237,0,0,1,.261-.17q8.278,0,16.556,0a.252.252,0,0,1,.27.191c.464,1.085.934,2.167,1.4,3.251a1.014,1.014,0,0,1-.082.818,2.265,2.265,0,0,1-1.694,1.377,2.6,2.6,0,0,1-2.706-.83A4.762,4.762,0,0,1,65.626,335.807Z" transform="translate(-50.648 -327.554)" fill={color}/>
                <path id="Path_10" data-name="Path 10" d="M82.715,496.858v-8.7a4.038,4.038,0,0,0,2.134-.018c0,.113,0,.2,0,.281q0,2.533,0,5.066c0,.42.107.527.528.527h11.8c.415,0,.524-.111.524-.532q0-2.522,0-5.043v-.295a4.077,4.077,0,0,0,2.128.014v8.7Z" transform="translate(-81.281 -476.865)" fill="#adb5d4"/>
                <path id="Path_11" data-name="Path 11" d="M82.525,238.752c0-.749-.011-1.484,0-2.219a.686.686,0,0,1,.718-.621c.833,0,1.666,0,2.5,0,4.357,0,8.715.006,13.072-.008a.793.793,0,0,1,.85.85c-.032.66-.008,1.322-.008,2Z" transform="translate(-81.096 -235.903)" fill="#adb5d4"/>
            </g>
        </svg>

    )
}

export default OrdersIcon
