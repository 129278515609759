import React, { useState } from 'react'

const DateRangeSelector = ({returnRange}) => {
    const [dateRangeLower, setDateRangeLower] = useState('');
    const [dateRangeUpper, setDateRangeUpper] = useState('');


    const saveRange = () => {
        console.log(dateRangeLower)
        if(dateRangeLower === '' || dateRangeUpper === '') return
        returnRange(`${dateRangeLower}><${dateRangeUpper}`)
    }

    return (
        <div className='w-full'>
        <label className='block mb-2 text-xs'>From:</label>
            <input type="date" 
                placeholder='Lower limit' 
                onChange={(e)=>{
                    setDateRangeLower(e.target.value)
                }} 
                onBlur={()=>{saveRange()}}
                className="py-2 px-3 w-full border rounded border-gray-300 text-xs" 
            />
            <label className='block mb-2 text-xs mt-4'>To:</label>
            <input 
                placeholder='Upper limit' 
                type="date" 
                onChange={(e)=>{
                    setDateRangeUpper(e.target.value)
                }} 
                onBlur={()=>{saveRange()}}
                className="py-2 px-3 w-full border rounded border-gray-300 text-xs"
            />
        </div>
    )
}

export default DateRangeSelector