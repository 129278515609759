import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import axios from "axios"
import Spinner from '../../../../components/elements/icons/Spinner'
import { useHistory, useLocation } from 'react-router-dom';
import { baseUrl, convertCamelCase, isValidObject } from '../../../../utils';
// import ChevronIcon from '../../../../components/elements/icons/ChevronIcon'
// import CommentIcon from '../../../../components/elements/icons/CommentIcon'
// import EyeIcon from '../../../../components/elements/icons/EyeIcon'
import DataTable from '../../../../components/elements/DataTable';
import SearchIcon from '../../../../assets/images/icons/search.svg'
import ExportIcon from '../../../../assets/images/icons/export.svg'

const BikeFitRequests = () => {
    const history = useHistory();
    const location = useLocation()
    const [ cookies ] = useCookies(['user'])

    const [requests, setRequests] = useState([])
    const [processing, setProcessing] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
      const getBikeFitRequests = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        setProcessing(true)
        try {
            const response = await axios.get(`/api/v1/admin/bike_fits`, { headers })       
            // console.log(response.data.data)     
            setRequests(response.data.data)
            setProcessing(false)

        } catch (error) {
            console.log(error)
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setProcessing(false)
            }
        }
      }

      getBikeFitRequests()
    
    }, [cookies.accessToken, cookies.tokenType, history, location.pathname])

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject || !isValidObject(sampleObject)) {
            return []
        }
        
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'actions' || key === 'status') {
                sortable = false
            }

            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const columnWidths = {
        id: 'w-full lg:w-1/12',
        number: 'w-full lg:w-2/12',
        subject: 'w-full lg:w-5/12',
        status: 'w-full lg:w-2/12',
        watchers: 'w-full lg:w-2/12' 
    }


    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            const requestObject = {
                id: <Link to={`bike-fit-requests/${object.num}`}>{object.id}</Link>,
                number: <Link to={`bike-fit-requests/${object.num}`}>{object.num}</Link>,
                subject: object.ticket_subject,
                status: <span className='font-sofia-pro rounded py-1 px-3 text-xs text-blue-700 bg-blue-100 flex flex-row items-center gap-x-2 capitalize'>
                    {object.status}
                </span>,
                watchers: `${object.watchers.length} watcher(s), ${object.comments.length} comment(s)`,
            }
            finalResult.push(requestObject)
        })
        return finalResult
    }

    const [ pagination, setPagination ] = useState({ 
        page: 1, 
        perPage: 25, 
        totalRecords: 0,
        nextPage: '',
        lastPage: '',
        previousPage: '',
        firstPage: ''
    })

    
  return (
    <>
    {processing ?
      <div className='w-full flex flex-col items-center justify-center mt-10'>
          <Spinner />
          <p className='text-sm text-gray-500 mt-5'>Fetching bike fit request...</p>
      </div>
      
      :

      <>
        {!error || error === '' ? 
          <>
            <div className='w-full'>
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search bike fits" />
                        </div>
                        {/* <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button> */}
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap">
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Help
                        </button>
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden lg:inline" /> Export
                        </button>
                        <Link to="new-bike-fit-request" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            Create Bike fit Request
                        </Link>
                    </div>
                </div>

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData(requests)[0]).headers} 
                    tableData={cleanUpData(requests)} 
                    columnWidths={columnWidths}
                    columnDataStyles={{}}
                    allFields={tableHeadersFields(cleanUpData(requests)[0]).fields}
                    onSelectItems={()=>{}}
                    tableOptions={{selectable: false}}
                    pagination={pagination}
                    paginate={setPagination}
                />
            </div>
            
            </>
            :
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                <p className='text-sm text-gray-600 mt-1'>{error}</p>
            </div>
            }
          </>
        }
      </>
    )
}

export default BikeFitRequests