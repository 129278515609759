import React from 'react'

const UpRightArrow = ({color, width, height}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12.244 12.244">
            <g id="Icon_feather-arrow-down-right" data-name="Icon feather-arrow-down-right" transform="translate(1.414 1.414)">
                <path id="Path_2966" data-name="Path 2966" d="M10.5,19.916,19.916,10.5" transform="translate(-10.5 -10.5)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <path id="Path_2967" data-name="Path 2967" d="M19.916,19.916V10.5H10.5" transform="translate(-10.5 -10.5)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            </g>
        </svg>
    )
}

export default UpRightArrow
