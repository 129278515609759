import React from 'react'
import Layout from '../../components/layout/Layout'
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
  } from "react-router-dom";
import AdminErrorPage from './AdminErrorPage';
// import MegaNav from './navigations/mega-nav';
import Pages from './navigations/pages/Pages';
import Brands from './navigations/brands/Brands';
import Downloads from './navigations/downloads/Downloads';
import Categories from './navigations/Categories';
import Colours from './navigations/Colours';
import MainSlider from './navigations/sliders/MainSlider';
import SaleSlider from './navigations/sliders/SaleSlider';
// import AdsBlock from './navigations/ads-block/AdsBlock';
import BlogArticles from './navigations/blog-articles/BlogArticles';
import EditPage from './navigations/pages/EditPage';
import NewPage from './navigations/pages/NewPage';
import NewSlider from './navigations/sliders/NewSlider';
// import NewAdsBlock from './navigations/ads-block/NewAdsBlock';
import Slide from './navigations/sliders/Slide';
import NewBlogArticle from './navigations/blog-articles/NewBlogArticle';
import BlogArticle from './navigations/blog-articles/BlogArticle';
import AdBlocks from './navigations/ad-blocks/AdBlocks';
import Ads from './navigations/ad-blocks/Ads';
import SingleAd from './navigations/ad-blocks/SingleAd';
import NewAd from './navigations/ad-blocks/NewAd';
import MenuList from './navigations/navigations/MenuList';
import MenuDetails from './navigations/navigations/MenuDetails';
import NewMenu from './navigations/navigations/NewMenu';

const Navigations = () => {
    let { 
        path, 
        // url 
    } = useRouteMatch();

    const sidebarLinks = [
        {
            label: "Menus",
            location: "/admin/navigations/menu-list",
            subLinks: []
        },
        {
            label: "Pages",
            location: "/admin/navigations/pages",
            sublinks: []
        },
        {
            label: "Brands",
            location: "/admin/navigations/brands",
            sublinks: []
        },
        {
            label: "Colours",
            location: "/admin/navigations/colours",
            sublinks: []
        },
        {
            label: "Categories",
            location: "/admin/navigations/categories",
            sublinks: []
        },
        // {
        //     label: "Downloads",
        //     location: "/admin/navigations/downloads",
        //     sublinks: []
        // },
        {
            label: "Sliders",
            location: "/admin/navigations/sliders",
            subLinks: [
                {
                    label: "Main Slider",
                    location: "/admin/navigations/sliders/main-slider"
                },
                {
                    label: "Sale Slider",
                    location: "/admin/navigations/sliders/sale-slider"
                },
            ]
        },
        {
            label: "widgets",
            location: "/admin/navigations/widgets",
            sublinks: []
        },
        {
            label: "Blog/Articles",
            location: "/admin/navigations/blog-articles",
            sublinks: []
        }
    ]
    return (
        <Layout pageTitle="Content" sidebarLinks={sidebarLinks}>
            {/* {path} */}
            <Switch>
                <Route
                    path={path}
                    exact
                    render={() => {
                        return (
                        <Redirect to="/admin/navigations/menu-list" />
                        // <Redirect to="/admin/navigations/mega-nav" />
                        )
                    }}
                    // render={() => {
                    //     return (
                    //     this.state.isUserAuthenticated ?
                    //     <Redirect to="/home" /> :
                    //     <Redirect to="/test1" /> 
                    //     )
                    // }}
                />

                {/* <Route path={`${path}/mega-nav`} exact>
                    <MegaNav />
                </Route> */}
                <Route path={`${path}/menu-list`} exact>
                    <MenuList />
                </Route>
                <Route path={`${path}/menu-list/menu/:id`} exact>
                    <MenuDetails />
                </Route>
                <Route path={`${path}/menu-list/new-menu`} exact>
                    <NewMenu />
                </Route>
                <Route path={`${path}/pages`} exact>
                    <Pages />
                </Route>
                <Route path={`${path}/pages/edit/:pageSlug`} exact>
                    <EditPage />
                </Route>
                <Route path={`${path}/pages/create-new-page`} exact>
                    <NewPage />
                </Route>
                <Route path={`${path}/brands`} exact>
                    <Brands />
                </Route>
                <Route path={`${path}/downloads`} exact>
                    <Downloads />
                </Route>
                <Route path={`${path}/categories`} exact>
                    <Categories />
                </Route>
                <Route path={`${path}/colours`} exact>
                    <Colours />
                </Route>
                <Route path={`${path}/sliders/main-slider`} exact>
                    <MainSlider />
                </Route>
                <Route path={`${path}/sliders/sale-slider`} exact>
                    <SaleSlider />
                </Route>
                <Route path={`${path}/sliders/new-slider`} exact>
                    <NewSlider />
                </Route>
                <Route path={`${path}/sliders/slide/:slideId`} exact>
                    <Slide />
                </Route>
                <Route path={`${path}/widgets`} exact>
                    <AdBlocks />
                </Route>
                <Route path={`${path}/ad-blocks/:id/ads`} exact>
                    <Ads />
                </Route> 
                <Route path={`${path}/ad-blocks/:id/ads/single/:adId`} exact>
                    <SingleAd />
                </Route> 
                <Route path={`${path}/ad-blocks/:id/ads/new-ad`} exact>
                    <NewAd />
                </Route> 
                {/* <Route path={`${path}/ads-block/new-ads-block`} exact>
                    <NewAdsBlock />
                </Route> */}
                <Route path={`${path}/blog-articles`} exact>
                    <BlogArticles />
                </Route>
                <Route path={`${path}/blog-articles/new-blog-article`} exact>
                    <NewBlogArticle />
                </Route>
                <Route path={`${path}/blog-articles/article/:articleId`} exact>
                    <BlogArticle />
                </Route>

                <Route component={AdminErrorPage} />
            </Switch>
        </Layout>
    )
}

export default Navigations
