import React from 'react'
import CheckCircle from '../../assets/images/icons/check-circle.svg'

const SystemStats = ({systemStats}) => {
    return (
        <div className="w-full p-5">
            <div className="p-4 border rounded border-dashed border-black bg-white bg-opacity-80">
                <div className="flex flex-row justify-between pb-3 border-b">
                    <div>
                        <p className="font-medium text-sm">System Status</p>
                        <p className="py-1 px-2 inline rounded-full font-light bg-blue-300 bg-opacity-40 text-xs mt-1">{systemStats.systemStatus}</p>
                    </div>
                    <img src={CheckCircle} alt="" className="w-9" />
                </div>
                <div className="flex flex-row justify-between py-3 items-center">
                    {/* <span className="w-2 h-2 rounded-full bg-gray-400" /> */}
                    <div className="">
                        <p className="text-gray-600 text-sm">Stock update:</p>
                    </div>
                    <div>
                        <p className="text-gray-600 text-sm">{systemStats.stockUpdate}</p>
                    </div>
                </div>
                <div className="flex flex-row justify-between py-3 items-center">
                    {/* <span className="w-2 h-2 rounded-full bg-gray-400" /> */}
                    <div className="">
                        <p className="text-gray-600 text-sm">Product update:</p>
                    </div>
                    <div>
                        <p className="text-gray-600 text-sm">{systemStats.productUpdate}</p>
                    </div>
                </div>
                <div className="flex flex-row justify-between py-3 items-center">
                    {/* <span className="w-2 h-2 rounded-full bg-gray-400" /> */}
                    <div className="">
                        <p className="text-gray-600 text-sm">Supplier stock update:</p>
                    </div>
                    <div>
                        <p className="text-gray-600 text-sm">{systemStats.supplierStockUpdate}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SystemStats
