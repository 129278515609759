import React, { Fragment, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import axios from "axios"
import Spinner from '../../../components/elements/icons/Spinner'
import { useHistory, useLocation } from 'react-router-dom';
import { baseUrl, convertCamelCase, isValidObject } from '../../../utils';
import DataTable from '../../../components/elements/DataTable';
import SearchIcon from '../../../assets/images/icons/search.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import { Dialog, Transition } from '@headlessui/react'
import AutocompleteSelect from '../../../components/elements/AutocompleteSelect';
import PlusIcon from '../../../components/elements/icons/PlusIcon';
import CloseIcon from '../../../components/elements/icons/CloseIcon';
import ErrorMessage from '../../../components/elements/ErrorMessage';
import UpdateRole from '../../../components/partials/settings/roles-permissions/UpdateRole';

const RolesPermissions = () => {
  const history = useHistory();
  const location = useLocation()
  const [ cookies ] = useCookies(['user'])

  const [roles, setRoles] = useState([])
  const [processing, setProcessing] = useState(true)
  const [error, setError] = useState(null)
  const [newRoleError, setNewRoleError] = useState(null)

  const [ pagination, setPagination ] = useState({ 
    page: 1, 
    perPage: 25, 
    totalRecords: 0,
    nextPage: '',
    lastPage: '',
    previousPage: '',
    firstPage: ''
  })

  const [allPermissions, setAllPermissions] = useState([])
  const [openNewRole, setOpenNewRole] = useState(false)
  const [triggerFetch, setTriggerFetch] = useState(true)

  useEffect(() => {

    const getBikeFitRequests = async () => {
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
      }
      setProcessing(true)
      try {
          const response = await axios.get(`/api/v1/admin/roles`, { headers })       
          // console.log(response.data.data)     
          setRoles(response.data.data)
          await getAllPermisiions()
          setProcessing(false)

      } catch (error) {
        console.log(error.response)
          if(error.response.data.developer_message.exception === "AuthenticationException") {
            window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
          } else {
              setError(error.response.data.developer_message.errors)
              setProcessing(false)
          }
      }
    }

    const getAllPermisiions = async () => {
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
      }
      setProcessing(true)
      try {
          const response = await axios.get(`/api/v1/admin/permissions?page[offset]=1&page[limit]=100000000000&page[order]=desc`, { headers })       
          // console.log(response.data.data)     
          setAllPermissions(response.data.data)
          setTriggerFetch(false)
          // setProcessing(false)

      } catch (error) {
        setTriggerFetch(false)
        console.log(error.response)
          if(error.response.data.developer_message.exception === "AuthenticationException") {
            window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
          } else {
              setError(error.response.data.developer_message.errors)
              setProcessing(false)
          }
      }
    }

    if(triggerFetch && triggerFetch === true) {
      getBikeFitRequests()
    }
  
  }, [cookies.accessToken, cookies.tokenType, history, location.pathname, triggerFetch])

  const tableHeadersFields = (sampleObject) => {
      if(!isValidObject(sampleObject)) {
          return
      }
      const headers = []
      const fields = []
      Object.keys(sampleObject).forEach((key, index)=>{
          let columnDataType = 'text'
          let forPopover = false
          let columnDisplayName = convertCamelCase(key)
          let sortable = true

          if(key === 'actions' || key === 'status') {
              sortable = false
          }

          let column = key

          if(key !== 'selected') {
              headers.push({
                  column,
                  columnDisplayName,
                  data: sampleObject[key],
                  sortable,
                  forPopover,
                  columnDataType
              })

              let fieldSelected = true

              if(index > 10) {
                  fieldSelected = false
              }
              fields.push({
                  name: columnDisplayName,
                  selected: fieldSelected
              })
          }
      });
      return {headers, fields}
  }

  const columnWidths = {
      id: 'w-full lg:w-1/12',
      name: 'w-full lg:w-3/12',
      guardName: 'w-full lg:w-3/12',
      created: 'w-full lg:w-3/12',
      lastUpdate: 'w-full lg:w-2/12' 
  }

  const [updateRole, setUpdateRole] = useState(false)

  // const openRole = (role) => {
  //   console.log(role)
  //   setActiveRole(role)
  //   setTimeout(() => {
  //     setOpenNewRole(true)
  //   }, 100);
  // } 

  const cleanUpData = (dataArray) => {
      let finalResult = [] 
      dataArray.forEach((object, objectIndex) => {
          const requestObject = {
              id: object.id,
              name: <button onClick={()=>{startUpdateRole(object)}}>{object.name}</button>,
              guardName: object.guard_name,
              created: new Date(object.created_at).toDateString(),
              lastUpdate:  new Date(object.updated_at).toDateString()
          }
          finalResult.push(requestObject)
      })
      return finalResult
  }

  const [newPermission, setNewPermission] = useState(null)

  const addPermissionToRole = () => {
    if(!newPermission || newPermission === null) {
      return
    }
    const tempRole = { ...activeRole }
    if(!tempRole.permissions) {
      tempRole.permissions =[]
    }
    tempRole.permissions.push({id: newPermission.id})
    setActiveRole(tempRole)
    setNewPermission(null)
  }

  const removePermissionFromRole = (perm) =>{
    const tempRole = { ...activeRole }

    const filtered = tempRole.permissions.filter((permission) => {
      return permission.id !== perm.id
    })

    tempRole.permissions = filtered

    setActiveRole(tempRole)

  }

  const permissionName = (id) => {
    const filtered = allPermissions.filter((permission) => {
      return permission.id === id
    })

    return filtered[0].name
  }

  const [activeRole, setActiveRole] = useState(null)

  const startCreateNewRole = () =>{
    setActiveRole({})
    setTimeout(() => {
      setOpenNewRole(true)
    }, 100);
  }
  
  const startUpdateRole = (role) =>{
    setUpdateRole(role)
    setTimeout(() => {
      setOpenUpdateRole(true)
    }, 100);
  }
  
  const [creating, setCreating] = useState(false)

  const closeRoleDialog = () =>{
    setActiveRole(null)
    setTimeout(() => {
      setOpenNewRole(false)
    }, 100);
  }

  const [openUpdateRole, setOpenUpdateRole] = useState(false)

  const closeUpdateRoleDialog = () =>{
    setUpdateRole(null)
    setTimeout(() => {
      setOpenUpdateRole(false)
    }, 100);
  }

  const [ validationErrors, setValidationErrors ] = useState({})

  const validateForm  = () => {
      let errors = {}
      
      if(!activeRole.name || activeRole.name === '') {
          errors.name = true
      }

      if(!activeRole.permissions || activeRole.permissions.length === 0) {
          errors.permissions = true
      }

      setValidationErrors(errors)
      console.log('Validation',errors)
      return errors
  }

  const createRole = async () => {
    const headers = {
      'Content-Type': 'application/json', 
      'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
    }

    if (Object.values(validateForm  ()).includes(true)) {
        setNewRoleError('Please check the highlighted fields')
        return
    }
    
    const requestPayload = activeRole

    setCreating(true)

    try {
        await axios.post(`/api/v1/admin/roles`, requestPayload, { headers })            
        setTriggerFetch(true)
        closeRoleDialog()
        setCreating(false)

    } catch (error) {
        console.error(error)
        setNewRoleError(error.response.data.msg)
        setCreating(false)
    }
  }


  return (
    <>
    <div className="px-16 py-8 mx-auto w-full">
    {processing ?
      <div className='w-full flex flex-col items-center justify-center mt-10'>
          <Spinner />
          <p className='text-sm text-gray-500 mt-5'>Fetching Roles...</p>
      </div>
      
      :

      <>
        {!error || error === '' ? 
          <>
            <div className='w-full'>
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search roles" />
                        </div>
                        {/* <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button> */}
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap">
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Help
                        </button>
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden lg:inline" /> Export
                        </button>
                        <button onClick={()=>[startCreateNewRole(true)]} className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            Create New Role
                        </button>
                    </div>
                </div>

                <DataTable 
                    tableHeaders={tableHeadersFields(cleanUpData(roles)[0]).headers} 
                    tableData={cleanUpData(roles)} 
                    columnWidths={columnWidths}
                    columnDataStyles={{}}
                    allFields={tableHeadersFields(cleanUpData(roles)[0]).fields}
                    onSelectItems={()=>{}}
                    tableOptions={{selectable: false}}
                    pagination={pagination}
                    paginate={setPagination}
                />
            </div>
            
            </>
            :
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                <p className='text-sm text-gray-600 mt-1'>{error}</p>
            </div>
            }
          </>
        }
      </div>


      {activeRole !== null && <Transition appear show={openNewRole} as={Fragment}>
          <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setOpenNewRole}
          >
          <div className="min-h-screen px-4 text-center">
              <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
              >
              &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
              <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                as="h3"
                className="text-md font-medium leading-6 text-ink-nvy"
                >
                    {/* {activeBrand && activeBrand !== '' 
                    ?
                        "Update brand"
                    :
                        "Create a New Brand"
                    } */}
                    Create New Role
                </Dialog.Title>
                  <div className="mt-2">
                    <label className='block mb-2 font-sofia-pro text-ink-navy text-sm'>Name</label>
                    <input 
                      type="text"  
                      value={activeRole.name || ''} 
                      onChange={(e)=>{setActiveRole({...activeRole, ...{name: e.target.value}})}} 
                      className={`w-full px-4 py-3 border text-gray-800 placeholder:text-gray-500 focus:border-gray-600 rounded focus:outline-none transition duration-200 text-xs text-gray-500 mb-6 ${validationErrors && validationErrors.name === true ? 'border-red-600' : 'border-gray-300' }`} 
                      placeholder="Permission name" />

                    <label className='block mb-1 font-sofia-pro text-ink-navy text-sm'>Add Permissions</label>
                    <label className='block mb-2 font-sofia-pro text-gray-500 text-xs'>Select a permission below and click on the + button to add the permission for this role </label>
                    <div className='flex flex-row gap-x-2'>
                      <AutocompleteSelect 
                        placeholderText="Find a permission..." 
                        selectOptions={allPermissions}
                        titleField='name'
                        displayImage={false}
                        imageField=''
                        hasError={validationErrors && validationErrors.permissions === true}
                        returnFieldValue={(value) => {setNewPermission(value)}}
                      />

                      <button className='px-3 py-2 rounded bg-blue-100 text-ink-navy hover:bg-ink-navy hover:text-white text-xs transition duration-200' onClick={()=>{addPermissionToRole()}}>
                        <PlusIcon className="w-5 h-5" />
                      </button>
                    </div>

                    {
                      activeRole.permissions && activeRole.permissions.length > 0 &&<div className='my-5'>
                        <label className='block mb-2 font-sofia-pro text-ink-navy text-sm'>Permissions added</label>

                        {activeRole.permissions.map((permission, permissionIndex) => (
                          <span key={permissionIndex} className='inline rounded px-2 py-1 bg-gray-200 text-gray-500 gap-x-3 text-xs mr-2 mb-4 w-max'>
                            {permissionName(permission.id)} <button onClick={()=>{removePermissionFromRole(permission)}}><CloseIcon classes="w-4 h-4" /></button>
                          </span>
                        ))}    
                      </div>
                    }

                    <div className="mt-4 w-full flex flex-row-reverse gap-x-3 mt-24">
                      
                      {/* {activeBrand && activeBrand !== '' 
                          ?
                              <button
                                  type="button"
                                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-ink-navy transition duration-200 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                  onClick={()=>{updateBrand()}}
                              >
                                  {/* Update Brand */}
                                  {/* {updating ? <span className='flex items-center gap-x-2'><Spinner /> Updating brand </span> : 'Update brand' } */}

                              {/* </button> */}
                          {/* :  */}
                              <button
                                  type="button"
                                  className="inline-flex justify-center gap-x-2 items-center px-4 py-2 text-sm font-medium text-white bg-ink-navy transition duration-200 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                  onClick={()=>{createRole()}}
                                  disabled={creating}
                              >
                                  {creating ? <span className='flex items-center gap-x-2'><Spinner /> Creating Role </span> : 'Create role' }
                              </button>
                      {/* } */}
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 transition duration-200 text-sm font-medium text-gray-500 bg-transparent border border-transparent rounded-md hover:bg-blue-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={closeRoleDialog}
                      >
                        Cancel
                      </button>
                    </div>
                    {newRoleError && newRoleError!=='' &&  
                      <ErrorMessage message={newRoleError} dismissHandler={()=>{setNewRoleError(false)}} />
                    } 
                </div>
              </div>
            </Transition.Child>
        </div>
      </Dialog>
    </Transition>}


      {updateRole !== null && <Transition appear show={openUpdateRole} as={Fragment}>
          <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setOpenUpdateRole}
          >
          <div className="min-h-screen px-4 text-center">
              <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
              >
              &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
              <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                as="h3"
                className="text-md font-medium leading-6 text-ink-nvy"
                >
                    {/* {activeBrand && activeBrand !== '' 
                    ?
                        "Update brand"
                    :
                        "Create a New Brand"
                    } */}
                    Update Role
                </Dialog.Title>
                <UpdateRole 
                  triggerClose={()=>[closeUpdateRoleDialog()]}
                  updateRole={updateRole} 
                  allPermissions={allPermissions}
                />
              </div>
            </Transition.Child>
        </div>
      </Dialog>
    </Transition>}
  </>
  )
}

export default RolesPermissions