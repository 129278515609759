import React, { Fragment } from 'react'
import MenuIcon from '../../assets/images/icons/menu.svg'
import { Link, NavLink } from 'react-router-dom'
import SystemStats from './SystemStats'
import DisclosureList from '../elements/DisclosureList'
import StoreSelector from './StoreSelector'

const Sidebar = ({toggleFunction, links, systemStats, pageTitle}) => {

    return (
        <div className="h-screen">
            <div className="h-24 flex flex-row items-center border-b border-gray-300 px-6 justify-between">
                <div className='w-11/12 relative' >
                    <StoreSelector />
                </div>
                <button className="cursor-pointer ml-3" onClick={()=>{toggleFunction()}}>
                    <img src={MenuIcon} alt="toggler" />
                </button>
            </div>
            {/* the sidebar */}
            <nav className="p-10">
                <Link to="/admin/dashboard" className="mb-4 font-medium text-md block text-ink-navy">{pageTitle}</Link>  
                {links.length > 0 && 
                links.map((link, linkIndex) => (
                    // {link}
                    <Fragment key={linkIndex}>
                        {link.subLinks && link.subLinks.length > 0 
                            ? <DisclosureList listTitle={link.label} listLinks={link.subLinks} /> 
                            // : <NavLink to={`${url}/${link.location}`} key={linkIndex} className="block text-gray-500 text-sm py-2 hover:text-ink-navy" activeClassName="text-ink-navy">{link.label}</NavLink> }
                            : <NavLink to={link.location} key={linkIndex} className="block text-gray-500 text-sm py-2 hover:text-ink-navy" activeClassName="text-ink-navy">{link.label}</NavLink> }
                    </Fragment>
                      
                ))}
            </nav>
            {systemStats && 
                <SystemStats systemStats={systemStats} />
            }
        </div>
    )
}

export default Sidebar
