import React from 'react'

const ReviewMetaRatingTypes = () => {
  return (
    <div className="px-16 py-8 mx-auto w-full">
        ReviewMetaRatingTypes
    </div>
  )
}

export default ReviewMetaRatingTypes