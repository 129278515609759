import React from 'react'
import {
    BrowserRouter as Router,
    // Switch,
    Route,
    // Link,
    // useParams,
    useRouteMatch,
    Redirect
  } from "react-router-dom";
import Bookings from './admin/Bookings';
import Customers from './admin/Customers';
import Dashboard from './admin/Dashboard';
import Navigations from './admin/Navigations';
import Orders from './admin/Orders';
import Products from './admin/Products';
import Reports from './admin/Reports';
import Settings from './admin/Settings';
import Support from './admin/Support';

const Admin = () => {
    let { 
        path, 
        // url 
    } = useRouteMatch();
    return (
        <Router>
            {/* <Switch> */}
                <Route exact path={path} render={() => {
                    return (
                        <Redirect to="/admin/dashboard" />
                    )
                }} />
                <Route path={`${path}/dashboard`} exact>
                    <Dashboard />
                </Route>
                <Route path={`${path}/orders`}>
                    <Orders />
                </Route>
                <Route path={`${path}/products`}>
                    <Products />
                </Route>
                <Route path={`${path}/navigations`}>
                    <Navigations />
                </Route>
                <Route path={`${path}/bookings`}>
                    <Bookings />
                </Route>
                <Route path={`${path}/customers`}>
                    <Customers />
                </Route>
                <Route path={`${path}/reports`}>
                    <Reports />
                </Route>
                <Route path={`${path}/settings`}>
                    <Settings />
                </Route>
                <Route path={`${path}/support`}>
                    <Support />
                </Route>
            {/* </Switch> */}
        </Router>
    )
}

export default Admin
