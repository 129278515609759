import React, { Fragment, useState, useEffect } from 'react'
import WhiteLogo from '../assets/images/logo-w.png'
// import Logo from '../assets/images/logo.png'
import { useHistory, useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
import Spinner from '../components/elements/icons/Spinner';
import axios from "axios"
import { baseUrl } from '../utils';
import ErrorMessage from '../components/elements/ErrorMessage'
import SuccessMessage from '../components/elements/SuccessMessage';

const VerificationPage = ( {match} ) => {
    const history = useHistory();
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')  
    const [success, setSuccess] = useState('')  

    // const hash = match.params.filter
    // const expiration = match.params.expiration
    // const signature = match.params.signature

    const { userId, expiration, hash, signature } = useParams()

    useEffect(() => {
        const verifyAccount = async  () => {
            const headers = {
                'Content-Type': 'application/json'
            }
            setProcessing(true)
            try {
                
                const response = await axios.get(`/api/v1/auth/verify/${userId}?expires=${expiration}&hash=${hash}&signature=${signature}`, { headers })            
                console.log(response)
                setSuccess('Account verification successful. Redirecting to log in page...')
                setTimeout(() => {
                    history.push("/");
                }, 3000);
            } catch (error) {
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }
        verifyAccount()
    }, [userId, hash, expiration, signature, history])

    return (
        <Fragment>
            <div className="w-full block lg:flex flex-row items-center">
                <div className="h-32 lg:h-screen w-full bg-ink-navy flex flex-col gap-y-8 items-center py-24">
                    <div className="w-36">
                        <img src={WhiteLogo} alt="push retail logo" />
                    </div>
                    <div className='rounded-lg w-4/5 md:w-3/5 lg:w-2/5 bg-white shadow-lg p-10 flex flex-col items-center'>
                        {!processing && error === '' && 
                            <>
                                <Spinner />
                                <p className='text-sm text-gray-500 mt-5'>Account verification in progress...</p>
                            </>
                        }
                        <p className='text-sm text-gray-600 mt-4 mb-4'>You will be redirected to the log in page once your account is verified</p>

                        {error && error!=='' &&  
                            <ErrorMessage message={error} dismissHandler={()=>{setError('')}} />
                        } 
                        
                        {success && success!=='' &&  
                            <SuccessMessage message={success} dismissHandler={()=>{setSuccess('')}} />
                        } 
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default VerificationPage
