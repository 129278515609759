import React from 'react'
import Chart from "react-apexcharts";

const TurnoverAnalysis = () => {
    const chartState = { 
        series: [
            {
                name: 'Previous week',
                type: 'area',
                data: [14, 9, 20, 14, 8, 18, 21]
            },
            {
                name: 'This week',
                type: 'column',
                data: [23, 11, 22, 27, 13, 22, 37]
            }
        ],
        options: {
          chart: {
            fontFamily: 'SofiaPro, sans-serif',
            foreColor: '#AEA8C4',
            toolbar: {
              show: false
            },
            height: 350,
            type: 'line',
            stacked: true,
          },
          stroke: {
            width: [2, 0],
            curve: 'smooth',
            colors: ['#58E7F2', '#58E7F2']
          },
          plotOptions: {
            bar: {
              columnWidth: '20%',
              borderRadius: 6
            }
          },
          fill: {
            type: ["gradient", "solid"],
            colors: ["#58E7F2", '#04194C' ],
            opacity: [0.25, 1],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 25, 100, 100]
            }
          },
          legend: {
            show: false
          },
          labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          markers: {
            size: 0
          },
          xaxis: {
            type: 'string'
          },
          yaxis: {
            title: {
              text: '',
            },
            min: 0
          },
          tooltip: {
            shared: true,
            intersect: false,
            style: {
                fontSize: '12px',
                fontFamily: 'SofiaPro, sans-serif'
            },
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "&pound;" + y.toFixed(0) + "K";
                }
                return y;
              }
            }
          }
        },
      };
    // }
    return (
        <div id="chart">
            <Chart options={chartState.options} series={chartState.series} type="line" height={350} />
        </div>
    )
}

export default TurnoverAnalysis
