import React from 'react'
import Mailchimp from './integrations/Mailchimp'
import GoogleTagManager from './integrations/GoogleTagManager'
import Trustpilot from './integrations/Trustpilot'
import GoogleRecaptcha from './integrations/GoogleRecaptcha'
import AdRoll from './integrations/AdRoll'
import Instagram from './integrations/Instagram'


const Integration = () => {
    return (
        <div>
            <h3 className="font-medium text-ink-navy mb-7 text-lg">Integrations</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6">
                <Mailchimp />

                <GoogleTagManager />

                <Trustpilot />
                
                <GoogleRecaptcha />
                
                <AdRoll />
                
                <Instagram />
            </div>

        </div>
    )
}

export default Integration
