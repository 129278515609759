import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import React, { Fragment, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router';
import HorizontalMenuIcon  from '../../../assets/images/icons/horizontal-menu-icon.svg'
import DataTable from '../../../components/elements/DataTable'
import { convertCamelCase, yesteryears } from '../../../utils';
import SearchIcon from '../../../assets/images/icons/search.svg'
import FilterIcon from '../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../assets/images/icons/bulk-items.svg'
import DropdownMenu from '../../../components/elements/DropdownMenu';
import Spinner from '../../../components/elements/icons/Spinner';
import axios from "axios"
import { baseUrl } from '../../../utils';
import { Link } from 'react-router-dom';
import ArrowIcon from '../../../components/elements/icons/ArrowIcon';
import PlusIcon from '../../../components/elements/icons/PlusIcon';

const AllSupportTickets = () => {
  const history = useHistory();
  const location = useLocation()

  const tickets = [
    {
      id: "145",
      user: "Mike Lewis",
      topic: "Support Topic",
      assignee: {
        id: "1",
        name: "Admin User"
      },
      lastUpdated: "2021-04-02",
      priority: "high",
      status: "pending"
    },
    {
      id: "146",
      user: "Gretta Davies",
      topic: "Support Topic",
      assignee: {
        id: "1",
        name: "Admin User"
      },
      lastUpdated: "2021-04-02",
      priority: "medium",
      status: "pending"
    },
    {
      id: "147",
      user: "Cecil B. Demented",
      topic: "Support Topic",
      assignee: {
        id: "1",
        name: "Admin User"
      },
      lastUpdated: "2021-04-02",
      priority: "medium",
      status: "processing"
    },
    {
      id: "148",
      user: "john Stockton",
      topic: "Support Topic",
      assignee: {
        id: "1",
        name: "Admin User"
      },
      lastUpdated: "2021-04-02",
      priority: "low",
      status: "resolved"
    },
    {
      id: "149",
      user: "Graham Bell",
      topic: "Support Topic",
      assignee: {
        id: "1",
        name: "Admin User"
      },
      lastUpdated: "2021-04-02",
      priority: "low",
      status: "rejected"
    },
    {
      id: "150",
      user: "Sandy Bullhorn",
      topic: "Support Topic",
      assignee: {
        id: "1",
        name: "Admin User"
      },
      lastUpdated: "2021-04-02",
      priority: "high",
      status: "unassigned"
    },
  ]

  const [allTickets, setAllTickets] = useState(tickets)
  const [users, setUsers] = useState([])

  const [ processing, setProcessing ] = useState(false)
  const [ error, setError ] = useState('')
  const [ cookies ] = useCookies(['user'])

  const [ pagination, setPagination ] = useState({ 
      page: 1, 
      perPage: 25, 
      totalRecords: 0,
      nextPage: '',
      lastPage: '',
      previousPage: '',
      firstPage: ''
    })

    useEffect(() => {
      const getUsers = async () => {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
      }
      setProcessing(true)
      try { 
          let response = null 

          response = await axios.get(`/api/v1/admin/users/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc`, { headers })            
          setUsers(response.data.data)

          setProcessing(false)
      } catch (error) {
          console.error(error)
          if(error.response.data.developer_message.exception === "AuthenticationException") {
            window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
          } else {
              setError(error.response.data.developer_message.errors)
              setProcessing(false)
          }
      }
    }

    const getSupportTickets = async () => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
      }
      setProcessing(true)
      try { 
          let response = null 

          if(filter && filter !== '' ) {
              response = await axios.get(`/api/v1/admin/orders/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc&filter[${filter.key}]=${filter.value}`, { headers })            
          } else {
              response = await axios.get(`/api/v1/admin/orders/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc`, { headers })            
          }
          // console.log(response)
          let apiOrders = response.data.data
          setAllTickets(apiOrders)

          setPagination({
              page: pagination.page,
              perPage: pagination.perPage,
              totalRecords: response.data.total_records,
              nextPage: response.data.next,
              lastPage: response.data.last
          })

          setProcessing(false)
      } catch (error) {
        console.error(error.response.data)
        if(error.response.data.developer_message.exception === "AuthenticationException") {
            window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
        } else {
            setError(error.response.data.msg)
            setProcessing(false)
        }
      }
    }
  
    return () => {}
    
  }, [])

  const userName = (userId) => {
    users.find((user) => {
      return user.id === userId
    })
  }

  const [ filter, setFilter ] = useState(null)

  const tabs = [
    {
        tabId: "all",
        tabLabel: "All",
        all: 2375
    },
    {
        tabId: "pending",
        tabLabel: "Pending",
        count: 95
    },
    {
        tabId: "processing",
        tabLabel: "Processing",
        count: 22
    },
    {
        tabId: "resolved",
        tabLabel: "Resolved",
        count: 18
    },
    {
        tabId: "rejected",
        tabLabel: "Rejected",
        count: 208
    },
    {
        tabId: "unassigned",
        tabLabel: "Unassigned",
        count: 987
    }
  ]

  const tableOptions = {
    selectable: true
  }

    const columnWidths = {
        'Ticket #': 'w-full lg:w-1/12',
        user: 'w-full lg:w-2/12',
        topic: 'w-full lg:w-2/12',
        assignedTo: 'w-full lg:w-2/12',
        lastUpdated: 'w-full lg:w-2/12',
        priority: 'w-full lg:w-2/12',
        status: 'w-full lg:w-1/12',
        actions: 'w-full lg:w-1/12'
    }

    const columnDataStyles = {}

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key
            if(key === 'actions') {
                sortable=false
            }
            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true

            if(index > 10) {
                fieldSelected = false
            }
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });
        return {headers, fields}
    }

    const ActionsButton = () => {
        return (
            <button className="">
                <img src={HorizontalMenuIcon} alt="" className="w-4" />
            </button> 
        )
    }

    const Priority = ({priority}) => {
        return (
            <div className='w-full flex items-center gap-x-1'>
                <p className={`capitalize font-medium ${priority === 'high' && 'text-red-600'} ${priority === 'medium' && 'text-yellow-600'} ${priority === 'low' && 'text-ink-navy'}`}>{priority}</p>
                <ArrowIcon className={`w-4 h-4 transform 
                    ${priority === 'high' && 'text-red-600 -rotate-90'} 
                    ${priority === 'medium' && 'text-yellow-600 -rotate-45'} 
                    ${priority === 'low' && 'text-ink-navy rotate-90'}`} 
                />
            </div>
        )
    }

    const Assignee = ({userObject}) => {
        return (
            <div className='select-wrapper rounded border-gray-300 focus:border-gray-900 text-xs px-3 py-2 w-32 border focus:outline-none transition duration-200'>
                <select className='outline-none border-none w-full'>
                    <option value={userObject.id}>{userObject.name}</option>
                </select>
            </div>
        )

    }

    const TicketLink = ({ticketId}) => {
        return (
            <Link to={`ticket/${ticketId}`} className='font-medium text-ink-navy'>{ticketId}</Link>
        )
    }

    const TicketUser = ({id, customerName, customerImage}) => {
        return (
            <Link to={`/admin/customers/all-customers/${id}`}>
                {/* <img src={customerImage} alt="" className="rounded-full w-5 h-5 inline-block mr-2" /> */}
                {customerName}
            </Link>
        )
    }

    const TicketStatus = ({status}) => {
        return (
        <div className='capitalize'>
            {status === 'unassigned' && <span className="w-2 h-2 bg-gray-300 rounded-full mr-2 inline-block" />}
            {status === 'pending' && <span className="w-2 h-2 bg-gray-500 rounded-full mr-2 inline-block" />}
            {status === 'resolved' && <span className="w-2 h-2 bg-ink-navy rounded-full mr-2 inline-block" />}
            {status === 'rejected' && <span className="w-2 h-2 bg-red-600 rounded-full mr-2 inline-block" />}
            {status === 'processing' && <span className="w-2 h-2 bg-green-500 rounded-full mr-2 inline-block" />}
            {status}
        </div>
        )
    }
    const cleanUpData = (dataArray) => {
        let finalResult = [] 
        dataArray.forEach((item, itemIndex) => {

            const itemObject = {
                'Ticket #': <TicketLink ticketId={item.id} />,
                user: <TicketUser 
                    id={item.user} 
                    customerName={item.user} 
                />,
                topic: item.topic,
                assignedTo: <Assignee userObject={item.assignee} />,
                lastUpdated: item.lastUpdated,
                priority: <Priority priority={item.priority} />,
                status: <TicketStatus status={item.status} />,
                actions: <ActionsButton />
            }
            
            finalResult.push(itemObject)
        })
        return finalResult
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

 const filters = [
    {
        optionName: "Brands",
        options: [
            {name: "RFR", selected: false},
            {name: "British League", selected: false},
            {name: "White", selected: false},
            {name: "Cube", selected: false},
        ]
    }, 
    {
        optionName: "Years",
        options: yesteryears(4),
    },
    {
        optionName: "Stock",
        options: [
            {name: "Available", selected: false},
            {name: "Out of Stock", selected: false},
        ],
    },
    {
        optionName: "Status",
        options: [
            {name: "Live", selected: false},
            {name: "Inactive", selected: false},
        ],
    },
    {
        optionName: "Filter options",
        options: [
            {name: "Option 1", selected: false},
            {name: "Option 2", selected: false},
            {name: "Option 3", selected: false},
        ]
    }
  ]

  const [filtersActive, setFiltersActive] = useState(false)
  const toggleFilters = () => {
      setFiltersActive(!filtersActive)
  }

  const [selectedFiltersCount, setSelectedFiltersCount] = useState(0)

  const [allFilters, setAllFilters] = useState(filters)
  const toggleFilterSelection = (optionIndex, filterIndex) => {
      let newFilters = JSON.parse(JSON.stringify(allFilters))
      newFilters[optionIndex].options[filterIndex].selected = !newFilters[optionIndex].options[filterIndex].selected
      setAllFilters(newFilters)
      setSelectedFiltersCount(countSelectedFilters(newFilters))
  }

  const countSelectedFilters = (filters) => {
      let count = 0
      filters.forEach((option) => {
          option.options.forEach((filter) => {
              if(filter.selected) {
                  count++
              }
          })
      })
      return count
  }

  const clearFilters = () => {
      let newFilters = JSON.parse(JSON.stringify(allFilters))
      newFilters.forEach((option) => {
          option.options.forEach((filter) => {
              filter.selected = false
          })
      })
      setAllFilters(newFilters)
      setSelectedFiltersCount(countSelectedFilters(newFilters))
  }

  const [activeTab, setActiveTab] = useState(tabs[0].tabId)
  let changeActiveTab = (tab) => {
      console.log(tab)
      if (tab.tabId === 'all') {
          setFilter(null)
      } else {
          setFilter({
              key: 'status',
              value: tab.tabLabel
          })
      }
      setActiveTab(tab.tabId)
  }

  const [selectedTicketsCount, setSelectedTicketsCount] = useState(0)

  const getSelectionCount = (count) => {
      return setSelectedTicketsCount(count)
  }

  const [selected, setSelected] = useState(tabs[0].tabId)


  return (
    <div className='p-12'>
    {/* Tabs */}
    <div className="hidden w-full lg:flex flex-row gap-x-2 border-b border-gray-300">
        {/* {tabs.map((tab, tabIndex) =>( */}
        {tabs.slice(0, 6).map((tab, tabIndex) =>(
            <button 
                key={tabIndex} 
                className={`text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-3 transition duration-200  ${activeTab === tab.tabId ? "bg-white border-b-3 border-b-red-300" : "bg-gray-100"}`}
                onClick={()=>{changeActiveTab(tab)}}>
                {tab.tabLabel} 
                    {!processing && activeTab === tab.tabId && <span className="rounded-full px-2 py-1 bg-ink-navy text-white ml-2">
                        {/* {tab.tabLabel === 'All' ? allOrders.length :
                            allOrders.filter((order) => { return order.status === tab.tabLabel }).length} */}
                        {pagination.totalRecords}
                    </span>}
            </button>
        ))}
        {/* <DropdownMenu 
            buttonIcon={HorizontalMenuIcon} 
            buttonType='icon' 
            buttonText="" 
            menuItems={getTabsDropdownItems(6)} 
            // itemAction={changeActiveTab}
            iconClasses="w-5" 
            buttonClasses="text-xs rounded-tr-lg rounded-tl-lg border border-b-0 border-gray-200 text-ink-navy font-medium font-sofia-pro inline-block px-5 py-5 transition duration-200 bg-gray-100" /> */}
    </div>

    <div className="w-full lg:hidden flex-row items-center justify-between mt-5">
        <label className="text-gray-400 text-xs mb-2 block mt-2">Select Category</label>
        <div className="">
            <Listbox value={selected} onChange={setSelected}>
                <div className="relative mt-1">
                <Listbox.Button className={`relative w-full text-left text-xs rounded border border-gray-300 text-ink-navy font-medium font-sofia-pro inline-block px-3 py-2 transition duration-200`}>
                    <span className="block truncate text-sm lg:text-xs">{selected}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                        className="w-4 h-4 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {tabs.map((option, optionIndex) => (
                        <Listbox.Option
                            key={optionIndex}
                            className={({ active }) =>
                                `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                    cursor-default select-none relative py-2 pl-10 pr-4`
                            }
                            value={option.tabLabel}
                            
                            >
                            {({ selected, active }) => (
                                <>
                                <span
                                    className={`${
                                    selected ? 'font-medium' : 'font-normal'
                                    } block truncate text-sm`}
                                    onClick={()=>{changeActiveTab(option)}}
                                >
                                    {option.tabLabel}
                                </span>
                                {selected ? (
                                    <span
                                    className={`${
                                        active ? 'text-amber-600' : 'text-amber-600'
                                    }
                                            absolute inset-y-0 left-0 flex items-center pl-3`}
                                    >
                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                    </span>
                                ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Transition>
                </div>
            </Listbox>
        </div>
    </div>

    {/* Tab area */}
    <div className="w-full">
        <div className="w-full block lg:flex flex-row justify-between">
            <div className="flex flex-row justify-between items-center w-full mb-4 lg:mb-0 mt-3 mb:mt-0">
                <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-full lg:w-10/12 mr-1">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm mt-1">
                            <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                        </span>
                    </div>
                    <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search support tickets" />
                </div>
                <div className="relative">
                    <div className="flex flex-row gap-x-2">
                        <button 
                            className={`rounded py-3 px-4 text-xs flex flex-row items-center border ${filtersActive ? 'text-gray-800 border-gray-800' : 'text-gray-400 border-gray-200'}`} 
                            onClick={()=>{toggleFilters()}} 
                        >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> <span className='hidden md:inline'>Filters</span> {selectedFiltersCount > 0 && <span className={`text-xs text-white rounded px-1 ml-1 mr-3 ${filtersActive ? 'bg-ink-navy' : 'bg-gray-400'}`}>{selectedFiltersCount}</span>}
                        </button>

                    </div>
                    {/* Filters popover */}
                    {filtersActive && 
                        <div className="absolute shadow-lg bg-white top-16 border border-gray-100 z-10" style={{width: '800px', left: '-200px',}}>
                            <div className="w-full flex flex-row items-center justify-between border-b p-4">
                                <p className="font-medium text-ink-navy text-xs">Filters</p>
                                <button className="font-sofia-pro underline text-blue-700 text-xs" onClick={()=>{clearFilters()}}>Clear filters</button>
                            </div>
                            <div className="w-full flex flex-row justify-between border-b p-8">
                                {allFilters.map((filterOption, optionIndex) => (
                                    <div key={optionIndex}>
                                        <p className="font-medium text-xs mb-3 text-ink-navy">{filterOption.optionName}</p>
                                        {filterOption.optionName === 'Brands' &&
                                            <input type="text" placeholder="Search" className="py-2 px-3 w-full border rounded border-gray-300 text-xs" />
                                        }
                                        {filterOption.options.map((filter, filterIndex) => (
                                            <div className="w-2/10 mt-1" key={filterIndex}>
                                                <input type="checkbox" onChange={()=>toggleFilterSelection(optionIndex, filterIndex)} checked={filter.selected} className="mr-2 mt-3" />
                                                <p className="text-xs inline-block mt-3">{filter.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    
                </div>
            </div>
            {/* </div> */}
            <div className="flex flex-row-reverse gap-x-2 w-full items-center">
                 <Link to="create-ticket" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs mt-3 text-white flex flex-row items-center border w-full lg:w-max">
                    <PlusIcon className={`w-4 h-4`} /> Create Ticket
                </Link>
                 <button className="rounded border-gray-800 py-3 px-4 text-xs mt-3 text-gray-800 flex flex-row items-center border w-full lg:w-max">
                    <img src={ExportIcon} alt="search" className="w-4 mr-2 hidden xl:inline" /> Export
                </button>
                {selectedTicketsCount > 1 && 
                    <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                }
            </div>
        </div>

        <div>
            {!processing && error === '' ?
                <DataTable
                    tableHeaders={tableHeadersFields(cleanUpData(allTickets)[0]).headers} 
                    tableData={cleanUpData(allTickets)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpData(allTickets)[0]).fields}
                    onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                    pagination={pagination}
                    paginate={setPagination}
                />
              :
                <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <Spinner />
                    <p className='text-sm text-gray-500 mt-5'>Fetching support tickets...</p>
                </div>
            }
        </div>
    </div>
</div>
  )
}

export default AllSupportTickets