import React, { Fragment, useState } from 'react'
import { sidebarState } from '../../utils'
// import MenuIcon from '../../assets/images/icons/menu.svg'
import AdminHeader from '../partials/AdminHeader'
import Sidebar from '../partials/Sidebar'
import StaticSidebar from '../partials/StaticSidebar'

const Layout = ({children, pageTitle, sidebarLinks, systemStats, noPadding}) => {
    const [sidebarActive, setSidebarActive] = useState(sidebarState())
    const toggleSidebar = () => {
        localStorage.setItem("sidebarState", JSON.stringify(sidebarActive ? 'hidden' : 'active'));
        setSidebarActive(!sidebarActive)
    }
    // const childrenWithProps = React.cloneElement(children, { sidebaractive: sidebarActive.toString() })
    // console.log(childrenWithProps.props.sidebaractive)

    // const [mobileMenuActive, setMobileMenuActive] = useState(false)
    // const toggleMobileMenu = () => {
    //     setMobileMenuActive(!mobileMenuActive)
    // }
    
    return (
        <Fragment>

            {/* <button className='p-3 bg-ink-navy text-white rounded-full fixed top-16 left-3 z-50 md:hidden'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button> */}

            <div className="flex flex-row">
                <div className="h-screen w-12 bg-ink-navy fixed z-20 hidden xl:inline-block">
                    <StaticSidebar/>
                </div>
                {sidebarActive && 
                <Fragment>
                    <div className="h-screen bg-grey w-1/5 ml-12 border-r border-gray-300 fixed z-10 hidden xl:inline-block">
                        <Sidebar 
                            toggleFunction={toggleSidebar} 
                            links={sidebarLinks} 
                            systemStats={systemStats} 
                            pageTitle={pageTitle}
                        />
                    </div>
                    <div className="w-1/5 z-0 mr-12 hidden xl:inline-block" />
                </Fragment>}
                <div className={`flex flex-col clear-left min-h-screen z-10 w-full ${!noPadding ? 'md:px-2' : ''} ${sidebarActive ? 'xl:w-4/5' : 'xl:w-full'}`}>
                    <AdminHeader 
                        pageTitle={pageTitle} 
                        sidebarActive={sidebarActive}  
                        sidebarToggleFunction={toggleSidebar} />
                        
                    {noPadding ? 
                        <div className='ml-1'>
                            <main>{children}</main>
                        </div>
                         : 
                        <div className={`py-10 ${sidebarActive ? 'px-10' : 'px-32'}`}>
                            <main>{children}</main>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default Layout
