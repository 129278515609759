import React from 'react'

const ErrorMessage = ({message, dismissHandler}) => {

    setTimeout(() => {
        dismissHandler()
    }, 10000);

    return (
        <div className='mt-5 text-sm font-sofia-pro block rounded px-3 py-2 bg-red-600 text-white transition duration-200 flex justify-between items-center shadow-lg'>   
            {message}
            <button onClick={dismissHandler} className='text-white hover:text-opacity-60 p-2 transition duration-200'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    )
}

export default ErrorMessage
