import React, {useEffect, useState} from 'react'
import Spinner from '../../elements/icons/Spinner'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { useHistory, useLocation } from 'react-router-dom';
import { baseUrl } from '../../../utils';
import RatingStars from '../../elements/RatingStars';
import Wysiwyg from '../../elements/Wysiwyg';

const ProductReview = ({reviewId, productDetails}) => {
    // const review = {
    //     customer: {
    //         name: "Christopher Kightly", 
    //         email: "chriskightly92@gmail.com"
    //     },
    //     review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //     productImage: "https://www.met-helmets.com/app/uploads/2020/02/allroad-cycling-helmet-GR1-1.jpg",
    //     peoductName:"",
    //     status: "approved",
    //     productLink: "https://cycleconcept.co.uk/product/5019/preview",

    // }

    const [ cookies ] = useCookies(['user'])
    const history = useHistory();
    const location = useLocation()

    const [review, setReview] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [fetching, setFetching] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
      const getReviewDetails = async () =>{
        if(!reviewId || reviewId === null) {
            return
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        setFetching(true)
        try {
            const response = await axios.get(`/api/v1/products/review/${reviewId}`, { headers })       
            setReview(response.data.data[0])
            getCustomerDetails(response.data.data[0].customer_id)
        } catch (error) {
            if(error.response.data.developer_message.exception === "AuthenticationException") {
                window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
            } else {
                setError(error.response.data.developer_message.errors)
                setFetching(false)
            }
        }
      }

      const getCustomerDetails = async (customerId) =>{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        try {
            const response = await axios.get(`/api/v1/admin/customer/${customerId}`, { headers })       
            setCustomer(response.data.data[0])
            setFetching(false)
        } catch (error) {
            setError(error.response.data.developer_message.errors)
            setFetching(false)
        }
      }

      getReviewDetails()
    
      return () => {
        
      }
    }, [cookies.accessToken, cookies.tokenType, history, location.pathname, reviewId])

    const getProductDescriptions = (descriptionsArray) => {
        const colours = []
        const images = []
        const sizes = []
        let sampleSku = '' 
        let stock = {
            total: 0, 
            locations: []
        }
        
        if (!descriptionsArray || descriptionsArray.length === 0 ) {
            return {colours, images, sizes, sampleSku, stock}
        }

        descriptionsArray.forEach((desc) => {
            colours.push(desc.colour)
            if(desc.assets && desc.assets.length > 0) {
                desc.assets.forEach((asset) => {
                    if(asset.type === 'image') {
                        images.push(`https://obj1-dev.s3.eu-west-2.amazonaws.com${asset.path}`)
                    }
                })
            }
            if(desc.sizes && desc.sizes.length > 0) {
                desc.sizes.forEach((size, sizeIndex) => {
                    if(sizeIndex === 0 && size.sku ) {
                        sampleSku = size.sku
                    }
                    sizes.push(size.size)
                    if(size.stock && size.stock.length > 0) {
                        size.stock.forEach((stockItem) => {
                            stock.total += stockItem.stock_qty
                            stock.locations.push({
                                location: stockItem.stock_location_name,
                                quantity: stockItem.stock_qty
                            })
                        })
                    }
                })
            }
        })
        return {colours, images, sizes, sampleSku, stock}
    }
    
    return (
        fetching ? 

            <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <Spinner />
                    <p className='text-sm text-gray-500 mt-5'>Fetching review details...</p>
                </div>
            :

            !error || error === null ? 

            <>
                <div className="w-full p-12">
                    <div className="flex flex-row gap-x-6 mt-6 relative pb-8">
                        <div className="w-1/2">
                            <label className="text-xs text-ink-navy font-medium mb-5 block">Product</label>
                            <div className="w-full h-full rounded border-gray-300 border p-6">
                                <img className="w-full" alt="" src={getProductDescriptions(productDetails.colours).images[0]} />
                            </div>
                        </div>
                        <div className="w-1/2">
                            <div className="w-full py-3">
                                <label className="text-xs text-ink-navy font-medium mb-3 block">Name</label>
                                <input type="text" readOnly className="px-4 py-2 w-full border rounded border-gray-300 text-xs font-sofia-pro text-gray-700" value={`${customer.user.first_name} ${customer.user.last_name}`} />
                            </div>
                            <div className="w-full py-3">
                                <label className="text-xs text-ink-navy font-medium mb-3 block">Email</label>
                                <input readOnly type="email" className="px-4 py-2 w-full border rounded border-gray-300 text-xs font-sofia-pro text-gray-700" value={customer.user.email} />
                            </div>
                            <div className="w-full py-3">
                                <label className="text-xs text-ink-navy font-medium mb-3 block">Status</label>
                                {/* <input type="text" className="capitalize px-4 py-2 w-full border rounded border-gray-300 text-xs font-sofia-pro text-gray-700" value={review.status} /> */}

                                <div className='select-wrapper w-full border-gray-300 border rounded px-4 py-2 text-xs my-3 focus:shadow-md focus:border-gray-200'>
                                    <select 
                                        className='w-full focus:outline-none text-gray-700'
                                        value={review.status}
                                        onChange={(e) => setReview({ ...review, ...{status: e.target.value}})}    
                                    >
                                        <option value="Approved">Approved</option>
                                        <option value="Pending">Pending</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-full py-3">
                                <label className="text-xs text-ink-navy font-medium mb-3 block">Overall Rating</label>
                                <RatingStars rating={review.rating} />
                            </div>
                            <div className="w-full py-3">
                                <label className="text-xs text-ink-navy font-medium mb-3 block">Review Meta Ratings</label>

                                <div className='w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-5'>
                                    {review.review_meta_ratings.map((rating, ratingIndex)=>(<div key={ratingIndex} className='w-full'>
                                        <label className="text-xs text-gray-400 font-medium mb-3 block">Meta Rating {rating.id}</label>
                                        <RatingStars rating={rating.rating} />
                                    </div>))}
                                </div>
                                {/* <RatingStars rating={review.rating} /> */}
                            </div>

                            <div className="w-full py-3">
                                <label className="text-xs text-ink-navy font-medium mb-3 block">Product link</label>
                                <input readOnly type="email" className="capitalize px-4 py-2 w-full border rounded border-gray-300 text-xs font-sofia-pro text-gray-700" value={review.productLink} />
                            </div>
                        </div>
                    </div>

                    <div className='w-full mt-4'>
                        <label className="text-xs text-ink-navy font-medium mb-3 block">Review</label>
                        <textarea value={review.review} readOnly className="resize-none px-4 py-2 h-20 w-full border rounded border-gray-300 text-xs font-sofia-pro text-gray-700" />
                    </div>

                    <div className='w-full mt-4'>
                        {/* <label className="text-xs text-ink-navy font-medium mb-3 block">Review</label>
                        <textarea value={review.review} readOnly className="resize-none px-4 py-2 h-20 w-full border rounded border-gray-300 text-xs font-sofia-pro text-gray-700" /> */}
                        <Wysiwyg fieldTitle="Content" initialValue={''} updateValue={(value)=>{}} />
                    </div>

                    <div className="w-full flex flex-row-reverse gap-x-3 mt-12">
                        <button className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            Save
                        </button>
                        <button className="rounded border-ink-navy py-3 px-4 text-xs text-ink-navy flex flex-row items-center border">
                            Cancel
                        </button>
                    </div>
                </div>
            </>

            :

            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                <p className='text-sm text-gray-600 mt-1'>{error}</p>
            </div>
    )
}

export default ProductReview
