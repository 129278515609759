import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
import ExportIcon from '../../../../assets/images/icons/export.svg'
import BulkActionsIcon from '../../../../assets/images/icons/bulk-items.svg'
import { convertCamelCase } from '../../../../utils'
import DataTable from '../../../../components/elements/DataTable'
import DropdownMenu from '../../../../components/elements/DropdownMenu'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../../utils';
import Spinner from '../../../../components/elements/icons/Spinner'
import { useHistory, useLocation } from 'react-router-dom';

const SizeGuides = () => {
    // const sizeGuides = [
    //     {
    //         title: "",
    //         brand: "",
    //         type: "",
    //         category: "",
    //         publishDate: "",
    //         lastUpdate: "",
    //         user: ""
    //     }
    // ]

    // const tableOptions = {
    //     selectable: true
    // }

    const columnWidths = {
        id: "w-1/12",
        content: "w-11/12",
    }

    const history = useHistory();
    const location = useLocation()

    const [sizeGuides, setSizeGuides] = useState([])
    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])

    const [ pagination, setPagination ] = useState({ 
        page: 1, 
        perPage: 25, 
        totalRecords: 0,
        nextPage: '',
        lastPage: '',
        previousPage: '',
        firstPage: ''
    })

    useEffect(() => {
        const fetchSizeGuides = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try { 
                let response = null 

                response = await axios.get(`/api/v1/admin/size_guides/?page[offset]=${pagination.page}&page[limit]=${pagination.perPage}&page[order]=desc`, { headers })            
                console.log(response)                
                setSizeGuides(response.data.data)

                setPagination({
                    page: pagination.page,
                    perPage: pagination.perPage,
                    totalRecords: response.data.total_records,
                    nextPage: response.data.next,
                    lastPage: response.data.last
                })

                setProcessing(false)
            } catch (error) {
                console.error(error)
                if(error.response.data.developer_message.exception === "AuthenticationException") {
                    window.location.href=`/?${new URLSearchParams({returnUrl: location.pathname, expiredToken: true}).toString()}`
                } else {
                    setError(error.response.data.developer_message.errors)
                    setProcessing(false)
                }
            }
        }

        fetchSizeGuides()
    
    }, [cookies.accessToken, cookies.tokenType, history, location.pathname, pagination.page, pagination.perPage])
    

    const columnDataStyles = {}

    const [selectionCount, setSelectionCount] = useState(0)

    const getSelectionCount = (count) => {
        return setSelectionCount(count)
    }

    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) { return }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = false
            let column = key

            if(key !== 'selected') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const bulkActions = [
        {
            label: "Change Status",
            icon: null,
            link: "/",
            action: null
        },
        {
            label: "delete",
            icon: null,
            link: "/",
            action: null
        },
    ]

    const [filtersActive, setFiltersActive] = useState(false)
    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    function createMarkup(text) { return {__html: text}; };

    const GuideBody = ({content}) => {
        return (
            <div dangerouslySetInnerHTML={createMarkup(content)} />
        )
    }

    const cleanUpData = (dataArray) => {
        if(!dataArray || dataArray.length === 0){ return }

        let finalResult = [] 
        dataArray.forEach((object, objectIndex) => {
            let requestObject = {}
            requestObject = {
                id: <Link to={`/admin/orders/size-guides/detail/${object.id}`}>{object.id}</Link>,
                content: <GuideBody content={object.content} />
            }

            finalResult.push(requestObject)
        })
        return finalResult
    }

    return ( 
    <>
        <div className="w-full block lg:flex flex-row justify-between pt-5">
            <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm mt-1">
                            <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                        </span>
                    </div>
                    <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search size guides" />
                </div>
                <button className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" onClick={()=>{toggleFilters()}} >
                    <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                </button>
            </div>
            <div className="flex flex-row gap-x-2">
                {selectionCount > 1 && 
                    <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                }
                <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                    <img src={ExportIcon} alt="search" className="w-4 mr-2" /> Export
                </button>
                <Link to="size-guides/new-size-guide" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg> 
                    Create Size Guide
                </Link>
            </div>
        </div>
        {!processing && error === '' ?

        <DataTable
            tableHeaders={tableHeadersFields(cleanUpData(sizeGuides)[0]).headers} 
            tableData={cleanUpData(sizeGuides )} 
            columnWidths={columnWidths}
            columnDataStyles={columnDataStyles}
            allFields={tableHeadersFields(cleanUpData(sizeGuides)[0]).fields}
            onSelectItems={getSelectionCount}
            tableOptions={{selectable:false}} 
            pagination={pagination}
            paginate={setPagination}
            tableName={`sizeguides`}
            sortingBy={''}
            updateSortBy={(field)=>{}}
            sortOrder={'desc'}
            updateSortOrder={(order)=>{}}
            bordered={true}

        />
         :
        <div className='w-full flex flex-col items-center justify-center mt-10'>
            <Spinner />
            <p className='text-sm text-gray-500 mt-5'>Fetching size guides...</p>
        </div>
        }
    </>
    )
}

export default SizeGuides
