import React, { Fragment } from 'react'

const NavPreview = ({items, activeFlyout, changeActiveFlyout, previewStyles, removeFlyout}) => {
    return (
        <>
        {items && items.length > 0 ? <div className={`border-dashed border border-gray-200 min-h-full p-4`} style={previewStyles}>
                <div className="flex flex-row border px-5 pt-3 items-center bg-white border-gray-300 gap-x-12">
                    <p className="text-xs pb-3 border-b-2 border-gray-700">{items[0].name}</p>
                    <span className="h-2 w-24 -mt-4 bg-gray-200"></span>
                    {/* <span className="h-3 w-24 -mt-4 bg-gray-200"></span> */}
                </div>
                <div className="flex flex-row">
                    <div className="h-screen bg-white border border-gray-100 shadow-sm">
                        {items[0].children.map((item, itemIndex) => (
                            <div key={itemIndex} className="w-44 relative" onMouseEnter={()=>{changeActiveFlyout(item.id)}} onMouseLeave={()=>{removeFlyout()}}>
                                <button className="pl-4 pr-10 py-6 w-full text-xs text-left hover:bg-blue-100 transition duration-200">{item.name}</button>
                                {item.children && item.children.length > 0 &&
                                <Fragment>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 absolute top-6 right-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <ul className={`absolute top-0 left-full shadow-sm ${activeFlyout === item.id ? 'block' : 'hidden'}`}>
                                        {item.children.map((child, childKey)=>(
                                            <Fragment key={childKey}>
                                                <li className="w-44 text-xs text-left px-8 py-4 block hover:bg-blue-100 bg-white mb-0 mt-0">{child.name}</li>
                                                {child.children && child.children.length > 0 && <Fragment>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 absolute top-6 right-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                                    </svg>
                                                    <ul className={`absolute top-0 left-full shadow-sm ${activeFlyout === child.id ? 'block' : 'hidden'}`}>
                                                        {child.children.map((grandChild, grandChildIndex)=>(
                                                            <li key={grandChildIndex} className="w-44 text-xs text-left px-8 py-4 block hover:bg-blue-100 bg-white mb-0 mt-0">{grandChild.name}</li>
                                                        ))}
                                                    </ul>
                                                </Fragment>}
                                            </Fragment>
                                        ))}
                                    </ul>
                                </Fragment>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div> 
            
            :

            <div className='w-full rounded-sm border border-ink-navy bg-gray-200 p-4 mt-6'>
                <p className='text-xs text-ink-navy'>No items added for this menu yet</p>
            </div>
            
            }
        </>
    )
}

export default NavPreview