import React from 'react'
import Layout from '../../components/layout/Layout'
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    // Link,
    // useParams,
    useRouteMatch,
    Redirect,
  } from "react-router-dom";
import AllProducts from './products/AllProducts';
import AdminErrorPage from './AdminErrorPage';
import EditProduct from './products/EditProduct';
import SupplierData from './products/SupplierData';
import ProductGroups from './products/ProductGroups';
import ProductGroup from './products/ProductGroup';
import SupplierStockFeeds from './products/SupplierStockFeeds';
import UncategorizedProducts from './products/UncategorizedProducts';
import NewProduct from './products/NewProduct';
import Promotions from './products/promotions/Promotions';
import NewPromotion from './products/promotions/NewPromotion';
import Promotion from './products/promotions/Promotion';
import NewProductGroup from './products/NewProductGroup';
// import SingleReview from '../../components/partials/add-edit-product/ProductReview';
import SizeGuides from './orders/size-guides/sizeGuides';
import NewSizeGuide from './orders/size-guides/NewSizeGuide';

const Products = () => {
    let { 
        path, 
        // url 
    } = useRouteMatch();

    const sidebarLinks = [
        {
            label: "All products",
            location: "/admin/products/all-products",
            subLinks: []
        },
        {
            label: "Groups",
            location: "/admin/products/groups",
            sublinks: []
        },
        {
            label: "Uncategorized products",
            location: "/admin/products/uncategorized",
            sublinks: []
        },
        {
            label: "Suppler Data (SIM)",
            location: "/admin/products/supplier-data",
            sublinks: []
        },
        {
            label: "Suppler Stock Feeds",
            location: "/admin/products/supplier-stock-feeds",
            sublinks: []
        },
        {
            label: "Promotions",
            location: "/admin/products/promotions",
            sublinks: []
        },
        {
            label: "Size Guides",
            location: "/admin/products/size-guides",
            sublinks: []
        },
    ]
    return (
        <Layout pageTitle="Products" sidebarLinks={sidebarLinks}>
            {/* {path} */}
            <Switch>
                <Route
                    path={path}
                    exact
                    render={() => {
                        return (
                        <Redirect to="/admin/products/all-products" />
                        )
                    }}
                    // render={() => {
                    //     return (
                    //     this.state.isUserAuthenticated ?
                    //     <Redirect to="/home" /> :
                    //     <Redirect to="/test1" /> 
                    //     )
                    // }}
                />

                <Route path={`${path}/all-products`} exact>
                    <AllProducts />
                </Route>
                <Route path={`${path}/new-product`} exact>
                    <NewProduct />
                </Route>
                <Route path={`${path}/product/:id`} exact>
                    <EditProduct />
                </Route>
                <Route path={`${path}/supplier-data`} exact>
                    <SupplierData />
                </Route>
                <Route path={`${path}/groups`} exact>
                    <ProductGroups />
                </Route>
                <Route path={`${path}/new-product-group`} exact>
                    <NewProductGroup />
                </Route>
                {/* <Route path={`${path}/product/:id/reviews/:reviewId`} exact>
                    <SingleReview />
                </Route> */}
                <Route path={`${path}/uncategorized`} exact>
                    <UncategorizedProducts />
                </Route>
                <Route path={`${path}/groups/:id`} exact>
                    <ProductGroup />
                </Route>
                <Route path={`${path}/supplier-stock-feeds`} exact>
                    <SupplierStockFeeds />
                </Route>
                <Route path={`${path}/promotions`} exact>
                    <Promotions />
                </Route>
                <Route path={`${path}/size-guides/new-size-guide`} exact>
                    <NewSizeGuide />
                </Route>
                <Route path={`${path}/size-guides`} exact>
                    <SizeGuides />
                </Route>
                <Route path={`${path}/promotions/single/:id`} exact>
                    <Promotion />
                </Route>
                <Route path={`${path}/promotions/new-promotion`} exact>
                    <NewPromotion />
                </Route>
                <Route component={AdminErrorPage} />

            </Switch>
        </Layout>
    )
}

export default Products
