import React from 'react'
import KlarnaLogo  from '../../assets/images/icons/klarna-logo.svg'

const OrderPaymentDetails = ({order}) => {
    return (
            <div className="w-full p-6 bg-gray-100 rounded-md mt-5">
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <h3 className="w-full pb-3 mb-3 border-b border-gray-200 text-sm text-ink-navy">Payment</h3>
                    {/* <img alt="" src={KlarnaLogo} className="-mt-5" /> */}
                    <p className='text-sm capitalize text-ink-navy font-bold text-3xl -mt-5'>{order.payment_provider}</p>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500 text-ink-navy font-medium capitalize">{order.payment_provider}</p>
                    </div>
                    {/* <div className="w-1/2">
                        <p className="text-sm text-gray-500">8ZCW6GBG</p>
                    </div> */}
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Reference:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        {/* <p className="text-sm text-gray-500">8ZCW6GBG</p> */}
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Order Id:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        <p className="text-sm text-gray-500">{order.order_uuid}</p>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Payment Type:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        {/* <p className="text-sm text-gray-500">Pay later</p> */}
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Payment status:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        <p className="text-sm text-red-800">{order.status}</p>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Fraud status:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        {/* <p className="text-sm text-green-800">ACCEPTED</p> */}
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Total amount:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        <p className="text-sm text-ink-navy font-bold">{order.currency_code} {parseInt(order.total).toLocaleString()}</p>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between mb-2">
                    <div className="w-1/2">
                        <p className="text-sm text-gray-500">Remaining authorized amount:</p>
                    </div>
                    <div className="w-1/2 text-right">
                        {/* <p className="text-sm text-ink-navy">92.99 GBP</p> */}
                    </div>
                </div>
            </div>
    )
}

export default OrderPaymentDetails
