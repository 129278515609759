import React, { Fragment, useEffect, useState } from 'react'
import axios from "axios"
import { baseUrl, convertCamelCase, slugify } from '../../../../utils';
// import ErrorMessage from '../../../../components/elements/ErrorMessage'
import { useCookies } from 'react-cookie';
// import ChevronIcon from '../../../components/elements/icons/ChevronIcon';
import Spinner from '../../../../components/elements/icons/Spinner';
import ErrorMessage from '../../../../components/elements/ErrorMessage';
import SuccessMessage from '../../../../components/elements/SuccessMessage';
import { Link,useParams } from "react-router-dom";
import { Switch } from '@headlessui/react';
import EditIcon  from '../../../../assets/images/icons/edit.svg'
import Increase  from '../../../../assets/images/icons/arrow-increase.svg'
import Reduce  from '../../../../assets/images/icons/arrow-reduce.svg'
import DataTable from '../../../../components/elements/DataTable';

const Promotion = () => {
    // const history = useHistory();
    const { id } = useParams()

    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')  
    const [ cookies ] = useCookies(['user']);

    // const [productDetails, setProductDetails] = useState({})

    const [name, setName] = useState('')
    const [group, setGroup] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [slug, setSlug] = useState('')
    const [description, setDescription] = useState('')
    const [fetching, setFetching] = useState(true)
    const [productGroups, setProductGroups] = useState([])
    const [fixedAmount, setFixedAmount] = useState('')
    const [percentage, setPercentage] = useState('')
    const [requiredPoints, setRequiredPoints] = useState('')
    const [discountType, setDiscountType] = useState('')
    const [active, setActive] = useState(false)
    // const [promotionDetails, setPromotionDetails] = useState({})
    const [updateError, setUpdateError] = useState('')
    const [products, setProducts] = useState([])

    useEffect(() => {
        // const fetchProductGroups = async () => {
        //     const headers = {
        //         'Content-Type': 'application/json',
        //         'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        //     }
        //     setFetching(true)
        //     try { 
        //         let response = null 

        //         response = await axios.get(`/api/v1/admin/product_groups?page[offset]=1&page[limit]=1000000&page[order]=desc`, { headers })            
                
        //         setProductGroups(response.data.data)
        //         fetchPromotionDetails()
        //     } catch (error) {
        //         console.error(error.response.data)
        //         setError(error.response.data.developer_message.errors)
        //         setFetching(false)
        //     }
        // }

        const fetchPromotionDetails = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setFetching(true)
            try { 
                let response = null 

                response = await axios.get(`/api/v1/promotions/${id}/products`, { headers })    
                console.log(':::::>', response.data.data)        
                
                setName(response.data.data.name)
                setGroup(response.data.data.product_group_id)
                setStartDate(response.data.data.valid_from)
                setEndDate(response.data.data.valid_to)
                setDiscountType(response.data.data.name)
                setSlug(response.data.data.slug)
                setDescription(response.data.data.description)
                setRequiredPoints(response.data.data.discount_rule.required_loyalty_points)
                setFixedAmount(response.data.data.discount_rule.fixed_amount)
                setPercentage(response.data.data.discount_rule.percentage_off)
                // fetchPromotionProducts()

                setProducts(response.data.data[0].products)
                setFetching(false)

            } catch (error) {
                console.error(error.response.data)
                setError(error.response.data.developer_message.errors)
                setFetching(false)
            }
        }
        fetchPromotionDetails()

        // fetchProductGroups()
    }, [cookies.accessToken, cookies.tokenType, id])


    const [ validationErrors, setValidationErrors ] = useState({})
    const [discountError, setDiscountError] = useState('')

    const validateForm = () => {
        let errors = {}

        if(!name || name === '') {
            errors.name = true
        }

        if(!slug || slug === '') {
            errors.slug = true
        }

        if(!description || description === '') {
            errors.description = true
        }

        if(!discountType || discountType === '') {
            errors.discountType = true
        }

        if((!percentage || percentage === '') && (!fixedAmount || fixedAmount === '')) {
            errors.discount = true
            setDiscountError('Please Choose at least one discount type above')
        }

        if(!startDate || startDate === '') {
            errors.startDate = true
        }

        if(!endDate || endDate === '') {
            errors.endDate = true
        }

        setValidationErrors(errors)
        return errors
    }

    const [success, setSuccess] = useState(null)

    const updatePromotion = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }

        if (Object.values(validateForm()).includes(true)) {
            setError('Please check the highlighted fields above')
            return
        }
        
        const requestPayload = {
            name,
            slug,
            // active,
            valid_from: startDate + ' 00:00:00',
            valid_to:endDate + ' 00:00:00',
            // description,
            discount_rule: {
                type: discountType,
                percentage_off: percentage === '' ? null : parseInt(percentage),
                fixed_cost: fixedAmount === '' ? null : parseInt(fixedAmount),
                X: null,
                for_X: null,
                required_loyalty_points: requiredPoints === '' ? parseInt(requiredPoints) : null
            },
            product_group_id: parseInt(group)
        }

        setProcessing(true)
        try {
            
            await axios.patch(`/api/v1/admin/promotions/${id}`, requestPayload, { headers })            
            // setBrands(response.data.data)
            setSuccess('Promotion update successfully')
            // setTimeout(() => {
            //     history.push(`/admin/products/promotions/`);
            // }, 3000);
            setProcessing(false)
        } catch (error) {
            console.error(error.response.data)
            setUpdateError(error.response.data.user_message)
            setProcessing(false)
        }
    }    

    const tableOptions = {
      selectable: false
      }

      const columnWidths = {
          productId: "w-full lg:w-1/12",
          productName: "w-full lg:w-3/12",
          sampleSku: "w-full lg:w-2/12",
          productImage: "w-full lg:w-1/12",
          brand: "w-full lg:w-1/12",
          status: "w-full lg:w-1/12",
          stock: "w-full lg:w-1/12",
          displayStock: "w-full lg:w-1/12",
          year: "w-full lg:w-1/12",
      }

      const columnDataStyles = {
          status: {
              isConditional: true,
              conditionals: {
                  live: "px-3 py-1 capitalize rounded-full bg-green-100 text-green-400",
                  inactive: "px-3 py-1 capitalize rounded-full bg-gray-200 text-gray-400"
              }
          },
      }

      const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true

            if(key === 'productId') {
                columnDisplayName = '#ID'
                columnDataType = 'link'
            }

            if(key === 'actions') {
                sortable = false
            }
            
            if(key === 'productImage') {
                columnDisplayName = 'Image'
            }

            if(key === 'productImage') {
                columnDataType = 'JSX'
            }

            if(key === 'stockAvailability') {
                forPopover = true
            }

            let column = key
            if(key === 'stock') {
                column = 'displayStock'
                columnDisplayName = 'stock'
                columnDataType = 'popoverTrigger'
            }
            if(key !== 'stockIncrease' && key !== 'selected' && key !== 'description' && key !== 'categories' && key !== 'colors' && key !== 'displayStock') {
                headers.push({
                    column,
                    columnDisplayName,
                    data: sampleObject[key],
                    sortable,
                    forPopover,
                    columnDataType
                })

                let fieldSelected = true

                if(index > 10) {
                    fieldSelected = false
                }
                fields.push({
                    name: columnDisplayName,
                    selected: fieldSelected
                })
            }
        });
        return {headers, fields}
    }

    const EditProductButton = ({productId}) => {
      return (
          <Link to={`product/${productId}`} className="font-sofia-pro rounded py-4 px-3 text-xs hover:text-blue-700 transition duration-200 hover:bg-blue-100 flex flex-row items-center">Edit <img src={EditIcon} alt="" className="w-3 ml-2 -mt-1 inline" /></Link>
      )
    }

    const ProductDetail = ({productIndex, name, brand, description, colors, categories}) => {
      const [activeFlyout, setActiveFlyout] = useState(null)
      const backgroundClass = (categoryIndex) => {
        if (categoryIndex === 0) {
            return 'bg-gray-500'
        }
        if (categoryIndex === 1) {
            return 'bg-gray-400'
        }
        if (categoryIndex === 2) {
            return 'bg-gray-300'
        }
          
      }
      return (
          <div className="relative">
              <span className="block w-full cursor-pointer" onMouseEnter={()=>{setActiveFlyout(productIndex)}} onMouseLeave={()=> {setActiveFlyout(null)}}>
                  {name}
              </span>
              <div className={`border rounded absolute top-0 left-full bg-white px-4 py-3 w-96 z-20 ${activeFlyout === productIndex ? "block" : "hidden"}`}>
                  <div className="text-center w-full py-3 border-b border-gray-200">
                      <h3 className="text-sm font-medium text-center">{name}</h3>
                  </div>
                  <div className="flex flex-row items-center gap-x-3 my-3">
                      {categories.map((category, categoryIndex) => (
                          <span key={categoryIndex} className={`${backgroundClass(categoryIndex)} text-xs font-sofia-pro px-3 py-2 text-white rounded`}>
                              {category}
                          </span>
                      ))}
                  </div>

                  <div className="mt-3">
                      <label className="font-medium text-xs text-ink-navy">Brand</label>
                      <p className="mb-3">{brand}</p>

                      <label className="font-medium text-xs text-ink-navy mt-5">Description</label>
                      <p className="mb-3">{description}</p>

                      <label className="font-medium text-xs text-ink-navy">Colours</label>
                      <div className="w-full flex flex-row gap-x-3 pb-5 pt-3">
                          {colors.map((color, colorIndex) => (
                              <span key={colorIndex} style={{backgroundColor: color}} className={`w-5 h-5 rounded-full`} />
                          ))}
                      </div>
                  </div>
              </div>
          </div>
      )
    }

    const ProductStock = ({stock, stockIncrease, stockAvailability, productIndex}) => {
      const [activeFlyout, setActiveFlyout] = useState(null)
      return (
          <div className="relative">
              <span onMouseEnter={()=>{setActiveFlyout(productIndex)}} onMouseLeave={()=> {setActiveFlyout(null)}}>
                  {stock} <img alt="" src={stockIncrease ? Increase : Reduce} className="inline ml-1 w-2" />
              </span>
              <div className={`border rounded absolute top-0 left-full bg-white px-4 py-3 w-52 z-20 ${activeFlyout === productIndex ? "block" : "hidden"}`}>
                  {stock > 0 && 
                  <>
                      <div className="w-full text-center">Available ({stock})</div>
                      <div className="w-full text-left">
                      {stockAvailability.map((availability, availabilityIndex)=>(
                          <p key={availabilityIndex} className="block text-xs my-2 relative text-gray-400">{availability.location} 
                              <span className="bg-gray-500 px-1 text-white text-xs absolute right-1">{availability.quantity}</span>
                          </p>
                      ))}
                      </div>
                  </>
                  }
                  {stock === 0 && 
                      <p className="text-xs text-gray-500">Out of stock</p>
                  }
              </div>
          </div>
      )
    }

    const getProductDescriptions = (descriptionsArray) => {
      const colours = []
      const images = []
      const sizes = []
      let sampleSku = '' 
      let stock = {
          total: 0, 
          locations: []
      }
      
      if (!descriptionsArray || descriptionsArray.length === 0 ) {
          return {colours, images, sizes, sampleSku, stock}
      }

      descriptionsArray.forEach((desc) => {
          colours.push(desc.colour)
          if(desc.assets && desc.assets.length > 0) {
              desc.assets.forEach((asset) => {
                  if(asset.type === 'image') {
                      images.push(`https://obj1-dev.s3.eu-west-2.amazonaws.com${asset.path}`)
                  }
              })
          }
          if(desc.sizes && desc.sizes.length > 0) {
              desc.sizes.forEach((size, sizeIndex) => {
                  if(sizeIndex === 0 && size.sku ) {
                      sampleSku = size.sku
                  }
                  sizes.push(size.size)
                  if(size.stock && size.stock.length > 0) {
                      size.stock.forEach((stockItem) => {
                          stock.total += stockItem.stock_qty
                          stock.locations.push({
                              location: stockItem.stock_location_name,
                              quantity: stockItem.stock_qty
                          })
                      })
                  }
              })
          }
      })
      return {colours, images, sizes, sampleSku, stock}
    }

    const getProductCategoryNames = (categoriesArray) => {
      const categoryArray = []
      if(!categoriesArray) {
          return categoryArray
      }
      categoriesArray.forEach((category) => {
          categoryArray.push(category.category)
          if (category.children && category.children.length > 0) {
              categoryArray.push(category.children[0].category)
              if (category.children[0].children && category.children[0].children.length > 0) {
                  categoryArray.push(category.children[0].children[0].category)
              }
          }
      })

      return categoryArray
    }

    // const ProductImage = ({image}) => {
    //     console.log('an image', image)
    //     return (
    //         <img src={image} alt="" />
    //     )
    // }

    const cleanUpProducts = (products) => {

      let finalResult = [] 
      products.forEach((product, productIndex) => {
          const productDescriptions = getProductDescriptions(product.colours)
          const productObject = {
              productId: <Link to={`product/${product.id}`}>{product.id}</Link>,
              productImage: <img src={productDescriptions.images[0]} alt="" className='h-12' />,
              productName: <ProductDetail 
                  productIndex={productIndex} 
                  name={product.name} 
                  brand={product.brand_name} 
                  description={product.description}
                  colors={productDescriptions.colours} 
                  categories={getProductCategoryNames(product.categories)}  />,
              displayStock: <ProductStock 
                  stock={productDescriptions.stock.total} 
                  stockIncrease={true} 
                  stockAvailability={productDescriptions.stock.locations} 
                  productIndex={productIndex} />,
              sampleSku: productDescriptions.sampleSku,
              brand: product.brand_name,
              status: "live",
              stock: productDescriptions.stock.total,
              year: product.year,
              actions: <EditProductButton productId={product.id} />,                
          }
          finalResult.push(productObject)
      })

      return finalResult
    }

    // const [selectedProductsCount, setSelectedProductsCount] = useState(0)

    // const getSelectionCount = (count) => {
    //   return setSelectedProductsCount(count)
    // }

    return (
        <Fragment>
            {fetching ? 
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                <p className='text-sm text-gray-500 mt-5'>Fetching promotion details...</p>
            </div>   
            :
            <>
            {error === '' ?
                <div className="w-full xl:w-10/12 mx-auto block">
                    <div className='w-full px-8 py-4'>
                        <p className="text-lg text-ink-navy block mb-8">Promotion Details</p>

                        <div className="w-full mb-5">
                            <div className="w-full mb-3">
                                <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.name && validationErrors.name === true ? 'text-red-500' : ''}`}>Name</label>
                                <input type="text" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.name && validationErrors.name === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="eg: Winter Sales" value={name} onChange={(e)=>{setName(e.target.value)}} onBlur={(e)=>{setSlug(slugify(e.target.value))}} />
                            </div>
                        </div>

                        <div className="w-full my-3">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.slug && validationErrors.slug === true ? 'text-red-500' : ''}`}>Slug</label>
                            <input type="text" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.slug && validationErrors.slug === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="eg: big-wheels" value={slug} onChange={(e)=>{setSlug(e.target.value)}} />
                        </div>

                        <div className="w-full my-3">
                            <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.description && validationErrors.description === true ? 'text-red-500' : ''}`}>Description</label>
                            <textarea className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 resize-none h-32 ${validationErrors.description && validationErrors.description === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} placeholder="Tell your customers what's what" value={description} onChange={(e)=>setDescription(e.target.value)} />
                        </div>

                        <div className="w-full flex gap-x-4 mt-6 mb-6">
                            <label className="text-xs text-gray-500 font-sofia-pro">Save Promotion as active </label>
                            <Switch
                                checked={active}
                                onChange={setActive}
                                className={`${
                                    active ? 'bg-ink-navy' : 'bg-gray-200'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                <span className="sr-only">Save as active</span>
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        active ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block w-3 h-3 transform bg-white rounded-full`}
                                />
                            </Switch>
                        </div>


                        <div className="w-full my-3 flex flex-row gap-x-4">
                            <div className="w-full mb-5">
                                <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.discountType && validationErrors.discountType === true ? 'text-red-500' : ''}`}>Discount type</label>
                                <div className={`select-wrapper border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.discountType && validationErrors.discountType === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`}>
                                    <select className="outline-none w-full" value={group} onChange={(e)=>setDiscountType(e.target.value)}>
                                        <option value="">Select a discount type</option>
                                        <option value='percentage'>Percentage Discount</option>
                                        <option value='fixed'>Fixed Amount</option>

                                    </select>
                                </div>
                            </div>

                            <div className="w-full mb-5">
                                <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500' : ''}`}>Required loyalty points</label>
                                <input type="number" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={requiredPoints} onChange={(e)=>{setRequiredPoints(e.target.value)}} />
                            </div>
                        </div>

                        <div className="w-full my-3 flex flex-row gap-x-4">
                            <div className="w-full mb-5">
                                <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500' : ''}`}>Percentage discount</label>

                                <input type="number" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={percentage} onChange={(e)=>{setPercentage(e.target.value)}} />
                            </div>
                            <div className="w-full mb-5">
                                <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500' : ''}`}>Fixed amount</label>
                                <input type="number" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.discount && validationErrors.discount === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={fixedAmount} onChange={(e)=>{setFixedAmount(e.target.value)}} />
                            </div>
                        </div>
                        {discountError !== '' && <p className='text-xs text-red-500 border-red-500 w-full py-2 rounded -mt-8'>{discountError}</p>}

                        <div className="w-full my-3 flex flex-row gap-x-4">
                            <div className="w-full mb-5">
                                <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.year && validationErrors.year === true ? 'text-red-500' : ''}`}>Start date</label>
                                <input type="date" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.name && validationErrors.name === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={startDate} onChange={(e)=>{setStartDate(e.target.value)}} />
                            </div>
                            <div className="w-full mb-5">
                                <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.endDate && validationErrors.endDate === true ? 'text-red-500' : ''}`}>End date</label>
                                <input type="date" className={`border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.styleCode && validationErrors.styleCode === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`} value={endDate} onChange={(e)=>{setEndDate(e.target.value)}} />
                            </div>
                        </div>

                        <div className="w-full my-3 flex flex-row gap-x-4">
                            <div className="w-full mb-5">
                                <label className={`text-xs text-gray-600 block mb-2 ${validationErrors.group && validationErrors.group === true ? 'text-red-500' : ''}`}>Restrict to group of products</label>
                                <div className={`select-wrapper border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brand && validationErrors.brand === true ? 'text-red-500 border-red-500 bg-red-100 bg-opacity-20' : ''}`}>
                                    <select className="outline-none w-full" value={group} onChange={(e)=>setGroup(e.target.value)}>
                                        <option value="">Select a product group</option>

                                        {productGroups.map((group, groupIndex) => (
                                            <option key={groupIndex} value={group.id}>{group.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="w-full mb-5">
                                <label className={`text-xs block mb-2 text-white`}>Edit groups</label>
                                <Link to="../groups" className="w-44 px-5 py-3 rounded text-ink-navy font-sofia-pro text-xs bg-white border border-ink-navy hover:bg-ink-navy hover:text-white transition duration-200 my-3 flex gap-x-2 items-center justify-center">Manage groups</Link>
                                
                            </div>
                        </div>

                        <button className="px-5 py-3 rounded text-white font-sofia-pro text-xs bg-blue-700 hover:bg-blue-500 transition duration-200 my-3 flex gap-x-2 items-center" onClick={()=>{
                                if(!processing) { updatePromotion() }
                                    }}>{processing ? <><Spinner />Saving Promotion...</> : 'Save Promotion'}</button>

                        {error && updateError!=='' &&  
                            <ErrorMessage message={updateError} dismissHandler={()=>{setUpdateError(false)}} />
                        } 

                        {success && success!=='' &&  
                            <SuccessMessage message={success} dismissHandler={()=>{setSuccess(false)}} />
                        } 

                        </div>
                </div>
                :
                <div className='w-full flex flex-col items-center justify-center mt-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error fetching promotion details</p>
                    <p className='text-sm text-gray-600 mt-1'>{error}</p>
                </div>
            }

            <div className='w-full xl:w-10/12 mx-auto block mb-96'>
                    {/* {!processing ?
                    <> */}
                        {error === '' ?
                            <DataTable 
                                tableHeaders={tableHeadersFields(cleanUpProducts(products)[0]).headers} 
                                tableData={cleanUpProducts(products)} 
                                columnWidths={columnWidths}
                                columnDataStyles={columnDataStyles}
                                allFields={tableHeadersFields(cleanUpProducts(products)[0]).fields}
                                // onSelectItems={getSelectionCount}
                                tableOptions={tableOptions}
                                // pagination={pagination}
                                // paginate={setPagination}
                            />
                            :
                            <div className='w-full flex flex-col items-center justify-center mt-10'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                                <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error fetching products for this promotion</p>
                                <p className='text-sm text-gray-600 mt-1'>{error}</p>
                            </div>
                        }
                    {/* </>

                        :

                        <div className='w-full flex flex-col items-center justify-center mt-10'>
                            <Spinner />
                            <p className='text-sm text-gray-500 mt-5'>Fetching products in this promotion...</p>
                        </div>
                    } */}
                </div>
            </>
            }
    </Fragment>
    )
}

export default Promotion