import React from 'react'
import Layout from '../../components/layout/Layout'
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    // Link,
    // useParams,
    useRouteMatch,
    Redirect,
  } from "react-router-dom";
import AllOrders from './orders/AllOrders';
import SpecialRequests from './orders/SpecialRequests';
import OrdersOverview from './orders/OrdersOverview';
import OrderDetail from './orders/OrderDetail';
import Customers from './orders/Customers';
import BikeFitRequests from './orders/bike-fits/BikeFitRequests';
import Postage from './orders/postage/Postage';
import SizeGuide from './orders/size-guides/SizeGuide';
import ConditionalContent from './orders/conditional-content/ConditionalContent';
import VoucherCodes from './orders/voucher-codes/VoucherCodes';
import Stores from './orders/stores/Stores';
import AdminErrorPage from './AdminErrorPage';
import NewEditPostageRule from './orders/postage/NewEditPostageRule';
import CourierRule from './orders/postage/CourierRule';
import NewSizeGuide from './orders/size-guides/NewSizeGuide';
import NewVoucherCode from './orders/voucher-codes/NewVoucherCode';
import VoucherCode from './orders/voucher-codes/VoucherCode';
import ConditionalContentDetail from './orders/conditional-content/ConditionalContentDetail';
import NewConditionalContent from './orders/conditional-content/NewConditionalContent';
import StoreDetail from './orders/stores/StoreDetail';
import BikeFitRequest from './orders/bike-fits/BikeFitRequest';
// import SizeGuides from './orders/size-guides/sizeGuides';
import NewBikeFitRequest from './orders/bike-fits/NewBikeFitRequest';

const Orders = () => {
    let { 
        path, 
        // url 
    } = useRouteMatch();

    const sidebarLinks = [
        {
            label: "Orders",
            location: "/admin/dashboard",
            subLinks: [
                {
                    label: "All Orders",
                    location: "/admin/orders/all-orders/all"
                },
                {
                    label: "Special Orders",
                    location: "/admin/orders/special-orders"
                },
            ]
        },
        // {
        //     label: "Reviews",
        //     location: "/admin/orders/reviews",
        //     sublinks: []
        // },
        {
            label: "Bike Fit Requests",
            location: "/admin/orders/bike-fit-requests",
            sublinks: []
        },
        {
            label: "Postage",
            location: "/admin/orders/postage",
            sublinks: []
        },
        // {
        //     label: "Size Guides",
        //     location: "/admin/orders/size-guides",
        //     sublinks: []
        // },
        {
            label: "Conditional Content",
            location: "/admin/orders/conditional-page-content",
            sublinks: []
        },
        {
            label: "Voucher Codes",
            location: "/admin/orders/voucher-codes",
            sublinks: []
        },
        {
            label: "Stores",
            location: "/admin/orders/stores",
            sublinks: []
        },
    ]

    return (
        <Layout pageTitle="Orders" sidebarLinks={sidebarLinks}>
            {/* {path} */}
            <Switch>
                <Route
                    path={path}
                    exact
                    render={() => {
                        return (
                        <Redirect to="/admin/orders/all-orders/all" />
                        )
                    }}
                    // render={() => {
                    //     return (
                    //     this.state.isUserAuthenticated ?
                    //     <Redirect to="/home" /> :
                    //     <Redirect to="/test1" /> 
                    //     )
                    // }}
                />
                <Route path={`${path}/overview`} exact>
                    <OrdersOverview />
                </Route>
                <Route path={`${path}/all-orders/:status`} exact>
                    <AllOrders />
                </Route>
                <Route path={`${path}/order/:id`} exact>
                    <OrderDetail />
                </Route>
                <Route path={`${path}/special-orders`} exact>
                    <SpecialRequests />
                </Route>
                <Route path={`${path}/customers`} exact>
                    <Customers />
                </Route>
                {/* <Route path={`${path}/reviews`} exact>
                    <Reviews />
                </Route> */}
                
                <Route path={`${path}/bike-fit-requests`} exact>
                    <BikeFitRequests />
                </Route>
                <Route path={`${path}/new-bike-fit-request`} exact>
                    <NewBikeFitRequest />
                </Route>
                <Route path={`${path}/bike-fit-requests/:requestNumber`} exact>
                    <BikeFitRequest />
                </Route>
                <Route path={`${path}/postage`} exact>
                    <Postage />
                </Route>
                <Route path={`${path}/postage/:postageId`} exact>
                    <NewEditPostageRule />
                </Route>
                <Route path={`${path}/postage/couriers/:courierId`} exact>
                    <CourierRule />
                </Route>
                <Route path={`${path}/postage/new-courier`} exact>
                    <CourierRule />
                </Route>
                {/* <Route path={`${path}/size-guides`} exact>
                    <SizeGuides />
                </Route> */}
                <Route path={`${path}/size-guides/detail/:id`} exact>
                    <SizeGuide />
                </Route>
                <Route path={`${path}/size-guides/new-size-guide`} exact>
                    <NewSizeGuide />
                </Route>
                <Route path={`${path}/conditional-page-content`} exact>
                    <ConditionalContent />
                </Route>
                <Route path={`${path}/conditional-page-content/new-conditional-page-content`} exact>
                    <NewConditionalContent />
                </Route>
                <Route path={`${path}/conditional-page-content/detail/:id`} exact>
                    <ConditionalContentDetail />
                </Route>
                <Route path={`${path}/voucher-codes`} exact>
                    <VoucherCodes />
                </Route>
                <Route path={`${path}/voucher-codes/detail/:voucherCode`} exact>
                    <VoucherCode />
                </Route>
                <Route path={`${path}/voucher-codes/new-voucher-code`} exact>
                    <NewVoucherCode />
                </Route>
                <Route path={`${path}/stores`} exact>
                    <Stores />
                </Route>
                <Route path={`${path}/stores/detail/:storeId`} exact>
                    <StoreDetail />
                </Route>
                <Route path={`${path}/stores/new-store`} exact>
                    <StoreDetail />
                </Route>
                <Route component={AdminErrorPage} />

            </Switch>
        </Layout>
    )
}

export default Orders
