import React, { useEffect, useState } from 'react'
import UpRightArrow from '../../../components/elements/icons/UpRightArrow'
import DeleteIcon from '../../../assets/images/icons/delete.svg'
import axios from "axios"
import { baseUrl } from '../../../utils';
import Spinner from '../../../components/elements/icons/Spinner';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Notification from '../../../components/elements/Notification';

const ProductGroup = () => {
    const displayOrderOptions = ["Newest", "Oldest", "Alphabetical"]
    const displayFormatOptions = ["Category View", "List", "Grid"]

    const [ cookies ] = useCookies(['user'])
    const { id } = useParams()
    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ groupDetails, setGroupDetails ] = useState(null)
    
    useEffect(() => {
      const getGroupDetails = async () => {
       const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        setProcessing(true)
        try {
            const response = await axios.get(`/api/v1/admin/product_groups/${id}`, { headers })            
            console.log(response)
            setGroupDetails(response.data.data)
            
            setProcessing(false)
        } catch (error) {
            console.log(error)
            setError(error.response.data.developer_message.errors)
            setProcessing(false)
        }
      }

      getGroupDetails()
    }, [cookies.accessToken, cookies.tokenType, id])

    const [ updating, setUpdating ] = useState(false)
    const [ notification, setNotification ] = useState(null)

    const updateGroup = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
        }
        let payload = groupDetails
        payload.parent_id = 0

        setUpdating(true)
        try {
            const response = await axios.patch(`/api/v1/admin/product_groups/${id}`, payload, { headers })            
            console.log(response)

            setUpdating(false)
        } catch (error) {
            console.log(error)
            // setError(error.response.data.developer_message.errors)
            setNotification({
                show: true,
                success: false,
                message: error.response.data.user_message
            })
            setUpdating(false)
        }
    }     
     

    const updateGroupDetails = (field, value) => {
        let details = JSON.parse(JSON.stringify(groupDetails))
        details[field] = value
        setGroupDetails(details)
    }

    const setGroup = () => {
        let details = JSON.parse(JSON.stringify(groupDetails))
        details.groups.push({
            or: false,
            rules: [
                {
                    left: "",
                    chain: "",
                    right: ""
                }
            ]
        })
        setGroupDetails(details)
    }
    
    const addChildGroup = () => {
        let details = JSON.parse(JSON.stringify(groupDetails))
        details.children.push({
            label: "",
            parent_id: groupDetails.id,
            display_order: "",
            display_format: "Category View",
            and_or: "and",
            filters: [],
            children: []
        })
        setGroupDetails(details)
        
    }
    
    const addEmptyFilter = (isChild, childIndex) => {
        let details = JSON.parse(JSON.stringify(groupDetails))
        if(isChild && isChild === true) {
            details.filters.push(
                {
                    column: "",
                    operator: "",
                    exclude: 0,
                    value: ""
                }
            )
        } else {
            details.filters.push(
                {
                    column: "",
                    operator: "",
                    exclude: 0,
                    value: ""
                }
            )
            setGroupDetails(details)
        }
    }

    // const  = (groupIndex) => {
    //     let details = JSON.parse(JSON.stringify(groupDetails))
    //     details.filters[groupIndex].rules.push(
    //         {
    //             column: "category",
    //             operator: "=",
    //             exclude: 0,
    //             value: "bikes"
    //         }
    //     )
    //     setGroupDetails(details)
    // }

    const updateGroupRules = ({ ruleIndex, field, data}) => {
        let details = JSON.parse(JSON.stringify(groupDetails))
        details.filters[ruleIndex][field] = data

        setGroupDetails(details)
    }

    // const toggleGroupRulesChain = (groupIndex) => {
    //     let details = JSON.parse(JSON.stringify(groupDetails))
    //     details.groups[groupIndex].or = !details.groups[groupIndex].or

    //     setGroupDetails(details)
    // }

    const removeChildGroup = (groupIndex) => {
        let details = JSON.parse(JSON.stringify(groupDetails))
        const filtered = details.groups.filter((value, index, arr) => { 
            return index !== groupIndex;
        });
        details.groups = filtered

        setGroupDetails(details)
    }

    const removeFilterRule = (ruleIndex) => {
        let details = JSON.parse(JSON.stringify(groupDetails))
        const filtered = details.filters.filter((value, index, arr) => { 
            return index !== ruleIndex;
        });

        details.filters = filtered
        setGroupDetails(details)
    }

    const lefts = [
        {
            value: "product_title",
            label: "Product title" 
        },
        {
            value: "category",
            label: "Category", 
        },
        {
            value: "colour",
            label: "Colour", 
        },
        {
            value: "price_range",
            label: "Price range"
        }
    ]
    const chains = [
        {
            value: "includes",
            label: "Contains", 
        },
        {
            value: "=",
            label: "Is equal to", 
        },
        {
            value: "!",
            label:"Is not", 
        },
        {
            value: ">",
            label: "Is above", 
        },
        {
            value: "<",
            label: "Is below", 
        },
        {
            value: "excludes",
            label: "Does not contain"
        },
        {
            value: "between",
            label: "Is between"
        },
    ]

    return (
        <>
            {notification && <Notification type={`${notification.success === true ? 'success' : 'error'}`} isShowing={notification.show} message={notification.message} dismissHandler={()=>{setNotification(null)}} />}

            {processing ?

            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                <p className='text-sm text-gray-500 mt-5'>Fetching product group details...</p>
            </div>

            :
            
            <>
            {error === '' ? 
            <>
            <div className="w-full px-12">
                <div className="w-full pb-8 px-6 border-b border-gray-300">
                    <label className="font-sofia-pro text-xs text-ink-navy block mb-3">Label</label>
                    <input value={groupDetails.label} onChange={(e)=>{updateGroupDetails("label", e.target.value)}} type="text" className="px-4 py-3 text-xs text-gray-600 border rounded border-gray-300 w-full focus:outline-none focus:border-gray-500" />
                </div>

                <div className="w-full py-8 px-6 border-b border-gray-300">
                    <p className="text-sm font-medium text-ink-navy mb-3">Product Filter Rules</p>
                    <p className="text-xs text-gray-500">Use this section to create rules, like a search filter, for the products you want to include in this group. This will always be live and automatically updated as the products are added and removed</p>

                    { groupDetails.filters && groupDetails.filters.length === 0 && <button className="text-ink-navy border flex flex-row gap-x-2 items-center rounded border-gray-200 border-dashed mt-3 bg-white text-xs px-3 py-2" onClick={()=>{setGroup()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                        </svg> 
                        Add Child Group 
                    </button>}

                    {/* { groupDetails.filters.length > 0 && 
                        groupDetails.filters.map((group, groupIndex) => ( */}
                            {/* <div key={groupIndex} className={`relative bg-gray-100 border border-gray-300 rounded p-6 mt-4 items-center ${groupIndex > 0 ? "w-11/12 ml-auto": "w-full"}`}> */}
                            <div className={`relative bg-gray-100 border border-gray-300 rounded p-6 mt-4 items-center w-full`}>
                                {/* <button className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 transition duration-200" onClick={()=>{removeGroup(groupIndex)}}> */}
                                <button className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 transition duration-200">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                                </button>
                                <div className="flex flex-row justify-between items-center border w-24 h-8 bg-gray-200 rounded mx-auto relative">
                                    {/* toggle switch underlay */}
                                    <div className={`w-1/2 transform transition duration-200 inline-block h-full text-sm cursor-pointer flex items-center justify-center border-2 rounded border-blue-800 shadow-md bg-blue-700 z-10 ${groupDetails.and_or === 'or' ? "absolute translate-x-full" : "absolute translate-x-0"}`} />
                                    <button 
                                        className={`w-1/2 transition duration-200 inline-block h-full text-xs cursor-pointer flex items-center justify-center z-20 ${groupDetails.and_or === 'and' ? "text-white" : "text-gray-800"}`} 
                                        onClick={() => {updateGroupDetails("and_or", 'and')}}
                                    >
                                        AND
                                    </button>
                                    <button 
                                        className={`w-1/2 transition duration-200 inline-block h-full text-xs cursor-pointer flex items-center  justify-center z-20 ${groupDetails.and_or === 'or' ? "text-white" : "text-gray-800"}`}
                                        // onClick={() => {toggleGroupRulesChain(groupIndex)}}
                                        onClick={() => {updateGroupDetails("and_or", 'or')}}
                                    >
                                        OR
                                    </button>
                                </div>

                                {groupDetails.filters && groupDetails.filters.map((rule, ruleIndex)=>(
                                    <div key={ruleIndex} className="flex flex-row gap-x-4 my-3">
                                        <select 
                                            className="px-2 py-3 text-xs text-gray-600 border rounded border-gray-300 w-full focus:outline-none focus:border-gray-500" 
                                            value={rule.column} 
                                            onChange={(e)=>{updateGroupRules({ruleIndex, field: "column", data: e.target.value})}}
                                        >
                                            {lefts.map((left, leftIndex) => (
                                                <option key={leftIndex} value={left.value}>{left.label}</option>
                                            ))}
                                        </select>
                                        <select 
                                            className="px-2 py-3 text-xs text-gray-600 border rounded border-gray-300 w-full focus:outline-none focus:border-gray-500" 
                                            value={rule.operator}
                                            onChange={(e)=>{updateGroupRules({ruleIndex, field: "operator", data: e.target.value})}}
                                        >
                                            {chains.map((chain, chainIndex) => (
                                                <option key={chainIndex} value={chain.value}>{chain.label}</option>
                                            ))}
                                        </select>
                                        <input 
                                            className="px-4 py-3 text-xs text-gray-600 border rounded border-gray-300 w-full focus:outline-none focus:border-gray-500" 
                                            value={rule.value} type="text" 
                                            placeholder="Please enter the condition" 
                                            onChange={(e)=>{updateGroupRules({ruleIndex, field: "value", data: e.target.value})}}
                                        />
                                        <button 
                                        onClick={()=>{removeFilterRule(ruleIndex)}}
                                        >
                                            <img src={DeleteIcon} alt="" className="w-12 opacity-60 hover:opacity-90 transition duration-200" />
                                        </button>
                                    </div>
                                ))}

                                <div className="flex flex-row gap-x-4">
                                    <button className="text-ink-navy border flex flex-row gap-x-2 items-center rounded border-gray-200 border-dashed mt-3 bg-white text-xs px-3 py-2" onClick={()=>{addChildGroup()}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                        </svg> 
                                        Add Group 
                                    </button>
                                    <button className="text-ink-navy border flex flex-row gap-x-2 items-center rounded border-gray-200 border-dashed mt-3 bg-white text-xs px-3 py-2" 
                                    onClick={()=>{addEmptyFilter()}}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                        </svg> 
                                        Add Filter Rule 
                                    </button>
                                </div>
                            </div>
                </div>
                <div className="w-full py-8 px-6 border-b border-gray-300 flex flex-row gap-x-6">
                    <div className="w-1/3">
                        <label className="font-sofia-pro text-xs text-gray-600 block mb-3">Display Order</label>
                        {/* <input type="text" className="px-4 py-3 text-xs text-gray-600 border rounded border-gray-300 w-full" /> */}
                        <select className="px-2 py-3 text-xs text-gray-600 border rounded border-gray-300 w-full focus:outline-none focus:border-gray-500" value={groupDetails.display_order} onChange={(e)=>{updateGroupDetails("display_order", e.target.value)}}>
                            <option>Choose Order</option>
                            {displayOrderOptions.map((option, optionIndex)=>(
                                <option key={optionIndex} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                    <div className="w-1/3">
                        <label className="font-sofia-pro text-xs text-gray-600 block mb-3">Display Format</label>
                        {/* <input type="text"  /> */}
                        <select className="px-2 py-3 text-xs text-gray-600 border rounded border-gray-300 w-full focus:outline-none focus:border-gray-500" value={groupDetails.display_format} onChange={(e)=>{updateGroupDetails("display_format", e.target.value)}}>
                            <option>Choose Format</option>
                            {displayFormatOptions.map((format, formatIndex)=>(
                                <option key={formatIndex} value={format}>{format}</option>
                            ))}
                        </select>
                    </div>
                    <div className="w-1/3">
                        <label className="font-sofia-pro text-xs text-gray-600 block mb-3">Limit Number of Results</label>
                        <input type="number" className="px-4 py-3 text-xs text-gray-600 border rounded border-gray-300 w-full focus:outline-none focus:border-gray-500" value={groupDetails.results_count} onChange={(e)=>{updateGroupDetails("resultsCount", e.target.value)}} />
                    </div>
                </div>

                <div className="w-full py-8 flex flex-row justify-between">
                    <button className="text-ink-navy border flex flex-row gap-x-2 items-center rounded border-ink-navy bg-transparent text-xs px-3 py-2">Preview products <UpRightArrow color={"#05194C"} height={10} width={10} /> </button>
                    <div className="flex flex-row-reverse gap-x-4">
                        <button 
                            className="flex flex-row gap-x-3 items-center justify-center text-white border rounded border-blue-700 bg-blue-700 text-xs px-3 py-2"
                            onClick={()=>{
                                if(!processing) { updateGroup() }
                            }}
                        >
                            {updating ? <><Spinner />Saving...</> : 'Save'}
                        </button>
                        <button className="text-ink-navy border rounded border-ink-navy bg-transparent text-xs px-3 py-2">Cancel </button>
                    </div>        
                </div>
            </div>
            </>
            :
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <p className='text-sm text-red-500 mt-5 font-medium'>Sorry, there was an error</p>
                <p className='text-sm text-gray-600 mt-1'>{error}</p>
            </div>
            }
            </>
        }
        </>
    )
}

export default ProductGroup
