import React, { useState, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import Logo from '../../assets/images/push-dark.svg'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

const StoreSelector = () => {
    const listOptions = [
        {
            name: 'PushRetail',
            storeLogo: Logo
        }
    ]
    const [selected, setSelected] = useState(listOptions[0])

    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className="w-full relative  mt-1">
                <Listbox.Button className={`relative w-full text-left block`}>
                    {/* <span className="block truncate text-sm lg:text-xs">{selected.name}</span> */}
                    <div className="w-full border-gray-200 rounded-lg shadow p-3 bg-white relative">
                        <img src={selected.storeLogo} alt="Logo" className="w-5/12 mb-2" />
                        <span className="text-sm text-gray-300 absolute bottom-0 left-12 font-sofia-pro">Workspace</span>
                    </div>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                        className="w-6 h-6 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="z-20 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {listOptions.map((option, optionIndex) => (
                        <Listbox.Option
                            key={optionIndex}
                            className={({ active }) =>
                                `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                    cursor-default select-none relative py-2 pl-10 pr-4`
                            }
                            value={option}
                            // onClick={itemAction(optionIndex)}
                            >
                            {({ selected, active }) => (
                                <>
                                <span
                                    className={`${
                                    selected ? 'font-medium' : 'font-normal'
                                    } block truncate`}
                                >
                                    {option.name}
                                </span>
                                {selected ? (
                                    <span
                                    className={`${
                                        active ? 'text-amber-600' : 'text-amber-600'
                                    }
                                            absolute inset-y-0 left-0 flex items-center pl-3`}
                                    >
                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                    </span>
                                ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                        <Listbox.Option
                            className={({ active }) =>
                                `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                    cursor-default select-none relative py-2 pl-10 pr-4`
                            }
                            >
                            {({ selected, active }) => (
                                <>
                                <span
                                    className={`${
                                    selected ? 'font-medium' : 'font-normal'
                                    } block truncate text-gray-400`}
                                >
                                    Create new store
                                </span>
                                {selected ? (
                                    <span
                                    className={`${
                                        active ? 'text-amber-600' : 'text-amber-600'
                                    }
                                            absolute inset-y-0 left-0 flex items-center pl-3`}
                                    >
                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                    </span>
                                ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default StoreSelector
