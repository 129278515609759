import React, {useState} from 'react'

const SettingsIcon = () => {
    const [color, setColor] = useState("#B8C0D5")

    return (
        <svg onMouseEnter={()=>{setColor("#ffffff")}} onMouseLeave={()=>{setColor("#B8C0D5")}} id="Unknown-6" xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 21.178 19.863">
            <path id="Unknown-6-2" data-name="Unknown-6" d="M132.853,272.252v1.806H128v-1.806Zm4.853-1.806a.839.839,0,0,1,.62.268.878.878,0,0,1,.262.635v3.611a.878.878,0,0,1-.262.635.839.839,0,0,1-.62.268h-3.53a.839.839,0,0,1-.62-.268.878.878,0,0,1-.262-.635v-3.611a.878.878,0,0,1,.262-.635.839.839,0,0,1,.62-.268Zm2.206-5.417v1.806H128v-1.806Zm-8.824-7.223v1.806H128v-1.806Zm18.09,14.446v1.806H139.03v-1.806ZM135.942,256a.839.839,0,0,1,.62.268.878.878,0,0,1,.262.635v3.612a.878.878,0,0,1-.262.635.839.839,0,0,1-.62.268h-3.53a.839.839,0,0,1-.62-.268.878.878,0,0,1-.262-.635V256.9a.878.878,0,0,1,.262-.635.839.839,0,0,1,.62-.268Zm8.824,7.223a.839.839,0,0,1,.62.268.878.878,0,0,1,.262.635v3.612a.878.878,0,0,1-.262.635.839.839,0,0,1-.62.268h-3.53a.839.839,0,0,1-.62-.268.878.878,0,0,1-.262-.635v-3.612a.878.878,0,0,1,.262-.635.839.839,0,0,1,.62-.268Zm4.412,1.806v1.806H146.09v-1.806Zm0-7.223v1.806H137.265v-1.806Z" transform="translate(-128 -256)" fill={color}/>
        </svg>

    )
}

export default SettingsIcon
