import React from 'react'
import Layout from '../../components/layout/Layout'
import {
    Switch,
    Route,
    // Link,
    // useParams,
    useRouteMatch,
    Redirect,
} from "react-router-dom";
import CustomerDetail from './customers/CustomerDetail';
import AllCustomers from './customers/AllCustomers';

const Customers = () => {
    let { 
        path, 
        // url 
    } = useRouteMatch();

    const sidebarLinks = [
        {
            label: "All Customers",
            location: "/admin/customers/all-customers",
            subLinks: []
        }
    ]
    return (
        <Layout pageTitle="Customers" sidebarLinks={sidebarLinks}>
            <Switch>
                <Route
                    path={path}
                    exact
                    render={() => {
                        return (
                        <Redirect to="/admin/customers/all-customers" />
                        )
                    }}
                    // render={() => {
                    //     return (
                    //     this.state.isUserAuthenticated ?
                    //     <Redirect to="/home" /> :
                    //     <Redirect to="/test1" /> 
                    //     )
                    // }}
                />
                <Route path={`${path}/all-customers`} exact>
                    <AllCustomers />
                </Route>
                <Route path={`${path}/all-customers/:id`} exact>
                    <CustomerDetail />
                </Route>
            </Switch>
        </Layout>
    )
}

export default Customers
