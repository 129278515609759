import React, { Fragment, useEffect, useState } from 'react'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import FilterIcon from '../../../../assets/images/icons/filter-list.svg'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl } from '../../../../utils';
import Spinner from '../../../../components/elements/icons/Spinner';
import { useParams } from 'react-router'
import { Switch } from '@headlessui/react'

const Ads = () => {
    // const blocks = [
    //     {
    //         name: "E-bikes",
    //         backgroundImage:"https://images.pexels.com/photos/6502400/pexels-photo-6502400.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
    //     },
    //     {
    //         name: "Kids bikes",
    //         backgroundImage:"https://images.pexels.com/photos/175353/pexels-photo-175353.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
    //     },
    //     {
    //         name: "New shoes",
    //         backgroundImage:"https://cdn.pixabay.com/photo/2016/03/15/18/52/shoes-1258964_960_720.jpg"
    //     },
    //     {
    //         name: "Helmets",
    //         backgroundImage:"https://cdn.pixabay.com/photo/2020/01/16/06/24/activity-4769731_960_720.jpg"
    //     },
    // ]

    const [ processing, setProcessing ] = useState(true)
    const [ error, setError ] = useState('')
    const [ cookies ] = useCookies(['user'])
    const [categories, setCategories] = useState([])

    const [blockDetails, setBlockDetails] = useState([])
    const { id } = useParams()

    const [ validationErrors, setValidationErrors ] = useState({})

    const validateBrandForm  = () => {
        let errors = {}
        
        // if(!brandTitle || brandTitle === '') {
        //     errors.brandTitle = true
        // }

        // if(!brandContent || brandContent === '') {
        //     errors.brandContent = true
        // }
        
        // if(!brandSlug || brandSlug === '') {
        //     errors.brandSlug = true
        // }

        setValidationErrors(errors)
        return errors
    }

    useEffect(() => {
        const fetchAdBlockDetails = async  () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setProcessing(true)
            try { 
                let response = null 

                response = await axios.get(`/api/v1/ad_blocks/${id}`, { headers })            
                setBlockDetails(response.data.data)
                await getCategories()
                // setPagination({
                //     page: pagination.page,
                //     perPage: pagination.perPage,
                //     totalRecords: response.data.total_records,
                //     nextPage: response.data.next,
                //     lastPage: response.data.last
                // })

                // setProcessing(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }
        fetchAdBlockDetails()

        const getCategories = async () => {
            const headers = {
              'Content-Type': 'application/json',
              'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
        
            try {
                const response = await axios.get(`/api/v1/categories/nested/?page[offset]=1&page[limit]=1000000&page[order]=desc`, { headers })            
                console.log(response)
                setCategories(response.data.data)
                setProcessing(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setProcessing(false)
            }
        }

    }, [cookies.accessToken, cookies.tokenType, id])

    const [position, setPosition] = useState(null)
    const [newBlockPosition, setNewBlockPosition] = useState(null)
    const [newBlockPositionIds, setNewBlockPositionIds] = useState([])
    // const [positionsForDisplay, setPositionsForDisplay] = useState([])
    const setBlockPosition = () => {
        // let tempPosition = null

        let tempBlock = { ...blockDetails }

        categories.map ((category) => {
            if(category.id === position) {
                // tempPosition = category
            }
        })
        setNewBlockPositionIds([...newBlockPositionIds, ...[position.id]])
        // setPositionsForDisplay([...positionsForDisplay, ...[tempPosition]])
    }

    const categoryName = (id) => {
        let name = ""
        categories.forEach((category) =>  {
            if (category.id.toString() === id) {
                name = category.category
            }
        }) 
        return name
    }
    
    return (
        <Fragment>
            {processing ?
            <div className='w-full flex flex-col items-center justify-center mt-10'>
                <Spinner />
                <p className='text-sm text-gray-500 mt-5'>Fetching ads...</p>
            </div>    
            :
            <div className="w-full">
                <div className="w-full block justify-between pt-5">
                    <h3 className='text-ink-navy text-lg'>Ad Block Details </h3>
                </div>
                <div className="w-full block lg:flex flex-row justify-between pt-5 items-center">
                    <div className="flex flex-row gap-x-4 w-full lg:w-3/5">
                        
                        {/* <div className='w-full flex flex-row gap-x-2 mb-3 mt-3'> */}
                            <div className='w-full'>
                                <label className={`text-xs text-sm block mb-2 ${validationErrors.name && validationErrors.name === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                    Ad Block Name
                                </label>
                                <input 
                                    type="text" 
                                    className={`border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.name && validationErrors.name === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: Red Trailer" 
                                    value={blockDetails.name}
                                    onChange={(e)=>{setBlockDetails({...blockDetails, ...{name: e.target.value}})}} 
                                />
                            </div>
                            <div>
                                <label className={`text-xs text-sm block mb-2 text-ink-navy`}>
                                    Enabled
                                </label>
                                <Switch
                                    checked={blockDetails.enabled}
                                    onChange={()=>{setBlockDetails({...blockDetails, ...{enabled: !blockDetails.enabled}})}} 
                                    className={`${
                                        blockDetails.enabled ? 'bg-ink-navy' : 'bg-gray-300'
                                    } relative inline-flex items-center h-7 rounded-full w-12 mt-2`}
                                    >
                                    <span className="sr-only">Enabled</span>
                                    <span
                                        className={`transform transition ease-in-out duration-200 ${
                                            blockDetails.enabled ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block w-5 h-5 transform bg-white rounded-full`}
                                    />
                                </Switch>
                            </div>

                            <div className='w-full'>
                                <label className={`text-xs text-sm block mb-2 ${validationErrors.position && validationErrors.position === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                    Position
                                </label>
                                <div 
                                    className={`select-wrapper border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200
                                    ${validationErrors.position && validationErrors.position === true ? 'border-red-500' : 'border-gray-0'} `} placeholder="Eg: red_trailer"
                                    // value={brandSlug}
                                    // onChange={(e)=>{setBrandSlug(e.target.value)}}
                                >
                                    <select className='w-full outline-none' 
                                        value={blockDetails.position} 
                                        onChange={(e) => {setBlockDetails( {...blockDetails, ...{ position: e.target.value }})}}>
                                    <option value="">Select position</option>
                                    <option value="home">Home Page</option>
                                    <option value="category">Category</option>
                                    </select>
                                </div>
                            </div>
                    </div>
                    
                    <div className="flex flex-row gap-x-2 mt-2">
                        <Link to="ads-block/new-ads-block" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                            Save
                        </Link>
                    </div> 
                </div>

                <div className='w-full lg:w-3/5'>
                    {blockDetails.position === 'category' && 
                        <>
                            <div className='flex flex-row gap-x-2 mb-3 mt-3 items-end relative'>
                                <div className='w-full'>
                                    <label className={`text-xs text-sm block mb-2 ${validationErrors.brandTitle && validationErrors.brandTitle === true ? 'text-red-500' : 'text-ink-navy'}`}>
                                        Categories
                                    </label>
                                    <label className={`text-xs text-sm block mb-2 text-gray-400`}>
                                        Select a category and click on "Add" to add multiple categories.
                                    </label>
                                    <div 
                                        className={`select-wrapper border rounded px-4 py-3 text-sm w-full focus:outline-none focus:border-gray-300 transition duration-200 ${validationErrors.brandSlug && validationErrors.brandSlug === true ? 'border-red-500' : 'border-gray-0'} `} 
                                        placeholder="Eg: red_trailer"
                                        // value={brandSlug}
                                        // onChange={(e)=>{setBrandSlug(e.target.value)}}
                                    >
                                        <select className='w-full outline-none' onChange={(e)=>{setPosition(e.target.value)}}>
                                            <option value="">Select category</option>
                                            {categories.map((category, categoryIndex) => (<option key={categoryIndex} value={category.id}>{category.category}</option>))}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center gap-x-2 items-center px-4 mb-1 py-2 text-sm font-medium text-ink-navy bg-ink-navy bg-opacity-10 transition duration-200 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 hover:bg-opacity-30"
                                        onClick={()=>{setBlockPosition()}}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>

                            <div className='w-full flex items-center gap-4 flex-wrap'>
                                {newBlockPositionIds.map((category, categoryIndex) => (<span key={categoryIndex} className='px-3 py-2 rounded bg-gray-100 text-gray-500 text-sm'>{categoryName(category)}</span>))}
                            </div>
                        </>
                    }
                </div>
                <div className="w-full block justify-between pt-5 border-t border-gray-100 mt-8">
                    <h3 className='text-ink-navy text-lg'>Ads in this Ad Block </h3>
                </div>
                <div className="w-full block lg:flex flex-row justify-between pt-5">
                    <div className="flex flex-row gap-x-4 items-center w-full lg:w-3/5">
                        <div className="relative rounded border border-gray-200 py-3 px-4 bg-white w-9/12">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm mt-1">
                                    <img src={SearchIcon} alt="search" className="w-5 -mt-2 opacity-30" />
                                </span>
                            </div>
                            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 text-xs" placeholder="Search ads" />
                        </div>
                        <button 
                            className="rounded border-gray-200 py-3 px-4 text-xs text-gray-400 flex flex-row items-center border" 
                            // onClick={()=>{toggleFilters()}} 
                        >
                            <img src={FilterIcon} alt="search" className="w-4 mr-2" /> Filters
                        </button>
                    </div>
                    <div className="flex flex-row gap-x-2 mt-2 lg:mt-0 flex-wrap">
                        {/* {selectedReviewsCount > 1 && 
                            <DropdownMenu buttonIcon={BulkActionsIcon} buttonType='combo' buttonText="Bulk actions" menuItems={bulkActions} iconClasses="w-4 mr-2 hidden lg:inline" buttonClasses="inline-flex justify-center w-full text-xs text-white rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border font-sofia-pro font-light" />
                        } */}
                        <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <span className='p-px rounded-full border border-ink-navy mr-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                        </span>
                            Reset default
                        </button>
                        {/* <button className="rounded border-gray-800 py-3 px-4 text-xs text-gray-800 flex flex-row items-center border">
                            <img src={UpRightArrow} alt="search" className="w-3 mr-2 hidden lg:inline" /> Downloads page
                        </button> */}
                        <Link to="ads/new-ad" className="rounded border-blue-700 bg-blue-700 py-3 px-4 text-xs text-white flex flex-row items-center border">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden lg:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg> 
                            Create new ad
                        </Link>
                    </div>
                </div>

                <div className='w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-10'>
                    {blockDetails.ads.map((ad, adIndex)=>(
                        // <SliderTile key={sliderIndex} slider={slider} />
                        <Link to={`/admin/navigations/ad-blocks/${id}/ads/${ad.id}`} key={adIndex}
                            className='w-full transition duration-200 hover:shadow-md cursor-pointer h-48 rounded-md relative flex items-center justify-center'
                            style={{backgroundImage: `url("${ad.background_image_url}")`, backgroundSize: 'cover'}}
                        >
                            <div className='w-full h-48 bg-black bg-opacity-30 flex items-center justify-center'>
                                <p className='font-medium uppercase text-white shadow-sm'>{ad.title_main}</p>
                            </div>
                            <div className='w-12 h-12 flex items-center justify-center rounded-l-lg bg-white absolute shadow-sm top-4 right-0'>
                                <h1 className='text-ink-navy font-bold text-xl'>{ad.order + 1}</h1>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            }
    </Fragment>
    )
}

export default Ads
