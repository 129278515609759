import React, { useState } from 'react'
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";


const CustomCss = () => {
    const [cssValue, setCssValue] = useState('')
    
    const onChange = (newValue) => {
        console.log("change", newValue);
        setCssValue(newValue)
    }

    return (
        <div className="w-full pb-10">
            <h3 className="font-medium text-ink-navy mb-7 text-lg">Custom CSS</h3>

            <AceEditor
                placeholder="Enter your custom CSS here"
                mode="css"
                theme="monokai"
                name="userCustomCss"
                onChange={onChange}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={cssValue}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
            }}/>

            <div className="border-t border-gray-200 py-10 flex flex-row-reverse gap-x-3 mt-8">
                <button className="px-4 py-2 bg-blue-600 text-white font-sofia-pro text-xs rounded">Save</button>
                <button className="px-4 py-2 bg-white border text-ink-navy text-xs border-ink-navy font-sofia-pro rounded">Cancel</button>
            </div>

        </div>
    )
}

export default CustomCss
