import React from 'react'
import FacebookLogo from '../../../assets/images/icons/facebook.svg'
import TwitterLogo from '../../../assets/images/icons/twitter.svg'
import InstagramLogo from '../../../assets/images/icons/instagram.svg'
import YoutubeLogo from '../../../assets/images/icons/youtube.svg'
import PinterestLogo from '../../../assets/images/icons/pinterest.svg'
import StravaLogo from '../../../assets/images/icons/strava.svg'
import GreenCheck from '../../../assets/images/icons/circle-check.svg'

const SocialMarketing = () => {

    const socialAccounts = [
        {
            label: "Connect Facebook",
            name: "facebook",
            accountUrl: "https://www.facebook.com/cycleconceptuk",
            placeholder: "Facebook link",
            icon: FacebookLogo,
            connected: true
        },
        {
            label: "Connect Twitter",
            name: "twitter",
            accountUrl: "https://www.twitter.com/cycleconceptuk",
            placeholder: "Twitter link",
            icon: TwitterLogo,
            connected: true
        },
        {
            label: "Connect Instagram",
            name: "instagram",
            accountUrl: "https://www.instagram.com/cycleconceptuk",
            placeholder: "Instagram link",
            icon: InstagramLogo,
            connected: true
        },
        {
            label: "Connect Youtube",
            name: "youtube",
            accountUrl: "",
            placeholder: "Youtube link",
            icon: YoutubeLogo,
            connected: false
        },
        {
            label: "Connect Pinterest",
            name: "pinterest",
            accountUrl: "",
            placeholder: "Pinterest link",
            icon: PinterestLogo,
            connected: false
        },
        {
            label: "Connect Sträva",
            name: "strava",
            accountUrl: "https://www.pinterest.com/cycleconceptuk",
            placeholder: "Your stravä club link",
            icon: StravaLogo,
            connected: false
        },
    ]

    return (
        <div>
            <h3 className="font-medium text-ink-navy text-lg mb-5">Social Marketing</h3>

            {socialAccounts.map((account, accountIndex)=>(
                <div key={accountIndex}>
                    <label className="text-xs text-gray-300">{account.label}</label>
                    <div className="border rounded-md border-gray-200 flex items center my-3 px-4 py-4 relative w-full ">
                        <div className="w-16 text-center">
                            <img src={account.icon} alt="" />
                        </div>
                        <input className="text-xs placeholder:text-gray-300 text-gray-500 w-2/3 focus:outline-none" placeholder={account.placeholder} />
                        <div className="flex flex-row items-center absolute right-3 top-4 gap-x-12">
                            {account.connected && <img src={GreenCheck} alt="" />}
                            <button className={`px-2 py-1 border rounded text-xs ${account.connected ? 'text-gray-300 border-gray-300 bg-gray-100 bg-opacity-100' : 'border-ink-navy text-ink-navy bg-transparent' }`}>{account.connected ? "Disconnect" : "Connect"}</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default SocialMarketing
