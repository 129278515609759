import React, { useState } from 'react'
import { Switch } from '@headlessui/react'
import Wysiwyg from '../../elements/Wysiwyg'

const OnsiteMessaging = () => {
    const [stockNumberEnabled, setStockNumberEnabled] = useState(false)
    const [inStockMessageEnabled, setInStockMessageEnabled] = useState(true)
    const [showLimitedStockMessage, setShowLimitedStockMessage] = useState(false)
    const [showNumberInLimitedStockMessage, setShowNumberInLimitedStockMessage] = useState(true)

    return (
        <div className="w-full pb-10">
            <h3 className="font-medium text-ink-navy mb-7 text-lg">On-site Messaging</h3>

            <div className="w-full my-3">
                <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Availability Check (HTML)</label>
                <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-20" placeholder="<strong> Please note that this is a demo website for the PushRetail eCommerce platform. You cannot purchase from here </strong>"></textarea>
            </div>

            <div className="w-full block lg:flex flex-row gap-x-4 my-3">
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Stock Message</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder="Your stock message here..."></textarea>
                    <div className="my-3">
                        <label className="text-gray-500 font-sofia-pro mb-2 text-sm mr-5">Show No of Stock in Message </label>
                        <Switch
                            checked={stockNumberEnabled}
                            onChange={setStockNumberEnabled}
                            className={`${
                                stockNumberEnabled ? 'bg-ink-navy' : 'bg-gray-200'
                            } relative inline-flex items-center h-5 rounded-full w-10`}
                            >
                            <span className="sr-only">Enable Stock Number</span>
                            <span
                                className={`transform transition ease-in-out duration-200 ${
                                    stockNumberEnabled ? 'translate-x-6' : 'translate-x-1'
                                } inline-block w-3 h-3 transform bg-white rounded-full`}
                            />
                        </Switch>
                    </div>
                </div>
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">In Stock Message</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder="Your stock message here..."></textarea>

                    <div className="my-3">
                        <label className="text-gray-500 font-sofia-pro mb-2 text-sm mr-5">Show in-stock Message </label>
                        <Switch
                            checked={inStockMessageEnabled}
                            onChange={setInStockMessageEnabled}
                            className={`${
                                inStockMessageEnabled ? 'bg-ink-navy' : 'bg-gray-200'
                            } relative inline-flex items-center h-5 rounded-full w-10`}
                            >
                            <span className="sr-only">Enable In Stock Message</span>
                            <span
                                className={`transform transition ease-in-out duration-200 ${
                                    inStockMessageEnabled ? 'translate-x-6' : 'translate-x-1'
                                } inline-block w-3 h-3 transform bg-white rounded-full`}
                            />
                        </Switch>
                    </div>
                </div>
            </div>

            <div className="w-full block lg:flex flex-row gap-x-4 my-3">
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Limited Stock Message</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder="Your stock message here..."></textarea>
                    <div className="my-2">
                        <label className="text-gray-500 font-sofia-pro mb-2 text-sm mr-5">Show Limited Stock Message </label>
                        <Switch
                            checked={showLimitedStockMessage}
                            onChange={setShowLimitedStockMessage}
                            className={`${
                                showLimitedStockMessage ? 'bg-ink-navy' : 'bg-gray-200'
                            } relative inline-flex items-center h-5 rounded-full w-10`}
                            >
                            {/* <span className="sr-only">Enable In Stock Message</span> */}
                            <span
                                className={`transform transition ease-in-out duration-200 ${
                                    showLimitedStockMessage ? 'translate-x-6' : 'translate-x-1'
                                } inline-block w-3 h-3 transform bg-white rounded-full`}
                            />
                        </Switch>
                    </div>
                    <div className="my-2">
                        <label className="text-gray-500 font-sofia-pro mb-2 text-sm">Show Number of Stock on Limited Stock Message </label>
                        <Switch
                            checked={showNumberInLimitedStockMessage}
                            onChange={setShowNumberInLimitedStockMessage}
                            className={`${
                                showNumberInLimitedStockMessage ? 'bg-ink-navy' : 'bg-gray-200'
                            } relative inline-flex items-center h-5 rounded-full w-10`}
                            >
                            {/* <span className="sr-only">Enable In Stock Message</span> */}
                            <span
                                className={`transform transition ease-in-out duration-200 ${
                                    showNumberInLimitedStockMessage ? 'translate-x-6' : 'translate-x-1'
                                } inline-block w-3 h-3 transform bg-white rounded-full`}
                            />
                        </Switch>
                    </div>
                </div>
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Click & Collect Only Message</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder="Your stock message here..."></textarea>
                </div>
            </div>

            <div className="w-full block lg:flex flex-row gap-x-4 my-3">
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Special Order Message</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder="Please contact us for pre-order availability"></textarea>
                </div>
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Out of Stock Message</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder="Out of Stock"></textarea>
                </div>
            </div>

            <div className="w-full block lg:flex flex-row gap-x-4 my-3">
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Out of Stock (List view)</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder="Out of Stock"></textarea>
                </div>
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Warehouse Leadtime</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder="Type message here..."></textarea>
                </div>
            </div>

            <div className="w-full block lg:flex flex-row gap-x-4 my-3">
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Warehouse Leadtime (List view)</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder="Out of Stock"></textarea>
                </div>
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Sale Terminology</label>
                    <select className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500">
                        <option>Sale</option>
                        <option>Deal</option>
                        <option>Discount</option>
                    </select>
                </div>
            </div>

            <div className="w-full block lg:flex flex-row gap-x-4 mt-3 pb-8 border-b border-gray-300 mb-8">
                <div className="w-full">
                    <label className="block text-ink-navy font-medium font-sofia-pro mb-2 text-sm">Top Bar (HTML)</label>
                    <textarea className="w-full rounded border border-gray-300 px-4 py-3 focus:outline-none resize-none focus:border-gray-600 text-xs font-sofia-pro placeholder-gray-500 h-16" placeholder=""></textarea>
                </div>
            </div>

            <div className="w-full pb-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Shopping Basket (Top)" />
                <div className="flex flex-row justify-between mt-4">
                    <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Appears at the top of the Shopping basket page</label>
                    <button className="text-xs text-gray-400 bg-gray-300 bg-opacity-40 border rounded border-gray-400 px-3">Preview</button>
                </div>
            </div>
            
            <div className="w-full py-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Shopping Basket (Bottom)" />
                <div className="flex flex-row justify-between mt-4">
                    <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Appears at the bottom of the Shopping basket page</label>
                    <button className="text-xs text-gray-400 bg-gray-300 bg-opacity-40 border rounded border-gray-400 px-3">Preview</button>
                </div>
            </div>

            <div className="w-full py-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Returns form text (Top)" />
                <div className="flex flex-row-reverse mt-4">
                    {/* <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Appears at the bottom of the Shopping basket page</label> */}
                    <button className="text-xs text-gray-400 bg-gray-300 bg-opacity-40 border rounded border-gray-400 px-3">Preview</button>
                </div>
            </div>

            <div className="w-full py-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Returns form text (Bottom)" />
                <div className="flex flex-row-reverse mt-4">
                    {/* <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Appears at the bottom of the Shopping basket page</label> */}
                    <button className="text-xs text-gray-400 bg-gray-300 bg-opacity-40 border rounded border-gray-400 px-3">Preview</button>
                </div>
            </div>

            <div className="w-full py-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Packing slip text (Top)" />
                <div className="flex flex-row-reverse mt-4">
                    {/* <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Appears at the bottom of the Shopping basket page</label> */}
                    <button className="text-xs text-gray-400 bg-gray-300 bg-opacity-40 border rounded border-gray-400 px-3">Preview</button>
                </div>
            </div>

            <div className="w-full py-8 border-b border-gray-300">
                <Wysiwyg fieldTitle="Packing slip text (Bottom)" />
                <div className="flex flex-row-reverse mt-4">
                    {/* <label className="block text-gray-400 font-medium font-sofia-pro mb-2 text-sm">Appears at the bottom of the Shopping basket page</label> */}
                    <button className="text-xs text-gray-400 bg-gray-300 bg-opacity-40 border rounded border-gray-400 px-3">Preview</button>
                </div>
            </div>


        </div>
    )
}

export default OnsiteMessaging
