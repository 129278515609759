import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
// import DropdownMenu from '../../elements/DropdownMenu'
// import ProductImage  from '../../../assets/images/bike-image.png'
import ListboxSelect from '../../elements/ListboxSelect'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import DataTable from '../../elements/DataTable'
import DialogLayout from '../../layout/DialogLayout'
import { useCookies } from 'react-cookie';
import axios from "axios"
import { baseUrl, convertCamelCase, yesteryears } from '../../../utils';

const ProductDetails = ({ productDetails }) => {
    let [isOpen, setIsOpen] = useState(false)
    // const [filterTerm, setFilterTerm] = useState('')
    const [displayChildForm, setDisplayChildForm] = useState({
        display: false,
        categoryIndex: null
    })
    
    const [displayGrandchildForm, setDisplayGrandchildForm] = useState({
        display:false,
        categoryindex: null,
        subCategoryIndex: null 
    })
    
    const toggleDisplayChildForm = (categoryIndex) => {
        let display = !displayChildForm.display
        setDisplayChildForm({
            display,
            categoryIndex
        })
    }
    
    const toggleDisplayGrandChildForm = (categoryIndex, childIndex) => {
        let display = !displayGrandchildForm.display
        setDisplayGrandchildForm({
            display,
            categoryIndex,
            childIndex
        })
    }
    
    const categories = [
        {
            displayName: "Bikes",
            categoryId: "bikes",
            selected: false,
            disclosureOpen: false,
            subCategories: [
                {
                    displayName: "Electric Bikes",
                    categoryId: "electric-bikes",
                    selected: false,
                    subCategories: [
                        {
                            displayName: "Electric Mountain",
                            categoryId: "electric-mountain",
                            selected: false,
                            disclosureOpen: false,
                            subCategories: []  
                        },
                        {
                            displayName: "Urban",
                            categoryId: "urban",
                            disclosureOpen: false,
                            selected: false,
                            subCategories: []  
                        },
                        {
                            displayName: "Road/Gravel",
                            categoryId: "road-gravel",
                            selected: false,
                            disclosureOpen: false,
                            subCategories: []  
                        },
                    ]
                },
                {
                    displayName: "Mountain",
                    categoryId: "mountain",
                    selected: false,
                    disclosureOpen: false,
                    subCategories: [
                        {
                            displayName: "Full Suspension",
                            categoryId: "full-suspension",
                            selected: false,
                            disclosureOpen: false,
                            subCategories: []  
                        },
                        {
                            displayName: "Road/Gravel",
                            categoryId: "road-gravel",
                            disclosureOpen: false,
                            selected: false,
                            subCategories: []  
                        },
                    ]
                },
                {
                    displayName: "Kids",
                    categoryId: "kids",
                    selected: false,
                    disclosureOpen: false,
                    subCategories: []
                },
                {
                    displayName: "Hybrid",
                    categoryId: "hybrid",
                    selected: false,
                    disclosureOpen: false,
                    subCategories: []
                },
                {
                    displayName: "Road",
                    categoryId: "road",
                    selected: false,
                    disclosureOpen: false,
                    subCategories: []
                },
            ]
        },
        {
            displayName: "E-Bikes",
            categoryId: "e-bikes",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
        {
            displayName: "Helmets",
            categoryId: "helmets",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
        {
            displayName: "Clothing",
            categoryId: "clothing",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
        {
            displayName: "Accessories",
            categoryId: "accessories",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
        {
            displayName: "Component",
            categoryId: "component",
            selected: false,
            disclosureOpen: false,
            subCategories: []
        },
    ]

    const closeModal = () => {
        // setActiveCategories(categories)
        setSelectedCategories(fetchSelectedCategories())
        setCategoryNotFound(false)
        setIsOpen(false)
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const [selectedCategories, setSelectedCategories] = useState(productDetails.categories || [])
    const [activeCategories, setActiveCategories] = useState(categories)
    const [newCategoryName, setNewCategoryName] = useState("")

    const filterCategories = (term) => {
         setNewCategoryName(term)
        let filtered = activeCategories.filter((category) => {
            console.log(category.displayName.includes(term))
            return category.displayName.includes(term)
        })

        if(filtered.length > 0) {
            setActiveCategories(filtered)
        } else {
            setActiveCategories(categories)
            setCategoryNotFound(true)
        }
    }

    const addCategory = () => {
        const tempCategories = JSON.parse(JSON.stringify(activeCategories))
        tempCategories.push({
            displayName: newCategoryName,
            categoryId: slugify(newCategoryName),
            selected: false,
            disclosureOpen: false,
            subCategories: []  
        })
        setActiveCategories(tempCategories)
        setNewCategoryName("")
    }

    const addChildCategory = (categoryIndex) => {
        const tempCategories = JSON.parse(JSON.stringify(activeCategories))
        tempCategories[categoryIndex].subCategories.push({
            displayName: newCategoryName,
            categoryId: slugify(newCategoryName),
            selected: false,
            disclosureOpen: false,
            subCategories: []  
        })
        setActiveCategories(tempCategories)
        setNewCategoryName("")
    }

    const addGrandchildCategory = (categoryIndex, childIndex) => {
        const tempCategories = JSON.parse(JSON.stringify(activeCategories))
        tempCategories[categoryIndex].subCategories[childIndex].subCategories.push({
            displayName: newCategoryName,
            categoryId: slugify(newCategoryName),
            selected: false,
            disclosureOpen: false,
            subCategories: []  
        })
        setActiveCategories(tempCategories)
        setNewCategoryName("")
    }

    const toggleCategorySelection = (categoryIndex) => {
        const tempCategories = JSON.parse(JSON.stringify(activeCategories))

        tempCategories[categoryIndex].selected = !tempCategories[categoryIndex].selected

        if(tempCategories[categoryIndex].subCategories && tempCategories[categoryIndex].subCategories.length > 0 && !tempCategories[categoryIndex].selected) {
            tempCategories[categoryIndex].subCategories.forEach((subCategory) => {
                subCategory.selected = false
                if(subCategory.subCategories && subCategory.subCategories.length > 0) {
                    subCategory.subCategories.forEach((subSubCategory) => {
                        subSubCategory.selected = false
                    })
                }
            })
        }

        setActiveCategories(tempCategories)
        setSelectedCategories(fetchSelectedCategories())
    }

    const toggleChildCategorySelection = (categoryIndex, childIndex) => {
        const tempCategories = JSON.parse(JSON.stringify(activeCategories))

        tempCategories[categoryIndex].selected = true
        tempCategories[categoryIndex].subCategories[childIndex].selected = !tempCategories[categoryIndex].subCategories[childIndex].selected
        
        if(tempCategories[categoryIndex].subCategories[childIndex].subCategories && tempCategories[categoryIndex].subCategories[childIndex].subCategories.length > 0 && !tempCategories[categoryIndex].subCategories[childIndex].selected) {
            tempCategories[categoryIndex].subCategories[childIndex].subCategories.forEach((subCategory) => {
                subCategory.selected = false
            })
        }

        setActiveCategories(tempCategories)
        setSelectedCategories(fetchSelectedCategories())
    }

    const toggleGrandChildCategorySelection = (categoryIndex, childIndex, grandChildIndex) => {
        const tempCategories = JSON.parse(JSON.stringify(activeCategories))
        tempCategories[categoryIndex].selected = true
        tempCategories[categoryIndex].subCategories[childIndex].selected = true

        tempCategories[categoryIndex].subCategories[childIndex].subCategories[grandChildIndex].selected = !tempCategories[categoryIndex].subCategories[childIndex].subCategories[grandChildIndex].selected

        setActiveCategories(tempCategories)
        setSelectedCategories(fetchSelectedCategories())
    }

    const fetchSelectedCategories = () => {
        const selected = []
        activeCategories.forEach((category, categoryIndex) => {
            let tempCategory = {}
            if(category.selected === true) {
                tempCategory.category = {displayName: category.displayName, categoryIndex }
                // push the object if drill down ended there
                if(!category.subCategories || category.subCategories.length === 0) {
                    selected.push(tempCategory)
                }

                category.subCategories.forEach((child, childIndex) => {
                    let tempChild = {}
                    if(child.selected === true) {
                        tempChild.category = {displayName: category.displayName, categoryIndex}
                        tempChild.subCategory = {displayName: child.displayName, subCategoryIndex: childIndex}
                        // push the object if drill down ended there
                        if(!child.subCategories || child.subCategories.length === 0) {
                            selected.push(tempChild)
                        }
                        child.subCategories.forEach((grandChild, grandChildIndex) => {
                            let tempGrandChild = {}
                            if(grandChild.selected === true) {
                                tempGrandChild.category = {displayName: category.displayName, categoryIndex}
                                tempGrandChild.subCategory = {displayName: child.displayName, subCategoryIndex: childIndex}
                                tempGrandChild.subSubCategory = {displayName: grandChild.displayName, subCategoryIndex: grandChildIndex}
                                selected.push(tempGrandChild)
                            }
                        })
                    // }
                    }
                })  
            }
        })
        return selected
    }

    const sortOptions = [
        {
            name: "Ascending",
            icon: null,
            link: "/",
            action: null
        },
        {
            name: "Descending",
            icon: null,
            link: "/",
            action: null
        },
    ]

    const slugify = (text) => {
        return text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
    }

    const [categoryNotFound, setCategoryNotFound] = useState(false)

    // const productVariations = [
    //     {
    //         id: "192590",
    //         image: ProductImage,
    //         sku: "14156",
    //         barcode: "4054571245243",
    //         colour: "blu/lim",
    //         simpleColour: "Blue",
    //         stock: "1",
    //         size: "T54",
    //         rrp: "£2199.00",
    //         salePrice: ""
    //     },
    //     {
    //         id: "191599",
    //         image: ProductImage,
    //         sku: "6307190",
    //         barcode: "4054571245175",
    //         colour: "blk/red",
    //         simpleColour: "Red",
    //         stock: "1",
    //         size: "T54",
    //         rrp: "£2199.00",
    //         salePrice: ""
    //     },
    //     {
    //         id: "191548",
    //         image: ProductImage,
    //         sku: "W403V2-1",
    //         barcode: "4054571245182",
    //         colour: "blk/red",
    //         simpleColour: "White",
    //         stock: "0",
    //         size: "T54",
    //         rrp: "£2199.00",
    //         salePrice: ""
    //     },
    //     {
    //         id: "192590",
    //         image: ProductImage,
    //         sku: "14156",
    //         barcode: "4054571245243",
    //         colour: "blk/lim",
    //         simpleColour: "Blue",
    //         stock: "1",
    //         size: "T54",
    //         rrp: "£2199.00",
    //         salePrice: ""
    //     },
    //     {
    //         id: "191599",
    //         image: ProductImage,
    //         sku: "6307190",
    //         barcode: "4054571245175",
    //         colour: "blk/lim",
    //         simpleColour: "Red",
    //         stock: "3",
    //         size: "T54",
    //         rrp: "£2199.00",
    //         salePrice: ""
    //     },
    //     {
    //         id: "191548",
    //         image: ProductImage,
    //         sku: "W403V2-1",
    //         barcode: "4054571245182",
    //         colour: "blu/lim",
    //         simpleColour: "White",
    //         stock: "0",
    //         size: "T54",
    //         rrp: "£2199.00",
    //         salePrice: ""
    //     },
    // ]



    const tableHeadersFields = (sampleObject) => {
        if(!sampleObject) {
            return []
        }
        const headers = []
        const fields = []
        Object.keys(sampleObject).forEach((key, index)=>{
            let columnDataType = 'text'
            let forPopover = false
            let columnDisplayName = convertCamelCase(key)
            let sortable = true
            let column = key
            // if(key === 'image') {
            //     columnDataType = 'image'
            // }
            if(key === 'sku' || key === 'salePrice' || key === 'image') {
                sortable = false
            }

            headers.push({
                column,
                columnDisplayName,
                data: sampleObject[key],
                sortable,
                forPopover,
                columnDataType
            })

            let fieldSelected = true

            if(index > 10) {
                fieldSelected = false
            }
            fields.push({
                name: columnDisplayName,
                selected: fieldSelected
            })
        });

        return {headers, fields}

    }

    const columnWidths = {
        id: "w-full lg:w-1/12",
        image: "w-full lg:w-1/12",
        sizes: "w-full lg:w-1/12",
        barcode: "w-full lg:w-2/12",
        colour: "w-full lg:w-1/12",
        simpleColour: "w-full lg:w-2/12",
        stock: "w-full lg:w-1/12",
        assets: "w-full lg:w-1/12",
        rrp: "w-full lg:w-1/12",
        salePrice: "w-full lg:w-1/12"
    }

    const tableOptions = {
        selectable: false
    }


    const getVariationSctock = (variation) => {
        let stock = 0 
            variation.sizes.forEach((size, sizeIndex)=> {
                if (size.stock && size.stock.length > 0) {
                    size.stock.forEach((stockItem, stockItemIndex) => {
                        stock += stockItem.stock_qty
                    })
                }
            })
        return stock
    }

    const getVariationImages = (variation) => {
        let images = [] 
            if(variation.assets && variation.assets.length > 0) {
                variation.assets.forEach((asset) => {
                    if(asset.type === 'image') {
                        images.push(`https://obj1-dev.s3.eu-west-2.amazonaws.com${asset.path}`)
                    }
                })
            }
        return images
    } 

    const cleanupVariations = (variations) => {
        let finalResult = [] 
        variations.forEach((variation, productIndex) => {
            const variationObject = {
                id: variation.id,
                image:  <img src={getVariationImages(variation)[0]} alt="" className='h-12' />,
                sizes: variation.sizes.length,
                barcode: "",
                colour: variation.colour,
                simpleColour: variation.colour,
                stock: getVariationSctock(variation),
                assets: variation.assets && variation.assets.length > 0 ? 
                    <button onClick={()=>{openAssets(variation.assets)}} className="flex gap-x-2 items-center"> 
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        {variation.assets.length} 
                    </button> : 
                    0,
                rrp: "",
                salePrice: ""
            }
            finalResult.push(variationObject)
        })
        console.log('final result ===> ', finalResult)
        return finalResult
    }

    const [sizesActive, setSizesActive] = useState(false)
    const [sizesArray, setSizesArray] = useState([])

    const openSizes = (sizes) => {
        setAssetsArray(sizes)
        setAssetsActive(true)
    }

    const closeSizes = () => {
        setSizesArray([])
        setSizesActive(false)
    }

    const VariationSizesModal = ({sizes}) => {
        return (
            <></>
        )
    }

    const [assetsActive, setAssetsActive] = useState(false)
    const [assetsArray, setAssetsArray] = useState([])

    const openAssets = (assets) => {
        setAssetsArray(assets)
        setAssetsActive(true)
    }

    const closeAssets = () => {
        setAssetsArray([])
        setAssetsActive(false)
    }

    const VariationAssetsModal = () => {
        return (
            <>
                <div className='grid grid-cols-2 lg:grid-cols-3 gap-4'>
                    {assetsArray.map((asset, assetIndex)=> (
                        <img key={assetIndex} src={`https://obj1-dev.s3.eu-west-2.amazonaws.com${asset.path}`} alt="" />
                    ))}
                </div>
            </>
        )
    }

    const removeSelectedCategory = (category) => {
        toggleCategorySelection(category.category.categoryIndex)
        if(category.subCategory) {
            toggleChildCategorySelection(category.category.categoryIndex, category.subCategory.subCategoryIndex)
        }

        if(category.subSubCategory){
            toggleGrandChildCategorySelection(category.category.categoryIndex, category.subCategory.subCategoryIndex, category.subSubCategory.subCategoryIndex)
        }
    }
    
    const [error, setError] = useState('')  
    const [brands, setBrands] = useState(null)
    const [productName, setProductName] = useState(productDetails.name)
    const [productYear, setProductYear] = useState(productDetails.year)
    const [brand, setBrand] = useState(productDetails.brand_id)
    const [variations, setVariations] = useState(productDetails.colours || [])
    const [ cookies ] = useCookies(['user']);
    const [fetching, setFetching] = useState(true)

    useEffect(() => {
        const fetchBrands = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${cookies.tokenType} ${cookies.accessToken}` 
            }
            setFetching(true)
            try {
                
                const response = await axios.get(`/api/v1/brands/?page[offset]=1&page[limit]=1000000&page[order]=desc`, { headers })            
                console.log(response)
                setBrands(response.data.data)
                setFetching(false)
            } catch (error) {
                console.error(error)
                setError(error.response.data.msg)
                setFetching(false)
            }
        }

        fetchBrands()
        
    }, [cookies.accessToken, cookies.tokenType])

    return (
        <Fragment>
            <div className="w-full block md:flex flex-row gap-x-4">
                <div className="w-full">
                    <label className="text-xs text-ink-navy font-medium mb-2 block">Product Name</label>
                    <input type="text" placeholder="The name of the product" className="rounded border-gray-300 focus:border-gray-900 text-xs px-4 py-3 w-full border focus:outline-none transition duration-200" value={productName} onChange={(e) => {setProductName(e.target.value)}} />

                    <div className="w-full flex flex-row gap-x-2 mt-5 mb-5">
                        <div className="w-2/3">
                            <label className="text-xs text-ink-navy font-medium mb-2 block">Brand</label>
                            {/* <input type="text" placeholder="Product brand" className="rounded border-gray-300 focus:border-gray-900 text-xs px-4 py-3 w-full border focus:outline-none transition duration-200" value={brand} onChange={(e) => {setBrand(e.target.value)}} /> */}
                            {brands && brands.length > 0 ? <div className="select-wrapper border rounded border-gray-00 px-4 py-3 text-xs w-full focus:outline-none focus:border-gray-300 transition duration-200">
                                <select className="outline-none w-full" value={brand} onChange={(e)=>setBrand(e.target.value)}>
                                    <option value="">Select brand</option>

                                    {brands.map((brand, brandIndex) => (
                                        <option key={brandIndex} value={brand.id}>{brand.name}</option>
                                    ))}
                                </select> 
                            </div> :
                            <input type="text" disabled placeholder="Loading brands..." className="rounded border-gray-300 focus:border-gray-900 text-xs px-4 py-3 w-full border focus:outline-none transition duration-200" />
                            }
                        </div>
                        <div className="w-1/3">
                            <label className="text-xs text-ink-navy font-medium mb-2 block">Year</label>
                            {/* <input type="text" placeholder="Year of production" className="rounded border-gray-300 focus:border-gray-900 text-xs px-4 py-3 w-full border focus:outline-none transition duration-200" /> */}

                            <div className="select-wrapper rounded border-gray-300 focus:border-gray-900 text-xs px-4 py-3 w-full border focus:outline-none transition duration-200">
                                <select className="outline-none border-none" value={productYear} onChange={(e) => {setProductYear(e.target.value)}}>        
                                    <option value="">Select year</option>
                                        {yesteryears(20).map((year, yearIndex)=>(
                                            <option key={yearIndex} value={year.name}>{year.name}</option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    <label className="text-xs text-ink-navy font-medium mb-2 block">
                        Categories 
                        <svg xmlns="http://www.w3.org/2000/svg" className="inline ml-1 -mt-1 h-5 w-5 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                        </svg>
                    </label>
                    {selectedCategories.length > 0 && 
                        selectedCategories.map((category, categoryIndex) => (
                            <div key={categoryIndex} className="my-2 bg-gray-100 px-2 py-1 w-full rounded flex flex-row gap-x-1 relative">
                               <span className="bg-gray-500 px-3 py-2 text-white rounded text-xs text-wite font-sofia-pro">{category.category}</span>
                               {category.children && category.children.length > 0 && 
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 mt-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="bg-gray-400 px-3 py-2 rounded text-white text-xs font-sofia-pro">{category.children[0].category}</span>
                                </>
                               }
                               {category.children[0].children && category.children[0].children.length > 0 &&
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 mt-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="bg-gray-300 px-3 py-2 rounded text-white text-xs font-sofia-pro">{category.children[0].children[0].category}</span>
                                </>
                               }

                               <button onClick={()=>{removeSelectedCategory(category)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hover:text-gray-400 text-gray-500 transition duration-200 absolute right-2 top-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                               </button>
                            </div>
                        ))
                    }

                    <button onClick={openModal} className="rounded border-gray-900 text-xs px-4 py-2 border transition duration-200 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                        Add Category
                    </button>
                </div>
            </div>

            <div className="w-full">
                {variations && variations.length > 0 ? <DataTable
                    tableHeaders={tableHeadersFields(cleanupVariations(variations)[0]).headers} 
                    // tableHeaders={tableHeadersFields(cleanupVariations(productDetails.)[0]).headers} 
                    tableData={cleanupVariations(variations)} 
                    columnWidths={columnWidths}
                    columnDataStyles={{}}
                    allFields={tableHeadersFields(cleanupVariations(variations)[0]).fields}
                    // onSelectItems={getSelectionCount}
                    tableOptions={tableOptions}
                /> : 
                <div className='bg-gray-100 rounded border border-gray-400 text-ink-navy text-center w-2/3 ml-auto mr-auto p-4 text-xs my-8'>
                    <p>No product variations available</p>
                </div>
                }
            </div>

            <DialogLayout dialogOpen={assetsActive} closeDialog={()=>{closeAssets()}} dialogTitle="Product Assets">
                <VariationAssetsModal />
            </DialogLayout>

            <>
                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-20 transition duration-200" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                            <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded relative">
                                <button className="absolute top-4 right-4" onClick={()=>{closeModal()}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 hover:text-gray-700 transition duration-200" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Select Category
                                </Dialog.Title>
                                <div className="mt-2">
                                    <div className="flex flex-row items-center justify-between w-full gap-x-3">
                                        <div className="w-9/12 flex flex-row items-center rounded border-gray-300 focus:border-gray-900 text-xs px-3 py-2 border focus:outline-none transition duration-200 relative">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                            </svg>
                                            <input className="focus:outline-none" placeholder="Search category" onChange={(e) => filterCategories(e.target.value)} />
                                            {categoryNotFound && 
                                                <button className="rounded-full text-gray-500 bg-gray-200 px-2 right-2 absolute" onClick={()=>{addCategory()}}> 
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 inline" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                                    </svg>
                                                    add category
                                                </button>
                                            }
                                        </div>
                                        <div className="w-3/12 -mt-1">
                                            <ListboxSelect 
                                                listOptions={sortOptions}
                                                buttonClasses="text-xs rounded border border-gray-300 text-ink-navy font-medium font-sofia-pro inline-block px-3 py-2 transition duration-200" 
                                            />
                                        </div>
                                    </div>

                                    <div className="w-full py-4">
                                        {activeCategories.map((category, categoryIndex) => (
                                            <div className="relative border-b py-2 pl-8" key={categoryIndex}>
                                                <input type="checkbox" checked={category.selected} onChange={()=>toggleCategorySelection(categoryIndex)} className="absolute left-1 top-4" />
                                                <Disclosure>
                                                    {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="pt-2 flex justify-between w-3/5 bg-transparent text-sm text-left text-gray-500 focus:outline-none focus-visible:ring-opacity-75 hover:text-ink-navy">
                                                            <span>{category.displayName}</span>
                                                            <ChevronUpIcon
                                                                className={`${
                                                                open ? '' : 'transform rotate-180'
                                                                } w-5 h-5 text-gray-500`}
                                                            />
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel className="px-4 pt-2 pb-0 text-sm text-gray-500">
                                                            {category.subCategories.map((subCategory, subCategoryIndex) => (
                                                                subCategory.subCategories && subCategory.subCategories.length > 0 ?
                                                                <div className="relative" key={subCategoryIndex}>
                                                                    <input type="checkbox" checked={subCategory.selected} className="absolute -left-5 top-2" onChange={()=>{toggleChildCategorySelection(categoryIndex, subCategoryIndex)}} />
                                                                    <Disclosure key={subCategoryIndex}>
                                                                        {({ open }) => (
                                                                        <>
                                                                            <Disclosure.Button className="pt-2 flex justify-between w-3/5 bg-transparent text-sm text-left text-gray-500 focus:outline-none focus-visible:ring-opacity-75 hover:text-ink-navy">
                                                                                <span>{subCategory.displayName}</span>
                                                                                <ChevronUpIcon
                                                                                    className={`${
                                                                                    open ? '' : 'transform rotate-180'
                                                                                    } w-5 h-5 text-gray-500`}
                                                                                />
                                                                            </Disclosure.Button>
                                                                            <Disclosure.Panel className="pl-6 pt-2 pb-0 text-sm text-gray-500">
                                                                                {subCategory.subCategories.map((subSubCategory, subSubCategoryIndex) => (
                                                                                    <div key={subSubCategoryIndex} className="flex flex-row relative">
                                                                                        <input type="checkbox" checked={subSubCategory.selected} className="absolute -left-5 top-1" onChange={()=>{toggleGrandChildCategorySelection(categoryIndex, subCategoryIndex, subSubCategoryIndex)}} />
                                                                                        <p className="block py-1 hover:text-ink-navy">{subSubCategory.displayName}</p>  
                                                                                    </div>
                                                                                ))}
                                                                                {displayGrandchildForm && displayGrandchildForm.categoryIndex === categoryIndex && displayGrandchildForm.childIndex === subCategoryIndex &&
                                                                                    <div className="flex flex-row gap-x-2">
                                                                                        <input className="px-1 py-2 text-xs border border-gray-400 rounded hover:outline-none w-full focus:outline-none focus:border-gray-500" placeholder="category name" />
                                                                                        <button className="px-2 py-1 bg-gray-400 border border-gray-400 hover:bg-gray-500 rounded inline text-xs text-white">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-1 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                                                                <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                                                                            </svg>
                                                                                        </button>
                                                                                        <button className="px-2 py-1 bg-white border border-white hover:border-gray-400 rounded inline text-xs text-gray-400">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                            </Disclosure.Panel>
                                                                        </>
                                                                        )}
                                                                        
                                                                    </Disclosure> 

                                                                    <button className="absolute right-2 top-1" onClick={()=>{toggleDisplayGrandChildForm(categoryIndex, subCategoryIndex)}}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-1  text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                                                        </svg>
                                                                    </button>
                                                                </div>

                                                                :
                                                                
                                                                <div key={subCategoryIndex} className="relative">
                                                                    <input type="checkbox" checked={subCategory.selected} className="absolute -left-5 top-2" onChange={()=>{toggleChildCategorySelection(categoryIndex, subCategoryIndex)}} />
                                                                    <p className="block py-1 hover:text-ink-navy">{subCategory.displayName}</p>  
                                                                    <button className="absolute right-2 top-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-1  text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            ))}
                                                            
                                                        </Disclosure.Panel>
                                                    </>
                                                    )}
                                                </Disclosure> 
                                                 <button className="absolute right-2 top-1" onClick={()=>{toggleDisplayChildForm(categoryIndex)}}>
                                                     <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                         <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                                     </svg>
                                                 </button>
                                                 {displayChildForm && displayChildForm.display === true && displayChildForm.categoryIndex === categoryIndex &&
                                                    <div className="flex flex-row gap-x-2">
                                                        <input className="px-1 py-2 text-xs border border-gray-400 rounded hover:outline-none w-full focus:outline-none focus:border-gray-500" placeholder="category name" onChange={(e) => setNewCategoryName(e.target.value)} />
                                                        <button className="px-2 py-1 bg-gray-400 border border-gray-400 hover:bg-gray-500 rounded inline text-xs text-white" onClick={()=>{addChildCategory(categoryIndex)}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-1 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                                            </svg>
                                                        </button>
                                                        <button className="px-2 py-1 bg-white border border-white hover:border-gray-400 rounded inline text-xs text-gray-400" onClick={()=>{toggleDisplayChildForm(categoryIndex)}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </>
        </Fragment>
    )
}

export default ProductDetails
